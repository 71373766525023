import React, { FC, useMemo, useRef } from 'react';

import { FormattedMessage } from 'react-intl';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { useBackgroundTask } from 'components/BackgroundTaskModal/hooks';

interface Props {
  backgroundTaskKey: string;
  onTaskCompleted?: (data: unknown) => void;
  onTaskError?: () => void;
}

const OutdatedDashboardBanner: FC<Props> = ({
  backgroundTaskKey,
  onTaskCompleted = () => {},
  onTaskError = () => {},
}) => {
  const helperTextRef = useRef<HTMLElement>(null);
  const taskDescriptor = useMemo(
    () => ({
      task_key: backgroundTaskKey,
      status: 'submitted',
    }),
    [backgroundTaskKey]
  );

  useBackgroundTask({
    taskDescriptor,
    onTaskCompleted: onTaskCompleted,
    onError: onTaskError,
    disabled: !backgroundTaskKey,
  });

  return (
    <div className="text-muted text-end pt-4" id="data-delayed-indicator">
      <span ref={helperTextRef}>
        <FormattedMessage
          id="app.views.widgets.dashboards.message.data_delayed"
          defaultMessage="Data is not real-time."
        />
        <i className="ms-2 fe fe-help-circle" />
      </span>
      <UncontrolledPopover
        placement="top"
        trigger="hover"
        target={helperTextRef}
      >
        <FormattedMessage
          id="app.views.widgets.dashboards.message.data_delayed_popover"
          defaultMessage="The page is currently fetching the latest real-time data in the background. Once this message disappears, the displayed information will be up to date."
        />
      </UncontrolledPopover>
    </div>
  );
};

export default OutdatedDashboardBanner;
