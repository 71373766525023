import * as consts from '../../consts/consts';

import {
  activitiesInHomepageFeedIsEnabled,
  continuousFeedbackInHomepageFeedIsEnabled,
  pulseChecksInHomepageFeedIsEnabled,
} from '../util/features';
import {
  getUserLocalStorage,
  removeUserLocalStorage,
  setUserLocalStorage,
} from '../models/User';

import axios, { type AxiosInstance } from 'axios';
import { defaultBackoffRetryConfig } from './axiosRetry';
import { isWhiteListedErrorMessage } from 'utils/util/error';

type ElasticsearchAPIType = {
  getObjectName: (object: any, index: string) => string;
  getObjectUrl: (object: any, index: string) => string;
  defaultSelectorMapFunction: (hit: any) => any;
  sendRequest: (
    method: string,
    url: string,
    data: any,
    callback: (
      data: any,
      error?: any,
      hardErrorMessage?: string | null
    ) => void,
    preSubmit?: (() => void) | null,
    additionalHeaders?: any
  ) => void;
  search: (
    auth0UserSubForCaching: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    urlPart: string,
    data: any,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void,
    ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists?: number | null
  ) => void;
  getPeopleWithDeclaredSkill: (
    auth0UserSub: string,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    skillId: string,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getPeopleWithCredential: (
    auth0UserSub: string,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    credentialId: string,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getActivitiesForContributorPerson: (
    auth0UserSub: string,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    personId: string,
    query: string,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void,
    showManagerOnlyPerformanceDetails: boolean
  ) => void;
  getActivitiesRelatedToActivity: (
    auth0UserSub: string,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    activity: any,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getActivitiesForSkill: (
    auth0UserSub: string,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    skillId: string,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getContributionsForActivity: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    activityId: string,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getContributionsForSkill: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    skillId: string,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getContributionTasks: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getSentInvitations: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getHighlightsFeed: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    features: any,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getFeedbackFeed: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    params: any,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getSentFeedbackRequests: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getNewOpportunities: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getRecommendedGroups: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getMostActiveGroups: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getMostContributedActivities: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getParticipants: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    campaignId: string,
    personId: string,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getPeopleByName: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    queryParams: any,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void,
    localCacheTTL: number
  ) => void;
  getPackets: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    optionalParams: any,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
  getPulseChecksForPerson: (
    auth0UserSub: string | null | undefined,
    currentProxyPerson: any,
    orgId: string | number | null | undefined,
    personId: string,
    setDataCallback: (data: any) => void,
    errorMessageCallback: (error: any) => void
  ) => void;
} & AxiosInstance;

// for sending requests to Confirm server
// @ts-expect-error
const ElasticsearchAPI: ElasticsearchAPIType = axios.create({
  baseURL: process.env.REACT_APP_CONFIRM_API_URL,
  responseType: 'json',
});

ElasticsearchAPI.getObjectName = (object, index) => {
  switch (index) {
    case 'activities':
      return object.name;
    case 'people':
      return object.full_name;
    case 'skills':
      return object.name;
    case 'credentials':
      return object.name;
    case 'organizations':
      return object.name;
    // aliasing the index will return objectives-<timestamp>
    case index?.match(/^objectives/)?.input ?? false:
      return object.name;
    case 'survey_responses':
      return object.email;
    default:
      console.error('Unrecognized Elasticsearch index: ' + index);
      return object.id;
  }
};

ElasticsearchAPI.getObjectUrl = (object, index) => {
  switch (index) {
    case 'activities':
      return consts.ACTIVITIES().path + '/' + object.id;
    case 'people':
      return object.url;
    case 'skills':
      return consts.SKILLS().path + '/' + object.id;
    case 'credentials':
      return consts.CREDENTIALS().path + '/' + object.id;
    case 'organizations':
      return consts.ORGANIZATIONS().path + '/' + object.id;
    case index?.match(/^objectives/)?.input ?? false:
      // no URL endpoint for individual objectives
      return `${object.owner_person.url}/objectives?date=${object.coverage_start_date}`;
    case 'survey_responses':
      return consts.PEOPLE_SUBROUTE().path + '/' + object.id;
    default:
      console.error('Unrecognized Elasticsearch index: ' + index);
      return '/404';
  }
};

// function for passing data in for dropdowns and other selection menus
ElasticsearchAPI.defaultSelectorMapFunction = (hit) => {
  return {
    _index: hit._index,
    id: hit._source.id,
    // above objects have name field except users with full name
    name: ElasticsearchAPI.getObjectName(hit._source, hit._index),
    url: ElasticsearchAPI.getObjectUrl(hit._source, hit._index),
    object: hit._source,
  };
};

// convenience function for handling request response and
// returning clean objects back
ElasticsearchAPI.sendRequest = (
  method,
  url,
  data,
  callback,
  preSubmit = null,
  additionalHeaders = null
) => {
  try {
    // run presubmit function if one was provided
    if (preSubmit) {
      preSubmit();
    }

    // set any optional headers if provided
    const config = additionalHeaders
      ? { headers: additionalHeaders, retryConfig: defaultBackoffRetryConfig() }
      : { retryConfig: defaultBackoffRetryConfig() };

    if (method !== 'POST') {
      throw new Error(
        `Invalid method provided for ElasticsearchAPI.sendRequest: ${method} (only POST is allowed)`
      );
    }
    const requestFunction = () => ElasticsearchAPI.post(url, data, config);
    // submit request
    requestFunction()
      .then((response) => {
        const status = response.status;

        if (status === 200 || status === 201 || status === 204) {
          const data = status === 204 && !response.data ? {} : response.data;

          if (Object.prototype.hasOwnProperty.call(data, 'error')) {
            // soft failure; surface details
            callback(null, data.error);
          } else {
            // pass the new data back to the callback
            callback(data);
          }
        } else {
          // unexpected response status; hard failure
          callback(null, response, 'Error ' + status);
        }
      })
      .catch((error) => {
        // Error
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */

          // NOTE: we want this to be null as in the case of a soft failure (e.g. a 400 validation error due to bad
          // input provided by the user, we do NOT want to pass back a hard error message as we don't want to show that
          // in the UI; we want to just send the data so it is parsed and shown in the UI field-by-field)
          let errorMessage = null;

          // if any other type of custom error was provided, use that
          if (error.response.data) {
            if (error.response.data.detail) {
              errorMessage = error.response.data.detail;
            } else if (error.response.data.message) {
              errorMessage = error.response.data.message;
            }
          } else {
            if (error.response.detail) {
              errorMessage = error.response.detail;
            } else if (error.response.message) {
              errorMessage = error.response.message;
            } else if (error.response.statusText) {
              errorMessage = error.response.statusText;
            } else {
              errorMessage = error.toString();
            }
          }

          callback(null, error.response, errorMessage);
        } else if (error.request) {
          // hard failure; take it from detail or message field if it exists, else convert to string
          const errorMessage = error.toString();

          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          if (isWhiteListedErrorMessage(errorMessage)) {
            console.warn('Request error: ' + errorMessage);
          } else {
            console.error('Request error: ' + errorMessage);
          }
          callback(null, error, errorMessage);
        } else {
          // if this is a token refresh, don't return anything (so screen doesn't change)
          if (
            error.error !== 'login_required' &&
            error.error !== 'consent_required'
          ) {
            // hard failure; take it from detail or message field if it exists, else convert to string
            const errorMessage = error.toString();

            // Something happened in setting up the request and triggered an Error
            console.error('Unexpected error: ' + errorMessage);
            callback(null, error, errorMessage);
          }
        }
      });
  } catch (error) {
    // hard failure
    // @ts-expect-error
    const errorMessage = error.toString();
    console.error('Error: ' + errorMessage);
    callback(null, error, errorMessage);
  }
};

ElasticsearchAPI.search = (
  auth0UserSubForCaching,
  currentProxyPerson,
  orgId,
  urlPart,
  data,
  setDataCallback,
  errorMessageCallback,
  // WARNING: passing in this flag means that it will pull from the
  // cache and NOT request from the server if it's in the cache; if
  // you want to instead first pull from the cache and then call
  // the callback again with data from the server, leave this param null/undefined
  ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists = null
) => {
  const url = '/search/' + urlPart;
  const dataToSend = {
    ...data,
    organization_id: orgId,
    proxy: currentProxyPerson ? currentProxyPerson.email : undefined,
  };

  let cacheKey = 'confirmApiUrl::' + url;
  if (dataToSend) {
    // remove undefined values and stringify objects.
    const filteredData = Object.keys(dataToSend).reduce((acc, key) => {
      const _acc = acc;
      if (dataToSend[key] !== undefined) {
        if (dataToSend[key] instanceof Array && dataToSend[key]?.length) {
          // If the key is an array, we sort so the cacheKey is unique.
          const newArray = [...dataToSend[key]];
          newArray.sort();
          // The array could contain objects so we stringify it.
          _acc[key] = JSON.stringify(newArray);
        } else if (dataToSend[key] instanceof Object) {
          // Stringify to avoid URLSearchParams to call toString on
          // an object which results in "[object Object]".
          _acc[key] = JSON.stringify(dataToSend[key]);
        } else {
          _acc[key] = dataToSend[key];
        }
      }
      return _acc;
    }, {});
    if (filteredData && Object.keys(filteredData).length > 0) {
      cacheKey += '?' + new URLSearchParams(filteredData).toString();
    }
  }

  // if auth0 user sub is provided, set data
  // callback from cache first to speed up
  // user perception, then fetch the latest
  // from the server
  if (auth0UserSubForCaching) {
    const cachedResult = getUserLocalStorage(
      auth0UserSubForCaching,
      currentProxyPerson,
      orgId,
      cacheKey,
      !!ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists
    );
    if (cachedResult) {
      setDataCallback(cachedResult);

      // NOTE: we do NOT send a request to the server, we ONLY use the cache
      // if this field is populated; use case: pulling a large object, e.g.
      // a full job catalog dataset that is updated very infrequently, from
      // the server
      if (ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists) {
        return;
      }
    }
  }

  return ElasticsearchAPI.sendRequest(
    'POST',
    url,
    dataToSend,
    (results, error, hardErrorMessage = null) => {
      if (error) {
        // clear cache as this could be a 404 or another type of error,
        // so if object were deleted or changed, we want to clear it out
        removeUserLocalStorage(
          auth0UserSubForCaching,
          currentProxyPerson,
          null,
          cacheKey
        );

        if (hardErrorMessage) {
          // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
          // leave this message blank as those errors will get surfaced below
          errorMessageCallback(hardErrorMessage);
        } else if (error) {
          if (error.data) {
            if (
              error.data.error?.root_cause?.length > 0 &&
              error.data.error.root_cause[0] &&
              error.data.error.root_cause[0].reason
            ) {
              errorMessageCallback(error.data.error.root_cause[0].reason);
            } else {
              errorMessageCallback(error.data);
            }
          } else {
            errorMessageCallback(error);
          }
        }
      } else {
        if (results?.aggregations || results?.hits?.hits) {
          let output;
          if (results?.aggregations && results?.hits?.hits) {
            output = {
              aggregations: results.aggregations,
              hits: results.hits.hits,
              total: results.hits.total.value,
            };
          } else if (results?.aggregations) {
            output = results.aggregations;
          } else {
            output = results.hits.hits;
          }

          // cache result for quicker fetching later
          if (auth0UserSubForCaching) {
            setUserLocalStorage(
              auth0UserSubForCaching,
              currentProxyPerson,
              orgId,
              cacheKey,
              output,
              // @ts-expect-error
              ttlInMillisecondsToFetchFromCacheWithoutRequestIfExists
            );
          }

          return setDataCallback(output);
        } else {
          // unknown error
          removeUserLocalStorage(
            auth0UserSubForCaching,
            currentProxyPerson,
            null,
            cacheKey
          );
          errorMessageCallback(
            'An error occurred and we have been notified so we will fix it soon! ' +
              'Please try again later.'
          );
          console.error('Unknown cache error: ' + JSON.stringify(results));
        }
      }
    }
  );
};

ElasticsearchAPI.getPeopleWithDeclaredSkill = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  skillId,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-people-with-declared-skill',
    {
      skill_id: skillId,
    },
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getPeopleWithCredential = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  credentialId,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-people-with-credential',
    {
      credential_id: credentialId,
    },
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getActivitiesForContributorPerson = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  personId,
  query,
  setDataCallback,
  errorMessageCallback,
  showManagerOnlyPerformanceDetails = false
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-activities-for-contributor-person',
    {
      person_id: personId,
      manager_view: showManagerOnlyPerformanceDetails,
      query: query,
    },
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getActivitiesRelatedToActivity = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  activity,
  setDataCallback,
  errorMessageCallback
) => {
  const contributions = activity.contributions;
  const personIds = contributions
    .filter((c) => c.contributor_person?.id)
    .map((c) => c.contributor_person.id);

  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-activities-related-to-activity',
    {
      activity_id: activity.id,
      person_ids: personIds,
    },
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getActivitiesForSkill = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  skillId,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-activities-for-skill',
    {
      skill_id: skillId,
    },
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getContributionsForActivity = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  activityId,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-contributions-for-activity',
    {
      activity_id: activityId,
    },
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getContributionsForSkill = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  skillId,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-contributions-for-skill',
    {
      skill_id: skillId,
    },
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getContributionTasks = (
  auth0UserSub,
  currentProxyPerson,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    // NOTE: we do not pass in the cache here because we can't safely return a promise twice
    // TODO: fix this
    undefined,
    currentProxyPerson,
    undefined,
    'get-contribution-tasks',
    {},
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getSentInvitations = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-sent-invitations',
    {},
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getHighlightsFeed = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  features,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-highlights-feed',
    {
      activities: activitiesInHomepageFeedIsEnabled(features),
      feedback: continuousFeedbackInHomepageFeedIsEnabled(features),
      pulse_checks: pulseChecksInHomepageFeedIsEnabled(features),
    },
    // NOTE: we include _index here because we need to know which index the hit came from
    (hits) =>
      setDataCallback(hits.map((h) => ({ ...h._source, _index: h._index }))),
    errorMessageCallback
  );
};

ElasticsearchAPI.getFeedbackFeed = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  params,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-feedback-feed',
    params,
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getSentFeedbackRequests = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-sent-feedback-requests',
    {},
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getNewOpportunities = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-new-opportunities',
    {},
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getRecommendedGroups = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-recommended-groups',
    {},
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getMostActiveGroups = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-most-active-groups',
    {},
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getMostContributedActivities = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-most-contributed-activities',
    {},
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getParticipants = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  campaignId,
  personId,
  setDataCallback,
  errorMessageCallback
) => {
  const params = {
    organization_id: orgId,
    campaign_id: campaignId,
    person_id: personId,
  };
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-participants',
    params,
    (result) => {
      if (result?.hits) {
        result.hits = result.hits.map((h) => h._source);
        setDataCallback(result);
      }
    },
    errorMessageCallback
  );
};

ElasticsearchAPI.getPeopleByName = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  queryParams,
  setDataCallback,
  errorMessageCallback,
  localCacheTTL
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-people-by-name',
    queryParams,
    setDataCallback,
    errorMessageCallback,
    localCacheTTL
  );
};

ElasticsearchAPI.getPackets = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  optionalParams = {},
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-packets',
    optionalParams,
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

ElasticsearchAPI.getPulseChecksForPerson = (
  auth0UserSub,
  currentProxyPerson,
  orgId,
  personId,
  setDataCallback,
  errorMessageCallback
) => {
  return ElasticsearchAPI.search(
    auth0UserSub,
    currentProxyPerson,
    orgId,
    'get-pulse-checks-for-person',
    {
      person_id: personId,
    },
    (hits) => setDataCallback(hits.map((h) => h._source)),
    errorMessageCallback
  );
};

export default ElasticsearchAPI;
