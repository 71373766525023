import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  DEVELOPMENT_PLANS,
  ICONS,
  PERFORMANCE,
  PROFILE_TAB_PERFORMANCE,
} from 'consts/consts';
import {
  DEVELOPMENT_PLANS_BASE_URL_PATH,
  DEVELOPMENT_PLAN_INITIAL_SUBMIT_INPUTS,
  DEVELOPMENT_PLAN_STATUSES,
  DevelopmentPlan as DevelopmentPlanType,
  fromApi,
  getDevelopmentPlanStatus,
  toApiSubmit,
} from 'utils/models/DevelopmentPlan';
import {
  Features,
  Organization,
  OrganizationSettings,
  Person,
  ReduxState,
} from 'types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import React, { FC, useCallback, useMemo, useState } from 'react';

import CardHeaderTitle from 'views/Widgets/Cards/CardHeaderTitle';
import ConfirmAPI from 'utils/api/ConfirmAPI';
import ConfirmationDialogModal from 'views/Widgets/Modals/ConfirmationDialogModal';
import DevelopmentPlanHistory from './DevelopmentPlanHistory';
import { INPUT_TYPES } from 'views/Widgets/Inputs/ValidatedInputTypes';
import Loading from 'views/Widgets/Loading';
import Modal from 'components/SafeModal';
import ModalEditor from 'views/Widgets/Modals/ModalEditor';
import ModalEditorButton from 'views/Widgets/Modals/ModalEditorButton';
import Page from 'views/Layout/Pages/Page';
import PersonCard from 'views/Widgets/Cards/PersonCard';
import RichTextEditor from 'views/Widgets/Inputs/RichTextEditor';
import RichTextViewer from 'views/Widgets/Inputs/RichTextViewer';
import { connect } from 'react-redux';
import { getPrettyDate } from 'utils/util/util';
import { loadOrRender } from 'utils/util/formatter';
import { toast } from 'react-toastify';
import { useConfirmApi } from 'utils/api/ApiHooks';

interface Props {
  developmentPlanId: number;
  className?: string;
  inModal: boolean;
  callback?: (arg: DevelopmentPlanType) => void;
  toggle?: () => void;
  onClosed?: () => void;
  meId: number;
  features: Features;
  currentOrganization: Organization;
  currentProxyPerson: Person;
  settings: OrganizationSettings;
}

const DevelopmentPlan: FC<Props> = ({
  developmentPlanId,
  inModal,
  callback,
  currentOrganization,
  settings,
  className,
  toggle,
  onClosed,
  meId,
}) => {
  const { formatMessage, locale } = useIntl();
  const history = useHistory();
  const [showHistory, setShowHistory] = useState(false);
  const toggleShowHistory = useCallback(
    () => setShowHistory(!showHistory),
    [showHistory]
  );
  const [showModalEditor, setShowModalEditor] = useState(false);
  const toggleShowModalEditor = useCallback(
    () => setShowModalEditor(!showModalEditor),
    [showModalEditor]
  );

  const [developmentPlan, setDevelopmentPlan] =
    useState<DevelopmentPlanType | null>(null);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const { error: errorMessage, status: initialLoadStatus } =
    useConfirmApi<DevelopmentPlanType>({
      method: 'GET',
      url: `/${DEVELOPMENT_PLANS_BASE_URL_PATH}/${developmentPlanId}`,
      postProcess: fromApi,
      callback: setDevelopmentPlan,
    });

  const candidate_person = useMemo(
    () => developmentPlan?.candidate_person,
    [developmentPlan?.candidate_person]
  );

  const author_person = useMemo(
    () => developmentPlan?.author_person,
    [developmentPlan?.author_person]
  );

  const status = useMemo(
    () => getDevelopmentPlanStatus(developmentPlan?.status ?? 'D'),
    [developmentPlan?.status]
  );

  const handleUpdatePropertiesCallback = useCallback(
    (p, toastSuccessMessage) => {
      if (p) {
        setDevelopmentPlan(fromApi(p));
        callback?.(p);
        if (toastSuccessMessage) {
          toast.success(toastSuccessMessage);
        }
      }
    },
    [callback]
  );

  const handleUpdateStateAndCommentUpdate = useCallback(
    (p: DevelopmentPlanType): void => {
      setDevelopmentPlan(fromApi(p));
      callback?.(p);
    },
    [callback]
  );

  const handleUpdateContent = useCallback(
    (p: DevelopmentPlanType): void => {
      setDevelopmentPlan((d) => (d ? { ...d, comments: p.comments } : d));
      callback?.(p);
    },
    [callback]
  );

  // TODO: enableHighLevelDetails1 is not used at the moment,
  // we'll keep it there once we have clear what se should display there
  const enableHighLevelDetails1 = false;

  const highLevelDetails1 = useMemo(
    () => [
      {
        className: 'col-12',
        name: (
          <FormattedMessage
            id="app.views.development_plans.development_plan.name.last_perf_cycle"
            defaultMessage="Last perf cycle"
          />
        ),
        description: (
          <span>
            <Link
              to={candidate_person?.url + PERFORMANCE().path}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="app.views.development_plans.development_plan.view_perf_results"
                defaultMessage="View perf results"
              />
            </Link>
          </span>
        ),
      },
    ],
    [candidate_person?.url]
  );

  const highLevelDetails2 = useMemo(
    () => [
      {
        name: (
          <FormattedMessage
            id="app.views.development_plans.development_plan.name.start_date"
            defaultMessage="Plan start date"
          />
        ),
        description: developmentPlan?.start_date
          ? getPrettyDate({
              dateString: developmentPlan?.start_date,
              locale,
            })
          : '-',
      },
      {
        name: (
          <FormattedMessage
            id="app.views.development_plans.development_plan.name.end_date"
            defaultMessage="Plan end date"
          />
        ),
        description: developmentPlan?.end_date
          ? getPrettyDate({
              dateString: developmentPlan?.end_date,
              locale,
            })
          : '-',
      },
    ],
    [developmentPlan, locale]
  );

  const highLevelDetails3 = useMemo(
    () => [
      {
        name: (
          <FormattedMessage
            id="app.views.development_plans.development_plan.name.status"
            defaultMessage="Status"
          />
        ),
        description: (
          <>
            <i
              className={status.icon}
              style={{ position: 'relative', top: '2px' }}
            />{' '}
            <span>{status.heading}</span>
          </>
        ),
      },
      {
        name: (
          <FormattedMessage
            id="app.views.development_plans.development_plan.name.supporter_person"
            defaultMessage="Supported by"
          />
        ),
        description: developmentPlan?.supporter_person ? (
          <PersonCard
            size="xxs"
            bodyOnly
            person={developmentPlan?.supporter_person}
            linked
            isExternalUrl={true}
            showDescription={false}
          />
        ) : (
          '-'
        ),
      },
    ],
    [status.icon, status.heading, developmentPlan?.supporter_person]
  );

  const userIsCandidate = meId === candidate_person?.id;
  const candidateCanManage = developmentPlan?.candidate_can_manage;
  const isEditable = !userIsCandidate || candidateCanManage;

  const toggleConfirmDeleteModal = useCallback(
    () => setConfirmDeleteModal(!confirmDeleteModal),
    [confirmDeleteModal]
  );

  const handleClickAcknowledge = useCallback(() => {
    ConfirmAPI.sendRequestToConfirm(
      'POST',
      `/${DEVELOPMENT_PLANS_BASE_URL_PATH}/${developmentPlan?.id}/actions`,
      { action: 'acknowledge' },
      (response, error, hardErrorMessage = null) => {
        if (error) {
          if (hardErrorMessage) {
            toast.error(hardErrorMessage);
          } else {
            toast.error(error);
          }
        } else {
          handleUpdateStateAndCommentUpdate(response);
          toast.success(
            formatMessage({
              id: 'app.views.development_plans.development_plan.plan_acknowledged',
              defaultMessage: 'Development plan ackwnowledged!',
            })
          );
        }
      },
      null
    );
  }, [developmentPlan?.id, formatMessage, handleUpdateStateAndCommentUpdate]);

  const deletePacket = useCallback(() => {
    ConfirmAPI.sendRequestToConfirm(
      'DELETE',
      `/${DEVELOPMENT_PLANS_BASE_URL_PATH}/${developmentPlan?.id}`,
      {},
      (response, error, hardErrorMessage = null) => {
        if (error) {
          if (hardErrorMessage) {
            toast.error(hardErrorMessage);
          } else {
            toast.error(error);
          }
        } else {
          toast.success(
            formatMessage({
              id: 'app.views.development_plans.development_plan.plan_deleted',
              defaultMessage: 'Development plan deleted!',
            })
          );
          history.replace(`/${DEVELOPMENT_PLANS_BASE_URL_PATH}`);
        }
      },
      null
    );
  }, [history, developmentPlan?.id, formatMessage]);

  const actions = useMemo(
    () => (
      <Row className="align-items-center">
        {isEditable && (
          <Col className="col-auto">
            <ModalEditorButton
              method="POST"
              url={`/${DEVELOPMENT_PLANS_BASE_URL_PATH}/${developmentPlan?.id}/actions`}
              buttonClassName="btn-block"
              color="primary"
              title={formatMessage({
                id: 'app.views.development_plans.development_plan.title.update_status_or_add_comments',
                defaultMessage: 'Update status or add comments',
              })}
              object={developmentPlan}
              inputs={[
                {
                  name: 'status',
                  type: INPUT_TYPES.DROPDOWN,
                  label: formatMessage({
                    id: 'app.views.development_plans.development_plan.label.status',
                    defaultMessage: 'Status',
                  }),
                  objects: DEVELOPMENT_PLAN_STATUSES,
                },
                {
                  name: 'comment_to_add',
                  type: INPUT_TYPES.TEXTAREA,
                  label: formatMessage({
                    id: 'app.views.development_plans.development_plan.label.comments',
                    defaultMessage: 'Comments',
                  }),
                  placeholder: formatMessage({
                    id: 'app.views.development_plans.development_plan.placeholder.enter_comments',
                    defaultMessage: 'Enter comments',
                  }),
                  multiLine: true,
                  minRows: 3,
                },
              ]}
              transformObjectBeforeSubmit={(p) => ({
                status: p.status,
                comment_to_add: p.comment_to_add,
                organization: currentOrganization?.id,
              })}
              callback={handleUpdateStateAndCommentUpdate}
            />
          </Col>
        )}
        {isEditable && (
          <Col className="col-auto">
            <Button color="light" onClick={toggleConfirmDeleteModal}>
              <i className="me-2 fe fe-trash-2" />
              <FormattedMessage
                id="app.views.development_plans.development_plan.delete_action"
                defaultMessage="Delete"
              />
            </Button>
          </Col>
        )}
        {!isEditable && userIsCandidate && (
          <Col className="col-auto">
            <Button color="primary" onClick={handleClickAcknowledge}>
              <i className={`me-2 ${ICONS.ACKNOWLEDGED} text-white`} />
              <FormattedMessage
                id="app.views.development_plans.development_plan.acknowledge_action"
                defaultMessage="Acknowledge"
              />
            </Button>
          </Col>
        )}
        {!isEditable && userIsCandidate && (
          <Col className="col-auto">
            <ModalEditorButton
              method="POST"
              url={`/${DEVELOPMENT_PLANS_BASE_URL_PATH}/${developmentPlan?.id}/actions`}
              buttonClassName="btn-block"
              color="light"
              title={formatMessage({
                id: 'app.views.development_plans.development_plan.title.add_comments',
                defaultMessage: 'Add comments',
              })}
              object={developmentPlan}
              inputs={[
                {
                  name: 'comment_to_add',
                  type: INPUT_TYPES.TEXTAREA,
                  label: formatMessage({
                    id: 'app.views.development_plans.development_plan.label.comments',
                    defaultMessage: 'Comments',
                  }),
                  placeholder: formatMessage({
                    id: 'app.views.development_plans.development_plan.placeholder.enter_comments',
                    defaultMessage: 'Enter comments',
                  }),
                  multiLine: true,
                  minRows: 3,
                },
              ]}
              transformObjectBeforeSubmit={(p) => ({
                action: 'add_a_comment',
                comment_to_add: p.comment_to_add,
                organization: currentOrganization?.id,
              })}
              callback={handleUpdateStateAndCommentUpdate}
            />
          </Col>
        )}
        <Col className="col-auto">
          <Button color="light" onClick={toggleShowHistory}>
            <i className={'me-2 ' + ICONS.HISTORY} />
            {showHistory
              ? formatMessage({
                  id: 'app.views.development_plans.development_plan.hide_history_action',
                  defaultMessage: 'Hide history',
                })
              : formatMessage({
                  id: 'app.views.development_plans.development_plan.view_history_action',
                  defaultMessage: 'View history',
                })}
          </Button>
        </Col>
        {inModal && (
          <Col className="col-auto">
            <Link
              className="btn btn-light"
              to={`${DEVELOPMENT_PLANS(formatMessage).path}/${
                developmentPlan?.id
              }`}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <i className="me-2 fe fe-external-link" />{' '}
              <FormattedMessage
                id="app.views.development_plans.development_plan.open_in_new_window_action"
                defaultMessage="Open in new window"
              />
            </Link>
          </Col>
        )}
        {!inModal && (
          <Col className="col-auto">
            <Button color="light" onClick={() => window.print()}>
              <i className="me-2 fe fe-upload" />
              <FormattedMessage
                id="app.views.development_plans.development_plan.export_action"
                defaultMessage="Export"
              />
            </Button>
          </Col>
        )}
        {confirmDeleteModal && (
          <ConfirmationDialogModal
            isOpen={confirmDeleteModal}
            toggle={toggleConfirmDeleteModal}
            confirmCallback={deletePacket}
            title={formatMessage({
              id: 'app.views.development_plans.development_plan.title.delete_development_plan',
              defaultMessage: 'Delete development plan?',
            })}
            description={formatMessage({
              id: 'app.views.development_plans.development_plan.description.are_you_sure_that_you_want_to_delete',
              defaultMessage:
                'Are you sure that you want to delete this development plan?',
            })}
            confirmText={formatMessage({
              id: 'app.views.development_plans.development_plan.confirm_text.delete_development_plan',
              defaultMessage: 'Delete Development Plan',
            })}
          />
        )}
      </Row>
    ),
    [
      developmentPlan,
      formatMessage,
      handleUpdateStateAndCommentUpdate,
      toggleConfirmDeleteModal,
      toggleShowHistory,
      handleClickAcknowledge,
      showHistory,
      inModal,
      confirmDeleteModal,
      deletePacket,
      currentOrganization?.id,
      isEditable,
      userIsCandidate,
    ]
  );

  const defaultDevelopmentPlanTemplate =
    settings.development_plans_templates.find(
      (it) => it.id === developmentPlan?.template_id
    )?.value ?? '';

  const inputs = useMemo(() => {
    return DEVELOPMENT_PLAN_INITIAL_SUBMIT_INPUTS(
      formatMessage,
      settings.development_plans_templates,
      false,
      developmentPlan?.template_id
    );
  }, [
    formatMessage,
    settings.development_plans_templates,
    developmentPlan?.template_id,
  ]);

  const developmentPlanBody = useMemo(
    () =>
      developmentPlan ? (
        <>
          <ModalEditor
            isOpen={showModalEditor}
            toggle={toggleShowModalEditor}
            method="PATCH"
            url={DEVELOPMENT_PLANS_BASE_URL_PATH}
            title={formatMessage({
              id: 'app.views.development_plans.development_plan.title.edit_development_plan_details',
              defaultMessage: 'Edit development plan details',
            })}
            submitText={formatMessage({
              id: 'app.views.development_plans.development_plan.submit_text.save',
              defaultMessage: 'Save',
            })}
            object={developmentPlan}
            inputs={inputs}
            onValidate={(_o) => []}
            transformObjectBeforeSubmit={(p) => {
              return toApiSubmit(p, currentOrganization);
            }}
            callback={handleUpdatePropertiesCallback}
          ></ModalEditor>
          <Row>
            {enableHighLevelDetails1 && (
              <Col className="col-12 col-md-6">
                <Card>
                  <CardBody>
                    <Row>
                      {highLevelDetails1.map((item, index) => (
                        <Col
                          key={index}
                          className={
                            item.className +
                            ' ' +
                            (index === highLevelDetails1?.length - 1
                              ? ''
                              : 'mb-4')
                          }
                        >
                          <div>
                            <h6 className="text-uppercase text-muted mb-2">
                              {item.name}
                            </h6>
                            <span className="h2 mb-0">{item.description}</span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}
            <Col
              className={`col-12 ${enableHighLevelDetails1 ? 'col-md-6' : ''}`}
              role={isEditable ? 'button' : undefined}
              onClick={isEditable ? toggleShowModalEditor : undefined}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      {highLevelDetails2.map((item, index) => (
                        <div className={index === 0 ? '' : 'mt-4'} key={index}>
                          <h6 className="text-uppercase text-muted mb-2">
                            {item.name}
                          </h6>
                          <span className="h2 mb-0">{item.description}</span>
                        </div>
                      ))}
                    </Col>
                    <Col>
                      {highLevelDetails3.map((item, index) => (
                        <div className={index === 0 ? '' : 'mt-4'} key={index}>
                          <h6 className="text-uppercase text-muted mb-2">
                            {item.name}
                          </h6>
                          <span className="h2 mb-0">{item.description}</span>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className={showHistory ? 'col-12 col-md-8' : 'col-12'}>
              <Card className="activity-card">
                {!isEditable && (
                  <>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col>
                          <CardHeaderTitle>
                            {formatMessage({
                              id: 'app.views.development_plans.development_plan.title.development_plan_content',
                              defaultMessage: 'Development plan content',
                            })}
                          </CardHeaderTitle>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <RichTextViewer
                            model={
                              developmentPlan?.description ||
                              defaultDevelopmentPlanTemplate
                            }
                            expanded={true}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </>
                )}
                {isEditable && (
                  <RichTextEditor
                    autoSave={true}
                    autoFocus={false}
                    showResetToDefaultButton={true}
                    method="POST"
                    url={`${DEVELOPMENT_PLANS_BASE_URL_PATH}/${developmentPlan?.id}/actions`}
                    title={formatMessage({
                      id: 'app.views.development_plans.development_plan.title.development_plan_content',
                      defaultMessage: 'Development plan content',
                    })}
                    name="description"
                    defaultValue={defaultDevelopmentPlanTemplate}
                    placeholder={formatMessage({
                      id: 'app.views.development_plans.development_plan.placeholder.enter_content',
                      defaultMessage: 'Enter content',
                    })}
                    saveId={String(developmentPlanId)}
                    value={developmentPlan?.description}
                    config={{
                      heightMin: 300,
                    }}
                    emptyStateTitle={formatMessage({
                      id: 'app.views.development_plans.development_plan.emptyStateTitle.no_content',
                      defaultMessage: 'No content',
                    })}
                    emptyStateSubtitle={formatMessage({
                      id: 'app.views.development_plans.development_plan.emptyStateSubtitle.please_provide_content_for_this',
                      defaultMessage:
                        'Please provide content for this development plan.',
                    })}
                    callback={handleUpdateContent}
                  />
                )}
              </Card>
            </Col>
            {showHistory && (
              <Col className="col-12 col-md-4">
                <DevelopmentPlanHistory developmentPlan={developmentPlan} />
              </Col>
            )}
          </Row>
        </>
      ) : (
        <Loading />
      ),
    [
      currentOrganization,
      developmentPlan,
      showModalEditor,
      toggleShowModalEditor,
      formatMessage,
      inputs,
      handleUpdateContent,
      handleUpdatePropertiesCallback,
      enableHighLevelDetails1,
      highLevelDetails1,
      isEditable,
      highLevelDetails2,
      highLevelDetails3,
      showHistory,
      defaultDevelopmentPlanTemplate,
      developmentPlanId,
    ]
  );

  const closeButton = useMemo(
    () => (
      <button
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label={formatMessage({
          id: 'app.views.development_plans.development_plan.aria_label.close',
          defaultMessage: 'Close',
        })}
        onClick={onClosed}
      />
    ),
    [onClosed, formatMessage]
  );

  const output = useMemo(() => {
    if (inModal) {
      return (
        <Modal
          // prevent Esc from closing editor (to avoid issues e.g.
          // when someone escapes file dialog and presses twice)
          keyboard={false}
          // prevent hiding when clicking outside
          backdrop="static"
          className={className}
          // autofocus must be false to enable inputs to have autoFocus on them directly
          autoFocus={false}
          isOpen={!!developmentPlanId}
          toggle={toggle}
          onClosed={onClosed}
          size="xl"
        >
          <ModalHeader toggle={toggle} close={closeButton}>
            {candidate_person && (
              <Row className="align-items-center">
                <Col className="pe-0">
                  <PersonCard
                    size="xs"
                    bodyOnly
                    person={{
                      ...candidate_person,
                      url: candidate_person?.url + PERFORMANCE().path,
                    }}
                    linked
                    showDescription={false}
                    isExternalUrl={true}
                  />
                </Col>
              </Row>
            )}
          </ModalHeader>
          <ModalBody>
            <Row className="align-items-center pb-4">
              {author_person && (
                <>
                  <Col className="col-auto pe-0">
                    <FormattedMessage
                      id="app.views.development_plans.development_plan.plan_created_by_prefix"
                      defaultMessage="Plan created by"
                    />
                  </Col>
                  <Col className="ps-2">
                    <PersonCard
                      size="xxs"
                      bodyOnly
                      person={author_person}
                      linked
                      showDescription={false}
                      isExternalUrl={true}
                      headerStyle={{ whiteSpace: 'nowrap' }}
                    />
                  </Col>
                </>
              )}
              <Col />
              <Col className="col-auto">{actions}</Col>
            </Row>
            {developmentPlanBody}
          </ModalBody>
          <ModalFooter>{actions}</ModalFooter>
        </Modal>
      );
    }

    return (
      <Page
        person={candidate_person}
        pretitle={
          <>
            <Link
              className="text-primary"
              to={DEVELOPMENT_PLANS(formatMessage).path}
            >
              <FormattedMessage
                id="app.views.development_plans.development_plan.development_plans"
                defaultMessage="Development plans"
              />
            </Link>
            {' > '}
            <FormattedMessage
              id="app.views.development_plans.development_plan.development_plan"
              defaultMessage="development plan"
            />
          </>
        }
        title={candidate_person?.full_name}
        titleLink={
          candidate_person &&
          candidate_person?.url + PROFILE_TAB_PERFORMANCE(formatMessage).path
        }
        subtitle={
          <Row>
            <Col className="col-auto pe-0">
              <FormattedMessage
                id="app.views.development_plans.development_plan.development_plan_created_by_prefix"
                defaultMessage="Development plan created by"
              />
            </Col>
            {author_person && (
              <Col className="ps-2">
                <PersonCard
                  size="xxs"
                  bodyOnly
                  person={author_person}
                  linked
                  showDescription={false}
                  isExternalUrl={true}
                  headerStyle={{ whiteSpace: 'nowrap' }}
                />
              </Col>
            )}
          </Row>
        }
      >
        {actions}
        {developmentPlanBody}
      </Page>
    );
  }, [
    actions,
    author_person,
    candidate_person,
    closeButton,
    developmentPlanBody,
    className,
    inModal,
    onClosed,
    developmentPlanId,
    toggle,
    formatMessage,
  ]);

  if (initialLoadStatus === 'LOADING') {
    // avoid loading flickering if it's modal
    return inModal ? <></> : <Loading />;
  }

  const loadOrRenderOutput = loadOrRender(developmentPlan, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  return output;
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson, me, features, settings } =
    state;

  return {
    currentOrganization,
    currentProxyPerson,
    settings,
    meId: me?.id,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(DevelopmentPlan));
