// Rich text editor NOTE: locales need to be inported here in order to work
import 'froala-editor/js/languages/it';
import 'froala-editor/js/languages/fr';
import 'froala-editor/js/languages/zh_cn';
import 'froala-editor/js/languages/zh_tw';
import 'froala-editor/js/languages/de';
import 'froala-editor/js/languages/es';
import 'froala-editor/js/languages/el';
import 'froala-editor/js/languages/ko';
// cronstrue resources
import 'cronstrue/locales/fr';
import 'cronstrue/locales/es';
import 'cronstrue/locales/de';
import 'cronstrue/locales/it';
import 'cronstrue/locales/ja';
import 'cronstrue/locales/zh_TW';
import 'cronstrue/locales/zh_CN';
import 'cronstrue/locales/ko';

// // Front-end application resources
import app_en from './en.json';
// Date picker Flatpickr
import datepicker_de from 'flatpickr/dist/l10n/de';
import datepicker_es from 'flatpickr/dist/l10n/es';
import datepicker_fr from 'flatpickr/dist/l10n/fr';
import datepicker_gr from 'flatpickr/dist/l10n/gr';
import datepicker_it from 'flatpickr/dist/l10n/it';
import datepicker_ja from 'flatpickr/dist/l10n/ja';
import datepicker_ko from 'flatpickr/dist/l10n/ko';
import datepicker_zh_CN from 'flatpickr/dist/l10n/zh';
import datepicker_zh_TW from 'flatpickr/dist/l10n/zh-tw';
// Emoji-bar resources
import emoji_de from '@emoji-mart/data/i18n/de.json';
import emoji_en from '@emoji-mart/data/i18n/en.json';
import emoji_es from '@emoji-mart/data/i18n/es.json';
import emoji_fr from '@emoji-mart/data/i18n/fr.json';
import emoji_it from '@emoji-mart/data/i18n/it.json';
import emoji_ja from '@emoji-mart/data/i18n/ja.json';
import emoji_ko from '@emoji-mart/data/i18n/kr.json';
import emoji_zh from '@emoji-mart/data/i18n/zh.json';

const generateTestKeys = (messages) => {
  return Object.keys(messages).reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {});
};

// This function is used by the backend to load the locale files
// This will generate a separate bundle for each locale, avoiding to load all the locales at once
// It's not used with regular expression to allow webpack to do code splitting
export const loadLocale = (locale: string): Promise<any> => {
  return new Promise((res) => {
    switch (locale) {
      case 'de':
        import('./de.json').then((data) => {
          res(data?.default);
        });
        break;
      case 'es-419':
        import('./es_419.json').then((data) => {
          res(data?.default);
        });
        break;
      case 'el':
        import('./el.json').then((data) => {
          res(data?.default);
        });
        break;
      case 'fr':
        import('./fr.json').then((data) => {
          res(data?.default);
        });
        break;
      case 'fr-CA':
        import('./fr_CA.json').then((data) => {
          res(data?.default);
        });
        break;
      case 'it':
        import('./it.json').then((data) => {
          res(data?.default);
        });
        break;
      case 'ja':
        import('./ja.json').then((data) => {
          res(data?.default);
        });
        break;
      case 'ko':
        import('./ko.json').then((data) => {
          res(data?.default);
        });
        break;
      case 'zh-TW':
        import('./zh_TW.json').then((data) => {
          res(data?.default);
        });
        break;
      case 'zh-CN':
        import('./zh_CN.json').then((data) => {
          res(data?.default);
        });
        break;
      case 'en-XA':
        res(generateTestKeys(app_en));
        break;
      default:
        res(app_en);
        break;
    }
  });
};

export const ALL_LOCALES: AppLocale[] = [
  'en-XA',
  'fr-CA',
  'zh-CN',
  'zh-TW',
  'it',
  'ja',
  'ko',
  'fr',
  'en',
  'el',
  'de',
  'es-419',
  // Add new languages here
];

// Locales supported by the application
type AppLocale =
  | 'en'
  | 'en-XA'
  | 'de'
  | 'es-419'
  | 'el'
  | 'fr'
  | 'fr-CA'
  | 'it'
  | 'ja'
  | 'ko'
  | 'zh-TW'
  | 'zh-CN';
// list of locales to display in the UI (defaulting to the first one),
// along with any other metadata we need about them
// NOTE: if you change this, ensure to update the backend
export interface LocaleConfig {
  id: AppLocale;
  name: string;
  intercomLocale: string;
  richTextEditor: string; // Note: Add the imports on the top of the file the respective locale imports in order to work properly
  cronstrueLocale: string; // Note: Add the imports on the top of the file the respective locale imports in order to work properly
  emojiBar: object;
  datePicker: unknown | null;
}

export interface LocaleConfigForSelect {
  id: AppLocale;
  name: string;
}

const EN_DEFAULT_LOCALE_CONFIG: LocaleConfig = {
  id: 'en',
  name: 'English',
  intercomLocale: 'en',
  richTextEditor: 'en',
  cronstrueLocale: 'en',
  emojiBar: emoji_en,
  datePicker: null,
};

export const LOCALES_DISPLAY_LIST: LocaleConfig[] = [
  EN_DEFAULT_LOCALE_CONFIG,
  {
    id: 'de',
    name: 'Deutsch',
    intercomLocale: 'de',
    richTextEditor: 'de',
    cronstrueLocale: 'de',
    emojiBar: emoji_de,
    datePicker: datepicker_de.de,
  },
  {
    id: 'es-419',
    name: 'Español',
    intercomLocale: 'es',
    richTextEditor: 'es',
    cronstrueLocale: 'es',
    emojiBar: emoji_es,
    datePicker: datepicker_es.es,
  },
  {
    id: 'el',
    name: 'Ελληνικά',
    intercomLocale: 'el',
    richTextEditor: 'el',
    cronstrueLocale: 'en;', // TODO: check if they add greek in the future
    emojiBar: emoji_en, // NOTE: Not supported yet. We can translate it ourselves in a second moment as the amount of keys to translate is not too big
    datePicker: datepicker_gr.gr,
  },
  {
    id: 'fr',
    name: 'Français',
    intercomLocale: 'fr',
    richTextEditor: 'fr',
    cronstrueLocale: 'fr',
    emojiBar: emoji_fr,
    datePicker: datepicker_fr.fr,
  },
  {
    id: 'fr-CA',
    name: 'Français (Canada)',
    intercomLocale: 'fr',
    richTextEditor: 'fr',
    cronstrueLocale: 'fr',
    emojiBar: emoji_fr,
    datePicker: datepicker_fr.fr,
  },
  {
    id: 'it',
    name: 'Italiano',
    intercomLocale: 'it',
    richTextEditor: 'it',
    cronstrueLocale: 'it',
    emojiBar: emoji_it,
    datePicker: datepicker_it.it,
  },
  {
    id: 'ja',
    name: '日本語',
    intercomLocale: 'ja',
    richTextEditor: 'ja',
    cronstrueLocale: 'ja',
    emojiBar: emoji_ja,
    datePicker: datepicker_ja.ja,
  },
  {
    id: 'ko',
    name: '한국어',
    intercomLocale: 'ko',
    richTextEditor: 'ko',
    cronstrueLocale: 'ko',
    emojiBar: emoji_ko,
    datePicker: datepicker_ko.ko,
  },
  {
    id: 'zh-TW',
    name: '中文 (繁體)',
    intercomLocale: 'zh-TW',
    richTextEditor: 'zh_tw',
    cronstrueLocale: 'zh_TW',
    emojiBar: emoji_zh, // NOTE: Only localized to zh
    datePicker: datepicker_zh_TW.zh,
  },
  {
    id: 'zh-CN',
    name: '中文 (简体)',
    intercomLocale: 'zh-CN',
    richTextEditor: 'zh_cn',
    cronstrueLocale: 'zh_CN',
    emojiBar: emoji_zh, // NOTE: Only localized to zh
    datePicker: datepicker_zh_CN.zh,
  },
];

export const LOCALES_DISPLAY_LIST_FOR_SELECT: LocaleConfigForSelect[] =
  LOCALES_DISPLAY_LIST.map(({ id, name }) => ({
    id,
    name,
  }));

export const configForLocale = (locale: string): LocaleConfig => {
  return (
    LOCALES_DISPLAY_LIST.find((l) => l.id === locale) ??
    EN_DEFAULT_LOCALE_CONFIG
  );
};

export const isValidLocale = (locale) => {
  return ALL_LOCALES.includes(locale);
};
