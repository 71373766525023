import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useMemo, useState } from 'react';

import ConfirmationDialogModal from '../Modals/ConfirmationDialogModal';
import FilterablePeopleTable from '../People/FilterablePeopleTable';
import { INPUT_TYPES } from './ValidatedInputTypes';
import PropTypes from 'prop-types';
import ValidatedInput from './ValidatedInput';
import { getId } from 'utils/util/util';

const ActionsComponent = (props) => {
  const { formatMessage } = useIntl();

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = useCallback(
    () => setConfirmDeleteModal(!confirmDeleteModal),
    [confirmDeleteModal]
  );

  const confirmDeleteCallback = useCallback(() => {
    props.onDelete();
    toggleConfirmDeleteModal();
  }, [props, toggleConfirmDeleteModal]);

  return (
    <>
      <ConfirmationDialogModal
        isOpen={confirmDeleteModal}
        toggle={toggleConfirmDeleteModal}
        confirmCallback={confirmDeleteCallback}
        title={formatMessage({
          id: 'app.views.widgets.inputs.table_editor.confirm_delete.title',
          defaultMessage: 'Delete this item?',
        })}
        description={formatMessage({
          id: 'app.views.widgets.inputs.table_editor.confirm_delete.description',
          defaultMessage: 'Are you sure that you want to delete this item?',
        })}
        confirmText={formatMessage({
          id: 'app.views.widgets.inputs.table_editor.confirm_delete.confirm_text',
          defaultMessage: 'Delete item',
        })}
      />
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn btn-sm btn-rounded-circle btn-white"
          role="button"
        >
          <i
            className="fe fe-more-horizontal"
            style={{ position: 'relative', top: '2px' }}
          />
        </DropdownToggle>
        <DropdownMenu end>
          <div>
            <DropdownItem onClick={toggleConfirmDeleteModal}>
              <FormattedMessage
                id="app.views.widgets.inputs.table_editor.dropdown.delete"
                defaultMessage="Delete"
              />
            </DropdownItem>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

const TableEditor: FC<Props> = ({
  reorderingDisabled = false,
  addingDisabled = false,
  deletingDisabled = false,
  showActions = true,
  isReadOnly = false,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [cellBeingEdited, setCellBeingEdited] = useState(null);
  const propsOnChange = props.onChange;
  const propsAddText =
    props.addText ??
    formatMessage({
      id: 'app.views.widgets.inputs.table_editor.add_text',
      defaultMessage: 'Add item',
    });
  const changeCellValue = useCallback(
    (rowIndex, field, value, uniqueIndexIdentifier) => {
      // to handle the undefined/blank case, set cell being edited to the unique index identifier
      setCellBeingEdited(uniqueIndexIdentifier);

      // replace the specific cell value and return a new list
      // @ts-expect-error
      const newList = props.value.map((item, index) => {
        if (rowIndex === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      });

      propsOnChange(newList);
    },
    [props.value, propsOnChange]
  );

  const onAddRow = useCallback(() => {
    const newListValue = [
      // @ts-expect-error
      ...props.value,
      props.columns.reduce((acc, col) => {
        // @ts-expect-error
        acc[col.field] = col.generateDefaultValue
          ? // @ts-expect-error
            col.generateDefaultValue(props.value?.length, props.value)
          : // @ts-expect-error
            col.defaultValue;
        return acc;
      }, {}),
    ];

    propsOnChange(newListValue);
  }, [propsOnChange, props.value, props.columns]);

  const onDeleteRow = useCallback(
    (row) => {
      // @ts-expect-error
      const newListValue = props.value.filter((r) => r !== row);
      propsOnChange(newListValue);
    },
    [props.value, propsOnChange]
  );

  const onDeleteRowIndex = useCallback(
    (rowIndex) => () => {
      // @ts-expect-error
      const newListValue = props.value.filter((r, index) => index !== rowIndex);
      propsOnChange(newListValue);
    },
    [props.value, propsOnChange]
  );

  const onRowsReordered = useCallback(
    (rows) => {
      propsOnChange(rows);
    },
    [propsOnChange]
  );

  const rows = useMemo(() => {
    return props.value?.map((item, index) => ({
      // set unique id as key for rendering each row if it doesn't
      // exist on the data itself
      // @ts-expect-error
      id: item.id ?? index,
      ...item,
      ...(deletingDisabled || isReadOnly || !showActions
        ? {}
        : {
            actions: [
              null,
              <ActionsComponent
                key={index}
                onDelete={() => onDeleteRow(item)}
              />,
            ],
          }),
    }));
  }, [onDeleteRow, deletingDisabled, props.value, isReadOnly, showActions]);

  // add a column for actions (i.e. edit and delete)
  const columns = useMemo(
    () => [
      ...props.columns.map((col) => ({
        ...col,
        // @ts-expect-error
        editable: isReadOnly || col.editable,
        renderIfEmpty: true,
        // note: col.editable === false && typeof v === 'undefined case
        // is not needed because the cell will be empty and the user can
        // click on it to edit it
        format: (v, uniqueIndexIdentifier, rowIndex, personFields) => (
          <>
            {/* @ts-expect-error */}
            {col.editable === false && typeof v !== 'undefined' && <>{v}</>}
            {/* @ts-expect-error */}
            {col.editable !== false &&
              typeof v !== 'undefined' &&
              uniqueIndexIdentifier !== cellBeingEdited &&
              // @ts-expect-error
              col.type !== INPUT_TYPES.CUSTOM_COMPONENT && (
                <div className="m-n3">
                  <div
                    className="list-editor-editable-cell rounded p-3"
                    role="button"
                    onClick={() => setCellBeingEdited(uniqueIndexIdentifier)}
                  >
                    {v}
                  </div>
                </div>
              )}
            {/* @ts-expect-error */}
            {col.editable !== false &&
              (typeof v === 'undefined' ||
                // @ts-expect-error
                col.type === INPUT_TYPES.CUSTOM_COMPONENT ||
                uniqueIndexIdentifier === cellBeingEdited) && (
                <ValidatedInput
                  {...col}
                  // @ts-expect-error
                  name={props.name + '_' + col.field}
                  // @ts-expect-error
                  type={col.type}
                  value={v}
                  // @ts-expect-error
                  placeholder={col.placeholder}
                  formGroupClassName="mb-0"
                  // @ts-expect-error
                  blur={
                    // if no input value, then keep the cell active and editable
                    // Note: the integer 0 is a valid value, so check for undefined/null
                    v != null
                      ? (event) => {
                          if (
                            // if the related target is not a translation button,
                            // then set the cell being edited to null
                            !event?.relatedTarget?.className?.includes(
                              'translation-button'
                            )
                          ) {
                            setCellBeingEdited(null);
                          }
                        }
                      : undefined
                  }
                  autoFocus={uniqueIndexIdentifier === cellBeingEdited}
                  onChange={(e) => {
                    changeCellValue(
                      rowIndex,
                      // @ts-expect-error
                      col.field,
                      // @ts-expect-error
                      col.formatValueOnSubmit &&
                        // @ts-expect-error
                        (!col.suspendFormatValueOnSubmit ||
                          // @ts-expect-error
                          !col.suspendFormatValueOnSubmit(e.target.value))
                        ? // @ts-expect-error
                          col.formatValueOnSubmit(e.target.value)
                        : e.target.value,
                      uniqueIndexIdentifier
                    );
                  }}
                  onDelete={onDeleteRowIndex(rowIndex)}
                  // @ts-expect-error
                  translationNamespace={col.translationNamespace}
                  jsonPath={
                    // @ts-expect-error
                    col && col.jsonPathItemGenerator && col.jsonPath
                      ? // @ts-expect-error
                        col.jsonPathItemGenerator(col.jsonPath, personFields)
                      : undefined
                  }
                />
              )}
          </>
        ),
      })),
      ...(deletingDisabled || isReadOnly || !showActions
        ? []
        : [
            {
              name: 'Action',
              field: 'actions',
              sortable: false,
              hideFromCSV: true,
              hideFromFilters: true,
            },
          ]),
    ],
    [
      cellBeingEdited,
      changeCellValue,
      onDeleteRowIndex,
      props.columns,
      deletingDisabled,
      props.name,
      isReadOnly,
      showActions,
    ]
  );

  const showAddButton = useMemo(() => !addingDisabled, [addingDisabled]);

  const output = useMemo(
    () => (
      <>
        {/* @ts-expect-error */}
        {showAddButton && !(rows?.length > 0) && (
          // @ts-expect-error
          <Button color="white" onClick={onAddRow} disabled={isReadOnly}>
            <i
              className="fe fe-plus"
              style={{ position: 'relative', top: '2px' }}
            />{' '}
            {propsAddText}
          </Button>
        )}
        {/* @ts-expect-error */}
        {rows?.length > 0 && (
          <>
            <FilterablePeopleTable
              // TODO: determine if this flag is truly needed here
              arrayValuesUsedForFormatting={true}
              hideFilters={true}
              hideExportButton={true}
              rowsAreReorderable={!reorderingDisabled && !isReadOnly}
              rawReorderableRows={props.value}
              onChange={onRowsReordered}
              // @ts-expect-error
              rows={rows}
              columns={columns}
              getUniqueRowId={getId}
            />
            {showAddButton && (
              // @ts-expect-error
              <Button
                className="btn btn-white"
                onClick={onAddRow}
                disabled={isReadOnly}
              >
                <i
                  className="fe fe-plus"
                  style={{ position: 'relative', top: '2px' }}
                />{' '}
                {propsAddText}
              </Button>
            )}
          </>
        )}
      </>
    ),
    [
      propsAddText,
      reorderingDisabled,
      props.value,
      rows,
      onAddRow,
      onRowsReordered,
      columns,
      showAddButton,
      isReadOnly,
    ]
  );

  return output;
};

const TableEditor_propTypes = {
  name: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
  addText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isReadOnly: PropTypes.bool,
  showActions: PropTypes.bool,
  reorderingDisabled: PropTypes.bool,
  addingDisabled: PropTypes.bool,
  deletingDisabled: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof TableEditor_propTypes>;

export default TableEditor;
