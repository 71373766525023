import { CATCHALL_GROUP_NAME, calculateEnpsScore } from './util';
import {
  ENGAGEMENT_SURVEY_OTHERS_EXPLANATION,
  ICONS,
} from '../../../../consts/consts';
import { EnpsCounts, EnpsField } from '../../../../apiTypes';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useMemo, useRef } from 'react';

import AccordionTable from '../../AccordionTable';
import EnpsBar from './EnpsBar';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { formatSimpleDecimal } from '../../../../utils/util/util';
import { getFilterDisplayName } from '../../../../utils/models/Filters';

interface Props {
  group: string;
  fields: EnpsField[];
  results: Record<string, EnpsCounts>;
}

const EnpsResultTable: React.FC<Props> = ({ group, fields, results }) => {
  const ref = useRef<HTMLSpanElement>(null);
  const { formatMessage } = useIntl();
  const intlUnknown = formatMessage({
    id: 'app.views.widgets.dashboards.takeaways_dashboard.enps_result_table.unknown',
    defaultMessage: 'Unknown',
  });

  const groupDisplayName = getFilterDisplayName(
    fields.find((field) => field.name === group)?.name ?? intlUnknown,
    formatMessage
  );

  const columns = [
    {
      name: groupDisplayName,
      field: 'group',
      className: 'col-2 pxy-1',
    },
    {
      name: formatMessage({
        id: 'app.views.widgets.dashboards.takeaways_dashboard.enps_result_table.score',
        defaultMessage: 'Score',
      }),
      field: 'score',
      className: 'col-1 pxy-1',
    },
    {
      name: '',
      field: 'bar',
      className: 'col-9 pxy-1',
    },
  ];

  const intlOther = useMemo(
    () => (
      <>
        <span ref={ref}>
          <FormattedMessage
            id="app.views.widgets.cards.text_question_table_card.rows.others"
            defaultMessage="Others"
          />
          <i
            className={
              ICONS.HELP + ' small ms-1 text-primary position-relative'
            }
          />
        </span>
        <UncontrolledPopover placement="top" trigger="hover click" target={ref}>
          {ENGAGEMENT_SURVEY_OTHERS_EXPLANATION(formatMessage)}
        </UncontrolledPopover>
      </>
    ),
    [formatMessage]
  );

  const rows = useMemo(() => {
    const entries = Object.entries(results ?? {}).filter(
      ([_groupName, counts]) =>
        counts.promoter + counts.detractor + counts.passive > 0
    );
    const unsortedRows = entries.map(([groupName, counts]) => ({
      group: groupName === CATCHALL_GROUP_NAME ? intlOther : groupName,
      rawScore: calculateEnpsScore(counts),
      score: <h1>{formatSimpleDecimal(calculateEnpsScore(counts))}</h1>,
      bar: (
        <EnpsBar
          promoters={counts.promoter}
          detractors={counts.detractor}
          passives={counts.passive}
          missing={counts.missing}
          showHeader={false}
          showFooter={true}
        />
      ),
    }));
    return unsortedRows.sort((a, b) => b.rawScore - a.rawScore);
  }, [intlOther, results]);

  return <AccordionTable rowClassname={'py-1'} columns={columns} rows={rows} />;
};

export default EnpsResultTable;
