import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { PERSON_STATUSES, PERSON_STATUSES_NAMES } from 'utils/models/Person';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  formatDateWithUnicode,
  getDateFormatForDatasetTransform,
  parseDateFromUnicode,
} from '../../../utils/util/time';

import { CAMPAIGN_STATUSES } from '../../../utils/models/Campaign';
import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import { INPUT_TYPES } from '../Inputs/ValidatedInputTypes';
// @ts-expect-error
import { IntercomAPI } from '../../../vendor/react-intercom';
import ModalEditor from './ModalEditor';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { uniq } from 'lodash';
import { useFeatures } from 'utils/util/features';

// should we treat this as a boolean choice?
// because there can be "Yes", "Y", "NO", "N", etc.
const isBooleanChoice = (options) => {
  if (options.DEFAULT === undefined) {
    return false;
  }

  const possibleValues = uniq(Object.values(options));
  if (
    possibleValues.length != 2 ||
    !possibleValues.includes(true) ||
    !possibleValues.includes(false)
  ) {
    return false;
  }

  return true;
};

// have id, first name, last name, email always come first
const FIELD_NAMES_TO_PRIORITIZE = [
  'external_id',
  'given_name',
  'legal_given_name',
  'preferred_given_name',
  'family_name',
  'full_name',
  'email',
];

const FIELD_NAMES_TO_DEPRIORITIZE = [
  'is_participating',
  'is_participating_in_self_phase',
];

const PARTICIPATION_FIELDS = new Set([
  'is_participating_in_self_phase',
  'is_evaluated_by_manager',
  'is_writing_peer_reviews',
  'is_receiving_peer_reviews',
  'is_writing_upward_manager_review',
  'is_rated',
  'is_only_receiving_review',
  'is_only_selecting_high_priority_peers',
]);

const ModalDatasetRecordEditor: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [wantToLoad, setWantToLoad] = useState(false);
  const [configs, setConfigs] = useState({});
  const { ifEnabled } = useFeatures();

  // reload the data whenever this is opened/re-opened, so we can
  // guarantee this dialog always has the most up-to-date starting point
  // need this extra variable so that we can allow the dialog to open
  // asynchronously / independently of when the load happens
  useEffect(() => {
    if (props.isOpen != isOpen) {
      if (props.isOpen) {
        setWantToLoad(true);
      } else {
        setWantToLoad(false);
      }
      setIsOpen(props.isOpen);
    }
  }, [isOpen, props.isOpen]);

  // do the actual data loading -- rather than get the data-to-be-changed
  // passed in via props, we just take the person/campaign/org and explicitly
  // call the backend for the latest info
  useEffect(() => {
    if (
      wantToLoad &&
      props.person_id &&
      props.campaign_id &&
      // @ts-expect-error
      props.currentOrganization?.id
    ) {
      ConfirmAPI.sendRequestToConfirm(
        'GET',
        '/survey-response-configs',
        {
          person: props.person_id,
          campaign: props.campaign_id,
          // @ts-expect-error
          organization: props.currentOrganization.id,
        },
        (data, error, message) => {
          if (error) {
            console.error('Could not fetch user configs: ' + message);
            // @ts-expect-error
            setConfigs(null);
            return;
          }
          setConfigs(data);
        }
      );
      setWantToLoad(false);
    }
  }, [
    wantToLoad,
    props.person_id,
    props.campaign_id,
    // @ts-expect-error
    props.currentOrganization.id,
  ]);

  const [mappings, setMappings] = useState();
  useEffect(() => {
    if (
      // @ts-expect-error
      !props.currentOrganization?.id ||
      // @ts-expect-error
      props.currentOrganization?.status === CAMPAIGN_STATUSES.DEMO ||
      !props.campaign_id
    ) {
      return;
    }

    const params = {
      // @ts-expect-error
      organization: props.currentOrganization?.id,
      campaign: props.campaign_id,
    };

    ConfirmAPI.getUrlWithCache(
      '/performance/admin/get-dataset-mappings',
      'dataset_mappings',
      // @ts-expect-error
      props.userSub,
      // @ts-expect-error
      props.currentProxyPerson,
      params,
      (data) => {
        if (data?.keys) {
          setMappings(data);
        }
      },
      () => {
        // @ts-expect-error
        setMappings(null);
      }
    );
  }, [
    // @ts-expect-error
    props.currentOrganization?.status,
    // @ts-expect-error
    props.currentOrganization?.id,
    // @ts-expect-error
    props.currentProxyPerson,
    // @ts-expect-error
    props.userSub,
    props.campaign_id,
  ]);

  // we get the fields to display from props. for now, perf admin gets the fields
  // from campaign mappings but this may change in the future, and this allows the
  // component user to customize what fields to include
  const fields = useMemo(() => {
    // @ts-expect-error
    if (!mappings?.keys) {
      return undefined;
    }
    const fields = {};
    // the ordering of fields is determined by the "order" field in dataset.mappings
    // but defaults to whatever arbitrary order we get from the keys() method
    // @ts-expect-error
    const order = mappings?.order || Object.keys(mappings.keys);
    order.forEach((theirName) => {
      // @ts-expect-error
      const ourName = mappings.keys[theirName];
      const info = {
        name: ourName,
        label: theirName,
      };

      // special handling for dates and multiple-choice
      // TODO: need to handle cost_center / strip_up_to?
      if (
        // @ts-expect-error
        mappings?.transforms &&
        // @ts-expect-error
        ourName in mappings.transforms &&
        ourName.includes('_date')
      ) {
        // @ts-expect-error
        info.type = 'date';
        // extract date format from list of transforms
        // @ts-expect-error
        mappings.transforms[ourName].forEach((transformObject) => {
          const transform = Object.keys(transformObject)[0];
          const format = getDateFormatForDatasetTransform(transform);
          if (format) {
            // @ts-expect-error
            info.transform = transform;
            // @ts-expect-error
            info.format = format.pikr;
            // @ts-expect-error
            info.placeholder = format.placeholder;
            // @ts-expect-error
            info.unicode = format.unicode;
          }
        });
        // @ts-expect-error
      } else if (mappings?.values && ourName in mappings.values) {
        // @ts-expect-error
        const optionMap = mappings.values[ourName];
        if (isBooleanChoice(optionMap)) {
          // @ts-expect-error
          info.type = 'boolean';
          // @ts-expect-error
          info.options = Object.entries(mappings.values[ourName]).map(
            ([label, value]) => ({ name: label, object: label, value })
          );
          // @ts-expect-error
          info.defaultValue = mappings.values[ourName].DEFAULT ?? false;

          // because the modal holds `true` and `false`, we need to map
          // them back to valid "labels" before sending them to the backend.
          // arbitrarily choose labels (that are not "DEFAULT").
          // @ts-expect-error
          info.trueChoice = info.options.find(
            (option) => option.value === true && option.name !== 'DEFAULT'
          ).name;
          // @ts-expect-error
          info.falseChoice = info.options.find(
            (option) => option.value === false && option.name !== 'DEFAULT'
          ).name;
        } else {
          // @ts-expect-error
          info.type = 'choice';
          // @ts-expect-error
          info.options = Object.entries(mappings.values[ourName]).map(
            ([label]) => ({ name: label, object: label })
          );
        }
      } else if (ourName === 'manager_email') {
        // @ts-expect-error
        info.type = 'manager_email_editor';
      } else if (ourName === 'additional_managers_identifiers') {
        // @ts-expect-error
        info.type = 'table';
        // @ts-expect-error
        info.standaloneRow = true;
        // @ts-expect-error
        info.hide = !ifEnabled('additional_managers', true, false);
      } else if (ourName === 'status') {
        const statusNames = PERSON_STATUSES_NAMES(formatMessage);
        // @ts-expect-error
        info.type = 'choice';
        // @ts-expect-error
        info.options = [PERSON_STATUSES.ACTIVE, PERSON_STATUSES.LEAVE].map(
          (status) => ({ object: status, name: statusNames[status] })
        );
      }

      fields[theirName] = info;
    });
    return fields;
  }, [mappings, formatMessage, ifEnabled]);

  const reverseKeyMapping = useMemo(
    () =>
      Object.fromEntries(
        // @ts-expect-error
        Object.entries(mappings?.keys || {}).map(([k, v]) => [v, k])
      ),
    [mappings]
  );

  const readOnlyFields = useMemo(() => {
    const hasParticipationFields =
      Object.keys(reverseKeyMapping).findIndex((k) =>
        PARTICIPATION_FIELDS.has(k)
      ) >= 0;

    // externally-imported ratings should never be editable in the UI
    const fields = ['rating', 'final_rating'];

    if (hasParticipationFields) {
      fields.push('is_participating');
    }

    return fields;
  }, [reverseKeyMapping]);

  // render inputs into three separate steps
  const renderInputs = useCallback((inputs) => {
    const rows = [];
    for (let i = 0; i < inputs.length; i += 3) {
      // @ts-expect-error
      rows.push(inputs.slice(i, i + 3));
    }
    return (
      <>
        {rows.map((row, index) => (
          <Row key={`row-${index}`} className="align-items-top">
            {/* @ts-expect-error */}
            {row.map((input, iindex) => (
              <Col
                key={`row-${index}-col-${iindex}`}
                className={input?.props?.className ?? 'col-4'}
              >
                {input}
              </Col>
            ))}
          </Row>
        ))}
      </>
    );
  }, []);

  const belowFormContents = (
    <div className="mt-4 text-center">
      <FormattedMessage
        id="app.views.widgets.modals.modal_dataset_record_editor.questions_or_concerns"
        defaultMessage="These changes will only affect this cycle. Questions or concerns?"
      />{' '}
      <button className="btn btn-link ps-0" onClick={() => IntercomAPI('show')}>
        <FormattedMessage
          id="app.views.widgets.modals.modal_dataset_record_editor.let_us_know"
          defaultMessage="Let us know."
        />
      </button>
    </div>
  );

  const inputs = useMemo(() => {
    if (!fields) {
      return [];
    }

    const unorderedInputs = Object.entries(fields)
      // @ts-expect-error
      .filter(([_name, info]) => !info.hide)
      .map(([name, info]) => {
        // @ts-expect-error
        const ourName = mappings?.keys[name] || name;
        const input = {
          required: false,
          name: name,
          // @ts-expect-error
          label: info.label,
          labelClassName: 'small',
          autoFocus: true,
          maxLength: 100,
          hideMaxLengthIndicator: true,
          // don't allow editing of the below fields
          // as we take them from the person object
          disabled:
            FIELD_NAMES_TO_PRIORITIZE.indexOf(ourName) !== -1 ||
            readOnlyFields.indexOf(ourName) !== -1 ||
            // @ts-expect-error
            info?.disabled,
          // @ts-expect-error
          standaloneRow: !!info.standaloneRow,
          // @ts-expect-error
          className: info?.className,
        };
        // @ts-expect-error
        if (info.type === 'date') {
          // @ts-expect-error
          input.type = INPUT_TYPES.DATE_PICKER;
          input.autoFocus = false;
          // @ts-expect-error
          input.transform = info.transform;
          // @ts-expect-error
          input.dateFormat = info.format;
          // @ts-expect-error
          input.altInput = true;
          // @ts-expect-error
          input.altFormat = info.format;
          // @ts-expect-error
          input.placeholder = info.placeholder;
          // @ts-expect-error
        } else if (info.type === 'choice') {
          // @ts-expect-error
          input.type = INPUT_TYPES.SELECT;
          input.autoFocus = false;
          // @ts-expect-error
          input.clearable = true;
          // @ts-expect-error
          input.defaultOptions = info.options;
          // @ts-expect-error
          input.isOptionSelected = (option, selectValue) => {
            return option.name === selectValue.name;
          };
          // @ts-expect-error
        } else if (info.type === 'boolean') {
          // @ts-expect-error
          input.type = INPUT_TYPES.SWITCH;
          // @ts-expect-error
          input.defaultValue = info.defaultValue;
          input.autoFocus = false;
          // @ts-expect-error
        } else if (info.type === 'table') {
          // @ts-expect-error
          input.type = INPUT_TYPES.TABLE_EDITOR;
          // @ts-expect-error
          input.columns = [
            {
              name: (
                <FormattedMessage
                  id="app.views.widgets.modals.modal_dataset_record_editor.additional_managers_identifiers.email.name"
                  defaultMessage="Email"
                />
              ),
              field: 'email',
              sortable: false,
              type: INPUT_TYPES.TEXT,
            },
            ...(reverseKeyMapping?.external_id
              ? [
                  {
                    name: (
                      <FormattedMessage
                        id="app.views.widgets.modals.modal_dataset_record_editor.additional_managers_identifiers.external_id.name"
                        defaultMessage="External ID"
                      />
                    ),
                    field: 'external_id',
                    sortable: false,
                    type: INPUT_TYPES.TEXT,
                  },
                ]
              : []),
          ];
          input.autoFocus = false;
          // @ts-expect-error
        } else if (info.type === 'manager_email_editor') {
          // @ts-expect-error
          input.type = INPUT_TYPES.AUTOSUGGEST;
          input.autoFocus = false;
          // @ts-expect-error
          input.getSuggestionValue = (x) => {
            return x.object.email;
          };
          // @ts-expect-error
          input.elasticsearchOptions = {
            url: 'get-survey-responses-by-name',
            getQuery: (query) => {
              return { name: query, campaign_id: props.campaign_id };
            },
          };
        }
        return input;
      });

    // put FIELD_NAMES_TO_PRIORITIZE first in UI
    const inputsAsObject = unorderedInputs.reduce((acc, input) => {
      // @ts-expect-error
      acc[mappings?.keys[input.name]] = input;
      return acc;
    }, {});

    const prioritizedInputs = FIELD_NAMES_TO_PRIORITIZE.map(
      (i) => inputsAsObject[i]
    ).filter((x) => !!x);
    const otherInputs = unorderedInputs.filter(
      (i) =>
        // @ts-expect-error
        FIELD_NAMES_TO_PRIORITIZE.indexOf(mappings?.keys[i.name]) === -1 &&
        // @ts-expect-error
        FIELD_NAMES_TO_DEPRIORITIZE.indexOf(mappings?.keys[i.name]) === -1 &&
        !i.standaloneRow
    );

    const deprioritizedInputs = FIELD_NAMES_TO_DEPRIORITIZE.map(
      (i) => inputsAsObject[i]
    ).filter((x) => !!x);

    const standaloneRowInputs = unorderedInputs
      .filter((i) => i.standaloneRow)
      // @ts-expect-error
      .reduce((acc, input) => [...acc, { ...input, className: 'col-12' }], []);

    const inputCount =
      prioritizedInputs.length +
      otherInputs.length +
      deprioritizedInputs.length;

    const trailingPlaceholdersCount = (3 - (inputCount % 3)) % 3;

    return [
      ...prioritizedInputs,
      ...otherInputs,
      ...deprioritizedInputs,
      ...Array.from({ length: trailingPlaceholdersCount }, (_, i) => i).map(
        (i) => ({
          type: INPUT_TYPES.CUSTOM_INPUT,
          component: (
            <div key={'placeholder_' + i} className="col-4">
              {''}
            </div>
          ),
        })
      ),
      // @ts-expect-error
      ...standaloneRowInputs,
    ];
  }, [
    fields,
    readOnlyFields,
    // @ts-expect-error
    mappings?.keys,
    props.campaign_id,
    reverseKeyMapping?.external_id,
  ]);

  // translate from external (e.g. survey response config) data to useful format for this modal
  const importData = useCallback(
    (data, fields) => {
      // @ts-expect-error
      const modalData = { id: configs.id };
      Object.entries(fields).forEach(([name, info]) => {
        if (name in data) {
          // @ts-expect-error
          if (info.type === 'date') {
            modalData[name] = data[name]
              ? // @ts-expect-error
                parseDateFromUnicode(data[name], info.unicode)
              : null;
            // @ts-expect-error
          } else if (info.type === 'choice') {
            modalData[name] =
              data[name] !== undefined && data[name] !== null
                ? // @ts-expect-error
                  info.options.find((x) => x.object === data[name])
                : undefined;
            // @ts-expect-error
          } else if (info.type === 'boolean') {
            modalData[name] =
              data[name] !== undefined && data[name] !== null
                ? // @ts-expect-error
                  info.options.find((x) => x.object === data[name]).value
                : undefined;
          } else {
            modalData[name] = data[name];
          }
        } else {
          modalData[name] = undefined;
        }
      });
      return modalData;
    },
    // @ts-expect-error
    [configs.id]
  );

  // translate from modal data to external (i.e. perf admin, survey response config) data
  const exportData = useCallback(
    (modalData) => {
      // @ts-expect-error
      const data = { id: configs.id };
      // @ts-expect-error
      Object.entries(fields).forEach(([name, info]) => {
        if (name in modalData) {
          // @ts-expect-error
          if (info.type === 'date') {
            if (modalData[name] && typeof modalData[name] === 'object') {
              // @ts-expect-error
              data[name] = formatDateWithUnicode(modalData[name], info.unicode);
            } else {
              data[name] = modalData[name];
            }
            // @ts-expect-error
          } else if (info.type === 'choice') {
            if (modalData[name] && typeof modalData[name] === 'object') {
              data[name] = modalData[name].object;
            } else {
              data[name] = modalData[name];
            }
            // @ts-expect-error
          } else if (info.type === 'boolean') {
            if (modalData[name] === undefined) {
              // @ts-expect-error
              modalData[name] = info.defaultValue;
            }

            // @ts-expect-error
            data[name] = modalData[name] ? info.trueChoice : info.falseChoice;
            // @ts-expect-error
          } else if (info.type === 'manager_email_editor') {
            if (typeof modalData[name] === 'object') {
              data[reverseKeyMapping.manager_email] =
                modalData[name].object.email;
              if ('manager_full_name' in reverseKeyMapping) {
                data[reverseKeyMapping.manager_full_name] =
                  modalData[name].object.name;
              }
              if ('manager_external_id' in reverseKeyMapping) {
                if (modalData[name].object.external_id) {
                  data[reverseKeyMapping.manager_external_id] =
                    modalData[name].object.external_id;
                }
              }
            } else {
              data[name] = modalData[name];
            }
          } else {
            data[name] = modalData[name];
          }
        } else {
          data[name] = undefined;
        }
      });
      return data;
    },
    // @ts-expect-error
    [reverseKeyMapping, fields, configs.id]
  );

  const dataObject = useMemo(() => {
    if (!fields || !configs) {
      return;
    }
    return importData(configs, fields);
  }, [configs, fields, importData]);

  return (
    <ModalEditor
      size="lg"
      method="PATCH"
      url="/survey-response-configs"
      title={`${formatMessage({
        id: 'app.views.widgets.modals.modal_dataset_record_editor.title.edit',
        defaultMessage: 'Edit',
      })} ${
        configs[reverseKeyMapping.preferred_given_name] ||
        configs[reverseKeyMapping.legal_given_name] ||
        configs[reverseKeyMapping.given_name]
      } ${configs[reverseKeyMapping.family_name]} (${props.campaign_name})`}
      submitText={formatMessage({
        id: 'app.views.widgets.modals.modal_dataset_record_editor.submit_text.save',
        defaultMessage: 'Save',
      })}
      renderInputs={renderInputs}
      // @ts-expect-error
      isHorizontalLayout={true}
      object={dataObject}
      inputs={inputs}
      isOpen={props.isOpen}
      toggle={props.toggle}
      onClosed={props.onClosed}
      transformObjectBeforeSubmit={exportData}
      callback={props.onSaved}
      belowFormContents={belowFormContents}
    />
  );
};

const ModalDatasetRecordEditor_propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onClosed: PropTypes.func,
  onSaved: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  campaign_name: PropTypes.string,
  campaign_id: PropTypes.number,
  person_id: PropTypes.number,
};

type Props = PropTypes.InferProps<typeof ModalDatasetRecordEditor_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(ModalDatasetRecordEditor));
