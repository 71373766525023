import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes, { InferProps } from 'prop-types';
import React, { FC, useMemo, useRef } from 'react';

import Loading from '../Widgets/Loading';
import PerformancePeersSelectorPage from './Peers/PerformancePeersSelectorPage';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { connect } from 'react-redux';
import { getOptionalPeers } from '../../utils/models/Performance';
import { setCurrentPerfSurveyResponse } from '../../actions';
import { withRouter } from 'react-router-dom';

const MAX_OPTIONAL_PEERS = 2;

const PerformanceStepAssessOptionalPeers: FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const campaign = props.campaign;
  const setCampaign = props.setCampaign;

  const assessPeersLearnMoreRef = useRef();

  const peers = useMemo(
    // @ts-expect-error
    () => getOptionalPeers(props.me?.id, campaign, MAX_OPTIONAL_PEERS),
    // @ts-expect-error
    [campaign, props.me?.id]
  );

  if (!peers) {
    return <Loading />;
  }

  const additionalPeerSlots = MAX_OPTIONAL_PEERS - peers?.length;

  return (
    <PerformancePeersSelectorPage
      campaign={campaign}
      setCampaign={setCampaign}
      title={formatMessage({
        id: 'app.views.performance.performance_step_assess_optional_peers.title.optional_give_feedback_to_peers',
        defaultMessage: 'Optional: give feedback to peers you choose.',
      })}
      peers={peers}
      additionalPeerSlots={additionalPeerSlots}
      isOptional={true}
    >
      <></>
      <div className="mb-4">
        <FormattedMessage
          id="app.views.performance.performance_step_assess_optional_peers.provide_feedback"
          defaultMessage="
        Provide feedback to up to two peers of your choice."
        />{' '}
        {/* @ts-expect-error */}
        <span className="text-primary" ref={assessPeersLearnMoreRef}>
          <FormattedMessage
            id="app.views.performance.performance_step_assess_optional_peers.learn_more"
            defaultMessage="
          Learn more
        "
          />
        </span>
        <br />
        <UncontrolledPopover
          delay={50}
          trigger="hover"
          placement="top"
          // @ts-expect-error
          target={assessPeersLearnMoreRef}
        >
          <FormattedMessage
            id="app.views.performance.performance_step_assess_optional_peers.learn_more.popover"
            defaultMessage="
          If there is someone you believe you should have been matched with, or
          someone who would benefit from your feedback, you can provide that
          here. If not, you can skip this step.
        "
          />
        </UncontrolledPopover>
      </div>
    </PerformancePeersSelectorPage>
  );
};

const PerformanceStepAssessOptionalPeers_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = InferProps<typeof PerformanceStepAssessOptionalPeers_propTypes>;

const mapStateToProps = (state) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error
)(withRouter(React.memo(PerformanceStepAssessOptionalPeers)));
