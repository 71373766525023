import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
} from 'reactstrap';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Bar } from 'react-chartjs-2';
import { CHART_COLORS } from '../../../consts/consts';
import CardHeaderTitle from './CardHeaderTitle';
import EmptyState from '../EmptyState';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { isEmpty } from 'lodash';
import { useComponentId } from '../../../utils/util/hooks';

const DEFAULT_ROW_LIMIT = 8;

function getCounter(counters, type, entity, option) {
  return type in counters &&
    entity in counters[type] &&
    option in counters[type][entity]
    ? counters[type][entity][option]
    : 0;
}

const TableHeadCell = (props) => {
  const componentId = useComponentId();
  const id = `distinfocard-tableheadcell-${componentId}`;
  return (
    <>
      <th
        role="button"
        style={{
          textAlign: props.index === 0 ? 'left' : 'right',
        }}
        onClick={props.onClick}
      >
        <span id={id} className="text-muted">
          {props.col}
        </span>
        {props.sortdir !== undefined && (
          <>
            <i className={`fe fe-chevron-${props.sortdir ? 'up' : 'down'}`} />
          </>
        )}
      </th>
      <UncontrolledPopover placement="top" target={id} trigger="hover legacy">
        {props.label}
      </UncontrolledPopover>
    </>
  );
};

const ColorCircle = (props) => {
  return (
    <span
      className="me-1"
      style={{
        height: '12px',
        width: '12px',
        backgroundColor: props.color,
        borderRadius: '50%',
        display: 'inline-block',
      }}
    ></span>
  );
};

const TableBodyCell = (props) => {
  return (
    <td
      style={{
        textAlign: props.index === 0 ? 'left' : 'right',
      }}
    >
      {props.index === 0 && <ColorCircle color={props.color} />}
      {props.item}
      {props.index > 0 && '%'}
    </td>
  );
};

function toPct(count, total) {
  if (total <= 0) {
    return 0;
  }
  return Math.round((100.0 * count) / total);
}

const DistributionInfoCard: FC<Props> = ({
  collapsed = false,
  counters: propsCounters = {},
  emptyStateText = 'There is no data available.',
  links = [],
  showOther = true,
  ...props
}) => {
  const options = useMemo(() => {
    return [...props.options];
  }, [props.options]);

  const counters = useMemo(
    () =>
      Object.assign({}, propsCounters, {
        baseline: props.baseline,
      }),
    [props.baseline, propsCounters]
  );

  // General fields
  const datasets = useMemo(() => {
    const rv = [];
    let colorIndex = 0;

    if (props.baseline) {
      // @ts-expect-error
      rv.push({
        label: 'Overall',
        key: 'Other',
        type: 'baseline',
        tooltip: 'Overall',
        color: CHART_COLORS[colorIndex++],
      });
    }

    // @ts-expect-error
    props.datasets.forEach((d) => {
      // @ts-expect-error
      if (counters && counters[d.type]) {
        // @ts-expect-error
        Object.keys(counters[d.type])
          .sort()
          .filter((ds) => !ds.startsWith('_')) // keys beginning with '_' are special and not part of the data
          .forEach((ds) => {
            if (!showOther && ds === 'Other') {
              return;
            }
            // @ts-expect-error
            rv.push({
              // @ts-expect-error
              label: d.key === 'overall' ? d.label : ds,
              key: ds,
              // @ts-expect-error
              type: d.type,
              // @ts-expect-error
              tooltip: d.tooltip,
              color: CHART_COLORS[colorIndex++],
            });
          });
      }
    });
    return rv;
  }, [counters, props.baseline, props.datasets, showOther]);

  // Chart-related fields
  const chartData = useMemo(() => {
    // @ts-expect-error

    const labels = options.map((opt) => opt.name);
    if (isEmpty(counters)) {
      return { labels, datasets: [] };
    }

    const chartDatasets = datasets.map((d) => {
      return {
        // @ts-expect-error
        datasetKeyProvider: () => `${d.key}-${d.label}-${d.color}`,
        // @ts-expect-error
        tooltip: d.tooltip,
        // @ts-expect-error
        label: d.label,
        // @ts-expect-error
        backgroundColor: d.color,
        data: options.map((opt) =>
          // @ts-expect-error
          d.type in counters
            ? toPct(
                // @ts-expect-error
                d.key in counters[d.type]
                  ? // @ts-expect-error
                    counters[d.type][d.key][opt.key] || 0
                  : 0,
                // @ts-expect-error
                counters[d.type][d.key]?._total || 0
              )
            : []
        ),
      };
    });

    return {
      labels,
      datasets: chartDatasets,
    };
  }, [counters, options, datasets]);

  const chartOptions = useMemo(() => {
    const max = Math.max(
      // @ts-expect-error
      ...chartData.datasets.map((dataset) => Math.max(...dataset.data))
    );
    const maxRoundedUp = Math.ceil((1.0 * max) / 10.0) * 10;
    function getRawValue(datasetIndex, dataIndex) {
      const d = datasets[datasetIndex];
      const o = options[dataIndex];
      // @ts-expect-error
      return getCounter(counters, d.type, d.key, o.key);
    }
    function getRawTotal(datasetIndex) {
      const d = datasets[datasetIndex];
      // @ts-expect-error
      return getCounter(counters, d.type, d.key, '_total');
    }
    return {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {
          // @ts-expect-error
          title: ([item]) => props?.options[item.index]?.label || '',
          label: (tooltipItem) => {
            const ds = chartData.datasets[tooltipItem.datasetIndex];
            return (
              '<span class="ms-2">' +
              (ds?.tooltip ? ds.tooltip : ds.label) +
              ': ' +
              getRawValue(tooltipItem.datasetIndex, tooltipItem.index) +
              ' of ' +
              getRawTotal(tooltipItem.datasetIndex) +
              ' (' +
              tooltipItem.yLabel +
              '%)</span>'
            );
          },
        },
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              labelString: '%',
            },
            ticks: {
              beginAtZero: true,
              max: maxRoundedUp,
              callback: (value) => `${value}%`,
              stepSize: maxRoundedUp >= 60 ? 20 : maxRoundedUp <= 20 ? 5 : 10,
            },
          },
        ],
      },
    };
  }, [chartData.datasets, datasets, options, counters, props?.options]);

  // Table-related fields
  const [rowLimit, setRowLimit] = useState(DEFAULT_ROW_LIMIT);
  const [sortBy, setSortBy] = useState(undefined);
  const [sortdirs, setSortdirs] = useState(undefined);
  const [tableData, setTableData] = useState(undefined);
  const tableColumns = useMemo(
    // @ts-expect-error
    () => ['', ...options.map((opt) => opt.name)],
    [options]
  );

  const doSort = useCallback(
    (index) => {
      // @ts-expect-error
      const direction = index === sortBy ? !sortdirs[index] : sortdirs[index];
      // @ts-expect-error
      const newTableData = [...tableData];
      newTableData.sort((a, b) =>
        a[index] < b[index] ? (direction ? -1 : 1) : direction ? 1 : -1
      );
      // @ts-expect-error
      const newSortdirs = [...sortdirs];
      newSortdirs[index] = direction;
      // @ts-expect-error
      setSortdirs(newSortdirs);
      setSortBy(index);
      // @ts-expect-error
      setTableData(newTableData);
    },
    [sortBy, sortdirs, tableData]
  );

  useEffect(() => {
    if (sortdirs === undefined) {
      // @ts-expect-error
      setSortdirs(tableColumns.map((x, index) => index === 0));
    }
  }, [sortdirs, tableColumns]);

  useEffect(() => {
    const newTableData = chartData.datasets.map((d) => {
      const row = [d.label, ...d.data];
      // @ts-expect-error
      row.color = d.backgroundColor;
      return row;
    });
    // @ts-expect-error
    setTableData(newTableData);
  }, [chartData]);

  if (datasets.length === 0 || isEmpty(counters)) {
    return (
      // @ts-expect-error
      <Card className={props.className} role={props.role} style={props.style}>
        <CardHeader>
          <CardHeaderTitle>{props.title}</CardHeaderTitle>
        </CardHeader>
        <CardBody>
          {/* @ts-expect-error */}
          <EmptyState title={emptyStateText} />
        </CardBody>
      </Card>
    );
  }

  if (collapsed) {
    return null;
  }

  return (
    // @ts-expect-error
    <Card className={props.className} role={props.role} style={props.style}>
      <CardHeader>
        <CardHeaderTitle>{props.title}</CardHeaderTitle>
      </CardHeader>
      <CardBody className="position-relative">
        <Row xs="1" sm="12">
          <Col className="col-12 col-md-6">
            <Bar data={chartData} options={chartOptions} />
          </Col>
          <Col className="col-12 col-md-6">
            <Table className="card-table table-sm">
              <thead>
                <tr>
                  {tableColumns.map((col, index) => (
                    <TableHeadCell
                      key={index}
                      // @ts-expect-error
                      label={index > 0 ? options[index - 1].label : ''}
                      col={col}
                      index={index}
                      // @ts-expect-error
                      sortdir={sortBy === index ? sortdirs[index] : undefined}
                      onClick={() => doSort(index)}
                    />
                  ))}
                </tr>
              </thead>
              <tbody className="list">
                {tableData &&
                  // @ts-expect-error
                  tableData.map(
                    (row, index) =>
                      index < rowLimit && (
                        <tr key={index}>
                          {row.map((item, rindex) => (
                            <TableBodyCell
                              key={rindex}
                              item={item}
                              index={rindex}
                              color={row.color}
                            />
                          ))}
                        </tr>
                      )
                  )}
              </tbody>
            </Table>
            {/* @ts-expect-error */}
            {!tableData || tableData.length === 0 ? (
              <></>
            ) : // @ts-expect-error
            tableData.length > rowLimit ? (
              <Button
                className="mb-4"
                color="link"
                onClick={() => {
                  // @ts-expect-error
                  setRowLimit(tableData.length + 1);
                }}
              >
                <FormattedMessage
                  id="app.views.widgets.cards.distribution_info_card.show_more_rows"
                  defaultMessage="Show more rows"
                />
              </Button>
            ) : // @ts-expect-error
            tableData.length > DEFAULT_ROW_LIMIT ? (
              <Button
                className="mb-4"
                color="link"
                onClick={() => {
                  setRowLimit(DEFAULT_ROW_LIMIT);
                }}
              >
                <FormattedMessage
                  id="app.views.widgets.cards.distribution_info_card.show_fewer_rows"
                  defaultMessage="Show fewer rows"
                />
              </Button>
            ) : (
              <></>
            )}
            {links &&
              links.map((link, index) => (
                <Row key={index} xs="1" sm="12">
                  <Col className="col-12">
                    <Button color="link" onClick={(e) => link.action(e)}>
                      {link.text}
                    </Button>
                  </Col>
                </Row>
              ))}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const DistributionInfoCard_propTypes = {
  baseline: PropTypes.object,
  className: PropTypes.object,
  /** is card expanded or collapsed? */
  collapsed: PropTypes.bool,
  /** backing data -- counters[type][dataset][option] */
  counters: PropTypes.object.isRequired,
  /** datasets to be rendered, in order to be shown */
  datasets: PropTypes.arrayOf(PropTypes.object),
  /** message to be shown when no data available */
  emptyStateText: PropTypes.string,
  /** range of options being counted, in order to be shown */
  links: PropTypes.arrayOf(PropTypes.any),
  /** range of options being counted, in order to be shown */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  showOther: PropTypes.bool,
  style: PropTypes.object,
  /** title of card */
  title: PropTypes.string.isRequired,
};

type Props = PropTypes.InferProps<typeof DistributionInfoCard_propTypes>;

export default React.memo(DistributionInfoCard);
