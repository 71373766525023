import {
  Button,
  Card,
  CardHeader,
  Col,
  Progress,
  Row,
  Table,
} from 'reactstrap';
import React, { FC, Fragment, useEffect, useState } from 'react';

import { CELL_TYPES } from '../Dashboards/CardRowDashboard/consts';
import CardHeaderTitle from './CardHeaderTitle';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const DEFAULT_ROW_LIMIT = 15;

// Sort table data given the column index to sort by, and the direction to sort in.
// Returns a sorted copy of the input data without modifying the input.
const sortTableData = (data, sortBy, ascending = true) => {
  const sorted = [...data];
  sorted.sort((aRow, bRow) => {
    const a = aRow[sortBy];
    const b = bRow[sortBy];
    const aVal = typeof a === 'object' ? a?.sortKey || a.name : a;
    const bVal = typeof b === 'object' ? b?.sortKey || b.name : b;
    const retval = aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
    return ascending ? retval : retval * -1;
  });
  return sorted;
};

const TableCardRow = (props) => {
  return (
    <tr>
      {props.row.map((item, index) => (
        <td key={index} className={`text-${props.fields[index].align}`}>
          {props.fields[index].type === CELL_TYPES.PROGRESS ? (
            <Row>
              <Col className="col-4 pe-0">
                <span className="text-nowrap">
                  {item}
                  {'%'}
                </span>
              </Col>
              <Col className="ps-0 mt-2">
                <Progress
                  className="progress-sm"
                  color="success"
                  value={item}
                />
              </Col>
            </Row>
          ) : typeof item !== 'object' ? (
            item
          ) : item.link ? (
            <Link to={item.link} target="_blank" rel="noopener noreferrer">
              {item.name}
            </Link>
          ) : (
            item.name
          )}
        </td>
      ))}
    </tr>
  );
};

const TableCard: FC<Props> = (props) => {
  const [rowLimit, setRowLimit] = useState(DEFAULT_ROW_LIMIT);
  const [rowData, setRowData] = useState(undefined);
  const [currentSortBy, setCurrentSortBy] = useState(undefined);
  const [sortDirections, setSortDirections] = useState(undefined); // array of booleans, true for ascending

  useEffect(() => {
    const newSortDirections = props.fields.map((f) =>
      // @ts-expect-error
      f?.defaultSort === 'descending' ? false : true
    );
    // @ts-expect-error
    setSortDirections(newSortDirections);
    // @ts-expect-error
    setCurrentSortBy(props.defaultSortBy);
  }, [props.fields, props.defaultSortBy]);

  useEffect(() => {
    if (sortDirections !== undefined) {
      const sortedRowData = sortTableData(
        props.rowData,
        currentSortBy,
        // @ts-expect-error
        sortDirections[currentSortBy]
      );
      // @ts-expect-error
      setRowData(sortedRowData);
    }
  }, [props.rowData, sortDirections, currentSortBy]);

  return (
    // @ts-expect-error
    <Card className={props.className} role={props.role} style={props.style}>
      <CardHeader>
        <CardHeaderTitle>{props.title}</CardHeaderTitle>
      </CardHeader>
      <Table
        className="card-table table-sm table-responsive-md"
        style={{ tableLayout: 'fixed' }}
      >
        <thead>
          <tr>
            {/* @ts-expect-error */}
            {props.fields.map(({ name }, index) => (
              <th
                // @ts-expect-error
                className={`text-${props.fields[index].align}`}
                key={index}
                role="button"
                onClick={() => {
                  if (currentSortBy === index) {
                    setSortDirections(
                      // @ts-expect-error
                      sortDirections.map((d, dindex) =>
                        dindex === index ? !d : d
                      )
                    );
                  } else {
                    // @ts-expect-error
                    setCurrentSortBy(index);
                  }
                }}
              >
                <span className="text-muted sort">{name}</span>
                {currentSortBy === index ? (
                  <>
                    <i
                      className={`fe fe-chevron-${
                        // @ts-expect-error
                        sortDirections[index] ? 'up' : 'down'
                      }`}
                    />
                  </>
                ) : (
                  <></>
                )}
              </th>
            ))}
          </tr>
        </thead>
        {/* @ts-expect-error */}
        {!rowData || rowData.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={props.fields.length}>{props.emptyStateText}</td>
            </tr>
          </tbody>
        ) : (
          <tbody className="list">
            {/* @ts-expect-error */}
            {rowData.map((row, index) => {
              if (index < rowLimit) {
                return (
                  <TableCardRow key={index} row={row} fields={props.fields} />
                );
              } else {
                return <Fragment key={index}></Fragment>;
              }
            })}
          </tbody>
        )}
      </Table>
      {/* @ts-expect-error */}
      {!rowData || rowData.length === 0 ? (
        <></>
      ) : // @ts-expect-error
      rowData.length > rowLimit ? (
        <Button
          className="mb-4"
          color="link"
          onClick={() => {
            // @ts-expect-error
            setRowLimit(rowData.length + 1);
          }}
        >
          <FormattedMessage
            id="app.views.widgets.cards.table_card.show_more"
            defaultMessage="
          Show more
        "
          />
        </Button>
      ) : // @ts-expect-error
      rowData.length > DEFAULT_ROW_LIMIT ? (
        <Button
          className="mb-4"
          color="link"
          onClick={() => {
            setRowLimit(DEFAULT_ROW_LIMIT);
          }}
        >
          <FormattedMessage
            id="app.views.widgets.cards.table_card.show_less"
            defaultMessage="
          Show less
        "
          />
        </Button>
      ) : (
        <></>
      )}
    </Card>
  );
};

const TableCard_propTypes = {
  className: PropTypes.object,
  defaultSortBy: PropTypes.number.isRequired,
  emptyStateText: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

type Props = PropTypes.InferProps<typeof TableCard_propTypes>;

export default React.memo(TableCard);
