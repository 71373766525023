import { Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ICONS, LEVELING_FRAMEWORK } from '../../../consts/consts';
import React, { FC, useMemo, useRef } from 'react';

import { CAMPAIGN_STATUSES } from '../../../utils/models/Campaign';
import { Link } from 'react-router-dom';
import PersonResponseRow from './PersonResponseRow';
import { PrivateDataBadge } from '../components/PrivateDataBadge';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import RichTextViewer from '../../Widgets/Inputs/RichTextViewer';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { connect } from 'react-redux';

export const NestedPerformanceHeaderBox: FC<{
  id?: string;
  title: string | object;
  helperText?: any;
  private?: boolean;
  body: any;
  width?: string;
}> = (props) => {
  const popoverTargetRef = useRef(null);

  return (
    <Col className={'col pe-0 ' + props.width || ''}>
      {props.title && (
        <h6 className="text-uppercase text-secondary mb-3">
          {props.title}
          {props.helperText && (
            <>
              <i
                ref={popoverTargetRef}
                className="ms-1 fe fe-help-circle text-primary"
              />
              <UncontrolledPopover
                placement="top"
                trigger="hover"
                target={popoverTargetRef}
              >
                {props.helperText}
              </UncontrolledPopover>
            </>
          )}
          {props.private && <PrivateDataBadge />}
        </h6>
      )}
      {props.body}
    </Col>
  );
};

const GOLD_STARS_SCROLL_TO_DEFAULT = () => {
  /* DO NOTHING */
};

const INFLUENCE_SCROLL_TO_DEFAULT = () => {
  /* DO NOTHING */
};

const HEADS_UPS_SCROLL_TO_DEFAULT = () => {
  /* DO NOTHING */
};

const RATING_SCALE_DEFAULT = [];

const RatingHeader: FC<Props> = ({
  goldStarCount = 0,
  influenceCount = 0,
  headsUpCount = 0,
  goldStarScrollTo = GOLD_STARS_SCROLL_TO_DEFAULT,
  influenceScrollTo = INFLUENCE_SCROLL_TO_DEFAULT,
  headsUpScrollTo = HEADS_UPS_SCROLL_TO_DEFAULT,
  ratingScale = RATING_SCALE_DEFAULT,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const isDemo = useMemo(
    // @ts-expect-error
    () => props.campaign.status === CAMPAIGN_STATUSES.DEMO,
    // @ts-expect-error
    [props.campaign.status]
  );

  const hasRatingDescriptions = useMemo(
    // @ts-expect-error
    () => ratingScale?.length && ratingScale.find((entry) => entry.description),
    [ratingScale]
  );

  const ratingPopover = useMemo(() => {
    if (!hasRatingDescriptions) {
      return '';
    }

    // @ts-expect-error
    return ratingScale.reduce((html, r) => {
      // @ts-expect-error
      const textMuted = props.ratingName !== r.name ? 'text-muted' : '';
      // @ts-expect-error
      html += `<p class=${textMuted}> <span class="fw-bold">${r.name}</span>: `;
      const noDescriptionProvided = formatMessage({
        id: 'app.views.person.person_performance.rating_header.no_description_provided',
        defaultMessage: 'No description provided',
      });
      html +=
        // @ts-expect-error
        r.description ||
        `<span class="fst-italic">${noDescriptionProvided}</span></p>`;
      return html;
    }, '');
  }, [ratingScale, props.ratingName, hasRatingDescriptions, formatMessage]);

  const monthsInRoleRef = useRef(null);

  const monthsInRole = useMemo(() => {
    const months = isDemo ? '12' : props.monthsInRole || '';
    if (!months) {
      return '';
    }
    return formatMessage(
      {
        id: 'app.views.person.person_performance.rating_header.months_in_role',
        defaultMessage:
          '{months} {months, plural, one {month} other {months}} in role',
      },
      { months }
    );
  }, [isDemo, props.monthsInRole, formatMessage]);

  const roleLevelHeader = useMemo(() => {
    const optionallyGetLevelsLink = (obj) =>
      // @ts-expect-error
      props.features?.levels?.enabled ? (
        <>
          (
          <Link
            to={LEVELING_FRAMEWORK(formatMessage).path}
            className="text-primary"
          >
            {obj}
          </Link>
          )
        </>
      ) : (
        <>({obj})</>
      );
    // @ts-expect-error
    const role = props.role || (
      <span className="text-muted">
        <FormattedMessage
          id="app.views.person.person_performance.rating_header.no_title_provided"
          defaultMessage="No title provided"
        />
      </span>
    );

    const title = (
      <>
        {formatMessage({
          id: 'app.views.person.person_performance.rating_header.role',
          defaultMessage: 'Role',
        })}{' '}
        {/* @ts-expect-error */}
        {props.role && props.level && (
          <>
            {formatMessage({
              id: 'app.views.person.person_performance.rating_header.level',
              defaultMessage: '(Level)',
            })}
          </>
        )}
      </>
    );
    return (
      <NestedPerformanceHeaderBox
        id="role-level-header"
        title={title}
        body={
          <>
            <div className="h3 mb-2">
              {role} {/* @ts-expect-error */}
              {props.role &&
                // @ts-expect-error
                props.level &&
                // @ts-expect-error
                optionallyGetLevelsLink(props.level)}
            </div>
            {props.showTimeInRole && monthsInRole ? (
              <>
                <div className="small text-muted">
                  <span ref={monthsInRoleRef}>
                    {monthsInRole}
                    <i className="fs-6 ms-1 me-2 fe fe-help-circle text-primary" />
                  </span>
                </div>
                <UncontrolledPopover
                  placement="bottom"
                  trigger="hover"
                  target={monthsInRoleRef}
                >
                  <FormattedMessage
                    id="app.views.person.person_performance.months_in_role.helper"
                    defaultMessage="How many months the person has been in their current role, calculated from their first day in their current role to the day this performance cycle began."
                  />
                </UncontrolledPopover>
              </>
            ) : (
              <></>
            )}
          </>
        }
      />
    );
  }, [
    // @ts-expect-error
    props.features,
    // @ts-expect-error
    props.level,
    monthsInRole,
    // @ts-expect-error
    props.role,
    props.showTimeInRole,
    formatMessage,
  ]);

  return (
    <Col>
      <Card>
        <CardBody>
          <Row>
            {props.ratingName !== null && (
              <NestedPerformanceHeaderBox
                id="final-rating-header"
                title={formatMessage({
                  id: 'app.views.person.person_performance.rating_header.title.final_rating',
                  defaultMessage: 'Final rating',
                })}
                body={
                  <div className="h3 mb-0">
                    <span id="final-rating-name">
                      {props.ratingName}{' '}
                      {ratingPopover && (
                        <i className="small fe fe-help-circle text-primary" />
                      )}
                    </span>
                    {ratingPopover && (
                      <UncontrolledPopover
                        placement="right"
                        trigger="click hover"
                        target="final-rating-name"
                      >
                        <RichTextViewer model={ratingPopover} expanded={true} />
                      </UncontrolledPopover>
                    )}
                  </div>
                }
              />
            )}
            {roleLevelHeader}
            {props.showTimeAtOrganization &&
              // @ts-expect-error
              (props.campaign?.status === CAMPAIGN_STATUSES.DEMO ||
                typeof props.monthsAtOrganization !== 'undefined') && (
                <NestedPerformanceHeaderBox
                  id="time-at-organization-header"
                  title={formatMessage(
                    {
                      id: 'app.views.person.person_performance.rating_header.title.time_at_organization',
                      defaultMessage: 'Time at {organizationName}',
                    },
                    {
                      organizationName: props.organizationName,
                    }
                  )}
                  body={
                    <>
                      <div className="h3 mb-0">
                        <FormattedMessage
                          id="app.views.person.person_performance.rating_header.months_at_organization"
                          defaultMessage="{months} {months, plural, one {month} other {months}}"
                          values={{
                            months:
                              // @ts-expect-error
                              props.campaign.status === CAMPAIGN_STATUSES.DEMO
                                ? '12'
                                : props.monthsAtOrganization,
                          }}
                        />
                      </div>
                    </>
                  }
                  helperText={formatMessage(
                    {
                      id: 'app.views.person.person_performance.rating_header.helper.time_at_organization',
                      defaultMessage:
                        'How many months the person has been at {organizationName}, calculated from their most recent start date to the day this performance cycle began.',
                    },
                    {
                      organizationName: props.organizationName,
                    }
                  )}
                />
              )}
          </Row>
          {props.showManagerOnlyPerformanceDetails && (
            <Row className="mt-5">
              <NestedPerformanceHeaderBox
                id="influence-summary-header"
                title={formatMessage({
                  id: 'app.views.person.person_performance.rating_header.title.influence',
                  defaultMessage: 'Influence',
                })}
                private={!props.showInfluenceSection}
                helperText={props.influenceHelperText}
                body={
                  <>
                    <div
                      className="h3 mb-0"
                      // @ts-expect-error
                      onClick={influenceScrollTo}
                      role="button"
                    >
                      <span
                        className={ICONS.INFLUENCE + ' me-2 position-relative'}
                        style={{ top: '1px' }}
                      ></span>
                      {influenceCount}
                    </div>
                  </>
                }
              />
              <NestedPerformanceHeaderBox
                id="gold-star-summary-header"
                title={formatMessage({
                  id: 'app.views.person.person_performance.rating_header.title.gold_stars',
                  defaultMessage: 'Gold stars',
                })}
                helperText={props.goldStarHelperText}
                private={!props.showGoldStarSection}
                body={
                  <>
                    <div
                      className="h3 mb-0"
                      // @ts-expect-error
                      onClick={goldStarScrollTo}
                      role="button"
                    >
                      <span
                        className={ICONS.GOLD_STAR + ' me-2 position-relative'}
                        style={{ top: '1px' }}
                      ></span>
                      {goldStarCount}
                    </div>
                  </>
                }
              />
              <NestedPerformanceHeaderBox
                id="heads-up-summary-header"
                title={formatMessage({
                  id: 'app.views.person.person_performance.rating_header.title.heads_ups',
                  defaultMessage: 'Heads ups',
                })}
                helperText={props.headsUpHelperText}
                private={true}
                body={
                  <>
                    <div
                      className="h3 mb-0"
                      // @ts-expect-error
                      onClick={headsUpScrollTo}
                      role="button"
                    >
                      <span
                        className={ICONS.HEADS_UP + ' me-2 position-relative'}
                        style={{ top: '1px' }}
                      ></span>
                      {headsUpCount}
                    </div>
                  </>
                }
              />
            </Row>
          )}
          {/* @ts-expect-error */}
          {props.managerRelationshipsWithFeedback?.length > 0 &&
            props.showManagerOnlyPerformanceDetails && (
              <div className="mt-5 small">
                <h6 className="text-uppercase text-muted mb-2">
                  <FormattedMessage
                    id="app.views.person.person_performance.rating_header.manager_comments"
                    defaultMessage="Manager comments"
                  />{' '}
                  {
                    <span className="py-2 badge rounded-pill me-2 bg-warning-soft">
                      <i className={'fe fe-lock'} />{' '}
                      <FormattedMessage
                        id="app.views.person.person_performance.rating_header.private"
                        defaultMessage="Private"
                      />
                    </span>
                  }
                </h6>
                <PersonResponseRow
                  // @ts-expect-error
                  author={props.manager}
                  text={
                    // @ts-expect-error
                    props.managerRelationshipsWithFeedback[0]?.rating_comments
                      ? // @ts-expect-error
                        props.managerRelationshipsWithFeedback[0]
                          .rating_comments
                      : formatMessage({
                          id: 'app.views.person.person_performance.rating_header.no_comments_provided',
                          defaultMessage: 'No comments provided',
                        })
                  }
                  last={true}
                />
              </div>
            )}
        </CardBody>
      </Card>
    </Col>
  );
};

const RatingHeader_propTypes = {
  ratingName: PropTypes.string,
  ratingScale: PropTypes.arrayOf(PropTypes.object),
  showTimeAtOrganization: PropTypes.bool,
  showTimeInRole: PropTypes.bool,
  showManagerOnlyPerformanceDetails: PropTypes.bool,
  managerRelationshipsWithFeedback: PropTypes.arrayOf(PropTypes.any),
  manager: PropTypes.object,
  campaign: PropTypes.object,
  monthsAtOrganization: PropTypes.number,
  organizationName: PropTypes.string,
  monthsInRole: PropTypes.number,
  goldStarCount: PropTypes.number,
  influenceCount: PropTypes.number,
  headsUpCount: PropTypes.number,
  goldStarScrollTo: PropTypes.func,
  influenceScrollTo: PropTypes.func,
  headsUpScrollTo: PropTypes.func,
  goldStarHelperText: PropTypes.string,
  influenceHelperText: PropTypes.string,
  headsUpHelperText: PropTypes.string,
  showGoldStarSection: PropTypes.bool,
  showInfluenceSection: PropTypes.bool,
  width: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof RatingHeader_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { features } = state;

  return {
    features,
  };
};

export default connect(mapStateToProps)(React.memo(RatingHeader));
