import { Button, Col, Row } from 'reactstrap';
import {
  DEVELOPMENT_PLANS_BASE_URL_PATH,
  DevelopmentPlanStatus,
  DevelopmentPlan as DevelopmentPlanType,
} from 'utils/models/DevelopmentPlan';
import { Features, Person, ReduxState } from 'types';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useMemo, useState } from 'react';

import DevelopmentPlan from './DevelopmentPlan';
import DevelopmentPlansFeatureDisabled from './DevelopmentPlansFeatureDisabled';
import DevelopmentPlansTable from './DevelopmentPlansTable';
import EmptyState from 'views/Widgets/EmptyState';
import Loading from 'views/Widgets/Loading';
import ModalDevelopmentPlanEditorButton from './ModalDevelopmentPlanEditorButton';
import Page from 'views/Layout/Pages/Page';
import { connect } from 'react-redux';
import { useConfirmApiWithDefault } from 'utils/api/ApiHooks';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

type DevelopmentPlan = {
  id: number;
  status: DevelopmentPlanStatus;
};

interface Props {
  embedded?: boolean;
  person?: Person;
  hideHeaderControls?: boolean;
  features: Features;
}

const DevelopmentPlansDashboard: FC<Props> = ({
  embedded = false,
  person,
  hideHeaderControls = false,
  features,
}) => {
  const { formatMessage } = useIntl();

  const [filterStatuses, setFilterStatuses] = useState<string[]>([]);
  const [selectedDevelopmentPlanId, setSelectedDevelopmentPlanId] = useState<
    number | undefined
  >(undefined);

  const [developmentPlans, setDevelopmentPlans] = useState<
    DevelopmentPlanType[]
  >([]);

  const toggleFilterStatuses = useCallback(
    (status) => {
      if (!filterStatuses.includes(status)) {
        setFilterStatuses([...filterStatuses, status]);
      } else {
        setFilterStatuses(filterStatuses.filter((i) => i !== status));
      }
    },
    [filterStatuses]
  );

  const { data, status } = useConfirmApiWithDefault<DevelopmentPlanType[]>({
    method: 'GET',
    url: DEVELOPMENT_PLANS_BASE_URL_PATH,
    defaultValue: [],
    params: { person_id: person?.id },
  });

  useDeepCompareEffectNoCheck(() => {
    setDevelopmentPlans(data);
  }, [data]);

  const filteredDevelopmentPlans = useMemo(
    () =>
      developmentPlans
        .filter(
          (developmentPlan) =>
            !filterStatuses.length ||
            filterStatuses.includes(developmentPlan.status)
        )
        .map((developmentPlan) => ({
          ...developmentPlan,
          action: (
            <span>
              <Button
                className="btn-sm"
                color="light"
                onClick={() => setSelectedDevelopmentPlanId(developmentPlan.id)}
              >
                <FormattedMessage
                  id="app.views..development_plans.development_plans_dashboard.review_packet"
                  defaultMessage="Review plan"
                />
              </Button>
            </span>
          ),
        })),
    [developmentPlans, filterStatuses]
  );

  const handleUpdateDetailsCallback = useCallback((x: DevelopmentPlanType) => {
    setDevelopmentPlans((developmentPlans: DevelopmentPlanType[]) =>
      developmentPlans.map((p) => (p.id === x.id ? x : p))
    );
  }, []);

  const handleAddNewItemCallback = useCallback((x: DevelopmentPlanType) => {
    setDevelopmentPlans((developmentPlans: DevelopmentPlanType[]) => [
      x,
      ...developmentPlans,
    ]);
  }, []);

  if (!features?.development_plans?.enabled) {
    return <DevelopmentPlansFeatureDisabled />;
  }

  return (
    <>
      {selectedDevelopmentPlanId && (
        <DevelopmentPlan
          developmentPlanId={selectedDevelopmentPlanId}
          inModal={true}
          toggle={() => setSelectedDevelopmentPlanId(undefined)}
          onClosed={() => setSelectedDevelopmentPlanId(undefined)}
          callback={handleUpdateDetailsCallback}
        />
      )}
      <Page
        embedded={embedded}
        title={formatMessage({
          id: 'app.views.development_plans.development_plans_dashboard.title.development_plans',
          defaultMessage: 'Development plans',
        })}
      >
        {!embedded && (
          <Row>
            <Col className="col-auto">
              <ModalDevelopmentPlanEditorButton
                title={formatMessage({
                  id: 'app.views.development_plans.development_plans_dashboard.title.create_development_plan',
                  defaultMessage: 'Create development plan',
                })}
                callback={handleAddNewItemCallback}
              />
            </Col>
          </Row>
        )}
        {status == 'LOADING' && (
          <Loading
            message={formatMessage({
              id: 'app.views.development_plans.development_plans_dashboard.message.loading_development_plans',
              defaultMessage: 'Loading development plans...',
            })}
          />
        )}
        {status == 'ERROR' && (
          <EmptyState
            title={formatMessage({
              id: 'app.views.development_plans.development_plans_dashboard.title.error_loading_development_plans',
              defaultMessage: 'Error loading development plans',
            })}
            subtitle={formatMessage({
              id: 'app.views.development_plans.development_plans_dashboard.subtitle.please_try_again_later',
              defaultMessage: 'Please try again later',
            })}
          />
        )}
        {status == 'SUCCESS' && !developmentPlans?.length && (
          <EmptyState
            wrapInCard={true}
            title={formatMessage({
              id: 'app.views.development_plans.development_plans_dashboard.title.you_do_not_have_any_promotion_packets',
              defaultMessage:
                'You do not have any development plans that you are eligible to view',
            })}
            subtitle={formatMessage({
              id: 'app.views.development_plans.development_plans_dashboard.subtitle.if_you_are_a_people_manager_or_yourself',
              defaultMessage:
                'If you are a people manager, you can create a new development plan for anyone that you manage. If your organization allows, you can also create a development plan for yourself.',
            })}
          >
            {/* {newPacketButton} */}
          </EmptyState>
        )}
        {status == 'SUCCESS' && !!developmentPlans?.length && (
          <DevelopmentPlansTable
            developmentPlans={filteredDevelopmentPlans}
            filterStatuses={filterStatuses}
            toggleFilterStatuses={toggleFilterStatuses}
            hideHeaderControls={hideHeaderControls}
          />
        )}
      </Page>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { features } = state;

  return {
    features,
  };
};

export default connect(mapStateToProps)(React.memo(DevelopmentPlansDashboard));
