import React, { FC, MouseEventHandler } from 'react';

import UncontrolledPopover from 'components/SafeUncontrolledPopover';

type UncontrolledPopoverProps = React.ComponentProps<
  typeof UncontrolledPopover
>;

const ScrollableUncontrolledPopover: FC<UncontrolledPopoverProps> = ({
  ...props
}) => {
  const stopPropagation: MouseEventHandler = (e) => {
    e.stopPropagation();
  };

  return (
    <UncontrolledPopover {...props}>
      <div onWheel={stopPropagation}>{props.children}</div>
    </UncontrolledPopover>
  );
};

export default ScrollableUncontrolledPopover;
