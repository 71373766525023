import { Col, Row } from 'reactstrap';
import React, { ReactNode, useRef } from 'react';

import Avatar from '../../People/Avatar';
import { FormattedMessage } from 'react-intl';
import { ICONS } from '../../../../consts/consts';
import { ONAInfo } from './common';
import TagsList from '../../TagsList';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';

type ONAListProps = {
  onaList: ONAInfo[];
  iconClassName: string;
  header: ReactNode;
  helperText?: ReactNode;
};

const ONAList: React.FC<ONAListProps> = ({
  onaList = [],
  iconClassName,
  header,
  helperText = null,
}) => {
  const targetRef = useRef<HTMLSpanElement>(null);
  if (onaList.length === 0) {
    return <></>;
  }

  return (
    <div className="mb-0">
      <div className="mb-3 fw-bold">
        <i className={iconClassName} />
        &nbsp;
        {onaList?.length ?? 0}
        &nbsp;
        {header}
        &nbsp;
        {helperText && (
          <span ref={targetRef}>
            <i className={ICONS.HELP} />
          </span>
        )}
      </div>
      {helperText && (
        <UncontrolledPopover placement="top" trigger="hover" target={targetRef}>
          {helperText}
        </UncontrolledPopover>
      )}
      {onaList.map((ona, index) => {
        return (
          <div key={index} className="border-0 py-0 mb-3">
            <Row className="flex-nowrap">
              <Col className="col-auto">
                <Avatar person={ona.giver} size="xs" isExternalUrl={true} />
              </Col>
              <Col className="col ms-n3">
                <div className="comment-body d-block px-3 py-2 small">
                  <Row>
                    <Col>
                      <span className="fw-bold small">
                        <span className="text-dark">{ona.giver.full_name}</span>
                        {!ona.is_anonymous && ona.giver.title && (
                          <span className="fw-normal">
                            <FormattedMessage
                              id="app.views.widgets.dashboards.team_dashboard.comma"
                              defaultMessage=","
                            />{' '}
                            {ona.giver.title}
                          </span>
                        )}
                      </span>
                      {ona.comments && (
                        <div className="mb-0 py-1">{ona.comments}</div>
                      )}
                      {!ona.comments && (
                        <div className="mb-0 py-1 fst-italic">
                          <FormattedMessage
                            id="app.views.widgets.dashboards.team_dashboard.no_comments_provided"
                            defaultMessage="No comments provided"
                          />
                        </div>
                      )}
                      {ona.skills?.length > 0 && (
                        <TagsList skills={ona.skills} isExternalUrl={true} />
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default ONAList;
