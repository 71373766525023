import { FormattedMessage, type IntlShape } from 'react-intl';

import { ICONS } from 'consts/consts';
import {
  INPUT_TYPES,
  getPeopleOrAllQuery,
} from 'views/Widgets/Inputs/ValidatedInputTypes';
import {
  DevelopmentPlanTemplateDescriptor,
  Organization,
  OrganizationSettings,
  Person,
} from 'types';
import React from 'react';
import { getDatePart, parseDateFromUnicode } from 'utils/util/time';

export type DevelopmentPlanStatus = 'D' | 'A' | 'T' | 'O' | 'C' | 'I';

export type DevelopmentPlan = {
  id: number;
  candidate_person: Person;
  supporter_person: Person;
  author_person?: Person;
  status: DevelopmentPlanStatus;
  start_date: Date;
  end_date?: Date;
  title?: string;
  description?: string;
  comments?: object[];
  template_id: string;
  candidate_acknowledged_at?: Date;
  candidate_can_manage: boolean;
};

export type DevelopmentPlanApiUpdateRequest = {
  id: number;
  status: DevelopmentPlanStatus;
  supporter_person: number;
  candidate_person: number;
  author_person?: number;
  organization: number;
  start_date: string;
  end_date?: string | null;
  template_id: string;
};

interface DevelopmentPlanStatusDescriptor {
  id: DevelopmentPlanStatus;
  sortOrder: number;
  name: string;
  headingPlain: string;
  heading: JSX.Element;
  icon: string;
  textColor: string;
  description: JSX.Element;
}

export const DEVELOPMENT_PLANS_BASE_URL_PATH = 'development-plans';

export const DEVELOPMENT_PLAN_STATUS_DRAFT: DevelopmentPlanStatusDescriptor = {
  id: 'D',
  sortOrder: 1,
  name: 'draft',
  headingPlain: 'Draft',
  heading: (
    <FormattedMessage
      id="app.views.development_plans.heading.draft"
      defaultMessage="Draft"
    />
  ),
  icon: ICONS.DRAFT,
  textColor: 'text-muted',
  description: (
    <FormattedMessage
      id="app.views.development_plans.description.draft"
      defaultMessage="Development plan drafted"
    />
  ),
};

export const DEVELOPMENT_PLAN_STATUS_ACTIVE: DevelopmentPlanStatusDescriptor = {
  id: 'A',
  sortOrder: 2,
  name: 'active',
  headingPlain: 'Active',
  heading: (
    <FormattedMessage
      id="app.views.development_plans.heading.active"
      defaultMessage="Active"
    />
  ),
  icon: ICONS.ACTIVITY,
  textColor: 'text-muted',
  description: (
    <FormattedMessage
      id="app.views.development_plans.description.active"
      defaultMessage="Development plan active"
    />
  ),
};

export const DEVELOPMENT_PLAN_STATUS_ON_TRACK: DevelopmentPlanStatusDescriptor =
  {
    id: 'T',
    sortOrder: 3,
    name: 'on-track',
    headingPlain: 'On Track',
    heading: (
      <FormattedMessage
        id="app.views.development_plans.heading.on_track"
        defaultMessage="On Track"
      />
    ),
    icon: ICONS.PENDING,
    textColor: 'text-warning',
    description: (
      <FormattedMessage
        id="app.views.development_plans.description.on_track"
        defaultMessage="Development plan on track"
      />
    ),
  };

export const DEVELOPMENT_PLAN_STATUS_OFF_TRACK: DevelopmentPlanStatusDescriptor =
  {
    id: 'O',
    sortOrder: 4,
    name: 'off-track',
    headingPlain: 'Off Track',
    heading: (
      <FormattedMessage
        id="app.views.development_plans.heading.approved"
        defaultMessage="Off Track"
      />
    ),
    icon: ICONS.ALERT,
    textColor: 'text-warning',
    description: (
      <FormattedMessage
        id="app.views.development_plans.description.off_track"
        defaultMessage="Development plan off track"
      />
    ),
  };

export const DEVELOPMENT_PLAN_STATUS_COMPLETE: DevelopmentPlanStatusDescriptor =
  {
    id: 'C',
    sortOrder: 5,
    name: 'complete',
    headingPlain: 'Complete',
    heading: (
      <FormattedMessage
        id="app.views.development_plans.heading.complete"
        defaultMessage="Complete"
      />
    ),
    icon: ICONS.APPROVED,
    textColor: 'text-success',
    description: (
      <FormattedMessage
        id="app.views.development_plans.description.denied"
        defaultMessage="Development plan complete"
      />
    ),
  };

export const DEVELOPMENT_PLAN_STATUS_ENDED_INCOMPLETE: DevelopmentPlanStatusDescriptor =
  {
    id: 'I',
    sortOrder: 6,
    name: 'ended incomplete',
    headingPlain: 'Ended incomplete',
    heading: (
      <FormattedMessage
        id="app.views.development_plans.heading.ended_incomplete"
        defaultMessage="Ended incomplete"
      />
    ),
    icon: ICONS.DENIED,
    textColor: 'text-danger',
    description: (
      <FormattedMessage
        id="app.views.development_plans.description.ended_incomplete"
        defaultMessage="Development plan ended incomplete"
      />
    ),
  };

export const DEVELOPMENT_PLAN_STATUSES: DevelopmentPlanStatusDescriptor[] = [
  DEVELOPMENT_PLAN_STATUS_DRAFT,
  DEVELOPMENT_PLAN_STATUS_ACTIVE,
  DEVELOPMENT_PLAN_STATUS_ON_TRACK,
  DEVELOPMENT_PLAN_STATUS_OFF_TRACK,
  DEVELOPMENT_PLAN_STATUS_COMPLETE,
  DEVELOPMENT_PLAN_STATUS_ENDED_INCOMPLETE,
];

// Return everyone below
const getAdminableOrUnderPersonQuery = (q) => ({
  ...getPeopleOrAllQuery(q),
  show_only_adminable_or_under_person: true,
});

// Return everyone below + themseves
const getAdminableOrUnderPersonIncludedQuery = (q) => ({
  ...getPeopleOrAllQuery(q),
  show_only_adminable_or_under_person_included: true,
});

const whichTypeQuestion = (
  creatingNew: boolean,
  formatMessage: IntlShape['formatMessage'],
  availableTemplates: DevelopmentPlanTemplateDescriptor[],
  templates: DevelopmentPlanTemplateDescriptor[]
) => {
  return {
    disabled: !creatingNew,
    type: INPUT_TYPES.DROPDOWN,
    required: true,
    name: 'template_id',
    label: formatMessage({
      id: 'app.utils.models.development_plans.label.template_id',
      defaultMessage: 'What kind of development plan is this?',
    }),
    objects: creatingNew ? availableTemplates : templates,
  };
};

const whoIsTheSupporterQuestion = (
  hasSelectedTemplate: boolean,
  formatMessage: IntlShape['formatMessage'],
  candidateCanManage: boolean
) => {
  return {
    disabled: !hasSelectedTemplate,
    required: true,
    type: INPUT_TYPES.SELECT,
    name: 'supporter_person',
    label: formatMessage({
      id: 'app.utils.models.development_plans.label.supporter_person',
      defaultMessage: 'Who is the manager or main supporter of this plan?',
    }),
    helperText: !hasSelectedTemplate
      ? undefined
      : candidateCanManage
      ? formatMessage({
          id: 'app.utils.models.development_plans.help.supporter_person.everyone',
          defaultMessage:
            'This person will be the supporter throughout the development plan. That person must be yourself or anyone else in the organization.',
        })
      : formatMessage({
          id: 'app.utils.models.development_plans.help.supporter_person.adminable',
          defaultMessage:
            'This person will be the supporter throughout the development plan. That person must be yourself or someone that you manage.',
        }),
    clearable: false,
    elasticsearchOptions: {
      index: 'people',
      url: 'get-people-by-name',
      getQuery: candidateCanManage
        ? getPeopleOrAllQuery
        : getAdminableOrUnderPersonIncludedQuery,
    },
  };
};

function whoIsThisPlanForQuestion(
  creatingNew: boolean,
  hasSelectedTemplate: boolean,
  formatMessage: IntlShape['formatMessage'],
  candidateCanManage: boolean
) {
  return {
    disabled: !creatingNew || !hasSelectedTemplate,
    required: true,
    type: INPUT_TYPES.SELECT,
    name: 'candidate_person',
    label: formatMessage({
      id: 'app.utils.models.development_plans.label.candidate_person',
      defaultMessage: 'Who is the plan for?',
    }),
    helperText: !hasSelectedTemplate
      ? undefined
      : candidateCanManage
      ? formatMessage({
          id: 'app.utils.models.development_plans.help.candidate_person.adminable_and_you',
          defaultMessage:
            'This person will be the main focus of the development plan. That person must be yourself or someone that you manage.',
        })
      : formatMessage({
          id: 'app.utils.models.development_plans.help.candidate_person.only_adminable',
          defaultMessage:
            'This person will be the main focus of the development plan. That person must be someone that you manage.',
        }),
    clearable: false,
    elasticsearchOptions: {
      index: 'people',
      url: 'get-people-by-name',
      getQuery: candidateCanManage
        ? getAdminableOrUnderPersonIncludedQuery
        : getAdminableOrUnderPersonQuery,
    },
  };
}

export const DEVELOPMENT_PLAN_INITIAL_SUBMIT_INPUTS = (
  formatMessage: IntlShape['formatMessage'],
  templates: DevelopmentPlanTemplateDescriptor[],
  creatingNew: boolean,
  selectedTemplate?: string
) => {
  const hasSelectedTemplate = selectedTemplate != null;
  const availableTemplates = templates.filter((t) => !t.deprecated);
  const candidateCanManage =
    templates.find((t) => t.id === selectedTemplate)?.candidate_can_manage ??
    false;

  return [
    whichTypeQuestion(
      creatingNew,
      formatMessage,
      availableTemplates,
      templates
    ),
    whoIsThisPlanForQuestion(
      creatingNew,
      hasSelectedTemplate,
      formatMessage,
      candidateCanManage
    ),
    whoIsTheSupporterQuestion(
      hasSelectedTemplate,
      formatMessage,
      candidateCanManage
    ),
    {
      type: INPUT_TYPES.DATE_PICKER,
      required: true,
      name: 'start_date',
      label: formatMessage({
        id: 'app.utils.models.development_plans.label.start_date',
        defaultMessage: 'When does this plan start?',
      }),
    },
    {
      type: INPUT_TYPES.DATE_PICKER,
      name: 'end_date',
      label: formatMessage({
        id: 'app.utils.models.development_plans.label.end_date',
        defaultMessage: 'When does this plan end, if ever?',
      }),
    },
  ];
};

export const getDevelopmentPlanStatus = (
  statusId: DevelopmentPlanStatus
): DevelopmentPlanStatusDescriptor => {
  const developmentStatusDescriptor = DEVELOPMENT_PLAN_STATUSES.find(
    (status) => status.id === statusId
  );
  if (!developmentStatusDescriptor) {
    throw new Error(`Development plan status with id ${statusId} not found`);
  }
  return developmentStatusDescriptor;
};

export const getTitleForDevelopmentPlan = (
  developmentPlan: DevelopmentPlan,
  settings: OrganizationSettings
): string => {
  return (
    developmentPlan.title ??
    settings.development_plans_templates.find(
      (it) => it.id === developmentPlan?.template_id
    )?.name ??
    '-'
  );
};

export const toApiSubmit = (
  p: DevelopmentPlan,
  organization: Organization
): DevelopmentPlanApiUpdateRequest => ({
  id: p.id,
  status: p.status,
  author_person: p.author_person ? p.author_person.id : undefined,
  supporter_person: p.supporter_person.id!,
  candidate_person: p.candidate_person.id!,
  organization: organization.id!,
  start_date: getDatePart(p.start_date),
  end_date: p.end_date ? getDatePart(p.end_date) : null,
  template_id: p.template_id,
});

export const fromApi = (p): DevelopmentPlan => ({
  ...p,
  start_date: parseDateFromUnicode(p.start_date, 'yyyy-MM-dd'),
  end_date: p.end_date
    ? parseDateFromUnicode(p.end_date, 'yyyy-MM-dd')
    : undefined,
});
