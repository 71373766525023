import * as consts from '../../consts/consts';

import {
  CAMPAIGN_STATUSES,
  getCampaignCoverageDurationMonthString,
  getRelationships,
  replaceRelationships,
} from '../../utils/models/Campaign';
import { Card, CardBody, Col, Row } from 'reactstrap';
import {
  DEFAULT_INFLUENCE_PEOPLE_MINIMUM,
  DEFAULT_PERFORMANCE_NETWORK_DESCRIPTION,
  LearnMoreAboutOna,
  PERFORMANCE_FEATURE_GOLD_STARS_AND_INFLUENCE_VISIBLE_TO_RECIPIENT,
  PERFORMANCE_FEATURE_NETWORK_STAKEHOLDERS,
  PERFORMANCE_NETWORK_DESCRIPTION,
  PERFORMANCE_NETWORK_STEP_PEOPLE_MINIMUM,
  getCampaignFeature,
  getCampaignHasFeatureEnabled,
  getCampaignHasFeatureEnabledDefaultOn,
  getCurrentPerformancePreviewPathPrefix,
  getPerfLearnMorePopover,
  getStepNumber,
  perfCampaignCallback,
  updateLatestPerfStep,
} from '../../utils/models/Performance';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  INPUT_ATTRIBUTES,
  INPUT_TYPES,
} from '../Widgets/Inputs/ValidatedInputTypes';
import PropTypes, { InferProps } from 'prop-types';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { confirmLeavePage, prepTagsForSubmit } from '../../utils/util/util';
import {
  isCurrentEmployee,
  peopleObjectsAreEqual,
} from '../../utils/models/Person';
import { useHistory, useLocation, withRouter } from 'react-router';

import Avatar from '../Widgets/People/Avatar';
import BackButtonLink from 'views/Widgets/Buttons/BackButtonLink';
import PerformancePage from './PerformancePage';
import { RELATIONSHIP_TYPES } from '../../utils/models/RelationshipUtils';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { setCurrentPerfSurveyResponse } from '../../actions';

// Optional: What makes your coworkers great?

const relationshipTypes = [
  RELATIONSHIP_TYPES.ENERGIZED_BY,
  RELATIONSHIP_TYPES.ADVISED_BY,
  RELATIONSHIP_TYPES.HAS_AS_STAKEHOLDER,
];

const PerformanceStepNetwork: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const energizerLearnMoreRef = useRef();
  const energizerDetailsLearnMoreRef = useRef();
  const advisorLearnMoreRef = useRef();
  const advisorDetailsLearnMoreRef = useRef();

  const campaign = props.campaign;
  const setCampaign = props.setCampaign;
  const propsDemoPeople = props.demoPeople;
  const me = props.me;

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        props.me,
        props.currentOrganization,
        campaign,
        props.demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;

  const isOnFirstPage = useMemo(
    () =>
      new URLSearchParams(location.search).get('page')
        ? new URLSearchParams(location.search).get('page') != '2'
        : true,
    [location.search]
  );

  const previewPathPrefix = getCurrentPerformancePreviewPathPrefix();
  const isDemoOrPreviewMode = useMemo(
    // @ts-expect-error
    () => campaign?.status === CAMPAIGN_STATUSES.DEMO,
    // @ts-expect-error
    [campaign?.status]
  );

  const callback = useCallback(
    (data) => {
      if (data) {
        // simulate server response in demo, but for non-demo
        // get actual data from server
        const updatedRelationships = isDemoOrPreviewMode
          ? data.relationships.map((r) => ({
              from_person: me,
              ...r,
            }))
          : data.relationships;

        setCampaign(
          replaceRelationships(
            campaign,
            relationshipTypes,
            updatedRelationships
          )
        );

        if (data) {
          if (isOnFirstPage && data.relationships?.length > 0) {
            // go to second page
            history.push(
              previewPathPrefix +
                consts.PERFORMANCE_STEP_NETWORK(formatMessage).path +
                '?page=2'
            );
            return;
          }

          propsSetCurrentPerfSurveyResponse(
            updateLatestPerfStep(
              props.currentPerfSurveyResponse,
              currentStepNumber
            )
          );

          perfCampaignCallback(
            props.me,
            props.currentOrganization,
            campaign,
            history,
            propsDemoPeople,
            data,
            formatMessage
          );
        }
      }
    },
    [
      isDemoOrPreviewMode,
      setCampaign,
      campaign,
      me,
      isOnFirstPage,
      propsSetCurrentPerfSurveyResponse,
      props.currentPerfSurveyResponse,
      props.me,
      props.currentOrganization,
      currentStepNumber,
      history,
      propsDemoPeople,
      formatMessage,
      previewPathPrefix,
    ]
  );

  const showStakeholders = useMemo(
    () =>
      // TODO: add
      // "&& getPerformanceFeatureEnabled(campaign, PERFORMANCE_FEATURE_PEER_FEEDBACK)"
      // and fix tests,
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_NETWORK_STAKEHOLDERS
      ),
    [campaign]
  );

  const relationships = useMemo(
    () => ({
      [RELATIONSHIP_TYPES.ENERGIZED_BY]: getRelationships(
        campaign,
        RELATIONSHIP_TYPES.ENERGIZED_BY
      ).filter((r) => peopleObjectsAreEqual(me, r.from_person)),
      [RELATIONSHIP_TYPES.ADVISED_BY]: getRelationships(
        campaign,
        RELATIONSHIP_TYPES.ADVISED_BY
      ).filter((r) => peopleObjectsAreEqual(me, r.from_person)),
      [RELATIONSHIP_TYPES.HAS_AS_STAKEHOLDER]: getRelationships(
        campaign,
        RELATIONSHIP_TYPES.HAS_AS_STAKEHOLDER
      ).filter((r) => peopleObjectsAreEqual(me, r.from_person)),
    }),
    [me, campaign]
  );

  const object = useMemo(
    () => ({
      energizers: relationships[RELATIONSHIP_TYPES.ENERGIZED_BY]?.map(
        (r) => r.to_person
      ),
      advisors: relationships[RELATIONSHIP_TYPES.ADVISED_BY]?.map(
        (r) => r.to_person
      ),
      stakeholders: showStakeholders
        ? relationships[RELATIONSHIP_TYPES.HAS_AS_STAKEHOLDER]?.map(
            (r) => r.to_person
          )
        : [],
    }),
    [relationships, showStakeholders]
  );

  const networkDetailObject = useMemo(
    () =>
      Object.fromEntries([
        ...(relationships[RELATIONSHIP_TYPES.ENERGIZED_BY]?.map((r) => [
          `energizes_${r.to_person.id}`,
          r.positive_skills || [],
        ]) ?? []),
        ...(relationships[RELATIONSHIP_TYPES.ADVISED_BY]?.map((r) => [
          `advises_${r.to_person.id}`,
          r.positive_skills || [],
        ]) ?? []),
        ...(relationships[RELATIONSHIP_TYPES.HAS_AS_STAKEHOLDER]?.map((r) => [
          `stakeholder_${r.to_person.id}`,
          true,
        ]) ?? []),
      ]),
    [relationships]
  );

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      const getToPerson = (p) => (isDemoOrPreviewMode ? p : p.id);

      const energizers = object.energizers?.map((p) => ({
        type: RELATIONSHIP_TYPES.ENERGIZED_BY,
        to_person: getToPerson(p),
        // persist any skills provided previously if this is preview mode
        // (in real mode, these skills are persisted on the server)
        ...(isDemoOrPreviewMode
          ? { positive_skills: networkDetailObject[`energizes_${p.id}`] }
          : {}),
      }));

      const advisors = object.advisors?.map((p) => ({
        type: RELATIONSHIP_TYPES.ADVISED_BY,
        to_person: getToPerson(p),
        // persist any skills provided previously if this is preview mode
        // (in real mode, these skills are persisted on the server)
        ...(isDemoOrPreviewMode
          ? { positive_skills: networkDetailObject[`advises_${p.id}`] }
          : {}),
      }));

      const stakeholders = showStakeholders
        ? object.stakeholders?.map((p) => ({
            type: RELATIONSHIP_TYPES.HAS_AS_STAKEHOLDER,
            to_person: getToPerson(p),
          }))
        : [];

      return {
        // @ts-expect-error
        campaign: campaign?.id,
        step: currentStepNumber,
        types: relationshipTypes,
        relationships: [...energizers, ...advisors, ...stakeholders],
      };
    },
    [
      showStakeholders,
      // @ts-expect-error
      campaign?.id,
      currentStepNumber,
      isDemoOrPreviewMode,
      networkDetailObject,
    ]
  );

  const isActive = (p) => !p.status || isCurrentEmployee(p?.status);

  const networkPeopleMinimum = useMemo(
    () =>
      getCampaignFeature(campaign, PERFORMANCE_NETWORK_STEP_PEOPLE_MINIMUM) ||
      DEFAULT_INFLUENCE_PEOPLE_MINIMUM,
    [campaign]
  );

  const onValidate = useCallback(
    (obj) => {
      const errors = {};

      if (!(obj.energizers?.length >= networkPeopleMinimum)) {
        errors['energizers'] = formatMessage(
          {
            id: 'app.views.performance.performance_step_network.energize_minimum_error',
            defaultMessage:
              'At least {min} current {min, plural, one {team member is} other {team members are}} required.',
          },
          {
            min: networkPeopleMinimum,
          }
        );
      }

      if (!(obj.advisors?.length >= networkPeopleMinimum)) {
        errors['advisors'] = formatMessage(
          {
            id: 'app.views.performance.performance_step_network.advisors_minimum_error',
            defaultMessage:
              "At least {min} current {min, plural, one {team member is} other {team members are}} required. If you can't think of anyone, mention those whose perspective you value most.",
          },
          {
            min: networkPeopleMinimum,
          }
        );
      }

      if (
        showStakeholders &&
        !(
          obj.stakeholders?.filter(isActive).length >= 3 &&
          obj.stakeholders?.filter(isActive).length <= 5
        )
      ) {
        errors['stakeholders'] = formatMessage({
          id: 'app.views.performance.performance_step_network.stakeholders_error',
          defaultMessage: '3-5 current team members are required.',
        });
      }

      const peopleLists = ['energizers', 'advisors', 'stakeholders'];

      for (let i = 0; i < peopleLists.length; i++) {
        const listName = peopleLists[i];
        const invalidPeople =
          obj[listName]?.length > 0 && obj[listName].filter((p) => !p.id);
        if (invalidPeople?.length > 0) {
          if (invalidPeople.length === 1) {
            errors[listName] = formatMessage(
              {
                id: 'app.views.performance.performance_step_network.person_not_found',
                defaultMessage: '"{person}" was not found.',
              },
              { person: invalidPeople[0].email }
            );
          } else {
            errors[listName] = formatMessage(
              {
                id: 'app.views.performance.performance_step_network.people_not_found',
                defaultMessage:
                  'The following people were not found: {peopleList}',
              },
              {
                peopleList: invalidPeople
                  .map((p) => '"' + p.email + '"')
                  .join(', '),
              }
            );
          }
        }
      }

      return errors;
    },
    [formatMessage, networkPeopleMinimum, showStakeholders]
  );

  const networkHeaderText = useMemo(
    () =>
      getCampaignFeature(campaign, PERFORMANCE_NETWORK_DESCRIPTION) ||
      DEFAULT_PERFORMANCE_NETWORK_DESCRIPTION(formatMessage),
    [campaign, formatMessage]
  );

  const renderNetworkDetailsForm = useCallback(
    (inputs, submitButton, draftWarning) => {
      return (
        <PerformancePage
          campaign={campaign}
          title={networkHeaderText}
          // this page's content is full of cards so we don't need
          // an outer card
          wrapBodyInCard={false}
        >
          <div className="d-flex align-items-center">
            <BackButtonLink
              className="me-4"
              onClick={() => {
                if (confirmLeavePage(hasUnsavedChanges)) {
                  history.push(
                    previewPathPrefix +
                      consts.PERFORMANCE_STEP_NETWORK(formatMessage).path
                  );
                }
              }}
            />
            {submitButton}
            {draftWarning}
          </div>
          {inputs}
        </PerformancePage>
      );
    },
    [
      networkHeaderText,
      campaign,
      hasUnsavedChanges,
      history,
      previewPathPrefix,
      formatMessage,
    ]
  );

  const transformNetworkDetailObjectBeforeSubmit = useCallback(
    (object) => {
      const localRelationships = Object.entries(object).reduce(
        (acc, [key, value]) => {
          let recipientId = parseInt(key.split('_')[1]);

          if (isDemoOrPreviewMode) {
            // @ts-expect-error
            const allRelationships = campaign?.relationships;

            // use people objects instead of ids for preview mode so data persists
            // when clicking back to Network step in nav from, say, Callouts step
            // after providing tags
            recipientId = allRelationships.find(
              (r) => parseInt(r.to_person?.id) === recipientId
            )?.to_person;
          }

          if (key.startsWith('stakeholder')) {
            // @ts-expect-error
            acc.push({
              type: RELATIONSHIP_TYPES.HAS_AS_STAKEHOLDER,
              to_person: recipientId,
            });
          } else if (key.startsWith('energize')) {
            // @ts-expect-error
            acc.push({
              type: RELATIONSHIP_TYPES.ENERGIZED_BY,
              to_person: recipientId,
              positive_skills: isDemoOrPreviewMode
                ? // @ts-expect-error
                  value?.map((v) => v.object ?? v)
                : // @ts-expect-error
                  prepTagsForSubmit(value, props.currentOrganization?.id),
            });
          } else if (key.startsWith('advise')) {
            // @ts-expect-error
            acc.push({
              type: RELATIONSHIP_TYPES.ADVISED_BY,
              to_person: recipientId,
              positive_skills: isDemoOrPreviewMode
                ? // @ts-expect-error
                  value?.map((v) => v.object ?? v)
                : // @ts-expect-error
                  prepTagsForSubmit(value, props.currentOrganization?.id),
            });
          }
          return acc;
        },
        []
      );
      return {
        // @ts-expect-error
        campaign: campaign?.id,
        step: currentStepNumber,
        types: relationshipTypes,
        relationships: localRelationships,
      };
    },
    [
      // @ts-expect-error
      campaign?.id,
      // @ts-expect-error
      campaign?.relationships,
      currentStepNumber,
      isDemoOrPreviewMode,
      // @ts-expect-error
      props.currentOrganization?.id,
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const networkDetailInputs = useMemo(
    () => [
      ...(relationships[RELATIONSHIP_TYPES.ENERGIZED_BY]?.map((r) => ({
        ...INPUT_ATTRIBUTES(formatMessage).SKILLS,
        helperText: undefined,
        name: `energizes_${r.to_person.id}`,
      })) || []),
      ...(relationships[RELATIONSHIP_TYPES.ADVISED_BY]?.map((r) => ({
        ...INPUT_ATTRIBUTES(formatMessage).SKILLS,
        helperText: undefined,
        name: `advises_${r.to_person.id}`,
      })) || []),
    ],
    [relationships, formatMessage]
  );

  const renderNetworkDetailInputs = useCallback(
    (inputs) => {
      const [energizers, advisors] = networkDetailInputs.reduce(
        (acc, x, idx) => {
          if (x.name?.startsWith('energizes')) {
            // @ts-expect-error
            acc[0].push(inputs[parseInt(idx)]);
          } else {
            // @ts-expect-error
            acc[1].push(inputs[parseInt(idx)]);
          }
          return acc;
        },
        [[], []]
      );
      return (
        <>
          <Row>
            <Col className="col-sm-9 col-12">
              <div className="mb-md-4">
                <label className="mb-1">
                  <FormattedMessage
                    id="app.views.performance.performance_step_network.tag_energized"
                    defaultMessage="
                  Tag the skills or behaviors that motivated or energized you.
                "
                  />
                </label>
                <small className="form-text text-muted">
                  <i className="fe fe-gift me-1" />
                  <FormattedMessage
                    id="app.views.performance.performance_step_network.anonymously_share"
                    defaultMessage="
                  We'll confidentially share them with each person you
                  recognize."
                  />{' '}
                  <span
                    className="text-primary"
                    // @ts-expect-error
                    ref={energizerDetailsLearnMoreRef}
                  >
                    <FormattedMessage
                      id="app.views.performance.performance_step_network.learn_more"
                      defaultMessage="Learn more"
                    />
                  </span>
                </small>

                <UncontrolledPopover
                  delay={50}
                  trigger="hover"
                  placement="top"
                  // @ts-expect-error
                  target={energizerDetailsLearnMoreRef}
                >
                  {getPerfLearnMorePopover(
                    formatMessage({
                      id: 'app.views.performance.performance_step_network.examples',
                      defaultMessage:
                        'Examples: enthusiasm, teamwork, creativity.',
                    }),
                    formatMessage({
                      id: 'app.views.performance.performance_step_network.comments_shared.perf_learn_popover',
                      defaultMessage:
                        'Your tags will be shared with each person confidentially. However, their manager and above and HR will see your identity.',
                    }),
                    <LearnMoreAboutOna />
                  )}
                </UncontrolledPopover>
              </div>
              <Card>
                <CardBody>
                  <ul className="list-group-flush my-n3 list-group">
                    {energizers.map((x, idx) => {
                      const recipient =
                        relationships[RELATIONSHIP_TYPES.ENERGIZED_BY][idx]
                          .to_person;
                      return (
                        <li
                          key={idx}
                          className="list-group-item network-form-group"
                        >
                          <Row className="align-items-center">
                            <Col className="col-xl-4 col-md-6 col-auto">
                              <Row className="align-items-center mb-3">
                                <Col className="col-auto">
                                  <Avatar size="sm" person={recipient}></Avatar>
                                </Col>
                                <Col className="ms-n3 d-sm-block d-none fw-bold ms-n2">
                                  {recipient.full_name}
                                </Col>
                              </Row>
                            </Col>
                            <Col className="col-xl-8 col-md-6 col">{x}</Col>
                          </Row>
                        </li>
                      );
                    })}
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-sm-9 col-12">
              <div className="mb-md-4">
                <label className="mb-1">
                  <FormattedMessage
                    id="app.views.performance.performance_step_network.tag_helped"
                    defaultMessage="Tag the skills or behaviors they helped you with."
                  />
                </label>
                <small className="form-text text-muted">
                  <i className="fe fe-gift me-1" />
                  <FormattedMessage
                    id="app.views.performance.performance_step_network.anonymously_recognize"
                    defaultMessage="We'll confidentially share them with each person you recognize."
                  />{' '}
                  <span
                    className="text-primary"
                    // @ts-expect-error
                    ref={advisorDetailsLearnMoreRef}
                  >
                    <FormattedMessage
                      id="app.views.performance.performance_step_network.learn_more"
                      defaultMessage="Learn more"
                    />
                  </span>
                </small>

                <UncontrolledPopover
                  delay={50}
                  trigger="hover"
                  placement="top"
                  // @ts-expect-error
                  target={advisorDetailsLearnMoreRef}
                >
                  {getPerfLearnMorePopover(
                    formatMessage({
                      id: 'app.views.performance.performance_step_network.examples_time_interviewing',
                      defaultMessage:
                        'Examples: time management, interviewing, career planning.',
                    }),
                    formatMessage({
                      id: 'app.views.performance.performance_step_network.comments_shared.perf_learn_popover',
                      defaultMessage:
                        'Your tags will be shared with each person confidentially. However, their manager and above and HR will see your identity.',
                    }),
                    <LearnMoreAboutOna />
                  )}
                </UncontrolledPopover>
              </div>
              <Card>
                <CardBody>
                  <ul className="list-group-flush my-n3 list-group">
                    {advisors.map((x, idx) => {
                      const recipient =
                        relationships[RELATIONSHIP_TYPES.ADVISED_BY][idx]
                          .to_person;
                      return (
                        <li
                          key={idx}
                          className="list-group-item network-form-group"
                        >
                          <Row className="align-items-center">
                            <Col className="col-xl-4 col-md-6 col-auto">
                              <Row className="align-items-center mb-3">
                                <Col className="col-auto">
                                  <Avatar size="sm" person={recipient}></Avatar>
                                </Col>
                                <Col className="ms-n3 d-sm-block d-none fw-bold">
                                  {recipient.full_name}
                                </Col>
                              </Row>
                            </Col>
                            <Col className="col-xl-8 col-md-6 col">{x}</Col>
                          </Row>
                        </li>
                      );
                    })}
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      );
    },
    [networkDetailInputs, relationships, formatMessage]
  );

  const goldStarsAndInfluenceVisibleToRecipientIsEnabled = useMemo(
    () =>
      getCampaignHasFeatureEnabledDefaultOn(
        campaign,
        PERFORMANCE_FEATURE_GOLD_STARS_AND_INFLUENCE_VISIBLE_TO_RECIPIENT
      ),
    [campaign]
  );

  return isOnFirstPage ? (
    <PerformancePage
      campaign={campaign}
      title={formatMessage({
        id: 'app.views.performance.performance_step_network.title.tell_us_about_your_network',
        defaultMessage: 'Tell us about your network.',
      })}
    >
      <ValidatedForm
        draftAutosaveEnabled={!isDemoOrPreviewMode}
        // @ts-expect-error
        uniqueFormKey={`campaign-${campaign.id}-network`}
        method="POST"
        url={isDemoOrPreviewMode ? undefined : 'relationships-list'}
        callback={callback}
        buttonIsBlock={false}
        buttonClassName="mt-0"
        submitText={formatMessage({
          id: 'app.views.performance.performance_step_network.submit_text.save_and_continue',
          defaultMessage: 'Save and continue',
        })}
        transformObjectBeforeSubmit={transformObjectBeforeSubmit}
        object={object}
        onValidate={onValidate}
        inputs={[
          {
            type: INPUT_TYPES.PEOPLE_EDITOR,
            isDemoMode: isDemoOrPreviewMode,
            name: 'energizers',
            label: consts.PERFORMANCE_QUESTIONS(formatMessage)['energizers'](
              getCampaignCoverageDurationMonthString(campaign, formatMessage),
              // @ts-expect-error
              props.currentOrganization.name
            ),
            helperText: (
              <span>
                <FormattedMessage
                  id="app.views.performance.performance_step_network.list_people"
                  defaultMessage="List {networkPeopleMinimum} or more people."
                  values={{ networkPeopleMinimum: networkPeopleMinimum }}
                />{' '}
                {/* @ts-expect-error */}
                <span className="text-primary" ref={energizerLearnMoreRef}>
                  <FormattedMessage
                    id="app.views.performance.performance_step_network.learn_more"
                    defaultMessage="Learn more"
                  />
                </span>
                <UncontrolledPopover
                  delay={50}
                  trigger="hover"
                  placement="top"
                  // @ts-expect-error
                  target={energizerLearnMoreRef}
                >
                  {getPerfLearnMorePopover(
                    <span>
                      <i className="fe fe-gift" />{' '}
                      <FormattedMessage
                        id="app.views.performance.performance_step_network.recognize_coworkers"
                        defaultMessage="<b> Who should I mention?</b><p>Recognize coworkers who invigorate you. Consider those who make you excited to come to work each day. If you can't think of anyone, mention those who least de-energize or demotivate you.</p>"
                        values={{
                          b: (msg) => <span className="fw-bold">{msg}</span>,
                          p: (msg) => <p>{msg}</p>,
                        }}
                      />
                    </span>,
                    goldStarsAndInfluenceVisibleToRecipientIsEnabled
                      ? formatMessage({
                          id: 'app.views.performance.performance_step_network.comments_shared',
                          defaultMessage:
                            'They will be shared with the person confidentially. However, their manager and above and HR will see your identity.',
                        })
                      : formatMessage({
                          id: 'app.views.performance.performance_step_network.comments_not_shared',
                          defaultMessage:
                            'Your comments will not be shared with the person, but their manager and above and HR can see them.',
                        }),
                    <LearnMoreAboutOna />
                  )}
                </UncontrolledPopover>
              </span>
            ),
            autoFocus: true,
          },
          {
            type: INPUT_TYPES.PEOPLE_EDITOR,
            isDemoMode: isDemoOrPreviewMode,
            name: 'advisors',
            label: consts.PERFORMANCE_QUESTIONS(formatMessage)['advisors'](
              getCampaignCoverageDurationMonthString(campaign, formatMessage),
              // @ts-expect-error
              props.currentOrganization.name
            ),
            helperText: (
              <span>
                <FormattedMessage
                  id="app.views.performance.performance_step_network.list_or_more"
                  defaultMessage="List {networkPeopleMinimum} or more people."
                  values={{ networkPeopleMinimum: networkPeopleMinimum }}
                />{' '}
                {/* @ts-expect-error */}
                <span className="text-primary" ref={advisorLearnMoreRef}>
                  <FormattedMessage
                    id="app.views.performance.performance_step_network.learn_more"
                    defaultMessage="Learn more"
                  />
                </span>
                <UncontrolledPopover
                  delay={50}
                  trigger="hover"
                  placement="top"
                  // @ts-expect-error
                  target={advisorLearnMoreRef}
                >
                  {getPerfLearnMorePopover(
                    <span>
                      <i className="fe fe-gift" />{' '}
                      <FormattedMessage
                        id="app.views.performance.performance_step_network.recognize_advisers"
                        defaultMessage="<b>Who should I mention?</b><p>Recognize coworkers who you find yourself going to for help, whether you are asking them questions or looking for their guidance. If you can't think of anyone, mention those whose perspective you value most.</p>"
                        values={{
                          b: (msg) => <span className="fw-bold">{msg}</span>,
                          p: (msg) => <p>{msg}</p>,
                        }}
                      />
                    </span>,
                    goldStarsAndInfluenceVisibleToRecipientIsEnabled
                      ? formatMessage({
                          id: 'app.views.performance.performance_step_network.comments_shared',
                          defaultMessage:
                            'They will be shared with the person confidentially. However, their manager and above and HR will see your identity.',
                        })
                      : formatMessage({
                          id: 'app.views.performance.performance_step_network.comments_not_shared',
                          defaultMessage:
                            'Your comments will not be shared with the person, but their manager and above and HR can see them.',
                        }),
                    <LearnMoreAboutOna />
                  )}
                </UncontrolledPopover>
              </span>
            ),
          },
          ...(showStakeholders
            ? [
                {
                  type: INPUT_TYPES.PEOPLE_EDITOR,
                  isDemoMode: isDemoOrPreviewMode,
                  name: 'stakeholders',
                  label: consts
                    .PERFORMANCE_QUESTIONS(formatMessage)
                    ['stakeholders'](
                      getCampaignCoverageDurationMonthString(
                        campaign,
                        formatMessage
                      ),
                      // @ts-expect-error
                      props.currentOrganization.name
                    ),
                  helperText: formatMessage({
                    id: 'app.views.performance.performance_step_network.list_3_5',
                    defaultMessage: 'List 3-5 people.',
                  }),
                },
              ]
            : []),
        ]}
      />
    </PerformancePage>
  ) : (
    <ValidatedForm
      draftAutosaveEnabled={!isDemoOrPreviewMode}
      // @ts-expect-error
      uniqueFormKey={`campaign-${campaign.id}-network-details`}
      method="POST"
      url={isDemoOrPreviewMode ? undefined : 'relationships-list'}
      callback={callback}
      buttonIsBlock={false}
      buttonClassName="mt-0"
      submitText={formatMessage({
        id: 'app.views.performance.performance_step_network.submit_text.save_and_continue',
        defaultMessage: 'Save and continue',
      })}
      transformObjectBeforeSubmit={transformNetworkDetailObjectBeforeSubmit}
      object={networkDetailObject}
      renderForm={renderNetworkDetailsForm}
      renderInputs={renderNetworkDetailInputs}
      setShouldShowPrompt={setHasUnsavedChanges}
      inputs={networkDetailInputs}
    />
  );
};

const PerformanceStepNetwork_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = InferProps<typeof PerformanceStepNetwork_propTypes>;

const mapStateToProps = (state) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error
)(withRouter(React.memo(PerformanceStepNetwork)));
