import { Col, Container, PopoverBody, Row } from 'reactstrap';
import React, { FC, useMemo, useRef } from 'react';

import CSVDownload from './CSVDownload';
import Cell from './Cell';
import { Person } from 'types';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';

export interface GridConfig {
  x_axis_label: string;
  y_axis_label: string;
  x_axis_metric_description: string;
  y_axis_metric_description: string;
  x_axis_metric_reversed: boolean;
  y_axis_metric_reversed: boolean;
  rows: number;
  columns: number;
  quadrants: {
    x: number;
    y: number;
    x_axis_boundary: [string, string];
    y_axis_boundary: [string, string];
    title: string;
    percentage: number;
    people_ids: number[];
  }[];
}

interface Props {
  idToPerson: { [key: number]: Person };
  config: GridConfig;
}

const Grid: FC<Props> = ({ config, idToPerson }) => {
  const xAxisLabelRef = useRef<HTMLHeadingElement>(null);
  const yAxisLabelRef = useRef<HTMLHeadingElement>(null);

  const totalPeopleOnGrid = useMemo(
    () =>
      config.quadrants.reduce(
        (acc, quadrant) =>
          acc + quadrant.people_ids.filter((id) => id in idToPerson).length,
        0
      ),
    [config.quadrants, idToPerson]
  );

  return (
    <>
      <CSVDownload config={config} idToPerson={idToPerson} />
      <Container>
        <Row className="pb-3 text-center">
          <Col>
            <h1>{`${config.y_axis_label} vs. ${config.x_axis_label}`}</h1>
          </Col>
        </Row>
        <Row className="col-12 pb-3">
          <Col className="p-0 m-0 b-0 col-1 align-self-center">
            <h1
              ref={yAxisLabelRef}
              className="p-0 m-0 b-0 text-nowrap"
              style={{ transform: `rotate(-90deg)` }}
            >
              {config.y_axis_label}
            </h1>
            <UncontrolledPopover
              trigger="hover"
              placement="bottom"
              target={yAxisLabelRef}
            >
              <PopoverBody>{config.y_axis_metric_description}</PopoverBody>
            </UncontrolledPopover>
          </Col>
          <Col>
            {Array.from(Array(config.rows).keys())
              .reverse()
              .map((y) => (
                <Row key={y} className="pb-0">
                  {Array.from(Array(config.columns).keys()).map((x) => {
                    const cell = config.quadrants.find(
                      (it) => it.x === x && it.y === y
                    )!;

                    const peopleForCell = cell.people_ids
                      .map((it) => idToPerson?.[it] ?? null)
                      .filter((it) => !!it);
                    const percentage =
                      totalPeopleOnGrid > 0
                        ? +(
                            (peopleForCell.length / totalPeopleOnGrid) *
                            100
                          ).toFixed(2)
                        : 0;

                    return (
                      <Col key={x} className="p-0">
                        <Cell
                          rows={config.rows}
                          columns={config.columns}
                          x={cell.x}
                          y={cell.y}
                          x_axis_boundary={cell.x_axis_boundary}
                          y_axis_boundary={cell.y_axis_boundary}
                          x_metric_description={
                            config.x_axis_metric_description
                          }
                          y_metric_description={
                            config.y_axis_metric_description
                          }
                          x_axis_reversed={config.x_axis_metric_reversed}
                          y_axis_reversed={config.y_axis_metric_reversed}
                          title={cell.title}
                          percentage={percentage}
                          people={peopleForCell}
                        />
                      </Col>
                    );
                  })}
                </Row>
              ))}
          </Col>
        </Row>
        <Row className="text-center">
          <Col className="col-1">{''}</Col>
          <Col>
            <h1 ref={xAxisLabelRef}>{config.x_axis_label}</h1>
            <UncontrolledPopover
              trigger="hover"
              placement="bottom"
              target={xAxisLabelRef}
            >
              <PopoverBody>{config.x_axis_metric_description}</PopoverBody>
            </UncontrolledPopover>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default React.memo(Grid);
