import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ICONS, LEVELING_FRAMEWORK } from '../../../../consts/consts';
import {
  PERFORMANCE_FEATURE_HEADS_UP_COMMENTS_REQUIRED,
  PERFORMANCE_FEATURE_HIDE_RATING_FROM_DIRECT_REPORT,
  getCampaignHasFeatureEnabled,
} from '../../../../utils/models/Performance';
import React, { FC, useCallback, useContext, useMemo } from 'react';

import Avatar from '../../../Widgets/People/Avatar';
import { CAMPAIGN_STATUSES } from '../../../../utils/models/Campaign';
import { CycleDiffBadge } from '../../components/CycleDiffBadge';
import { Link } from 'react-router-dom';
import ManagerRatingCard from './ManagerRatingCard';
import { NestedPerformanceHeaderBox } from '../RatingHeader';
import PersonData from './PersonData';
import PersonPerformanceContext from '../PersonPerformanceContext';
import PropTypes from 'prop-types';
import TagsList from '../../../Widgets/TagsList';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { computeHistoricalCampaignONAData } from './HistoryChartUtils';
import { getPrettyDate } from '../../../../utils/util/util';
import { getUnattributedPerson } from '../../../../utils/models/Person';

const TrajectorySummary: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();
  const personPerformanceContext = useContext(PersonPerformanceContext);

  const selectedCampaign = personPerformanceContext.selectedCampaignData;

  const selectedCampaignPrevious =
    personPerformanceContext.previousCampaignDataWithOna;

  const selectedCampaignONA = computeHistoricalCampaignONAData(
    selectedCampaign,
    selectedCampaignPrevious
  );

  const hideRatingFromDirectReport = props.campaign
    ? getCampaignHasFeatureEnabled(
        props.campaign,
        PERFORMANCE_FEATURE_HIDE_RATING_FROM_DIRECT_REPORT
      )
    : true;

  const displayRating =
    (!hideRatingFromDirectReport || props.showManagerOnlyPerformanceDetails) &&
    personPerformanceContext.displayRatingWidgets;

  const calculateNetworkBuckets = (cycles) => {
    const buckets = {
      superInfluence: 0,
      aboveAverageInfluence: 0,
      belowAverageInfluence: 0,
      superGoldStar: 0,
      aboveAverageGoldStar: 0,
      belowAverageGoldStar: 0,
      headsUps: 0,
      totalCycles: cycles.length,
    };

    cycles.forEach((e) => {
      const benchmarks = e.benchmarks.organization.all;
      if (benchmarks) {
        const influenceRatio = (
          e.influence.length / benchmarks.influence
        ).toFixed(2);
        const goldStarRatio = (
          e.gold_stars.length / benchmarks.gold_stars
        ).toFixed(2);
        buckets.headsUps += e.heads_ups.length;

        // @ts-expect-error
        if (influenceRatio > 2) {
          buckets.superInfluence++;
          buckets.aboveAverageInfluence++;
          // @ts-expect-error
        } else if (influenceRatio >= 1) {
          buckets.aboveAverageInfluence++;
        } else {
          buckets.belowAverageInfluence++;
        }

        // @ts-expect-error
        if (goldStarRatio > 2) {
          buckets.superGoldStar++;
          buckets.aboveAverageGoldStar++;
          // @ts-expect-error
        } else if (goldStarRatio >= 1) {
          buckets.aboveAverageGoldStar++;
        } else {
          buckets.belowAverageGoldStar++;
        }
      }
    });

    return buckets;
  };

  const networkBuckets = useMemo(() => {
    if (!props.data) {
      return null;
    }

    const results = calculateNetworkBuckets(props.data);
    return {
      ...results,
      isSuperImpactful: results.superGoldStar / results.totalCycles >= 0.5,
      isImpactful: results.aboveAverageGoldStar / results.totalCycles >= 0.5,
      isSuperInfluential: results.superInfluence / results.totalCycles >= 0.5,
      isInfluential: results.aboveAverageInfluence / results.totalCycles >= 0.5,
    };
  }, [props.data]);

  const networkBucketsTitle = useMemo(() => {
    const terms = [];
    // @ts-expect-error
    if (networkBuckets.isSuperImpactful) {
      terms.push(
        // @ts-expect-error
        formatMessage({
          id: 'performance.trajectory.summary.trajectory_summary.super_impactful',
          defaultMessage: 'Incredibly impactful',
        })
      );
      // @ts-expect-error
    } else if (networkBuckets.isImpactful) {
      terms.push(
        // @ts-expect-error
        formatMessage({
          id: 'performance.trajectory.summary.trajectory_summary.impactful',
          defaultMessage: 'Impactful',
        })
      );
    }

    // @ts-expect-error
    if (networkBuckets.isSuperInfluential) {
      terms.push(
        // @ts-expect-error
        formatMessage({
          id: 'performance.trajectory.summary.trajectory_summary.super_influential',
          defaultMessage: 'Super influential',
        })
      );
      // @ts-expect-error
    } else if (networkBuckets.isInfluential) {
      terms.push(
        // @ts-expect-error
        formatMessage({
          id: 'performance.trajectory.summary.trajectory_summary.influential',
          defaultMessage: 'Influential',
        })
      );
    }

    if (terms.length > 0) return terms.join(' and ');

    return (
      <>
        <div>
          <FormattedMessage
            id="app.views.person.person_performance.trajectory.trajectory_summary.not_recognized_yet"
            defaultMessage="
          Not recognized yet
          "
          />
          <i
            className="fe fe-help-circle text-primary fs-4 position-relative"
            id="no-recognition-helper-text"
            style={{ top: '-6px', right: '-6px' }}
          />
        </div>
        <UncontrolledPopover
          trigger={'hover click'}
          placement="top"
          target="no-recognition-helper-text"
        >
          <FormattedMessage
            id="app.views.person.person_performance.trajectory.trajectory_summary.if_you_have_not_received_gold_stars_or_influence"
            defaultMessage="
          If you have not received gold stars or influence, this may have
          nothing to do with your performance.Others might not be aware of your
          contributions, or your collaborations may not prompt them to seek your
          help or advice. Consider asking your manager about this in your next
          performance conversation.
        "
          />
        </UncontrolledPopover>
      </>
    );
  }, [networkBuckets, formatMessage]);

  const headsUpHelperText = useMemo(() => {
    const commentString =
      selectedCampaign?.heads_ups_display == 'full'
        ? getCampaignHasFeatureEnabled(
            selectedCampaign,
            PERFORMANCE_FEATURE_HEADS_UP_COMMENTS_REQUIRED
          )
          ? formatMessage({
              id: 'performance.trajectory.summary.trajectory_summary.heads_up_helper_text_comments_required',
              defaultMessage: 'Comments are required.',
            })
          : formatMessage({
              id: 'performance.trajectory.summary.trajectory_summary.heads_up_helper_text_comments_optional',
              defaultMessage: 'Comments are optional.',
            })
        : null;

    return formatMessage(
      {
        id: 'performance.trajectory.summary.trajectory_summary.heads_up_helper_text',
        defaultMessage:
          "The number of people concerned about {personFirstName}'s behaviors, actions, or impact. {commentsDirectives}",
      },
      {
        personFirstName: props.firstName,
        commentsDirectives: commentString,
      }
    );
  }, [selectedCampaign, props.firstName, formatMessage]);

  const HEADS_UPS_THRESHOLD = 2;

  const multipleHeadsUpsWarning = useMemo(() => {
    const headsUpsPercentiles =
      selectedCampaign?.benchmarks?.organization?.ona?.heads_ups;

    if (!headsUpsPercentiles) {
      return null;
    }

    const cutoffPercentile = headsUpsPercentiles.findIndex(
      (e) => e >= HEADS_UPS_THRESHOLD
    );

    let headsUpsGap;
    if (cutoffPercentile === -1) {
      headsUpsGap = '<1';
    } else {
      headsUpsGap = String(Math.max(99 - cutoffPercentile, 1));
    }

    return (
      <>
        <br />
        <FormattedMessage
          id="performance.trajectory.summary.trajectory_summary.multiple_heads_ups_warning"
          defaultMessage="<bold>{headsUpsGap}% of participants</bold> in this cycle received multiple heads ups."
          values={{
            headsUpsGap,
            bold: (chunks) => <span className="fw-bold">{chunks}</span>,
          }}
        />
      </>
    );
  }, [selectedCampaign]);

  const isDemo = useMemo(
    // @ts-expect-error
    () => props.campaign && props.campaign.status === CAMPAIGN_STATUSES.DEMO,
    [props.campaign]
  );

  const monthsInRole = useMemo(() => {
    // @ts-expect-error
    const months = isDemo ? '12' : props.monthsInRole || '';
    if (!months) {
      return '';
    }

    return formatMessage(
      {
        id: 'performance.trajectory.summary.trajectory_summary.months_in_role',
        defaultMessage:
          '{months} {months, plural, one {month} other {months}} in role',
      },
      { months }
    );
    // @ts-expect-error
  }, [isDemo, props.monthsInRole, formatMessage]);

  const optionallyGetLevelsLink = useCallback(
    (obj) =>
      // @ts-expect-error
      props.features?.levels?.enabled ? (
        <Link
          to={LEVELING_FRAMEWORK(formatMessage).path}
          className="text-primary"
        >
          {obj}
        </Link>
      ) : (
        <span>{obj}</span>
      ),
    // @ts-expect-error
    [props.features?.levels?.enabled, formatMessage]
  );

  const roleAndLevel = useMemo(() => {
    // @ts-expect-error
    const role = props.role || (
      <span className="text-muted">
        <FormattedMessage
          id="app.views.person.person_performance.trajectory.trajectory_summary.no_title_provided"
          defaultMessage="No title provided"
        />
      </span>
    );

    const level =
      // @ts-expect-error
      props.role && props.level ? optionallyGetLevelsLink(props.level) : null;

    const spacer = level ? ' ' : '';
    return (
      <>
        {role}
        {spacer}
        {level}
      </>
    );
    // @ts-expect-error
  }, [props.role, props.level, optionallyGetLevelsLink]);

  const snapshotStartDate = useMemo(() => {
    const dateString = selectedCampaign?.campaign?.coverage_start_date;
    if (!dateString) {
      return '';
    }

    const prettyDate = getPrettyDate({
      dateString,
      hideYear: false,
      includeTime: false,
      locale,
    });

    return formatMessage(
      {
        id: 'app.views.person.person_performance.trajectory.trajectory_summary.snapshot_start_date',
        defaultMessage: '{prettyDate} snapshot',
      },
      { prettyDate }
    );
  }, [formatMessage, locale, selectedCampaign]);

  return (
    <Col className="col-12">
      <Row>
        {personPerformanceContext.showGoldStarsAndInfluenceToRecipient ||
        props.showManagerOnlyPerformanceDetails ||
        displayRating ? (
          <Col sm="12" lg="9">
            {displayRating && (
              <ManagerRatingCard
                // @ts-expect-error
                person={props.person}
                // @ts-expect-error
                isInReviewFlow={props.isInReviewFlow}
                hideRatingFromDirectReport={hideRatingFromDirectReport}
                // @ts-expect-error
                showManagerOnlyPerformanceDetails={
                  props.showManagerOnlyPerformanceDetails
                }
                managerRelationshipsWithFeedback={
                  // @ts-expect-error
                  props.managerRelationshipsWithFeedback
                }
                // @ts-expect-error
                manager={props.manager}
              />
            )}

            {(personPerformanceContext.showGoldStarsAndInfluenceToRecipient ||
              props.showManagerOnlyPerformanceDetails) &&
            personPerformanceContext.canDisplayOna ? (
              <Card>
                <CardBody>
                  {/* network-recognition */}
                  <div className="mb-5">
                    <h6 className="text-secondary text-uppercase">
                      <FormattedMessage
                        id="app.views.person.person_performance.trajectory.trajectory_summary.network_recognition"
                        defaultMessage="
                  Network recognition
                "
                      />
                    </h6>
                    <h2 className="mb-3">{networkBucketsTitle}</h2>
                  </div>
                  <div className="d-flex gap-2 justify-content-between mt-3 mb-5">
                    <NestedPerformanceHeaderBox
                      id="trajectory-influence-summary-header"
                      title={formatMessage({
                        id: 'app.views.person.person_performance.trajectory.trajectory_summary.title.influence',
                        defaultMessage: 'Influence',
                      })}
                      body={
                        <>
                          <Row
                            className="h3 mb-0"
                            role="button"
                            // @ts-expect-error
                            onClick={() => props.scrollTo()}
                            id={'trajectory-summary-influence'}
                          >
                            <Col className={'pe-1 col-auto'}>
                              <i className={ICONS.INFLUENCE} />
                            </Col>
                            <Col className="col-auto ps-1 pe-0 d-flex align-items-center">
                              <span>
                                {selectedCampaignONA?.influence?.count}
                              </span>
                            </Col>
                            {personPerformanceContext.canDisplayDeltas && (
                              <Col className="col-auto px-0 d-flex">
                                <CycleDiffBadge
                                  // @ts-expect-error
                                  value={
                                    selectedCampaignONA?.['influence']
                                      ?.difference
                                  }
                                />
                              </Col>
                            )}
                          </Row>
                        </>
                      }
                    />
                    <NestedPerformanceHeaderBox
                      id="trajectory-gold-star-summary-header"
                      title={formatMessage({
                        id: 'app.views.person.person_performance.trajectory.trajectory_summary.title.gold_stars',
                        defaultMessage: 'Gold stars',
                      })}
                      body={
                        <>
                          <Row
                            className="h3 mb-0"
                            role="button"
                            // @ts-expect-error
                            onClick={() => props.scrollTo()}
                            id={'trajectory-summary-gold-stars'}
                          >
                            <Col className={'pe-1 col-auto'}>
                              <i className={ICONS.GOLD_STAR} />
                            </Col>
                            <Col className="col-auto px-1 d-flex align-items-center">
                              <span>
                                {selectedCampaignONA?.goldStars?.count}
                              </span>
                            </Col>
                            {personPerformanceContext.canDisplayDeltas && (
                              <Col className="col-auto px-0 d-flex">
                                <CycleDiffBadge
                                  // @ts-expect-error
                                  value={
                                    selectedCampaignONA?.goldStars?.difference
                                  }
                                />
                              </Col>
                            )}
                          </Row>
                        </>
                      }
                    />
                    {((selectedCampaign?.heads_ups_display &&
                      selectedCampaign?.heads_ups_display !== 'hidden') ||
                      props.showManagerOnlyPerformanceDetails) && (
                      <NestedPerformanceHeaderBox
                        id="trajectory-heads-up-summary-header"
                        title={formatMessage({
                          id: 'app.views.person.person_performance.trajectory.trajectory_summary.title.heads_ups',
                          defaultMessage: 'Heads ups',
                        })}
                        body={
                          <>
                            <div
                              className="h3 mb-0"
                              id="trajectory-summary-heads-ups"
                              role="button"
                              // @ts-expect-error
                              onClick={() => props.scrollTo()}
                            >
                              <span
                                className={
                                  ICONS.HEADS_UP + ' me-2 position-relative'
                                }
                                style={{ top: '1px' }}
                              ></span>
                              {selectedCampaignONA?.headsUps?.count}
                            </div>
                          </>
                        }
                        helperText={
                          <>
                            {headsUpHelperText}
                            {selectedCampaignONA?.headsUps?.count > 0 &&
                              selectedCampaign?.heads_ups_display == 'full' && (
                                <>
                                  {<br />}
                                  {selectedCampaignONA?.headsUps?.count >=
                                    HEADS_UPS_THRESHOLD &&
                                    multipleHeadsUpsWarning}
                                  <hr />
                                  <ListGroup className="list-group-flush mt-0 pt-0 mb-n3">
                                    {selectedCampaign?.heads_ups?.map(
                                      (hu, index) => {
                                        const fromPerson =
                                          hu.from_person ||
                                          getUnattributedPerson(
                                            formatMessage,
                                            index,
                                            null,
                                            true // Assume anonymous if fromPerson doesn't exist
                                          );
                                        return (
                                          <ListGroupItem
                                            key={index}
                                            className="border-0 py-0 mb-3"
                                          >
                                            <Row className="flex-nowrap">
                                              <Col className="col-auto">
                                                <Avatar
                                                  person={fromPerson}
                                                  size="xs"
                                                  isExternalUrl={true}
                                                />
                                              </Col>
                                              <Col className="col ms-n3">
                                                <div className="comment-body d-block px-3 py-2 small">
                                                  <Row>
                                                    <Col>
                                                      <span className="fw-bold small">
                                                        <span className="text-dark">
                                                          {fromPerson.full_name}
                                                        </span>
                                                        {fromPerson.title && (
                                                          <span className="fw-normal">
                                                            <FormattedMessage
                                                              id="app.views.person.person_performance.trajectory.trajectory_summary.separator"
                                                              defaultMessage="
                                                    , "
                                                            />
                                                            {fromPerson.title}
                                                          </span>
                                                        )}
                                                      </span>
                                                      {hu.negative_comments && (
                                                        <div className="mb-0 py-1">
                                                          {hu.negative_comments}
                                                        </div>
                                                      )}
                                                      {!hu.negative_comments && (
                                                        <div className="mb-0 py-1 fst-italic">
                                                          <FormattedMessage
                                                            id="app.views.person.person_performance.trajectory.trajectory_summary.no_comments_provided"
                                                            defaultMessage="
                                                  No comments provided
                                                "
                                                          />
                                                        </div>
                                                      )}
                                                      {/* @ts-expect-error */}
                                                      {hu.negative_skills
                                                        ?.length > 0 && (
                                                        <TagsList
                                                          skills={
                                                            hu.negative_skills
                                                          }
                                                          isExternalUrl={true}
                                                        />
                                                      )}
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Col>
                                            </Row>
                                          </ListGroupItem>
                                        );
                                      }
                                    )}
                                  </ListGroup>
                                </>
                              )}
                          </>
                        }
                        // @ts-expect-error
                        private={
                          selectedCampaign?.heads_ups_display !== 'full' &&
                          props.showManagerOnlyPerformanceDetails
                        }
                      />
                    )}
                  </div>
                  {/* top-skills-list */}
                  <div>
                    <h6 className="text-uppercase text-secondary">
                      <FormattedMessage
                        id="app.views.person.person_performance.trajectory.trajectory_summary.title.top_skills_mentioned"
                        defaultMessage="
                  Top skills mentioned
                "
                      />
                    </h6>
                    {/* @ts-expect-error */}
                    {props.topSkills?.list?.length > 0 ? (
                      <div>
                        {/* @ts-expect-error */}
                        <span className="fw-bold">{props.topSkills?.list}</span>
                      </div>
                    ) : (
                      <span className="fst-italic">
                        <FormattedMessage
                          id="app.views.person.person_performance.trajectory.trajectory_summary.no_skills_mentioned"
                          defaultMessage="The network has not recognized any of {firstName}'s skills."
                          values={{
                            firstName: props.firstName,
                          }}
                        />
                      </span>
                    )}
                  </div>
                </CardBody>
              </Card>
            ) : null}
          </Col>
        ) : null}
        <Col sm="12" lg="3">
          <Card className="position-sticky card-fill" style={{ top: '24px' }}>
            <CardBody className="m-0">
              {/* role-and-tenure */}
              <div className="d-flex">
                <div>
                  <h6 className="text-uppercase text-secondary">
                    {snapshotStartDate}
                  </h6>
                  <PersonData
                    className="mt-5"
                    header={formatMessage({
                      id: 'app.views.person.person_performance.trajectory.trajectory_summary.cycle_role',
                      defaultMessage: 'Cycle Role',
                    })}
                    data={roleAndLevel}
                  />
                  <PersonData
                    className="mt-5"
                    header={formatMessage({
                      id: 'app.views.person.person_performance.trajectory.trajectory_summary.cycle_time_in_role',
                      defaultMessage: 'Cycle Time in role',
                    })}
                    data={monthsInRole}
                    helperText={formatMessage({
                      id: 'app.views.person.person_performance.months_in_role.helper',
                      defaultMessage:
                        'How many months the person has been in their current role, calculated from their first day in their current role to the day this performance cycle began.',
                    })}
                  />
                  <PersonData
                    className="mt-5"
                    header={formatMessage(
                      {
                        id: 'app.views.person.person_performance.trajectory.trajectory_summary.cycle_time_at_org',
                        defaultMessage: 'Cycle Time at {organizationName}',
                      },
                      // @ts-expect-error
                      { organizationName: props.organization.name }
                    )}
                    data={formatMessage(
                      {
                        id: 'app.views.person.person_performance.trajectory.trajectory_summary.months_at_org',
                        defaultMessage:
                          '{monthsAtOrganization} {monthsAtOrganization, plural, one {month} other {months}}',
                      },
                      {
                        monthsAtOrganization: props.monthsAtOrganization ?? 0,
                      }
                    )}
                    helperText={formatMessage(
                      {
                        id: 'app.views.person.person_performance.rating_header.helper.time_at_organization',
                        defaultMessage:
                          'How many months the person has been at {organizationName}, calculated from their most recent start date to the day this performance cycle began.',
                      },
                      // @ts-expect-error
                      { organizationName: props.organization.name }
                    )}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

const TrajectorySummary_propTypes = {
  firstName: PropTypes.string,
  person: PropTypes.object,
  organization: PropTypes.object,
  data: PropTypes.array,
  campaign: PropTypes.object,
  scrollTo: PropTypes.func,
  topSkills: PropTypes.object,
  managerView: PropTypes.bool,
  isInReviewFlow: PropTypes.bool,
  level: PropTypes.string,
  monthsAtOrganization: PropTypes.number,
  showManagerOnlyPerformanceDetails: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof TrajectorySummary_propTypes>;

export default TrajectorySummary;
