import CardRowDashboard, { CARD_TYPES, CELL_TYPES } from './CardRowDashboard';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useEffect, useMemo, useState } from 'react';

import ElasticsearchAPI from '../../../utils/api/ElasticsearchAPI';
import Loading from '../Loading';
import PageError from '../../Layout/Pages/Errors/PageError';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { connect } from 'react-redux';
import { getUniqueHtmlId } from '../../../utils/util/formatter';
import { useAuth0 } from '@auth0/auth0-react';
import { withRouter } from 'react-router';
import { type RouteComponentProps } from 'react-router-dom';

const makeTableCardInfo = (
  type,
  dataKey,
  appendName,
  typeDisplay,
  formatMessage
) => {
  const title =
    type === 'leader' || type === 'manager'
      ? formatMessage(
          {
            id: 'app.views.widgets.dashboards.participation_dashboard.title.completion_rate_of_people_managers_under_type',
            defaultMessage: 'Completion rate of people managers under {type}',
          },
          {
            type:
              type === 'leader'
                ? formatMessage({
                    id: 'app.views.widgets.dashboards.participation_dashboard.title.senior_leader',
                    defaultMessage: 'senior leader',
                  })
                : typeDisplay,
          }
        )
      : formatMessage(
          {
            id: 'app.views.widgets.dashboards.participation_dashboard.title.completion_rate_by_type',
            defaultMessage: 'Completion rate by {type}',
          },
          { type: typeDisplay }
        );
  const targetId = getUniqueHtmlId();
  return {
    title: (
      <>
        {title}
        {(type === 'leader' || type === 'manager') && (
          <>
            {' '}
            <i
              id={targetId}
              className="fe fe-help-circle text-primary position-relative"
              style={{ top: '2px' }}
            />
            <UncontrolledPopover
              trigger="hover"
              placement="top"
              target={targetId}
            >
              <FormattedMessage
                id="app.views.widgets.dashboards.participation_dashboard.popover"
                defaultMessage="This table shows the completion rates of the people managers under the selected {type}."
                values={{ type: typeDisplay }}
              />
            </UncontrolledPopover>
          </>
        )}
      </>
    ),
    type: CARD_TYPES.PROGRESS_TABLE,
    fields: [
      {
        name: formatMessage(
          {
            id: 'app.views.widgets.dashboards.participation_dashboard.title.fields.name',
            defaultMessage: '{type} {appendName, select, true {name} other {}}',
          },
          {
            type,
            appendName: !!appendName,
          }
        ),
        type: CELL_TYPES.DEFAULT,
        align: 'left',
      },
      {
        name: formatMessage({
          id: 'app.views.widgets.dashboards.participation_dashboard.title.fields.complete',
          defaultMessage: 'Complete',
        }),
        type: CELL_TYPES.DEFAULT,
        align: 'right',
      },
      {
        name: formatMessage({
          id: 'app.views.widgets.dashboards.participation_dashboard.title.fields.eligible',
          defaultMessage: 'Eligible',
        }),
        type: CELL_TYPES.DEFAULT,
        align: 'right',
      },
      {
        name: formatMessage({
          id: 'app.views.widgets.dashboards.participation_dashboard.title.fields.percent_complete',
          defaultMessage: '% complete',
        }),
        type: CELL_TYPES.PROGRESS,
        align: 'left',
        defaultSort: 'descending',
      },
    ],
    dataKey,
    defaultSortBy: 0,
    emptyStateText: formatMessage(
      {
        id: 'app.views.widgets.dashboards.participation_dashboard.title.emtpy_state_text.there_is_no_available_data',
        defaultMessage: 'There is no available {type} completion data.',
      },
      { type: typeDisplay }
    ),
  };
};

// TODO: Pull display names for these from an organization-specific configuration
const dashboardItems = (formatMessage) => [
  {
    title: formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.overall_completion_rate',
      defaultMessage: 'Overall completion rate',
    }),
    type: CARD_TYPES.PROGRESS_CHART,
    labels: ['Complete', 'Incomplete'],
    dataKey: 'overall',
    emptyStateText: formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.overall_completion_rate.there_is_no_available_data',
      defaultMessage: 'There is no available overall completion data.',
    }),
  },
  // NOTE: this should match campaigns.py TAGS list
  makeTableCardInfo(
    'business unit',
    'business_unit',
    false,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.business_unit',
      defaultMessage: 'business unit',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'business unit id',
    'business_unit_id',
    true,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.business_unit_id',
      defaultMessage: 'business unit id',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'department',
    'department',
    false,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.department',
      defaultMessage: 'department',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'department id',
    'department_id',
    true,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.department_id',
      defaultMessage: 'department id',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'location',
    'location',
    false,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.location',
      defaultMessage: 'location',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'country',
    'country',
    true,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.country',
      defaultMessage: 'country',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'senior leader',
    'leader',
    true,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.senior_leader',
      defaultMessage: 'senior leader',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'reporting function',
    'function',
    false,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.reporting_function',
      defaultMessage: 'reporting function',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'reporting function id',
    'function_id',
    true,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.reporting_function_id',
      defaultMessage: 'reporting function id',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'HRBP',
    'hrbp',
    false,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.hrbp',
      defaultMessage: 'HRBP',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'manager',
    'manager',
    true,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.manager',
      defaultMessage: 'manager',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'cost center',
    'cost_center',
    true,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.cost_center',
      defaultMessage: 'cost center',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'cost center id',
    'cost_center_id',
    true,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.cost_center_id',
      defaultMessage: 'cost center id',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'level',
    'level_id',
    true,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.level',
      defaultMessage: 'level',
    }),
    formatMessage
  ),
  makeTableCardInfo(
    'level id',
    'level',
    true,
    formatMessage({
      id: 'app.views.widgets.dashboards.participation_dashboard.title.level_id',
      defaultMessage: 'level id',
    }),
    formatMessage
  ),
];

const ParticipationDashboard: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [data, setData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth0();

  useEffect(() => {
    // @ts-expect-error
    const campaignId = props.campaignId || props.campaign.id || undefined;
    // @ts-expect-error
    if (props.currentOrganization?.id && campaignId) {
      ElasticsearchAPI.search(
        // only cache if no query provided (so we don't take up too much
        // memory story search results as searches are done)
        user?.sub,
        props.currentProxyPerson,
        // @ts-expect-error
        props.currentOrganization.id,
        'get-participation-stats',
        { campaign_id: campaignId },
        (hits) => {
          setIsLoading(false);
          if (hits.length > 0) {
            setData(hits[0]);
          }
        },
        (msg) => {
          setIsLoading(false);
          setErrorMessage(msg);
        }
      );
      setIsLoading(true);
    }
  }, [
    props.campaign,
    props.campaignId,
    // @ts-expect-error
    props.currentOrganization?.id,
    props.currentProxyPerson,
    user?.sub,
  ]);

  const dashboardData = useMemo(() => {
    if (!data || !props?.phaseType) {
      return undefined;
    }

    // massage the data received from the backend to match the input format
    // required by the generic ProgressChartCard component.
    // @ts-expect-error
    const retval = data?.participation_stats[props.phaseType] ?? {};
    if (retval?.overall) {
      if (retval.overall[0].length === 2) {
        retval.overall[0].push(
          formatMessage({
            id: 'app.views.widgets.dashboards.participation_dashboard.title.overall_completion_rate.evaluations_received',
            defaultMessage: 'evaluations received',
          })
        );
        retval.overall[1].push(
          formatMessage({
            id: 'app.views.widgets.dashboards.participation_dashboard.title.overall_completion_rate.managers_all_done',
            defaultMessage: 'managers all done',
          })
        );
      } else {
        retval.overall = [retval.overall];
        retval.overall[0].push(
          formatMessage({
            id: 'app.views.widgets.dashboards.participation_dashboard.title.overall_completion_rate.completed',
            defaultMessage: 'completed',
          })
        );
      }
    }

    return retval;
  }, [data, formatMessage, props.phaseType]);

  const rows = useMemo(() => {
    if (!dashboardData || !Object.keys(dashboardData).length) {
      return [];
    }

    // remove empty tables
    const nonTableOrNonEmptyTableItems = dashboardItems(formatMessage).filter(
      (item) => {
        const dashboardDataKeyData = dashboardData[item.dataKey] ?? {};
        return (
          // is overall
          item.type !== CARD_TYPES.PROGRESS_TABLE ||
          // has more than one key or has one key
          Object.keys(dashboardDataKeyData).length > 1 ||
          // has one key and that key is not 'Other'
          (Object.keys(dashboardDataKeyData).length == 1 &&
            !dashboardDataKeyData['Other'])
        );
      }
    );

    const dashboardRows = [];

    // populate two items per dashboard row
    for (let i = 0; i < nonTableOrNonEmptyTableItems.length; i += 2) {
      if (i + 1 == nonTableOrNonEmptyTableItems.length) {
        // @ts-expect-error
        dashboardRows.push([nonTableOrNonEmptyTableItems[i]]);
      } else {
        // @ts-expect-error
        dashboardRows.push([
          nonTableOrNonEmptyTableItems[i],
          nonTableOrNonEmptyTableItems[i + 1],
        ]);
      }
    }

    return dashboardRows;
  }, [dashboardData, formatMessage]);

  if (errorMessage) {
    console.error('Could not load participation stats: ' + errorMessage);
    return <PageError message={errorMessage} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CardRowDashboard
      data={dashboardData}
      emptyStateText={formatMessage({
        id: 'app.views.widgets.dashboards.participation_dashboard.emptyStateText.no_available_campaigns',
        defaultMessage: 'No available campaigns.',
      })}
      rows={rows}
    />
  );
};

const ParticipationDashboard_propTypes = {
  /** campaign for which to show participation */
  campaign: PropTypes.object,
  /** currently selected campaign for dropdown (0 if none selected) */
  campaignId: PropTypes.number,
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  /** campaign phase for which to show participation */
  phaseType: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof ParticipationDashboard_propTypes> &
  RouteComponentProps;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(
  withRouter(React.memo(ParticipationDashboard))
);
