import { Badge, Card, CardBody, CardHeader, Col, List, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Organization,
  Person,
  Relationship,
  RelationshipType,
} from '../../../types';
import React, { FC, useEffect, useRef } from 'react';

import AvatarGroup from '../../Widgets/People/AvatarGroup';
import CardHeaderTitle from '../../Widgets/Cards/CardHeaderTitle';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { getUnattributedPerson } from '../../../utils/models/Person';
import { useWindowResize } from '../../../utils/util/hooks';

export interface InfluenceCardProps {
  organization: Organization;
  person?: Person;
  influence?: Relationship[];
}

export const InfluenceCard: FC<InfluenceCardProps> = (props) => {
  const { formatMessage } = useIntl();
  const firstName = props.person?.given_name ?? '';
  const influenceBoxLearnMoreRef = useRef<HTMLAnchorElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const BREAKPOINT = 360;

  const windowWidth = useWindowResize();

  const [avatarSize, setAvatarSize] = React.useState('sm');

  useEffect(() => {
    const card = cardRef.current;
    if (card) {
      const width = card.offsetWidth;
      setAvatarSize(width < BREAKPOINT ? 'xs' : 'sm');
    }
  }, [windowWidth, cardRef]);

  const advisesArray =
    props.influence?.filter(
      (rel) => rel.type === RelationshipType.ADVISED_BY
    ) || [];

  const energizesArray =
    props.influence?.filter(
      (rel) => rel.type === RelationshipType.ENERGIZED_BY
    ) || [];

  return (
    <Card innerRef={cardRef}>
      <CardHeader>
        <CardHeaderTitle>
          <i className="fe fe-share-2 me-2 text-info" />
          <FormattedMessage
            id="app.views.person.person_performance.influence_card.influence"
            defaultMessage="Influence"
          />
          <Badge className="bg-info-soft fw-bolder fs-5 ms-3">
            {props.influence?.length}
          </Badge>
        </CardHeaderTitle>
      </CardHeader>
      <CardBody className="card-body pt-3">
        {props.influence?.length && props.influence?.length > 0 ? (
          <>
            <Row>
              {advisesArray?.length > 0 && (
                <Col xl={6} lg={12} className="mt-3">
                  <h6 className="text-secondary text-uppercase">
                    <FormattedMessage
                      id="app.views.person.person_performance.influence_card.advises_people"
                      defaultMessage="Advises"
                    />
                  </h6>
                  <AvatarGroup
                    people={advisesArray.map(
                      (rel) =>
                        rel.from_person ??
                        getUnattributedPerson(
                          formatMessage,
                          rel.id,
                          rel.type,
                          false
                        )
                    )}
                    maxFaces={4}
                    size={avatarSize}
                  />
                </Col>
              )}
              {energizesArray?.length > 0 && (
                <Col xl={6} lg={12} className="mt-3">
                  <h6 className="text-secondary text-uppercase">
                    <FormattedMessage
                      id="app.views.person.person_performance.influence_card.energizes_people"
                      defaultMessage="Energizes"
                    />
                  </h6>
                  <AvatarGroup
                    people={energizesArray.map(
                      (rel) =>
                        rel.from_person ??
                        getUnattributedPerson(
                          formatMessage,
                          rel.id,
                          rel.type,
                          false
                        )
                    )}
                    maxFaces={4}
                    size={avatarSize}
                  />
                </Col>
              )}
            </Row>
          </>
        ) : (
          <div>
            <FormattedMessage
              id="app.views.person.person_performance.influence_card.empty_state"
              defaultMessage="{firstName} did not receive Influence this cycle. <a>Learn more</a>"
              values={{
                firstName: firstName,
                a: (chunks) => (
                  <>
                    <a className="text-link" ref={influenceBoxLearnMoreRef}>
                      {chunks}
                    </a>
                    <UncontrolledPopover
                      trigger={'hover click'}
                      placement="top"
                      target={influenceBoxLearnMoreRef}
                    >
                      <FormattedMessage
                        id="app.views.person.person_performance.influence_card.learn_more"
                        defaultMessage="Influence is the total number of people who reported being advised or energized by you this cycle. It's important to remember: <ol><li>Not everyone receives Influence! In a typical performance cycle, up to 20% of employees will receive none. The absence of Influence should not be interpreted as a signal of poor performance.</li> <li>Certain roles or groups may be more likely to receive Influence than others. Managers, for example, are more likely to receive Influence than individual contributors. Employees with more tenure are also more likely to receive Influence.</li></ol> If you feel your Influence is low relative to the quality of your contributions, talk to your manager. You may find opportunities to increase visibility or impact for your team."
                        values={{
                          ol: (chunks) => (
                            <List unstyled="true" tag="ol" className="ps-4">
                              {chunks}
                            </List>
                          ),
                          li: (chunks) => (
                            <li className="my-3 px-1">{chunks}</li>
                          ),
                        }}
                      />
                    </UncontrolledPopover>
                  </>
                ),
              }}
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
};
