import { Button, Col, PopoverBody, Row } from 'reactstrap';
import {
  CAMPAIGN_STATUSES,
  getEarliestPhaseStartDate,
} from '../../utils/models/Campaign';
import {
  FORMAT_AVATAR_ONLY,
  FORMAT_AVATAR_WITH_TITLE,
} from '../Widgets/People/Filters/common';
import {
  Features,
  Me,
  Organization,
  OrganizationSettings,
  Person,
  ReduxState,
} from '../../types';
import { FormattedMessage, useIntl } from 'react-intl';
import { ICONS, PROMOTION_PACKETS } from '../../consts/consts';
import {
  PACKET_STATUSES,
  PACKET_STATUS_PENDING,
  PROMO_PACKETS_BULK_APPROVAL_SUBMIT_INPUTS,
  PROMO_PACKET_COMMENT_FIELDS,
  preparePacketForFrontend,
} from '../../utils/models/PromotionPackets';
import {
  PERSON_METADATA_NON_SENSITIVE_FIELD_IDS,
  personNonSensitiveColumnDescriptors,
} from 'views/Widgets/People/Table/commons';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  comparePeople,
  getId,
  getPrettyDate,
  stripHtml,
} from '../../utils/util/util';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import EmptyState from '../Widgets/EmptyState';
import FilterablePeopleTable from '../Widgets/People/FilterablePeopleTable';
import Loading from '../Widgets/Loading';
import ModalEditor from '../Widgets/Modals/ModalEditor';
import ModalPromotionPacketEditorButton from '../Widgets/Modals/ModalPromotionPacketEditorButton';
import Page from '../Layout/Pages/Page';
import PerformanceCycleDropdown from '../Widgets/Dropdowns/PerformanceCycleDropdown';
import PromotionPacket from './PromotionPacket';
import RelativeTime from '../Widgets/RelativeTime';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import moment from 'moment';
import { parseAndFormatQuestionResponse } from '../../utils/models/Performance';
import { peopleIdsAreEqual } from '../../utils/models/Person';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';

const NEW_PROMO_PACKET_BUTTON_ID = 'new-promo-packet-button';
const BELOW_MANAGER_FILTER_PREFIX = 'below_';

const getCutoffDateString = (campaign) => {
  // We bucket packets into campaigns based on the
  // start date of the campaign itself so that anyone
  // that creates a packet after the campaign starts will
  // see that packet in that campaign, and any created
  // before the campaign starts will go into the campaign before
  // (note that the UI says "and after" to indicate that if a packet
  // is created after a campaign ends, it'll still be associated with
  // that campaign as long as it is created before the next campaign starts)
  // This matches the backend that pulls in specific packets for the
  // specific campaign in the perf admin for showing a link to the packet
  // in that UI.
  // (NOTE: if you change this, also change the backend there, search_queries.py
  // for the function get_promo_packets_from_date_range_es_query)
  const mmddyyyyFormattedDate = campaign?.phases
    ? getEarliestPhaseStartDate(campaign.phases)
    : null;

  // convert mm/dd/YYYY format to YYYY-mm-dd format
  return mmddyyyyFormattedDate
    ? moment(mmddyyyyFormattedDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : null;
};

interface Props {
  currentOrganization: Organization;
  me: Me;
  meId: number;
  currentProxyPerson: Person;
  features: Features;
  settings: OrganizationSettings;
}

const PromotionPacketsDashboard: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();
  const [campaigns, setCampaigns] = useState(undefined);
  const [selectedCampaignIndex, setSelectedCampaignIndex] = useState(-1);
  const [packets, setPackets] = useState(undefined);
  const [currentPromotionPacketId, setCurrentPromotionPacketId] =
    useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [filterStatuses, setFilterStatuses] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [selectedRowIdsSet, setSelectedRowIdsSet] = useState(new Set());
  const [
    bulkApproveConfirmationDialogIsOpen,
    setBulkApproveConfirmationDialogIsOpen,
  ] = useState(false);
  const toggleBulkApproveConfirmationDialog = useCallback(
    () =>
      setBulkApproveConfirmationDialogIsOpen(
        !bulkApproveConfirmationDialogIsOpen
      ),
    [bulkApproveConfirmationDialogIsOpen]
  );
  const { user } = useAuth0();
  const userSub = user?.sub;

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    ConfirmAPI.getUrlWithCache(
      '/campaigns',
      'campaigns',
      user?.sub,
      props.currentProxyPerson,
      {
        status: CAMPAIGN_STATUSES.ACTIVE,
        organization: props.currentOrganization?.id,
      },
      (data) => {
        if (data?.results) {
          setCampaigns(data.results.filter((x) => !!x.phases));
          if (data.results?.length > 0) {
            setSelectedCampaignIndex(0);
          }
        } else {
          console.error('Unexpected missing campaign results');
          // @ts-expect-error
          setCampaigns(null);
        }
      },
      (message) => {
        console.error('Could not fetch campaigns: ' + message);
        // @ts-expect-error
        setCampaigns(null);
      }
    );
  }, [props.currentOrganization?.id, props.currentProxyPerson, user?.sub]);

  const onCampaignChange = useCallback(
    (option) => {
      setSelectedCampaignIndex(
        // @ts-expect-error
        campaigns.findIndex((c) => c.id === option.campaignId)
      );
    },
    [campaigns]
  );

  const differentCampaignWhereNewPacketsGo = useMemo(() => {
    // @ts-expect-error
    if (!(campaigns?.length > 0)) {
      return null;
    }

    // get the campaign that has the most recent cutoff date that is in the past
    const now = new Date();
    // @ts-expect-error
    const pastCampaigns = campaigns.filter(
      // @ts-expect-error

      (c) => new Date(getCutoffDateString(c)) < now
    );

    if (pastCampaigns.length === 0) {
      return null;
    }

    // sort from newest cutoff date to oldest
    pastCampaigns.sort((a, b) => {
      return (
        // @ts-expect-error
        new Date(getCutoffDateString(b)) - new Date(getCutoffDateString(a))
      );
    });

    const outputCampaign = pastCampaigns[0];
    // if the output campaign is the currently selected campaign, return null
    // since we don't need to show the disclaimer in the UI
    if (
      selectedCampaignIndex >= 0 &&
      // @ts-expect-error
      outputCampaign.id === campaigns[selectedCampaignIndex].id
    ) {
      return null;
    }

    return outputCampaign;
  }, [campaigns, selectedCampaignIndex]);

  const selectedCampaignName = useMemo(() => {
    if (selectedCampaignIndex === -1) {
      return null;
    }
    // @ts-expect-error
    return campaigns[selectedCampaignIndex]?.name;
  }, [campaigns, selectedCampaignIndex]);

  const currentCampaignCutoffDate = useMemo(() => {
    if (selectedCampaignIndex === -1) {
      return null;
    }
    // @ts-expect-error
    return campaigns[selectedCampaignIndex]
      ? // @ts-expect-error
        getCutoffDateString(campaigns[selectedCampaignIndex])
      : null;
  }, [campaigns, selectedCampaignIndex]);

  const nextCampaignCutoffDate = useMemo(() => {
    if (selectedCampaignIndex === -1) {
      return null;
    }

    // if there is a campaign with a coverage end date later than selectedCampaign
    // in campaigns, return that date
    if (selectedCampaignIndex === 0) {
      return null;
    }

    // @ts-expect-error
    if (campaigns.length > 1) {
      // @ts-expect-error
      return getCutoffDateString(campaigns[selectedCampaignIndex - 1]);
    }
  }, [campaigns, selectedCampaignIndex]);

  // fetch org setting if there's a custom promo packet default description
  const promoPacketOrgSettings = props.settings;

  useEffect(() => {
    if (!isMounted || !promoPacketOrgSettings) {
      return;
    }

    // fetch promotion packet from backend (pull from ES for faster returns)
    if (
      userSub &&
      typeof campaigns !== 'undefined' &&
      // @ts-expect-error
      (campaigns?.length === 0 || selectedCampaignIndex !== -1)
    ) {
      ElasticsearchAPI.getPackets(
        userSub,
        props.currentProxyPerson,
        props.currentOrganization?.id,
        // restrict packets to those within the time of the campaign provided
        currentCampaignCutoffDate || nextCampaignCutoffDate
          ? {
              start_date: currentCampaignCutoffDate,
              end_date: nextCampaignCutoffDate,
            }
          : {},
        (data) => {
          if (isMounted) {
            if (data) {
              setPackets(data);
            }
          }
        },
        (message) => {
          console.error('Could not fetch packets: ' + message);
          // @ts-expect-error
          setPackets(null);
          setErrorMessage(message);
        }
      );
    }
  }, [
    campaigns,
    currentCampaignCutoffDate,
    isMounted,
    nextCampaignCutoffDate,
    promoPacketOrgSettings,
    props.currentOrganization?.id,
    props.currentProxyPerson,
    selectedCampaignIndex,
    userSub,
  ]);

  const toggleFilterStatuses = useCallback(
    (status) => {
      // @ts-expect-error
      if (filterStatuses.indexOf(status) === -1) {
        // @ts-expect-error
        setFilterStatuses([...filterStatuses, status]);
      } else {
        setFilterStatuses(filterStatuses.filter((i) => i !== status));
      }
    },
    [filterStatuses]
  );

  const insertNewPacketAndOpenIt = useCallback(
    (newPacket) => {
      // if differentCampaignWhereNewPacketsGo is set, we are inserting
      // into a different campaign than the one currently selected, so
      // show a toast communicating as such
      if (differentCampaignWhereNewPacketsGo) {
        toast.success(
          formatMessage(
            {
              id: 'app.views.promotion_packets.promotion_packets_dashboard.toast',
              defaultMessage:
                'This packet has been added to the {campaignName} cycle as it has the most recent start date in the past.',
            },
            {
              campaignName: differentCampaignWhereNewPacketsGo.name,
            }
          )
        );
      } else {
        // @ts-expect-error
        setPackets([preparePacketForFrontend(newPacket), ...packets]);
        setCurrentPromotionPacketId(newPacket.id?.toString());
      }
    },
    [differentCampaignWhereNewPacketsGo, packets, formatMessage]
  );

  const isAdminOfPacket = useCallback((p) => {
    return p?.is_adminable;
  }, []);

  const nextReviewerOfPacketIsMe = useCallback(
    (p) => {
      return (
        p?.next_reviewer_person &&
        props.meId &&
        peopleIdsAreEqual(props.meId, p?.next_reviewer_person.id)
      );
    },
    [props.meId]
  );

  const isRowSelectable = useCallback(
    (p) =>
      p.status == PACKET_STATUS_PENDING.id &&
      (isAdminOfPacket(p) || nextReviewerOfPacketIsMe(p)),
    [isAdminOfPacket, nextReviewerOfPacketIsMe]
  );

  const onUpdatePacket = useCallback(
    (updatedPacket) => {
      if (updatedPacket) {
        setPackets(
          // @ts-expect-error
          packets.map((p) =>
            p.id === updatedPacket.id
              ? preparePacketForFrontend(updatedPacket)
              : p
          )
        );

        // if row was in selected set but is no longer selectable, remove from selectable set
        if (
          selectedRowIdsSet.has(updatedPacket.id) &&
          !isRowSelectable(updatedPacket)
        ) {
          setSelectedRowIdsSet(
            new Set(
              Array.from(selectedRowIdsSet).filter(
                (rid) => rid !== updatedPacket.id
              )
            )
          );
        }
      }
    },
    [isRowSelectable, packets, selectedRowIdsSet]
  );

  const rows = useMemo(() => {
    // @ts-expect-error
    if (!(packets?.length > 0)) {
      return [];
    }
    // @ts-expect-error
    let draftRows = packets.map((packet) => {
      return {
        ...preparePacketForFrontend(packet),
        // add field for each person above in chain of command for filtering
        // by a person's full team
        ...(packet.candidate_person.chain_of_command_list
          ? packet.candidate_person.chain_of_command_list.reduce(
              (dict, p) => ({
                ...dict,
                [BELOW_MANAGER_FILTER_PREFIX + p.id]: p,
              }),
              {}
            )
          : {}),
        action: (
          <span>
            <Button
              className="btn-sm"
              color="light"
              onClick={() => setCurrentPromotionPacketId(packet.id?.toString())}
            >
              <FormattedMessage
                id="app.views.promotion_packets.promotion_packets_dashboard.review_packet"
                defaultMessage="Review packet"
              />
            </Button>
            {/*<Link
              tag="Button"
              className="btn btn-light btn-sm"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'inherit' }}
              to={PROMOTION_PACKETS.path + '/' + packet.id}
            >
              Review packet
            </Link>*/}
          </span>
        ),
      };
    });

    // change urls for candidates to go to packet
    draftRows = draftRows.map((r) => ({
      ...r,
      candidate_person: {
        ...r.candidate_person,
        url: PROMOTION_PACKETS(formatMessage).path + '/' + r.id,
      },
    }));

    // filter by whichever types are selected
    if (filterStatuses?.length > 0) {
      // @ts-expect-error
      const statusTypeIds = filterStatuses.map((t) => t.id);
      draftRows = draftRows.filter(
        (f) => statusTypeIds.indexOf(f.status) !== -1
      );
    }

    return draftRows;
  }, [filterStatuses, packets, formatMessage]);

  // show managers list for the "mananger and above" filter functionality
  const managersList = useMemo(() => {
    if (!(rows.length > 0)) {
      return [];
    }

    return rows.reduce((arr, row) => {
      return [...arr, ...(row.candidate_person.chain_of_command_list || [])];
    }, []);
  }, [rows]);

  const customQuestionResponses = useMemo(() => {
    // if the org defined any custom questions, include their output here
    const customQuestions =
      promoPacketOrgSettings?.default_promotion_packet_questions;
    // @ts-expect-error
    if (!customQuestions?.length > 0) {
      return [];
    }

    // @ts-expect-error
    const customQuestionsWithoutExistingCsvHeaders = customQuestions.filter(
      (q) =>
        q.name !== 'configs_new_title' &&
        q.name !== 'configs_is_management_role'
    );

    return customQuestionsWithoutExistingCsvHeaders.map((q) => ({
      name: q.summaryLabel ? (
        q.summaryLabel
      ) : (
        <RichTextViewer model={q.label} expanded={true} />
      ),
      field: q.name,
      format: (val) => {
        const displayValue = parseAndFormatQuestionResponse({
          q: {
            question: q,
            response: val,
          },
          // @ts-expect-error
          noneElement: '-',
          locale,
          formatMessage,
        });
        return typeof displayValue === 'string' &&
          displayValue.startsWith('https://') ? (
          <a href={displayValue} target="_blank" rel="noopener noreferrer">
            {displayValue}
          </a>
        ) : q.name === 'configs_packet_url' ? (
          '-' // display "-" for configs_packet_url if we are not using a URL
        ) : (
          displayValue
        );
      },
      // the org may define just label and id for a given field, so fall back
      // to label if csvName is not defined
      csvName: q.csvName ?? stripHtml(q.label),
      csvFormat: (val) => {
        return parseAndFormatQuestionResponse({
          q: {
            question: q,
            response: val,
          },
          // @ts-expect-error
          noneElement: '',
          formatForCsv: true,
          locale,
          formatMessage,
        });
      },
    }));
  }, [promoPacketOrgSettings, locale, formatMessage]);

  const allowsField = useCallback(
    (field) => {
      // if the org defined any custom questions, include their output here
      const customQuestions =
        promoPacketOrgSettings?.default_promotion_packet_questions;
      // @ts-expect-error
      if (!customQuestions?.length > 0) {
        return true;
      }
      // @ts-expect-error
      return customQuestions.find((q) => q.name === field);
    },
    [promoPacketOrgSettings]
  );

  const columns = useMemo(
    () => [
      {
        csvOnly: true,
        name: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.configs_external_id',
          defaultMessage: 'Candidate ID',
        }),
        field: 'configs_external_id',
        hideFromFilters: true,
      },
      {
        name: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.candidate_person',
          defaultMessage: 'Candidate',
        }),
        field: 'candidate_person',
        isPerson: true,
        format: FORMAT_AVATAR_WITH_TITLE,
        csvName: ['Candidate name', 'Candidate email'],
        csvFormat: (c) => ({
          'Candidate name': c.full_name,
          'Candidate email': c.email,
        }),
        getFilterDisplayValue: (c) => c.full_name,
        nameTransformerFunction: (name) => name + ' as candidate',
        popoverContent: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.candidate_person"
            defaultMessage="Name, title, and link to resume of candidate"
          />
        ),
        sort: (a, b) => comparePeople(a?.candidate_person, b?.candidate_person),
      },
      {
        name: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.author_person',
          defaultMessage: 'Mgr',
        }),
        field: 'author_person',
        isPerson: true,
        format: FORMAT_AVATAR_ONLY,
        csvFormat: (c) => c.email,
        getFilterDisplayValue: (c) => c.full_name,
        nameTransformerFunction: (name) => name + ' as author',
        ifEmpty: '-',
        popoverContent: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.author_person"
            defaultMessage="The manager who submitted or plans to submit the packet"
          />
        ),
        sort: (a, b) => comparePeople(a?.author_person, b?.author_person),
      },
      ...personNonSensitiveColumnDescriptors(formatMessage),
      {
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.previous_reviewer_person"
            defaultMessage="Previous"
          />
        ),
        csvName: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.csv.previous_reviewer_person',
          defaultMessage: 'Most recently reviewed by',
        }),
        isPerson: true,
        field: 'previous_reviewer_person',
        format: FORMAT_AVATAR_ONLY,
        getFilterDisplayValue: (c) => c.full_name,
        nameTransformerFunction: (name) => name + ' as previous reviewer',
        csvFormat: (c) => c.email,
        ifEmpty: '-',
        popoverContent: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.previous_reviewer_person"
            defaultMessage="The most recent person who reviewed this packet, if anyone"
          />
        ),
        sort: (a, b) =>
          comparePeople(
            a?.previous_reviewer_person,
            b?.previous_reviewer_person
          ),
      },
      {
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.next_reviewer_person"
            defaultMessage="Next"
          />
        ),
        csvName: 'Next approval needed',
        isPerson: true,
        field: 'next_reviewer_person',
        format: FORMAT_AVATAR_ONLY,
        getFilterDisplayValue: (c) => c.full_name,
        nameTransformerFunction: (name) => name + ' as next reviewer',
        csvFormat: (c) => c.email,
        ifEmpty: '-',
        popoverContent: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.next_reviewer_person"
            defaultMessage="The next person who needs to approve this packet, if anyone"
          />
        ),
        sort: (a, b) =>
          comparePeople(a?.next_reviewer_person, b?.next_reviewer_person),
      },
      {
        csvOnly: true,
        name: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.configs_time_in_role_in_months',
          defaultMessage: 'Time in Current Role (months)',
        }),
        field: 'configs_time_in_role_in_months',
      },
      {
        csvOnly: true,
        name: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.configs_tenure_in_months',
          defaultMessage: 'Total Tenure (months)',
        }),
        field: 'configs_tenure_in_months',
      },
      ...(allowsField('configs_last_orm_transfer')
        ? [
            {
              csvOnly: true,
              name: formatMessage({
                id: 'app.views.promotion_packets.promotion_packets_dashboard.name.configs_last_orm_transfer',
                defaultMessage: 'Date of Last ORM transfer or Promotion',
              }),
              field: 'configs_last_orm_transfer',
            },
          ]
        : []),
      ...(allowsField('configs_level_id')
        ? [
            {
              csvOnly: true,
              name: formatMessage({
                id: 'app.views.promotion_packets.promotion_packets_dashboard.name.configs_level_id_csv',
                defaultMessage: 'Current Level',
              }),
              field: 'configs_level_id',
            },
          ]
        : []),
      ...(allowsField('configs_new_level_id')
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.LEVEL_FILTER} />
                </span>
              ),
              filterIcon: ICONS.LEVEL_FILTER,
              filterDescription: 'New level',
              csvName: formatMessage({
                id: 'app.views.promotion_packets.promotion_packets_dashboard.csv.configs_new_level_id',
                defaultMessage: 'Proposed Level',
              }),
              field: 'configs_new_level_id',
              ifEmpty: '-',
              popoverContent: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.configs_new_level_id"
                  defaultMessage="The level after the promotion takes effect"
                />
              ),
            },
          ]
        : []),
      {
        name: (
          <span>
            <i className={ICONS.LEADER_FILTER} />
          </span>
        ),
        filterIcon: ICONS.LEADER_FILTER,
        csvName: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.csv.configs_is_management_role',
          defaultMessage: 'Is management role',
        }),
        field: 'configs_is_management_role',
        popoverContent: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.configs_is_management_role"
            defaultMessage="The proposed promotion is for a management role"
          />
        ),
      },
      ...(allowsField('configs_is_job_family_change')
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.JOB_FAMILY_FILTER} />
                </span>
              ),
              filterIcon: ICONS.JOB_FAMILY_FILTER,
              csvName: formatMessage({
                id: 'app.views.promotion_packets.promotion_packets_dashboard.csv.configs_is_job_family_change',
                defaultMessage: 'Is job family change',
              }),
              field: 'configs_is_job_family_change',
              popoverContent: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.configs_is_job_family_change"
                  defaultMessage="The proposed promotion involves a job family change"
                />
              ),
            },
          ]
        : []),
      {
        name: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.configs_new_title',
          defaultMessage: 'New business title',
        }),
        filterIcon: ICONS.TITLE_FILTER,
        field: 'configs_new_title',
        ifEmpty: '-',
        popoverContent: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.configs_new_title"
            defaultMessage="Optional, for anyone that needs a custom business title"
          />
        ),
      },
      ...(allowsField('configs_new_job_code')
        ? [
            {
              name: formatMessage({
                id: 'app.views.promotion_packets.promotion_packets_dashboard.name.configs_new_job_code',
                defaultMessage: 'New job code',
              }),
              field: 'configs_new_job_code',
              filterIcon: ICONS.HRBP_FILTER,
              ifEmpty: '-',
              popoverContent: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.configs_new_job_code"
                  defaultMessage="New job code after the promotion takes effect"
                />
              ),
            },
          ]
        : []),
      ...customQuestionResponses,
      ...(allowsField('configs_new_location')
        ? [
            {
              name: formatMessage({
                id: 'app.views.promotion_packets.promotion_packets_dashboard.name.configs_new_location',
                defaultMessage: 'New location',
              }),
              field: 'configs_new_location',
              csvOnly: true,
            },
          ]
        : []),
      ...(allowsField('configs_has_org_changes')
        ? [
            {
              csvOnly: true,
              name: formatMessage({
                id: 'app.views.promotion_packets.promotion_packets_dashboard.name.configs_has_org_changes',
                defaultMessage: 'Does this promotion yield any org changes?',
              }),
              field: 'configs_has_org_changes',
            },
          ]
        : []),
      ...(allowsField('configs_org_changes')
        ? [
            {
              csvOnly: true,
              name: formatMessage({
                id: 'app.views.promotion_packets.promotion_packets_dashboard.name.configs_org_changes',
                defaultMessage: 'Describe org changes',
              }),
              field: 'configs_org_changes',
            },
          ]
        : []),
      ...(allowsField('configs_final_rating')
        ? [
            {
              name: (
                <span>
                  <i className={ICONS.FINAL_RATING} />
                </span>
              ),
              filterIcon: ICONS.FINAL_RATING,
              csvName: formatMessage({
                id: 'app.views.promotion_packets.promotion_packets_dashboard.csv.configs_final_rating',
                defaultMessage: 'Most Recent Performance Rating',
              }),
              field: 'configs_final_rating',
              popoverContent: (
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.configs_final_rating"
                  defaultMessage="The most recent performance rating"
                />
              ),
            },
          ]
        : []),
      ...(allowsField('configs_rating')
        ? [
            {
              csvOnly: true,
              name: formatMessage({
                id: 'app.views.promotion_packets.promotion_packets_dashboard.name.configs_rating',
                defaultMessage: 'DRAFT Performance Rating (before calibration)',
              }),
              field: 'configs_rating',
            },
          ]
        : []),
      {
        name: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.submitted_at',
          defaultMessage: 'Submitted',
        }),
        field: 'submitted_at',
        popoverContent: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.submitted_at"
            defaultMessage="When the packet was submitted, if ever"
          />
        ),
        format: (date) => {
          return date ? <RelativeTime unit="day" datetime={date} /> : '-';
        },
        hideFromFilters: true,
      },
      {
        name: (
          <span>
            <i className={ICONS.ACTIVITY} />
          </span>
        ),
        filterIcon: ICONS.ACTIVITY,
        filterDescription: 'Status',
        csvName: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.csv.status',
          defaultMessage: 'Status',
        }),
        csvFormat: (s) =>
          PACKET_STATUSES.find((status) => status.id === s)?.name,
        field: 'status',
        format: (s, uniqueIndexIdentifier) => {
          const status = PACKET_STATUSES.find((status) => status.id === s);
          return (
            <>
              <i
                id={'status-' + uniqueIndexIdentifier}
                // @ts-expect-error
                className={status.icon}
              />
              <UncontrolledPopover
                trigger="hover"
                placement="top"
                target={'status-' + uniqueIndexIdentifier}
              >
                <PopoverBody>
                  <div className="text-dark">
                    <FormattedMessage
                      id="app.views.promotion_packets.promotion_packets_dashboard.packet_popover_heading"
                      defaultMessage="<span>{heading}</span> packet"
                      values={{
                        // @ts-expect-error
                        heading: status.heading,
                        span: (chunks) => (
                          <span className="fw-bold">{chunks}</span>
                        ),
                      }}
                    />
                  </div>
                  <div>
                    {
                      // @ts-expect-error
                      status.description
                    }
                  </div>
                </PopoverBody>
              </UncontrolledPopover>
            </>
          );
        },
        popoverContent: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.status"
            defaultMessage="Packet status"
          />
        ),
      },
      {
        name: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.action',
          defaultMessage: 'Action',
        }),
        field: 'action',
        sortable: false,
        hideFromCSV: true,
        hideFromFilters: true,
        popoverContent: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.popover_content.action"
            defaultMessage="Review packet to approve, deny, add comments, etc."
          />
        ),
      },
      {
        csvOnly: true,
        name: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.approved_at',
          defaultMessage: 'Approved',
        }),
        field: 'approved_at',
      },
      {
        csvOnly: true,
        name: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.denied_at',
          defaultMessage: 'Denied',
        }),
        field: 'denied_at',
      },
      {
        csvOnly: true,
        name: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.withdrawn_at',
          defaultMessage: 'Withdrawn',
        }),
        field: 'withdrawn_at',
      },
      ...(allowsField('configs_packet_url')
        ? [
            {
              csvOnly: true,
              name: formatMessage({
                id: 'app.views.promotion_packets.promotion_packets_dashboard.name.configs_packet_url',
                defaultMessage: 'Template',
              }),
              field: 'configs_packet_url',
            },
          ]
        : []),
      {
        csvOnly: true,
        name: formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.name.description',
          defaultMessage: 'Description',
        }),
        field: 'description',
        csvFormat: (val) => stripHtml(val, true),
      },
      // to help with filtering
      {
        filterOnly: true,
        field: 'configs_level',
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.configs_level"
            defaultMessage="Current level"
          />
        ),
        filterIcon: ICONS.LEVEL_FILTER,
      },
      {
        filterOnly: true,
        field: 'configs_level_id',
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.configs_level_id"
            defaultMessage="Current level"
          />
        ),
        filterIcon: ICONS.LEVEL_FILTER,
      },
      {
        filterOnly: true,
        field: 'configs_title',
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.configs_title"
            defaultMessage="Current title"
          />
        ),
        filterIcon: ICONS.TITLE_FILTER,
      },
      {
        filterOnly: true,
        field: 'configs_function',
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.configs_function"
            defaultMessage="Current function"
          />
        ),
        filterIcon: ICONS.FUNCTION_FILTER,
      },
      {
        filterOnly: true,
        field: 'configs_location',
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.configs_location"
            defaultMessage="Current location"
          />
        ),
        filterIcon: ICONS.LOCATION_FILTER,
      },
      {
        filterOnly: true,
        field: 'configs_department',
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.configs_department"
            defaultMessage="Current department"
          />
        ),
        filterIcon: ICONS.DEPARTMENT_FILTER,
      },
      {
        filterOnly: true,
        field: 'configs_department_id',
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.configs_department_id"
            defaultMessage="Current department ID"
          />
        ),
        filterIcon: ICONS.DEPARTMENT_FILTER,
      },
      {
        filterOnly: true,
        field: 'configs_job_code',
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.configs_job_code"
            defaultMessage="Current job code"
          />
        ),
        filterIcon: ICONS.FUNCTION_FILTER,
      },
      {
        filterOnly: true,
        field: 'configs_job_profile',
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.configs_job_profile"
            defaultMessage="Current job profile"
          />
        ),
        filterIcon: ICONS.FUNCTION_FILTER,
      },
      {
        filterOnly: true,
        field: 'configs_cost_center',
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.configs_cost_center"
            defaultMessage="Current cost center"
          />
        ),
        filterIcon: ICONS.COST_CENTER_FILTER,
      },
      {
        filterOnly: true,
        field: 'configs_cost_center_id',
        name: (
          <FormattedMessage
            id="app.views.promotion_packets.promotion_packets_dashboard.name.configs_cost_center_id"
            defaultMessage="Current cost center ID"
          />
        ),
        filterIcon: ICONS.COST_CENTER_FILTER,
      },
      // map fields for filtering for each person in the chain of command
      ...managersList.map((p) => ({
        filterOnly: true,
        name: p.full_name + "'s full team",
        field: BELOW_MANAGER_FILTER_PREFIX + p.id,
        isPerson: true,
        getFilterDisplayValue: (c) => c.full_name,
        nameTransformerFunction: (name) => name + "'s full team",
      })),
    ],
    [allowsField, customQuestionResponses, managersList, formatMessage]
  );

  const customLevelOptions = useMemo(
    () =>
      promoPacketOrgSettings &&
      promoPacketOrgSettings?.default_promotion_level_options,
    [promoPacketOrgSettings]
  );

  const urlInputAttributes = useMemo(
    () =>
      promoPacketOrgSettings &&
      promoPacketOrgSettings?.default_promotion_url_input_attributes,
    [promoPacketOrgSettings]
  );

  const prettyEffectiveDate = useMemo(
    () =>
      promoPacketOrgSettings &&
      promoPacketOrgSettings?.default_promotion_effective_date
        ? getPrettyDate({
            dateString:
              promoPacketOrgSettings?.default_promotion_effective_date,
            locale,
          })
        : undefined,
    [promoPacketOrgSettings, locale]
  );

  const newPacketButton = useMemo(() => {
    const outputButton = (
      <ModalPromotionPacketEditorButton
        promoPacketOrgSettings={promoPacketOrgSettings}
        customLevelOptions={customLevelOptions}
        urlInputAttributes={urlInputAttributes}
        callback={insertNewPacketAndOpenIt}
      />
    );

    if (differentCampaignWhereNewPacketsGo) {
      // show popover on top of button indicating that the
      // new packet will show up in a different campaign
      return (
        <>
          <UncontrolledPopover
            trigger="hover"
            placement="bottom"
            target={NEW_PROMO_PACKET_BUTTON_ID}
          >
            <PopoverBody>
              <div className="text-dark">
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packets_dashboard.warning_popover"
                  defaultMessage="<boldSpan>Warning:</boldSpan> New packets will be
                  put into the {campaignName} cycle as it has the most recent start
                  date in the past.
                  {br}
                  {br}
                  If this is not what you expected, please contact your HR
                  administrator."
                  values={{
                    boldSpan: (chunks) => (
                      <span className="fw-bold">{chunks}</span>
                    ),
                    campaignName: differentCampaignWhereNewPacketsGo.name,
                    br: <br />,
                  }}
                />
              </div>
            </PopoverBody>
          </UncontrolledPopover>
          <div id={NEW_PROMO_PACKET_BUTTON_ID}>{outputButton}</div>
        </>
      );
    }

    return outputButton;
  }, [
    customLevelOptions,
    differentCampaignWhereNewPacketsGo,
    insertNewPacketAndOpenIt,
    promoPacketOrgSettings,
    urlInputAttributes,
  ]);

  const cycleDropdown = useMemo(() => {
    return (
      // @ts-expect-error
      campaigns?.length > 0 && (
        <PerformanceCycleDropdown
          allowNoSelection={true}
          // @ts-expect-error
          campaigns={campaigns}
          onChange={onCampaignChange}
          suffix={
            ' ' +
            formatMessage({
              id: 'app.views.promotion_packets.promotion_packets_dashboard.suffix._cycle_and_after',
              defaultMessage: 'cycle and after',
            })
          }
        />
      )
    );
  }, [campaigns, onCampaignChange, formatMessage]);

  const toggleSelectedRowField = useCallback(
    (rowId) => {
      if (selectedRowIdsSet.has(rowId)) {
        setSelectedRowIdsSet(
          new Set(Array.from(selectedRowIdsSet).filter((rid) => rid !== rowId))
        );
      } else {
        setSelectedRowIdsSet(
          new Set([...Array.from(selectedRowIdsSet), rowId])
        );
      }
    },
    [selectedRowIdsSet]
  );

  const onBulkApproval = useCallback(
    (response) => {
      if (!response.packets) {
        console.error(
          'There was an error approving these packets. Our engineering team has been notified. Please try again later.'
        );
        return;
      }

      toast.success(
        formatMessage({
          id: 'app.views.promotion_packets.promotion_packets_dashboard.approvals_submitted',
          defaultMessage:
            'Approvals submitted. These may take a few minutes to show up after you refresh this page.',
        })
      );

      // update UI for each packet based on these approvals
      setPackets(
        // @ts-expect-error
        packets.map((p) => {
          const foundPacket = response.packets.find((p2) => p2.id === p.id);

          if (foundPacket) {
            return foundPacket;
          } else {
            return p;
          }
        })
      );

      // remove all current selections given the success
      setSelectedRowIdsSet(new Set());
    },
    [packets, formatMessage]
  );

  const transformBulkApprovalObjectBeforeSubmit = useCallback((object) => {
    return {
      ...object,
      previous_reviewer_person: object.previous_reviewer_person?.id,
      next_reviewer_person: object.next_reviewer_person?.id,
    };
  }, []);

  const actionButtons = useMemo(
    () => (
      <Row>
        {
          // @ts-expect-error
          packets?.length > 0 && (
            <Col className="col-auto">{newPacketButton}</Col>
          )
        }
        <Col className="col-auto pe-4">{cycleDropdown}</Col>
      </Row>
    ),
    // @ts-expect-error
    [cycleDropdown, newPacketButton, packets?.length]
  );

  const output = useMemo(() => {
    const loadOrRenderOutput = loadOrRender(packets, errorMessage);
    if (loadOrRenderOutput) {
      return loadOrRenderOutput;
    }

    if (typeof packets === 'undefined') {
      return <Loading />;
    }

    return (
      <>
        {currentPromotionPacketId && (
          <PromotionPacket
            // @ts-expect-error
            promotionPacketId={currentPromotionPacketId?.toString()}
            inModal={true}
            toggle={() => setCurrentPromotionPacketId(null)}
            onClosed={() => setCurrentPromotionPacketId(null)}
            callback={onUpdatePacket}
          />
        )}
        <Page
          title={formatMessage({
            id: 'app.views.promotion_packets.promotion_packets_dashboard.title.promotion_packets',
            defaultMessage: 'Promotion packets',
          })}
        >
          {actionButtons}

          {
            // @ts-expect-error
            !(packets?.length > 0) && (
              <EmptyState
                title={formatMessage(
                  {
                    id: 'app.views.promotion_packets.promotion_packets_dashboard.title.you_do_not_have_any_promotion_packets',
                    defaultMessage:
                      'You do not have any promotion packets that you are eligible to view{selectedCampaignName, select, none {.} other { for {selectedCampaignName}.}}',
                  },
                  { selectedCampaignName: selectedCampaignName ?? 'none' }
                )}
                subtitle={formatMessage({
                  id: 'app.views.promotion_packets.promotion_packets_dashboard.subtitle.if_you_are_a_people_manager',
                  defaultMessage:
                    'If you are a people manager, you can create a new packet.',
                })}
              >
                {newPacketButton}
              </EmptyState>
            )
          }
          {
            // @ts-expect-error
            packets?.length > 0 && (
              <FilterablePeopleTable
                // we may use arrays in values, e.g. for list of direct reports, so this must be false
                arrayValuesUsedForFormatting={false}
                tableClassName="calibration-table"
                headerContent={
                  <Row className="align-items-center">
                    <Col className="col-auto ps-2">
                      <ul className="pagination pagination-sm mb-0">
                        {PACKET_STATUSES.map((type, index) => (
                          <li
                            key={index}
                            className={
                              'page-item' +
                              // @ts-expect-error
                              (filterStatuses.indexOf(type) !== -1
                                ? ' active'
                                : '')
                            }
                            role="button"
                            onClick={() => toggleFilterStatuses(type)}
                          >
                            <span className="page-link">
                              <i
                                className={'me-2 ' + type.icon}
                                style={{ position: 'relative', top: 1 }}
                              />
                              {type.heading}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </Col>
                    <Col></Col>
                    <Col className="col-auto pe-0">
                      {
                        <ModalEditor
                          method="PATCH"
                          url="packets/approve"
                          title={formatMessage(
                            {
                              id: 'app.views.promotion_packets.promotion_packets_dashboard.title.approve',
                              defaultMessage:
                                'Approve {count, plural, one {1 packet?} other {{count} packets?}}',
                            },
                            {
                              count: selectedRowIdsSet.size,
                            }
                          )}
                          isOpen={bulkApproveConfirmationDialogIsOpen}
                          toggle={toggleBulkApproveConfirmationDialog}
                          submitText={formatMessage(
                            {
                              id: 'app.views.promotion_packets.promotion_packets_dashboard.button.approve',
                              defaultMessage:
                                'Approve {count, plural, one {1 packet} other {{count} packets}}',
                            },
                            {
                              count: selectedRowIdsSet.size,
                            }
                          )}
                          callback={onBulkApproval}
                          transformObjectBeforeSubmit={
                            transformBulkApprovalObjectBeforeSubmit
                          }
                          object={{ packets: Array.from(selectedRowIdsSet) }}
                          renderInputs={(inputs) => {
                            return (
                              <>
                                {inputs}

                                <div className="text-center">
                                  {allowsField('configs_new_job_code') && (
                                    <>
                                      <FormattedMessage
                                        id="app.views.promotion_packets.promotion_packets_dashboard.blank_codes"
                                        defaultMessage="
                                    Any blank job codes will set automatically
                                    based on level and job family."
                                      />{' '}
                                    </>
                                  )}
                                  <FormattedMessage
                                    id="app.views.promotion_packets.promotion_packets_dashboard.more_approvals"
                                    defaultMessage="
                                If any packets require more approvals, they will
                                be routed to the next approver.
                              "
                                  />
                                </div>
                                {prettyEffectiveDate && (
                                  <div className="text-center text-muted">
                                    <FormattedMessage
                                      id="app.views.promotion_packets.promotion_packets_dashboard.effective_date"
                                      defaultMessage="
                                  Packets with no effective date set will
                                  default to {prettyEffectiveDate}."
                                      values={{
                                        prettyEffectiveDate:
                                          prettyEffectiveDate,
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            );
                          }}
                          inputs={[
                            ...PROMO_PACKETS_BULK_APPROVAL_SUBMIT_INPUTS,
                            ...PROMO_PACKET_COMMENT_FIELDS,
                          ]}
                        />
                      }
                      {selectedRowIdsSet.size > 0 && (
                        <Button
                          color="light"
                          className="btn-sm"
                          onClick={toggleBulkApproveConfirmationDialog}
                        >
                          <FormattedMessage
                            id="app.views.promotion_packets.promotion_packets_dashboard.approve_packets"
                            defaultMessage="Approve {size} packets..."
                            values={{
                              size: selectedRowIdsSet.size,
                            }}
                          />
                        </Button>
                      )}
                    </Col>
                  </Row>
                }
                isRowSelectable={isRowSelectable}
                toggleSelectedRowField={toggleSelectedRowField}
                getUniqueRowId={getId}
                selectedRowToggleFieldsSet={selectedRowIdsSet}
                setSelectedRowToggleFieldsSet={setSelectedRowIdsSet}
                hideColumnSelector={false}
                rows={rows}
                columns={columns}
                defaultUnselectedColumns={
                  PERSON_METADATA_NON_SENSITIVE_FIELD_IDS
                }
                // sort by newest first
                defaultSort={['id']}
                defaultSortAtoZ={false}
              ></FilterablePeopleTable>
            )
          }
        </Page>
      </>
    );
  }, [
    actionButtons,
    allowsField,
    bulkApproveConfirmationDialogIsOpen,
    columns,
    currentPromotionPacketId,
    errorMessage,
    filterStatuses,
    isRowSelectable,
    newPacketButton,
    onBulkApproval,
    onUpdatePacket,
    packets,
    prettyEffectiveDate,
    rows,
    selectedCampaignName,
    selectedRowIdsSet,
    toggleBulkApproveConfirmationDialog,
    toggleFilterStatuses,
    toggleSelectedRowField,
    transformBulkApprovalObjectBeforeSubmit,
    formatMessage,
  ]);

  return output;
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson, me, features, settings } =
    state;

  return {
    currentOrganization,
    currentProxyPerson,
    me,
    meId: me?.id,
    features,
    settings,
  };
};

export default connect(mapStateToProps)(React.memo(PromotionPacketsDashboard));
