import * as consts from '../../consts/consts';

import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import {
  ExtendedPerson,
  Organization,
  Person,
  Profile,
  ReduxState,
} from 'types';
import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  getContactMethodIcon,
  getContactMethodLink,
  getContactMethodName,
} from '../../utils/models/ContactMethods';

import Avatar from '../Widgets/People/Avatar';
import AvatarGroup from '../Widgets/People/AvatarGroup';
import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import { Link } from 'react-router-dom';
import ModalPersonProfileEditButton from '../Widgets/Modals/ModalPersonProfileEditButton';
import PersonCard from '../Widgets/Cards/PersonCard';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { connect } from 'react-redux';
import { getPrettyDate } from '../../utils/util/util';
import { useAuth0 } from '@auth0/auth0-react';
import usePersonOrgChartRelatives from './PersonOrgChartRelatives';

const PROFILE_MAX_LENGTH_INTRO = 255;
const PROFILE_MAX_LENGTH_PRONOUNS = 50;
const PROFILE_MAX_LENGTH_NAME_PRONUNCIATION = 120;
const PROFILE_MAX_LENGTH_PREFERRED_GIVEN_NAME = 50;

export const PERSON_PROFILE_COMPONENT_PREFERRED_FIRST_NAME =
  'preferred_first_name';
export const PERSON_PROFILE_COMPONENT_PRONOUNS = 'pronouns';
export const PERSON_PROFILE_COMPONENT_CONTACT = 'contact';
export const PERSON_PROFILE_COMPONENT_WANTS_TO_MENTOR = 'wants_to_mentor';
export const PERSON_PROFILE_COMPONENT_MANAGER = 'manager';
export const PERSON_PROFILE_COMPONENT_DIRECT_REPORTS = 'direct_reports';
export const PERSON_PROFILE_COMPONENT_BUSINESS_UNIT = 'business_unit';
export const PERSON_PROFILE_COMPONENT_JOINED = 'joined';

interface Props {
  currentOrganization: Organization;
  currentProxyPerson?: Person;
  isMe: boolean;
  isOpen: boolean;
  successCallback?: (pr: Profile) => void;
  onClosed?: () => void;
  person: ExtendedPerson;
  title?: string;
  showPersonHeader?: boolean;
  fetchPersonDetails?: boolean;
  isExternalUrl?: boolean;
  renderParts?: (components) => ReactNode;
  showOperatingManualLink?: boolean;
}

const defaultRenderParts = (components) => {
  return (
    <>
      <Col sm={6}>
        {components[PERSON_PROFILE_COMPONENT_PREFERRED_FIRST_NAME]}
        {components[PERSON_PROFILE_COMPONENT_PRONOUNS]}
        {components[PERSON_PROFILE_COMPONENT_CONTACT]}
        {components[PERSON_PROFILE_COMPONENT_WANTS_TO_MENTOR]}
      </Col>
      <Col sm={6} className="mb-4">
        {components[PERSON_PROFILE_COMPONENT_MANAGER]}
        {components[PERSON_PROFILE_COMPONENT_DIRECT_REPORTS]}
        {components[PERSON_PROFILE_COMPONENT_BUSINESS_UNIT]}
        {components[PERSON_PROFILE_COMPONENT_JOINED]}
      </Col>
    </>
  );
};

const PersonProfileIntroSummary: FC<Props> = ({
  showOperatingManualLink = true,
  ...props
}) => {
  const { formatMessage, locale } = useIntl();
  const orgChartRelatives = usePersonOrgChartRelatives(
    props.currentOrganization,
    props.currentProxyPerson,
    props.person
  );
  const [showAllContactMethods, setShowAllContactMethods] = useState(false);
  const MAX_CONTACT_LINKS_BEFORE_HIDING = 4;
  const [person, setPerson] = useState<ExtendedPerson>(props.person);

  const mostReachableVia = person?.profile?.most_reachable_via?.filter(
    (o) => o.value
  );
  const [personDetailsFetched, setPersonDetailsFetched] = useState(
    !props.fetchPersonDetails
  );

  const { user } = useAuth0();
  const userSub = user?.sub;

  const propsSuccessCallback = props.successCallback;

  const successCallback = useCallback(
    (profile) => {
      setPersonDetailsFetched(false);
      if (propsSuccessCallback) {
        propsSuccessCallback(profile);
      }
    },
    [propsSuccessCallback]
  );

  useEffect(() => {
    // if we don't have fetchPersonDetails set, we need to refresh person
    // after the data is being edited
    if (!props.fetchPersonDetails && !personDetailsFetched && props.person) {
      setPerson(props.person);
    }

    // if fetchPersonDetails is provided, it means
    // that only partial information was passed in and
    // we need to fetch the full person from the backend
    // to fill out the rest
    if (
      !props.fetchPersonDetails ||
      !props.person ||
      !userSub ||
      personDetailsFetched
    ) {
      return;
    }

    setPersonDetailsFetched(true);

    // fetch person's details
    ConfirmAPI.getObject(
      userSub,
      props.currentProxyPerson,
      ConfirmAPI.OBJECT_TYPES.PEOPLE,
      props.person.id,
      (data) => {
        const newPerson = data || null;
        setPerson(newPerson);
      },
      (message) => {
        console.error('Failed to fetch person from intro summary: ' + message);
      }
    );
  }, [
    props.person,
    props.fetchPersonDetails,
    userSub,
    props.currentProxyPerson,
    personDetailsFetched,
  ]);

  const notProvidedElement = useMemo(
    () => (
      <span className="fst-italic text-muted">
        <FormattedMessage
          id="app.views.person.person_profile_intro_summary.not_provided"
          defaultMessage="Not provided"
        />
      </span>
    ),
    []
  );

  const moreThanOneTeam = useMemo(() => {
    let teamCount = 0;
    if (person?.business_unit) {
      teamCount++;
    }
    if (person?.department) {
      teamCount++;
    }
    if (person?.function) {
      teamCount++;
    }
    if (person?.location) {
      teamCount++;
    }

    return teamCount > 1;
  }, [
    person?.business_unit,
    person?.department,
    person?.function,
    person?.location,
  ]);

  const object = useMemo(
    () => ({
      ...person.profile,
      preferred_given_name: person.preferred_given_name,
      wants_to_mentor_since: person.profile?.wants_to_mentor_since
        ? 'Yes'
        : 'No',
    }),
    [person.preferred_given_name, person.profile]
  );

  const components = useMemo(() => {
    const available_components = {
      [PERSON_PROFILE_COMPONENT_PREFERRED_FIRST_NAME]: (
        <>
          <h5 className="mb-1 text-muted">
            <FormattedMessage
              id="app.views.person.person_profile_intro_summary.preferred_first"
              defaultMessage="Preferred first name"
            />
          </h5>
          <div className="mb-3">
            {person?.given_name}
            {person?.profile?.name_pronunciation && (
              <>
                &nbsp;
                <i
                  id="pronunciation-icon"
                  className="fe fe-volume-2 me-2 align-middle"
                />
                <UncontrolledPopover
                  placement="right"
                  trigger="hover"
                  target="pronunciation-icon"
                >
                  {person?.profile?.name_pronunciation}
                </UncontrolledPopover>
              </>
            )}
          </div>
        </>
      ),
      [PERSON_PROFILE_COMPONENT_PRONOUNS]: (
        <>
          <h5 className="mb-1 text-muted">
            <FormattedMessage
              id="app.views.person.person_profile_intro_summary.pronouns"
              defaultMessage="Pronouns"
            />
          </h5>
          <div className="mb-3">
            {person?.profile?.pronouns
              ? person.profile.pronouns
              : notProvidedElement}
          </div>
        </>
      ),
      [PERSON_PROFILE_COMPONENT_CONTACT]: (
        <>
          <h5 className="mb-1 text-muted">
            <FormattedMessage
              id="app.views.person.person_profile_intro_summary.contact"
              defaultMessage="Contact"
            />
          </h5>
          <div className="mb-3">
            {mostReachableVia?.length
              ? mostReachableVia.map(
                  (o, index, arr) =>
                    (arr?.length <= MAX_CONTACT_LINKS_BEFORE_HIDING ||
                      (arr?.length > MAX_CONTACT_LINKS_BEFORE_HIDING &&
                        index < MAX_CONTACT_LINKS_BEFORE_HIDING - 1) ||
                      showAllContactMethods) && (
                      <div key={index}>
                        <span
                          className={getContactMethodIcon(o) + ' me-2'}
                        ></span>
                        <a
                          className="text-dark"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={getContactMethodLink(o)}
                        >
                          {getContactMethodName(o)}
                        </a>
                      </div>
                    )
                )
              : notProvidedElement}
            {!!mostReachableVia &&
              mostReachableVia?.length > MAX_CONTACT_LINKS_BEFORE_HIDING &&
              !showAllContactMethods && (
                <div>
                  <span
                    role="button"
                    onClick={() => setShowAllContactMethods(true)}
                  >
                    <FormattedMessage
                      id="app.views.person.person_profile_intro_summary.button.more.text"
                      defaultMessage="
                      More "
                    />
                    <span className="dropdown-toggle" />
                  </span>
                </div>
              )}
          </div>
        </>
      ),
      [PERSON_PROFILE_COMPONENT_WANTS_TO_MENTOR]: (
        <>
          {person?.profile?.wants_to_mentor_since && (
            <>
              <h5 className="mb-1 text-muted">
                <FormattedMessage
                  id="app.views.person.person_profile_intro_summary.wants_to_mentor_since"
                  defaultMessage="Wants to mentor"
                />
              </h5>
              <div className="mb-3">
                <FormattedMessage
                  id="app.views.person.person_profile_intro_summary.yes"
                  defaultMessage="Yes"
                />
              </div>
            </>
          )}
        </>
      ),
      [PERSON_PROFILE_COMPONENT_MANAGER]: (
        <>
          {orgChartRelatives?.manager && (
            <>
              <h5 className="mb-1 text-muted">
                <FormattedMessage
                  id="app.views.person.person_profile_intro_summary.manager"
                  defaultMessage="Manager"
                />
              </h5>
              <div className="mb-3">
                <PersonCard
                  size="xxs"
                  bodyOnly
                  person={orgChartRelatives.manager}
                  linked
                  showDescription={false}
                />
              </div>
            </>
          )}
        </>
      ),
      [PERSON_PROFILE_COMPONENT_DIRECT_REPORTS]: (
        <>
          {!!orgChartRelatives?.direct_reports.length && (
            <>
              <h5 className="mb-1 text-muted">
                <FormattedMessage
                  id="app.views.person.person_profile_intro_summary.direct_remport"
                  defaultMessage="Direct reports"
                />
              </h5>
              <div className="mb-3">
                {orgChartRelatives?.direct_reports.length === 1 ? (
                  <PersonCard
                    size="xxs"
                    bodyOnly
                    person={orgChartRelatives.direct_reports[0]}
                    linked
                    showDescription={false}
                  />
                ) : (
                  <AvatarGroup
                    size="xs"
                    people={orgChartRelatives.direct_reports}
                  />
                )}
              </div>
            </>
          )}
        </>
      ),
      [PERSON_PROFILE_COMPONENT_BUSINESS_UNIT]: (
        <>
          {(person?.business_unit ||
            person?.department ||
            person?.function ||
            person?.location) && (
            <>
              <h5 className="mb-1 text-muted">
                <FormattedMessage
                  id="app.views.person.person_profile_intro_summary.team"
                  defaultMessage="{moreThanOneTeam, select, true {Teams} other {Team}}"
                  values={{
                    moreThanOneTeam,
                  }}
                />
              </h5>
              <div className="mb-3">
                {person?.business_unit && (
                  <div>
                    <i className={consts.ICONS.BUSINESS_UNIT_FILTER} />{' '}
                    {person.business_unit}
                  </div>
                )}
                {person?.department && (
                  <div>
                    <i className={consts.ICONS.DEPARTMENT_FILTER} />{' '}
                    {person.department}
                  </div>
                )}
                {person?.function && (
                  <div>
                    <i className={consts.ICONS.FUNCTION_FILTER} />{' '}
                    {person.function}
                  </div>
                )}
                {person?.location && (
                  <div>
                    <i className={consts.ICONS.LOCATION_FILTER} />{' '}
                    {person.location}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      ),
      [PERSON_PROFILE_COMPONENT_JOINED]: (
        <>
          <h5 className="mb-1 text-muted">
            <FormattedMessage
              id="app.views.person.person_profile_intro_summary.joined"
              defaultMessage="Joined"
            />
          </h5>
          <div className="mb-3">
            {person?.latest_hire_date
              ? getPrettyDate({
                  dateString: person.latest_hire_date,
                  locale,
                })
              : notProvidedElement}
          </div>
        </>
      ),
    };
    return available_components;
  }, [
    person,
    locale,
    moreThanOneTeam,
    mostReachableVia,
    notProvidedElement,
    orgChartRelatives?.direct_reports,
    orgChartRelatives?.manager,
    showAllContactMethods,
  ]);

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle icon="fe fe-user me-2">
          {props.title ? (
            props.title
          ) : (
            <FormattedMessage
              id="app.views.person.person_profile_intro_summary.intro"
              defaultMessage="Intro"
            />
          )}
        </CardHeaderTitle>
        {props.isMe && (
          <ModalPersonProfileEditButton
            isOpen={props.isOpen}
            onClosed={props.onClosed}
            title={formatMessage({
              id: 'app.views.person.person_profile_intro_summary.title.edit_personal_intro',
              defaultMessage: 'Edit Personal Intro',
            })}
            anchorTrigger="edit-intro"
            object={object}
            transformObjectBeforeSubmit={(object) => {
              return {
                preferred_given_name: object.preferred_given_name,
                profile: {
                  intro: object.intro,
                  pronouns: object.pronouns,
                  wants_to_mentor_since:
                    object.wants_to_mentor_since === 'Yes' ? new Date() : null,
                  most_reachable_via: object.most_reachable_via?.filter(
                    (o) => o.value
                  ),
                  name_pronunciation: object.name_pronunciation,
                },
              };
            }}
            inputs={[
              {
                label: formatMessage({
                  id: 'app.views.person.person_profile_intro_summary.introduction',
                  defaultMessage: 'Introduction',
                }),
                name: 'intro',
                helperText: formatMessage({
                  id: 'app.views.person.person_profile_intro_summary.intro.helper_text',
                  defaultMessage: 'A sentence or two in your own words',
                }),
                type: INPUT_TYPES.MENTION_EDITOR,
                config: {
                  charCounterMax: PROFILE_MAX_LENGTH_INTRO,
                },
              },
              {
                label: formatMessage({
                  id: 'app.views.person.person_profile_intro_summary.preferred_first',
                  defaultMessage: 'Preferred first name',
                }),
                name: 'preferred_given_name',
                helperText: formatMessage({
                  id: 'app.views.person.person_profile_intro_summary.preferred_first.helper_text',
                  defaultMessage:
                    'The first name you want people to call you in most situations',
                }),
                maxLength: PROFILE_MAX_LENGTH_PREFERRED_GIVEN_NAME,
              },
              {
                name: 'name_pronunciation',
                label: formatMessage({
                  id: 'app.views.person.person_profile_intro_summary.name_pronunciation',
                  defaultMessage: 'Name pronunciation',
                }),
                helperText: formatMessage({
                  id: 'app.views.person.person_profile_intro_summary.name_pronunciation.helper_text',
                  defaultMessage: 'How to pronounce your name',
                }),
                maxLength: PROFILE_MAX_LENGTH_NAME_PRONUNCIATION,
              },
              {
                label: formatMessage({
                  id: 'app.views.person.person_profile_intro_summary.pronouns',
                  defaultMessage: 'Pronouns',
                }),
                helperText: formatMessage({
                  id: 'app.views.person.person_profile_intro_summary.pronouns.helper_text',
                  defaultMessage:
                    'For example, she/her/hers, he/him/his, they/them/their, etc.',
                }),
                name: 'pronouns',
                maxLength: PROFILE_MAX_LENGTH_PRONOUNS,
              },
              {
                label: formatMessage({
                  id: 'app.views.person.person_profile_intro_summary.wants_to_mentor',
                  defaultMessage: 'Do you want to be a mentor?',
                }),
                helperText: formatMessage({
                  id: 'app.views.person.person_profile_intro_summary.wants_to_mentor.helper_text',
                  defaultMessage:
                    'Saying "Yes" will allow others to see you in search results when searching for a mentor.',
                }),
                name: 'wants_to_mentor_since',
                type: INPUT_TYPES.MULTIPLE_CHOICE,
                options: [
                  {
                    id: 'Yes',
                    name: formatMessage({
                      id: 'app.views.person.person_profile_intro_summary.yes',
                      defaultMessage: 'Yes',
                    }),
                  },
                  {
                    id: 'No',
                    name: formatMessage({
                      id: 'app.views.person.person_profile_intro_summary.no',
                      defaultMessage: 'No',
                    }),
                  },
                ],
              },
              {
                label: formatMessage({
                  id: 'app.views.person.person_profile_intro_summary.most_reachable_via',
                  defaultMessage: 'Most reachable via',
                }),
                name: 'most_reachable_via',
                type: INPUT_TYPES.CONTACT_METHOD_EDITOR,
              },
            ]}
            successCallback={successCallback}
          />
        )}
      </CardHeader>
      <CardBody>
        <Row>
          {props.showPersonHeader && person && (
            <div className="d-flex gap-3 mb-4">
              <Avatar person={person} size="sm" isExternalUrl={true} />
              <div>
                <p className="fw-bold mb-0">{person.full_name}</p>
                <p className="text-secondary mb-0">{person.title}</p>
              </div>
            </div>
          )}
          {person?.profile?.intro && (
            <Col sm={12} className="mb-4">
              <RichTextViewer model={person.profile.intro} expanded={true} />
            </Col>
          )}
          {props.renderParts
            ? props.renderParts(components)
            : defaultRenderParts(components)}

          {showOperatingManualLink && (
            <Col>
              <Link
                to={person.url + '/manual'}
                target={props.isExternalUrl ? '_blank' : undefined}
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="app.views.person.person_profile_intro_summary.view_operating_manual.link.text"
                  defaultMessage="View Operating Manual"
                />
                {' ›'}
              </Link>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson } = state;

  return { currentOrganization, currentProxyPerson };
};

export default connect(mapStateToProps)(React.memo(PersonProfileIntroSummary));
