import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ENGAGEMENT_SURVEY_OTHERS_EXPLANATION } from '../../../../consts/consts';
import HeatmapRow from './HeatmapRow';
import ObjectsDropdown from '../../Dropdowns/ObjectsDropdown';
import PropTypes from 'prop-types';
import RichTextViewer from '../../Inputs/RichTextViewer';
import StaticHeatmapRow from './StaticHeatmapRow';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { truncateString } from '../../../EngagementSurvey/utils';

const Heatmap: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [segment, setSegment] = useState(null);

  const [horizontalScrollPossible, setHorizontalScrollPossible] =
    useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    updateHorizontalScroll();
  }, [scrollRef, segment]);

  const updateHorizontalScroll = () => {
    const BUFFER = 15;

    // @ts-expect-error
    const scrollWidth = scrollRef.current?.scrollWidth;
    // @ts-expect-error
    const clientWidth = scrollRef.current?.clientWidth;
    // @ts-expect-error
    const scrollLeft = Math.ceil(scrollRef.current?.scrollLeft);

    setHorizontalScrollPossible(
      BUFFER + scrollLeft + clientWidth < scrollWidth
    );
  };

  const segments = useMemo(() => {
    // Need to cycle through every question to get all
    // possible segments since some questions may get no
    // responses (so no segments would appear)
    let segmentList = [];

    props.data?.forEach((q) => {
      // @ts-expect-error
      const qSegments = Object.keys(q?.responses);
      // @ts-expect-error
      segmentList = [...segmentList, ...qSegments];
    });

    // Dedupe the segment list
    // @ts-expect-error
    segmentList = [...new Set(segmentList)];
    segmentList.sort();

    // Formatting so it plays nice with the dropdown
    return segmentList?.map((s) => ({ id: s, name: s }));
  }, [props.data]);

  useEffect(() => {
    if (segment === null) {
      // @ts-expect-error
      setSegment(segments[0]);
    }
  }, [segment, segments]);

  const segmentValues = useMemo(() => {
    // Need to cycle through every question to get all
    // possible segment values since some questions may get no
    // responses (so no segments would appear). If no questions
    // contain a segment, we omit it
    let segmentValueList = [];

    if (segment) {
      props.data?.forEach((q) => {
        // @ts-expect-error
        const qSegmentValues = Object.keys(q?.responses?.[segment.name]);
        // @ts-expect-error
        segmentValueList = [...segmentValueList, ...qSegmentValues];
      });

      // Dedupe the segment list
      // @ts-expect-error
      segmentValueList = [...new Set(segmentValueList)];
      segmentValueList.sort();
    }

    return segmentValueList;
  }, [segment, props.data]);

  const frozenColumns = useMemo(() => {
    const columnHeaders = [
      <>
        <span className="text-muted pe-2">
          <FormattedMessage
            id="app.views.widgets.charts.heatmap.heatmap.group_by"
            defaultMessage="Group by"
          />
        </span>
        <span>
          <ObjectsDropdown
            objects={segments}
            // @ts-expect-error
            value={segment?.id}
            onChange={(e) => {
              const match = segments.find((s) => s.id === e.target.value);
              // @ts-expect-error
              setSegment(match);
            }}
            size={'sm'}
            className="d-inline"
            renderItem={props.renderItem}
          />
        </span>
      </>,
      'All',
    ];

    return (
      <Row
        className="gx-0 mt-5 mb-4"
        style={{ display: 'block', whiteSpace: 'nowrap' }}
      >
        {columnHeaders?.map((c, i) => (
          <Col
            key={i}
            className={
              (i ? 'rotate-45 col-2 py-3 ms-4 ' : 'col-10 ') +
              // @ts-expect-error
              (c.className ? c.className : '')
            }
            style={{ display: 'inline-block', float: 'none' }}
          >
            {c}
          </Col>
        ))}
      </Row>
    );
  }, [segment, segments, props.renderItem]);

  const unfrozenColumns = useMemo(() => {
    return (
      <Row
        className="gx-0 mt-5 mb-4"
        style={{ display: 'block', whiteSpace: 'nowrap' }}
      >
        {segmentValues?.map((c, i) => {
          const HEADER_LENGTH = 15;

          // @ts-expect-error
          let rawValue = c?.name || c;

          // Handle "_others" separately, since it is a collection
          // of small employee segments
          const isOthers = rawValue === '_others';
          if (isOthers) {
            rawValue = 'others';
          }

          const showPopover = rawValue?.length > HEADER_LENGTH;
          const formattedValue = truncateString(rawValue, HEADER_LENGTH);
          const id = 'header-' + i;

          return (
            <Col
              key={i}
              id={id}
              className={
                'rotate-45 py-3 col-2 ' +
                // 'ms-4' ensures rotated title aligns in middle
                // of cell
                (i ? '' : ' ms-4') +
                // @ts-expect-error
                (c.className ? c.className : '')
              }
              style={{ display: 'inline-block', float: 'none' }}
            >
              <span>{formattedValue}</span>
              {showPopover && !isOthers && (
                <UncontrolledPopover
                  placement="top"
                  trigger="hover click"
                  target={id}
                >
                  {rawValue}
                </UncontrolledPopover>
              )}
              {isOthers && (
                <UncontrolledPopover
                  placement="top"
                  trigger="hover click"
                  target={id}
                >
                  {ENGAGEMENT_SURVEY_OTHERS_EXPLANATION(formatMessage)}
                </UncontrolledPopover>
              )}
            </Col>
          );
        })}
      </Row>
    );
  }, [segmentValues, formatMessage]);

  const createRows = useCallback(
    (columns, data, firstColumnIsTitle = false) => {
      const groupedData = {};
      columns.forEach((c) => {
        let cTotal = 0;
        data.forEach((d) => {
          cTotal += d?.[c]?.responses?.count || 0;
        });

        groupedData[c] = cTotal;
      });

      const headers = [];
      if (props.isMultipleChoice) {
        // @ts-expect-error
        headers.push({ name: 'Respondents' });
        // @ts-expect-error
        headers.push({ name: 'Total' });
      }

      const headerRows = headers?.map((r, ri) => {
        const data = {
          configs: r,
          responses: groupedData,
        };

        return (
          <StaticHeatmapRow
            key={'static-' + ri}
            index={ri}
            columns={columns}
            data={data}
            firstColumnIsTitle={firstColumnIsTitle}
            formatValue={props.formatValue}
            total={!!ri}
          />
        );
      });

      const bodyRows = data?.map((r, ri) => {
        return (
          <HeatmapRow
            key={ri}
            // @ts-expect-error
            index={ri}
            columns={columns}
            data={r}
            firstColumnIsTitle={firstColumnIsTitle}
            colorScale={props.colorScale}
            formatValue={props.formatValue}
            formatPopover={props.formatPopover}
            popoverChart={props.popoverChart}
            isMultipleChoice={props.isMultipleChoice}
          />
        );
      });

      return [...headerRows, ...bodyRows];
    },
    [
      props.colorScale,
      props.formatValue,
      props.isMultipleChoice,
      props.formatPopover,
      props.popoverChart,
    ]
  );

  const frozenRows = useMemo(() => {
    // These two columns are hardcoded for all heatmaps
    // since they are frozen for side scroll
    const headers = ['name', 'all'];
    const data = props.frozenRowData?.map((d) => ({
      ...d,
      // @ts-expect-error
      _segment: segment?.name,
    }));

    return createRows(headers, data, true);
  }, [segment, props.frozenRowData, createRows]);

  const createUnfrozenRowData = useMemo(
    () => props.unFrozenRowData,
    [props.unFrozenRowData]
  );

  const unfrozenRows = useMemo(() => {
    // @ts-expect-error
    const data = createUnfrozenRowData(segment?.name);
    return createRows(segmentValues, data);
  }, [segment, segmentValues, createUnfrozenRowData, createRows]);

  return (
    <div className={'m-4 fs-5'}>
      {props.showTitle && (
        <Row>
          <Col className="fw-bold text-dark col-12 fs-4">
            <RichTextViewer
              // @ts-expect-error
              model={props.data[0]?.displayName}
              expanded={false}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col className="col-6 pe-0">
          <div className={'heatmap-header'}>{frozenColumns}</div>
          {frozenRows}
        </Col>
        <Col className="col-6 ps-0">
          <div
            ref={scrollRef}
            style={{ overflowX: 'auto' }}
            onScroll={updateHorizontalScroll}
          >
            <Row>
              <Col>
                <div className={'heatmap-header'}>{unfrozenColumns}</div>
                {unfrozenRows}
              </Col>
              {horizontalScrollPossible && (
                <Col
                  className={'col-1 h-100'}
                  style={{
                    width: '5%',
                    backgroundImage:
                      'linear-gradient(to right, transparent , white)',
                    right: '0%',
                    position: 'absolute',
                  }}
                >
                  <span></span>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const Heatmap_propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  showTitle: PropTypes.bool,
  colorScale: PropTypes.func,
  formatValue: PropTypes.func,
  frozenRowData: PropTypes.arrayOf(PropTypes.object),
  unFrozenRowData: PropTypes.func,
  formatPopover: PropTypes.func,
  popoverChart: PropTypes.func,
  isMultipleChoice: PropTypes.bool,
  renderItem: PropTypes.func,
};

type Props = PropTypes.InferProps<typeof Heatmap_propTypes>;

export default React.memo(Heatmap);
