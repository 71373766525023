import * as consts from '../../consts/consts';

import { ARTICLE_IDS, ArticleLink } from 'components/intercom';
import {
  CAMPAIGN_STATUSES,
  getCampaignCoverageDurationMonthString,
  getManagerPerson,
  getPhaseByType,
  transformToSuveyTarget,
} from './Campaign';
import {
  Campaign,
  Phase,
  CampaignQuestion,
  ReduxState,
  Organization,
  Me,
} from '../../types';
import { FormattedMessage, useIntl, type IntlShape } from 'react-intl';
import {
  INPUT_ATTRIBUTES,
  INPUT_TYPES,
} from '../../views/Widgets/Inputs/ValidatedInputTypes';
import { PopoverBody } from 'reactstrap';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { basicDropdownStringSearch, getPrettyDate, log } from '../util/util';
import { difference, intersection, uniq } from 'lodash';
import {
  isCurrentEmployee,
  peopleIdsAreEqual,
  peopleObjectsAreEqual,
} from './Person';
import {
  loadCampaignsAndSurveyResponses,
  setCurrentDemoOrPreviewPerfCampaign,
  setCurrentPerfCampaign,
} from '../../actions';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from '../../views/Widgets/People/Avatar';
import ConfirmAPI from '../api/ConfirmAPI';
import { PERFORMANCE_STEP_CHOOSE_MUST_HAVE_PEERS } from '../../consts/consts';
import PersonCardList from '../../views/Widgets/People/PersonCardList';
import { RELATIONSHIP_TYPES } from './RelationshipUtils';
import RichTextViewer from '../../views/Widgets/Inputs/RichTextViewer';
import TagsList from '../../views/Widgets/TagsList';
import { applyCustomFiltersToQuestions } from '../../views/Widgets/People/Filters/common';
import axios from 'axios';
import { parseDateFromUnicode } from '../util/time';
import { relationshipIsCompleted } from './Relationship';
import { useAuth0 } from '@auth0/auth0-react';
import { toPersonIdToSurveyResponseLookup } from './SurveyResponse';
import { YYYY_MM_DD_WITH_DASHES_FORMAT } from 'views/Widgets/Inputs/DatePicker';
import { useLocation } from 'react-router-dom';

// these need to match up with declaration in models.Campaign
export const INTRODUCTION_UI_PHASE_KEY = 'introduction_ui_phase';
// Possible performance phases
export const PHASE_TYPE_INTRODUCTION_UI_ONLY = 'INTRO';
export const PHASE_TYPE_SELF = 'S';
export const PHASE_TYPE_OTHERS = 'O';
export const PHASE_TYPE_EVALUATION = 'E';
export const PHASE_TYPE_CALIBRATION = 'C';
export const PHASE_TYPE_REPORTING = 'R';
export const PHASE_NAMES = {
  [PHASE_TYPE_SELF]: 'self',
  [PHASE_TYPE_OTHERS]: 'others',
  [PHASE_TYPE_EVALUATION]: 'evaluation',
  [PHASE_TYPE_CALIBRATION]: 'calibration',
  [PHASE_TYPE_REPORTING]: 'reporting',
};
export const PHASE_TYPES_IN_ORDER = [
  PHASE_TYPE_SELF,
  PHASE_TYPE_OTHERS,
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_CALIBRATION,
  PHASE_TYPE_REPORTING,
];

// list of performance features enabled in a given phase
// NOTE: these should match the phase objects declared in a given phase object
// in the backend campaign object's phase array
// And any changes here should also be changed in models.py's Campaign object
// in the backend to match
// **IMPORTANT** FOR ANY NEW FEATURES HERE, BE SURE TO ADD THEM TO THE CAMPAIGN
// EDITOR SO ADMINS AT ORGANIZATIONS CAN CONFIGURE THEM BY THEMSELVES
export const PERFORMANCE_FEATURE_WELCOME_HEADER = 'welcome_header';
export const PERFORMANCE_FEATURE_WELCOME_MEDIA = 'welcome_media';
export const PERFORMANCE_FEATURE_WELCOME_MEDIA_CAPTION =
  'welcome_media_caption';
export const PERFORMANCE_FEATURE_WELCOME_MESSAGE_HTML = 'welcome_message_html';
export const PERFORMANCE_FEATURE_EVALUATION_PHASE_OPEN_RESPONSES_HELPER_TEXT =
  'open_responses_helper_text';
export const PERFORMANCE_FEATURE_NETWORK_STAKEHOLDERS = 'network_stakeholders';
export const PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_CALLOUTS =
  'previous_direct_report_callouts';
export const PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_COMMENTS_REQUIRED =
  'previous_direct_report_comments_required';
export const PERFORMANCE_FEATURE_GOLD_STAR_COMMENTS_REQUIRED =
  'gold_star_comments_required';
export const PERFORMANCE_FEATURE_HEADS_UP_COMMENTS_REQUIRED =
  'heads_up_comments_required';
export const PERFORMANCE_FEATURE_SELF_REFLECTION_INTRO_MESSAGE_HTML =
  'self_reflection_intro_message_html';
export const DEFAULT_SELF_REFLECTION_INTRO_MESSAGE_HTML = (formatMessage) =>
  formatMessage({
    id: 'app.utils.models.performance.self_reflection_intro_message_html.default',
    defaultMessage:
      '<p>The last few steps were about the people around you. ' +
      'Next, we&apos;re going to switch gears and talk about you.</p>' +
      '<p>On the next page, you&apos;ll be asked questions about yourself. ' +
      'Your manager will be able to see your responses.</p>',
  });
export const PERFORMANCE_FEATURE_COMPLETE_RESUME_STEP = 'complete_resume_step';
export const PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_STEP =
  'complete_objectives_step'; // Deprecated
export const PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP =
  'complete_objectives_for_quarter_step';
export const PERFORMANCE_FEATURE_OBJECTIVES_HEADER = 'objectives_header';
export const DEFAULT_OBJECTIVES_HEADER = 'Define your objectives.';
export const DEFAULT_OBJECTIVES_MESSAGE_HTML =
  'Consider the work you plan to do for the next quarter. Set your objectives and key results you would like to obtain accordingly.';
export const PERFORMANCE_FEATURE_OBJECTIVES_MESSAGE_HTML =
  'objectives_message_html';
export const PERFORMANCE_FEATURE_CONTRIBUTION_SELF_REFLECTIONS =
  'contribution_self_reflections';
export const PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS =
  'self_open_response_questions';
export const PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS =
  'others_open_response_questions';
export const PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS =
  'evaluation_open_response_questions';
export const PERFORMANCE_FEATURE_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS =
  'assess_organization_open_response_questions';
export const PERFORMANCE_FEATURE_ENGAGEMENT_SURVEY_FILTERS =
  'engagement_survey_filters';
export const PERFORMANCE_FEATURE_ENGAGEMENT_SURVEY_SUMMARIES =
  'engagement_survey_summaries';
export const PERFORMANCE_FEATURE_HIGH_PRIORITY_PEERS = 'high_priority_peers';
export const PERFORMANCE_FEATURE_ASSESS_MANAGER = 'assess_manager';
export const PERFORMANCE_FEATURE_PEER_FEEDBACK = 'peer_feedback';
export const PERFORMANCE_FEATURE_PEER_FEEDBACK_ASSIGNMENT_TIME =
  'peer_feedback_assignment_time';
export const PERFORMANCE_FEATURE_PEER_FEEDBACK_ASSIGNMENT_DONE =
  'peer_feedback_assignment_done';
export const PERFORMANCE_FEATURE_ADD_OPTIONAL_PEER_FEEDBACK = 'optional_peers';
export const PERFORMANCE_FEATURE_ASSESS_ORGANIZATION = 'assess_organization';
export const PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_NAME =
  'custom_question_employee_nps_rating';
export const PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED =
  'employee_nps_question_enabled';
export const PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_OPTIONAL =
  'employee_nps_question_optional';
export const PERFORMANCE_FEATURE_HIDE_PEER_FEEDBACK_FROM_SUBJECT =
  'hide_peer_feedback_from_subject';
export const PERFORMANCE_FEATURE_HIDE_ASSESS_MANAGER_FEEDBACK_FROM_SUBJECT =
  'hide_assess_manager_feedback_from_subject';
export const PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER =
  'promotion_nomination_by_manager';
export const PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_LABEL =
  'promotion_nomination_by_manager_label';
export const PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_HELPER_TEXT =
  'promotion_nomination_by_manager_helper_text';
export const PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_ALLOW_COMMENTS =
  'promotion_nomination_by_manager_allow_comments';
export const PERFORMANCE_FEATURE_PROMOTION_PACKETS_REQUIRED =
  'promotion_packets_required';
export const PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER =
  'SALARY_INCREASE_NOMINATION_by_manager';
export const PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_LABEL =
  'SALARY_INCREASE_NOMINATION_by_manager_label';
export const PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_HELPER_TEXT =
  'SALARY_INCREASE_NOMINATION_by_manager_helper_text';
export const PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_ALLOW_COMMENTS =
  'SALARY_INCREASE_NOMINATION_by_manager_allow_comments';
export const PERFORMANCE_FEATURE_MANAGER_CONTRIBUTION_FEEDBACK =
  'manager_contribution_feedback';
export const PERFORMANCE_FEATURE_PEER_CONTRIBUTION_FEEDBACK =
  'peer_contribution_feedback';
export const PERFORMANCE_FEATURE_HIDE_INFLUENCE_FROM_MANAGERS =
  'hide_influence_from_managers';
export const PERFORMANCE_FEATURE_SHOW_RECOGNITION_TO_MANAGERS =
  'show_recognition_to_managers';
export const PERFORMANCE_FEATURE_SHOW_OBJECTIVES_TO_MANAGERS =
  'show_objectives_to_managers';
export const PERFORMANCE_FEATURE_HIDE_TIMELINE_ACTIVITIES =
  'hide_timeline_activities';
export const PERFORMANCE_FEATURE_SHOW_CRITICAL_FEEDBACK_TO_MANAGERS =
  'show_critical_feedback_to_managers';
export const PERFORMANCE_FEATURE_HIDE_EVALUATION_POSITIVE_TAG_RECOMMENDATIONS =
  'hide_evaluation_positive_tag_recommendations';
export const PERFORMANCE_FEATURE_HIDE_EVALUATION_NEGATIVE_TAG_RECOMMENDATIONS =
  'hide_evaluation_negative_tag_recommendations';
export const PERFORMANCE_FEATURE_SHOW_TALENT_MATRICES = 'show_talent_matrices';
export const PERFORMANCE_FEATURE_TALENT_MATRICES = 'talent_matrices';
export const PERFORMANCE_FEATURE_ALLOW_FEEDBACK_REQUESTS_WHEN_CLAIMING_CONTRIBUTIONS =
  'allow_feedback_requests_when_claiming_contributions';
export const PERFORMANCE_FEATURE_HIDE_TIME_AT_ORGANIZATION =
  'hide_time_at_organization';
export const PERFORMANCE_FEATURE_HIDE_TIME_IN_ROLE = 'hide_time_in_role';
export const PERFORMANCE_FEATURE_RATING_SCALE = 'rating_scale';
export const PERFORMANCE_FEATURE_HEADS_UP_ANONYMOUS_TOGGLE =
  'heads_ups_anonymous_toggle';
export const PERFORMANCE_FEATURE_DISPLAY_HEADS_UPS_TO_RECIPIENT =
  'display_heads_ups_to_recipient';
export const PERFORMANCE_FEATURE_GOLD_STARS_AND_INFLUENCE_VISIBLE_TO_RECIPIENT =
  'gold_stars_and_influence_visible_to_recipient';
// Used only in cases where "gold_stars_and_influence_visible_to_recipient" is enabled
// BUT we want to hide the influence section (so only show gold stars, ie. Carta H2 2022)
export const PERFORMANCE_FEATURE_HIDE_INFLUENCE_FROM_RECIPIENT =
  'hide_influence_from_recipient';
export const PERFORMANCE_FEATURE_SHOW_PERFORMANCE_PERIOD_WITH_SPECIFIC_DATES =
  'show_performance_period_with_specific_dates';
export const PERFORMANCE_FEATURE_MANAGER_UNEDITABLE = 'manager_uneditable';
export const PERFORMANCE_FEATURE_MINIMUM_MANDATORY_PEERS =
  'minimum_mandatory_peers';
export const PERFORMANCE_FEATURE_MAXIMUM_MANDATORY_PEERS =
  'maximum_mandatory_peers';
export const PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS =
  'upward_manager_open_response_questions';
export const PERFORMANCE_FEATURE_RESUME_OKR_REMINDER = 'resume_okr_reminder';
export const PERFORMANCE_FEATURE_HIDE_PULSE_ANONYMOUS_DESCRIPTION =
  'hide_pulse_survey_anonymous_description';
export const PERFORMANCE_FEATURE_HIDE_MANAGER_RATING = 'hide_manager_rating';
export const PERFORMANCE_FEATURE_MANAGER_RATING_IS_INTERIM_RATING =
  'manager_rating_is_interim_rating';
export const PERFORMANCE_FEATURE_HIDE_RATING_FROM_DIRECT_REPORT =
  'hide_rating_from_direct_report';
export const PERFORMANCE_CALLOUTS_DESCRIPTION = 'callouts_description';
export const DEFAULT_PERFORMANCE_CALLOUTS_DESCRIPTION = (formatMessage) =>
  formatMessage({
    id: 'app.utils.models.performance.callouts_description.default',
    defaultMessage: 'Tell us about your Callouts.',
  });
export const PERFORMANCE_NETWORK_DESCRIPTION = 'network_description';
export const DEFAULT_PERFORMANCE_NETWORK_DESCRIPTION = (formatMessage) =>
  formatMessage({
    id: 'app.utils.models.performance.network_description.default',
    defaultMessage: 'Optional: What makes your coworkers great?',
  });
export const DEFAULT_PERFORMANCE_PROMOTION_NOMINATION_BY_MANAGER_LABEL =
  undefined;
export const DEFAULT_PERFORMANCE_PROMOTION_NOMINATION_BY_MANAGER_HELPER_TEXT =
  undefined;
export const DEFAULT_PERFORMANCE_SALARY_INCREASE_NOMINATION_BY_MANAGER_LABEL =
  undefined;
export const DEFAULT_PERFORMANCE_SALARY_INCREASE_NOMINATION_BY_MANAGER_HELPER_TEXT =
  undefined;
export const PERFORMANCE_NETWORK_STEP_PEOPLE_MINIMUM = 'network_people_minimum';
export const DEFAULT_INFLUENCE_PEOPLE_MINIMUM = 3;
export const DEFAULT_MINIMUM_SURVEY_RESPONDENTS = 3;
export const PERFORMANCE_ACCOMPLISHMENTS_DEFAULT_VISIBILITY_PRIVATE =
  'accomplishments_default_visibility_private';
export const PERFORMANCE_ACCOMPLISHMENTS_MINIMUM = 'accomplishments_minimum';
export const PERFORMANCE_ACCOMPLISHMENTS_MAXIMUM = 'accomplishments_maximum';
export const PERFORMANCE_ACCOMPLISHMENTS_INSTRUCTIONS = 'resume_instructions';
export const PERFORMANCE_FEATURE_DISABLE_AUTO_CALIBRATION =
  'disable_auto_calibration';
export const PERFORMANCE_FEATURE_ALLOW_EXTERNAL_MANAGER_RATINGS =
  'allow_external_manager_ratings';
export const PERFORMANCE_FEATURE_DISABLE_CONVERSATION_ACKNOWLEDGEMENTS =
  'disable_conversation_acknowledgements';
export const PERFORMANCE_FEATURE_DISABLE_NETWORK = 'disable_network';
export const PERFORMANCE_FEATURE_DISABLE_CALLOUTS = 'disable_callouts';
export const PERFORMANCE_FEATURE_MAXIMUM_PEERS_TO_SHOW =
  'maximum_peers_to_show';
export const PERFORMANCE_FEATURE_MINIMUM_SURVEY_RESPONDENTS =
  'minimum_survey_respondents';

export const WIZARD_TYPE_PERFORMANCE = 'P';
export const WIZARD_TYPE_ENGAGEMENT_ONLY = 'E';
export const WIZARD_TYPE_ENPS_ONLY = 'N';
export const WIZARD_TYPE_ONA_ONLY = 'O';

export type WizardTypes =
  | typeof WIZARD_TYPE_PERFORMANCE
  | typeof WIZARD_TYPE_ENGAGEMENT_ONLY
  | typeof WIZARD_TYPE_ENPS_ONLY
  | typeof WIZARD_TYPE_ONA_ONLY;

export const getWizardType = (campaign: Campaign): WizardTypes => {
  const key = campaign?.[WIZARD_TYPE_KEY];
  if (
    key !== WIZARD_TYPE_PERFORMANCE &&
    key !== WIZARD_TYPE_ENGAGEMENT_ONLY &&
    key !== WIZARD_TYPE_ENPS_ONLY &&
    key !== WIZARD_TYPE_ONA_ONLY
  ) {
    console.error('Unrecognized campaign wizard type: ' + key);
    return WIZARD_TYPE_PERFORMANCE;
  }
  return key;
};

export const WIZARD_TYPES_PHASES = {
  [WIZARD_TYPE_ONA_ONLY]: [
    PHASE_TYPE_INTRODUCTION_UI_ONLY,
    PHASE_TYPE_SELF,
    PHASE_TYPE_REPORTING,
  ],
  [WIZARD_TYPE_ENGAGEMENT_ONLY]: [
    PHASE_TYPE_INTRODUCTION_UI_ONLY,
    PHASE_TYPE_OTHERS,
  ],
  [WIZARD_TYPE_ENPS_ONLY]: [PHASE_TYPE_INTRODUCTION_UI_ONLY, PHASE_TYPE_OTHERS],
  [WIZARD_TYPE_PERFORMANCE]: [
    PHASE_TYPE_INTRODUCTION_UI_ONLY,
    PHASE_TYPE_SELF,
    PHASE_TYPE_OTHERS,
    PHASE_TYPE_EVALUATION,
    PHASE_TYPE_CALIBRATION,
    PHASE_TYPE_REPORTING,
  ],
};

/* EXAMPLE PERFORMANCE CAMPAIGN PHASE JSON OBJECTS:

OPTIONAL PHASE "OVERRIDE" PARAMETERS FOR EACH PHASE:
- name: display name in left nav
- description: description to show in phases overview for the given phases

Deel Q4 2021 (simple, 1 phase without resume completion or self reflections)
[{
    "type": "S",
    "end_date": "10/21/2021",
    "start_date": "10/13/2021",
    "complete_objectives_step": true
}]

Nansen Q4 2021 (simple, 1 phase without resume completion or self reflections)
[{
    "type": "S",
    "end_date": "10/21/2021",
    "start_date": "10/13/2021",
}]

Confirm Q4 2021 (simple, 2 phase flow, no peer or upward feedback, but has calibration):
[
  {
    "type": "S",
    "end_date": "10/07/2021",
    "start_date": "09/29/2021",
    "welcome_header": "Welcome to Confirm's Performance Cycle for Q2-Q3 2021!",
    "complete_resume_step": true,
    "welcome_message_html": "<p>At Confirm, we care about both the impact of your work and how you get that work done. You’ll be asked to reflect on these two topics for yourself and, if you are a people manager, your direct reports.</p><p>Make sure to provide clear, actionable feedback that focuses on behaviors, actions, and impact, not personality or assumed traits. <a href=\"https://docs.google.com/document/d/1OsEvUUejZcbXL0MCD32ceYsKjC_D2RE9ln84KoaHOPA/edit\" rel=\"noopener noreferrer\" target=\"_blank\">Learn more</a></p>",
    "self_open_response_questions": [
      {
        "type": "section",
        "label": "Your overall impact"
      },
      {
        "name": "accomplishment_impact",
        "type": "textarea",
        "label": "Briefly summarize your overall impact on your team's goals or north star over the past 6 months.",
        "required": true
      },
      {
        "type": "section",
        "label": "How you work"
      },
      {
        "name": "positive_comments",
        "label": "Over the past 6 months, how did your actions and behaviors align with <a href=\"https://app.confirm.com/leveling-framework/all\" target=\"_blank\" rel=\"noopener noreferrer\">Confirm's expectations of your level</a>?",
        "required": true,
        "helperText": "Keep in mind your team's metrics, deliverables, objectives, etc."
      },
      {
        "name": "self_inclusive_journey",
        "type": "textarea",
        "label": "Over the past 6 months, describe your journey of becoming a more inclusive Confirmn. Include specific actions you've taken.",
        "required": true,
        "helperText": "Include team conversations, events, workshops, trainings, books, and more.",
        "helperTextLearnMorePopover": "<span>Review our inclusive leadership behaviors when considering your journey:</span><ul class=\"mt-2 mb-0\"><li>Access: We invite others to have a seat at the table. We don&apos;t block or deny access to meetings, initiatives, or senior leaders. We provide fair and equitable access for opportunities and promotions for all Confirmns.</li><li>Amplify: We amplify voices. We don&apos;t mute, interrupt or silence others. We don&apos;t yell or scream or belittle. We show up with kindness and create psychologically safe places for all individuals. We amplify their work; we don&apos;t steal or take credit. We allow them to share and own their impact.</li><li>Advocate: We champion and celebrate each other. We coach each other to be relentless and unconventional. As advocates, we are helpful, providing thoughtful and continuous feedback to help reach our full potential.</li></ul>"
      },
      {
        "type": "section",
        "label": "Looking ahead..."
      },
      {
        "name": "negative_skills",
        "type": "skills",
        "label": "Which skills, behaviors, or <a href=\"https://app.getguru.com/card/T5Ra6pqc/Confirms-OPITs\" target=\"_blank\" rel=\"noopener noreferrer\">OPITs</a> would you like to develop in the next 6 months?",
        "required": true
      },
      {
        "name": "self_manager_support",
        "type": "textarea",
        "label": "What support do you need from your manager to develop in these areas?",
        "required": true
      }
    ],
    "previous_direct_report_callouts": true
  },
  {
    "type": "E",
    "end_date": "10/25/2021",
    "start_date": "10/08/2021",
    "hide_time_in_role": true,
    "open_responses_helper_text": "Aim to write one short paragraph for each question. Remember to use <a href=\"https://docs.google.com/document/d/1OsEvUUejZcbXL0MCD32ceYsKjC_D2RE9ln84KoaHOPA/edit?usp=sharing\" target=\"_blank\" rel=\"noopener noreferrer\">the SBI model</a> and include clear, actionable examples.",
    "promotion_nomination_by_manager": true,
    "evaluation_open_response_questions": [
      {
        "name": "positive_skills",
        "type": "skills",
        "label": "<span>Which skills, behaviors, or <a href=\"https://app.getguru.com/card/T5Ra6pqc/Confirms-OPITs\" target=\"_blank\" rel=\"noopener noreferrer\">OPITs</a> is {{name}} demonstrating consistently?</span>",
        "required": true
      },
      {
        "name": "negative_skills",
        "type": "skills",
        "label": "<span>Which skills, behaviors, or <a href=\"https://app.getguru.com/card/T5Ra6pqc/Confirms-OPITs\" target=\"_blank\" rel=\"noopener noreferrer\">OPITs</a> could {{name}} develop further?</span>",
        "required": true
      },
      {
        "name": "positive_comments",
        "type": "textarea",
        "label": "What does {{name}} need to take away from this performance cycle?",
        "required": true,
        "helperText": "Consider what you would add or clarify in {{name}}'s description of activities/impact during these past 6 months."
      }
    ],
    "rating_scale": [
      {
        "value": 4,
        "name": "Exceptional",
        "description": "Always delivers work results above and beyond expectations; solves problems in a proactive and impactful way. Exemplifies and inspires others to live Confirm's Identity Traits and Operating Principles."
      },
      {
        "value": 3,
        "name": "Fully performing",
        "description": "Consistently meets expectations and sometimes delivers above and beyond; solves identified problems with minimal guidance. Regularly demonstrates Confirm's Identity Traits and Operating Principles."
      },
      {
        "value": 2,
        "name": "Growing",
        "description": "Partially meets expectations; shows promise with noted areas for development. At times demonstrates Confirm's Identity Traits and Operating Principles, but will benefit from further development."
      },
      {
        "value": 1,
        "name": "Needs improvement",
        "description": "Consistently performing below expectations; person must demonstrate improved performance within immediate period of time. Lacks consistency in demonstrating Confirm's Identity Traits and Operating Principles."
      }
    ]
  },
  {
    "type": "C",
    "end_date": "11/10/2021",
    "start_date": "11/01/2021"
  }
]

Confirm Q2 2021 (complex, 3 phase flow, full 360 with deep project feedback + calibration):
[
  {
    "type": "S",
    "end_date": "04/07/2021",
    "start_date": "03/22/2021",
    "hide_time_in_role": true,
    "high_priority_peers": true,
    "complete_resume_step": true,
    "network_stakeholders": true,
    "detailed_self_resume_mode": true,
    "self_open_response_questions": [
      {
        "name": "self_manager_support",
        "type": "textarea",
        "label": "How can your manager best support you to make changes or improvements?",
        "required": true,
        "helperText": "<span>Mention Confirm&apos;s <a href=\"https://app.getguru.com/card/T5Ra6pqc/Confirms-OPITs\" target=\"_blank\" rel=\"noopener noreferrer\">OPITs</a> if you can.</span>"
      },
      {
        "name": "self_inclusive_journey",
        "type": "textarea",
        "label": "Over the past 6 months, describe your journey of becoming a more inclusive Confirmn. Include specific actions you've taken.",
        "required": true,
        "helperText": "Include team conversations, events, workshops, trainings, books, and more.",
        "helperTextLearnMorePopover": "<span>Review our inclusive leadership behaviors when considering your journey:</span><ul class=\"mt-2 mb-0\"><li>Access: We invite others to have a seat at the table. We don&apos;t block or deny access to meetings, initiatives, or senior leaders. We provide fair and equitable access for opportunities and promotions for all Confirmns.</li><li>Amplify: We amplify voices. We don&apos;t mute, interrupt or silence others. We don&apos;t yell or scream or belittle. We show up with kindness and create psychologically safe places for all individuals. We amplify their work; we don&apos;t steal or take credit. We allow them to share and own their impact.</li><li>Advocate: We champion and celebrate each other. We coach each other to be relentless and unconventional. As advocates, we are helpful, providing thoughtful and continuous feedback to help reach our full potential.</li></ul>"
      },
      {
        "name": "self_updates_to_manager",
        "type": "textarea",
        "label": "Optional: what would you like to tell your manager that they may not already know?"
      }
    ],
    "contribution_self_reflections": true
  },
  {
    "type": "O",
    "end_date": "04/19/2021",
    "start_date": "04/8/2021",
    "peer_feedback": true,
    "assess_manager": true,
    "optional_peers": true,
    "assess_organization": true,
    "hide_influence_from_managers": true,
    "peer_contribution_feedback": true,
    "manager_contribution_feedback": true
  },
  {
    "type": "E",
    "end_date": "04/30/2021",
    "start_date": "04/20/2021",
    "hide_time_in_role": true,
    "promotion_nomination_by_manager": true,
    "promotion_nomination_by_manager_allow_comments": true,
    "rating_scale": [
      {
        "value": 4,
        "name": "Exceptional",
        "description": "Always delivers work results above and beyond expectations; solves problems in a proactive and impactful way. Exemplifies and inspires others to live Confirm's Identity Traits and Operating Principles."
      },
      {
        "value": 3,
        "name": "Fully performing",
        "description": "Consistently meets expectations and sometimes delivers above and beyond; solves identified problems with minimal guidance. Regularly demonstrates Confirm's Identity Traits and Operating Principles."
      },
      {
        "value": 2,
        "name": "Growing",
        "description": "Partially meets expectations; shows promise with noted areas for development. At times demonstrates Confirm's Identity Traits and Operating Principles, but will benefit from further development."
      },
      {
        "value": 1,
        "name": "Needs improvement",
        "description": "Consistently performing below expectations; person must demonstrate improved performance within immediate period of time. Lacks consistency in demonstrating Confirm's Identity Traits and Operating Principles."
      },
    ]
  },
  {
    "type": "C",
    "end_date": "05/18/2021",
    "start_date": "05/04/2021"
  }
]

*/

const questionIsFocusable = (question: CampaignQuestion): boolean => {
  // if a question exists but has no type, we treat is as a text input
  // so we consider this focusable
  if (!question.type) {
    return true;
  }

  if (
    typeof question.type === 'string' &&
    question.type.toUpperCase() !== INPUT_TYPES.SECTION
  ) {
    return true;
  }

  return false;
};

export function getAutoFocusIndex(
  questions: Array<CampaignQuestion> | null | undefined
): number {
  if (!questions?.length) {
    return -1;
  }

  return questions.findIndex(questionIsFocusable);
}

export const prepareOpenResponseQuestion = (
  formatMessage,
  q,
  negativeTagRecommendations,
  negativeTagsAndCommentsAreRequired,
  name,
  positiveTagRecommendations,
  isPeer,
  campaign,
  organization,
  autoFocus,
  index,
  isDemoMode
) => {
  // capitalize type for processing to stay consistent with validatedInput types
  // as we used the lowercase forms of what is in ValidatedInput.js
  if (q.type) {
    q.type = q.type.toUpperCase();
  }

  const otherProps = {
    isDemoMode,
  };

  const label =
    q.type === INPUT_TYPES.SECTION && q.label
      ? '<h2 class="header-title">' + q.label + '</h2>'
      : q.label;

  const textReplacers = {
    autoFocus: autoFocus,
    label: q.label && (
      <RichTextViewer
        expanded={true}
        model={replaceCampaignQuestionText(
          label,
          name,
          campaign,
          organization,
          formatMessage
        )}
      />
    ),
    helperText: q.helperText && (
      <RichTextViewer
        expanded={true}
        model={replaceCampaignQuestionText(
          q.helperText,
          name,
          campaign,
          organization,
          formatMessage
        )}
        afterContent={
          q.helperTextLearnMorePopover && (
            <>
              {' '}
              <span
                className="text-primary"
                id={q.name + '-learn-more-popover'}
              >
                <FormattedMessage
                  id="app.utils.models.performance.open_response_question.learn_more.text"
                  defaultMessage="
                Learn more
              "
                />
              </span>
              <UncontrolledPopover
                delay={50}
                trigger="hover"
                placement="top"
                target={q.name + '-learn-more-popover'}
              >
                <PopoverBody>
                  <span className="text-dark">
                    <RichTextViewer
                      expanded={true}
                      model={q.helperTextLearnMorePopover}
                    />
                  </span>
                </PopoverBody>
              </UncontrolledPopover>
            </>
          )
        }
      />
    ),
  };

  // if it's a section, just display it as such
  if (q.type === INPUT_TYPES.SECTION) {
    return {
      ...textReplacers,
      ...otherProps,
      name: 'undefined-' + index,
      className: 'd-none',
    };
  }

  if (q.type === INPUT_TYPES.SELECT) {
    return {
      ...q,
      ...otherProps,
      type: INPUT_TYPES.SELECT,
      loadOptions: basicDropdownStringSearch,
      ...textReplacers,
    };
  }

  if (q.type === INPUT_TYPES.LIKERT) {
    return {
      ...q,
      ...otherProps,
      objects: q.objects.map((o) => ({
        ...o,
        value: replaceCampaignQuestionText(
          o.value,
          name,
          campaign,
          organization,
          formatMessage
        ),
      })),
      ...textReplacers,
    };
  }

  if (q.name === 'positive_skills') {
    return {
      recommendations:
        positiveTagRecommendations?.length > 0
          ? positiveTagRecommendations
          : [],
      ...INPUT_ATTRIBUTES(formatMessage).SKILLS,
      // NOTE: this question can be overridden into,
      // for example, a dropdown,
      // so q needs to come after INPUT_ATTRIBUTES.SKILLS
      ...q,
      ...otherProps,
      // ensure that a "skills" type in the campaign still renders as a tags input
      type:
        !q.type || q.type === INPUT_TYPES.SKILLS
          ? INPUT_TYPES.TAGS_INPUT
          : q.type,
      ...textReplacers,
    };
  }

  if (q.name === 'positive_comments') {
    return {
      required: !isPeer,
      maxLength: 1000,
      minRows: 3,
      maxRows: 15,
      type: INPUT_TYPES.TEXTAREA,
      ...q,
      ...otherProps,
      ...textReplacers,
    };
  }

  if (q.name === 'negative_skills') {
    return {
      required: negativeTagsAndCommentsAreRequired,
      recommendations:
        negativeTagRecommendations?.length > 0
          ? negativeTagRecommendations
          : [],
      ...INPUT_ATTRIBUTES(formatMessage).SKILLS,
      // NOTE: this question can be overridden into,
      // for example, a dropdown,
      // so q needs to come after INPUT_ATTRIBUTES.SKILLS
      ...q,
      ...otherProps,
      // ensure that a "skills" type in the campaign still renders as a tags input
      type:
        !q.type || q.type === INPUT_TYPES.SKILLS
          ? INPUT_TYPES.TAGS_INPUT
          : q.type,
      ...textReplacers,
    };
  }

  if (q.name === 'negative_comments') {
    return {
      required: !isPeer && negativeTagsAndCommentsAreRequired,
      maxLength: 1000,
      minRows: 3,
      maxRows: 15,
      type: INPUT_TYPES.TEXTAREA,
      ...q,
      ...otherProps,
      ...textReplacers,
    };
  }

  if (q.type === INPUT_TYPES.SKILLS) {
    return {
      ...INPUT_ATTRIBUTES(formatMessage).SKILLS,
      ...q,
      ...otherProps,
      // the actual type is tags input
      type: INPUT_TYPES.TAGS_INPUT,
      ...textReplacers,
    };
  } else if (q.type === INPUT_TYPES.DATE_PICKER) {
    return {
      // we want to save YYYY-MM-DD without time/timezone for custom questions to ensure that
      // it's stored to the DB without a timezone so that when rendering responses we don't
      // show different dates to the user in different timezones since this datepicker for UGC
      // is for dates only with no times
      format: YYYY_MM_DD_WITH_DASHES_FORMAT,
      ...q,
      ...otherProps,
      ...textReplacers,
    };
  } else if (q.type === INPUT_TYPES.TEXTAREA || !q.type) {
    return {
      minRows: 2,
      type: INPUT_TYPES.TEXTAREA,
      ...q,
      ...otherProps,
      ...textReplacers,
    };
  } else {
    return {
      // include all fields provided in question so we can use the full
      // capabilities of ValidatedInput.js, e.g. type "multiple_choice"
      ...q,
      ...otherProps,
      // ensure text replaces show up
      ...textReplacers,
    };
  }
};

export const getPhaseIsVisibleInReviewFlow = (phase) => {
  return (
    phase.type === PHASE_TYPE_SELF ||
    phase.type === PHASE_TYPE_OTHERS ||
    phase.type === PHASE_TYPE_EVALUATION
  );
};

export const getPhaseIsManagersOnly = (phase) => {
  return phase.type === PHASE_TYPE_EVALUATION;
};

export const getPhasePrefix = (index, formatMessage) => {
  if (index === 0)
    return formatMessage({
      id: 'app.utils.models.performance.phase_i',
      defaultMessage: 'Phase I',
    });
  if (index === 1)
    return formatMessage({
      id: 'app.utils.models.performance.phase_ii',
      defaultMessage: 'Phase II',
    });
  if (index === 2)
    return formatMessage({
      id: 'app.utils.models.performance.phase_iii',
      defaultMessage: 'Phase III',
    });
  if (index === 3)
    return formatMessage({
      id: 'app.utils.models.performance.phase_iv',
      defaultMessage: 'Phase IV',
    });
  if (index === 4)
    return formatMessage({
      id: 'app.utils.models.performance.phase_v',
      defaultMessage: 'Phase V',
    });
};

export const getFilteredPhaseOpenResponseQuestions = ({
  targetPerson,
  campaign,
  phase,
  type,
}) => {
  const unfilteredQuestions = getPhaseOpenResponseQuestions(phase, type);

  const phasedQuestions = applyCustomFiltersToQuestions({
    questions: unfilteredQuestions,
    campaign,
    targetPerson,
  });

  if (
    unfilteredQuestions &&
    unfilteredQuestions.length > 0 &&
    (!phasedQuestions || phasedQuestions.length === 0)
  ) {
    console.error(
      'No phasedQuestions found for phase: ',
      JSON.stringify(phase)
    );
  }

  return phasedQuestions;
};

export const getPhase = (campaign, phase) => {
  return campaign.phases.find((p) => p.type === phase);
};

export const getHiddenPhase = (campaign, phase) => {
  return (campaign.configs ?? {})[`hidden_phase_${phase}`];
};

export const getPhaseOrHiddenPhase = (campaign, phase) => {
  return getPhase(campaign, phase) ?? getHiddenPhase(campaign, phase);
};

export const getPhaseOpenResponseQuestions = (phase, type) => {
  if (!phase) {
    console.error('getPhaseOpenResponseQuestions received null phase');
    return null;
  }

  if (type && type in phase) {
    return phase[type];
  }

  if (
    phase.type === PHASE_TYPE_SELF &&
    PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS in phase
  ) {
    return phase[PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS];
  }

  if (
    phase.type === PHASE_TYPE_OTHERS &&
    PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS in phase
  ) {
    return phase[PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS];
  }

  if (
    phase.type === PHASE_TYPE_EVALUATION &&
    PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS in phase
  ) {
    return phase[PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS];
  }

  return null;
};

const PHASE_OPEN_RESPONSE_QUESTION_KEYS = {
  [PHASE_TYPE_SELF]: PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS,
  [PHASE_TYPE_OTHERS]: PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS,
  [PHASE_TYPE_EVALUATION]:
    PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS,
};

export const setPhaseOpenResponseQuestions = (phase, type, questions) => {
  if (!phase) {
    console.error('setPhaseOpenResponseQuestions received a null phase');
  }

  const key = type || PHASE_OPEN_RESPONSE_QUESTION_KEYS[phase.type];

  if (key) {
    phase[key] = questions;
  } else {
    console.error(
      `setPhaseOpenResponseQuestions received an invalid phase: ${phase.type}`
    );
  }
};

export const phaseWithFeatureEnabled = (phase, feature) => {
  return phase[feature];
};

export const getCampaignHasFeatureEnabled = (campaign, feature) => {
  return (
    (campaign?.phases?.length > 0 &&
      campaign.phases.findIndex((p) => phaseWithFeatureEnabled(p, feature)) !==
        -1) ||
    campaign?.configs?.[INTRODUCTION_UI_PHASE_KEY]?.[feature]
  );
};

export const getCampaignHasFeatureEnabledDefaultOn = (campaign, feature) => {
  // if feature is not present, default to true
  if (!campaign?.phases?.length) {
    return true;
  }

  // if feature found, return true as long as it is not false
  const phase = campaign.phases.find((p) => typeof p[feature] !== 'undefined');
  if (phase && typeof phase[feature] !== 'undefined') {
    // for default on features, they must be explicitly set to false to be disabled
    return phase[feature] !== false;
  }

  if (campaign?.configs?.[INTRODUCTION_UI_PHASE_KEY]?.[feature] !== undefined) {
    return campaign.configs[INTRODUCTION_UI_PHASE_KEY][feature];
  }

  // if feature not found, default to true
  return true;
};

export const getCampaignFeature = (campaign, feature) => {
  if (!(campaign?.phases?.length > 0)) {
    return null;
  }

  const phase = campaign.phases.find((p) =>
    phaseWithFeatureEnabled(p, feature)
  );

  if (
    !phase &&
    campaign?.configs?.[INTRODUCTION_UI_PHASE_KEY]?.[feature] !== undefined
  ) {
    return campaign.configs[INTRODUCTION_UI_PHASE_KEY][feature];
  }

  if (!phase) {
    return null;
  }

  return phase[feature];
};

// matching the DEFAULT_RATINGS in models.py (~line 143)
export const DEFAULT_RATINGS = [
  {
    name: 'Does not meet expectations',
    value: 1,
    description:
      'Consistently performing below expectations; person must demonstrate improved performance within immediate period of time.',
  },
  {
    name: 'Partially meets expectations',
    value: 2,
    description:
      'Partially meets expectations; shows promise with noted areas for development.',
  },
  {
    name: 'Meets expectations',
    value: 3,
    description:
      'Performance is consistently acceptable and meets expectations. Good performance allowing core position requirements to be successfully fulfilled.',
  },
  {
    name: 'Exceeds expectations',
    value: 4,
    description:
      'Consistently meets expectations and sometimes delivers above and beyond.',
  },
  {
    name: 'Strongly exceeds expectations',
    value: 5,
    description:
      'Truly outstanding performance that results in extraordinary and exceptional accomplishments with significant contributions to team objectives.',
  },
];

const ratingsMap = (r) => ({
  ...r,
  // map value to "key" and "id" fields as string for use in dropdowns
  key: r.value?.toString(),
  id: r.value?.toString(),
  // add label, e.g. "4 - fully performing" for longer labels
  label: r.value?.toString() + ' - ' + r.name,
});

export const getCampaignRatings = (campaign, sortByValue = false) => {
  // if this is an external campaign and this is a situation where the frontend
  // does have access to campaign.configs, it must access the rating scale
  // within hidden_phase_E (but if configs isn't passed to the frontend, the
  // backend will inject it as rating_scale within the calibration phase
  if (
    getCampaignHasFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_ALLOW_EXTERNAL_MANAGER_RATINGS
    ) &&
    campaign?.configs?.hidden_phase_E?.rating_scale
  ) {
    return campaign.configs.hidden_phase_E.rating_scale.map(ratingsMap);
  }

  if (!campaign?.phases) {
    return DEFAULT_RATINGS.map(ratingsMap);
  }

  // NOTE: by default PERFORMANCE_FEATURE_RATING_SCALE ("rating_scale") is on
  // the evaluation phase, but when PERFORMANCE_FEATURE_ALLOW_EXTERNAL_MANAGER_RATINGS
  // is set, it is injected into the calibration phase on the backend
  // (because it lives in configs that aren't provided to the frontend, namely
  // campaign.configs.hidden_phase_E.rating_scale), so this code will identify
  // it properly in either case
  for (let i = 0; i < campaign.phases.length; i++) {
    if (PERFORMANCE_FEATURE_RATING_SCALE in campaign.phases[i]) {
      const mappedRatings =
        campaign.phases[i][PERFORMANCE_FEATURE_RATING_SCALE]?.map(ratingsMap);
      return sortByValue
        ? mappedRatings.sort((a, b) => b.value - a.value)
        : mappedRatings;
    }
  }

  return DEFAULT_RATINGS.map(ratingsMap);
};

// return list of integer campaign ratings, sorted ascending
export const getCampaignRatingList = (campaign) => {
  const ratings = getCampaignRatings(campaign, true);
  return ratings.map((r) => r.value).sort();
};

export const getPhaseDisplayName = (
  campaign: Campaign,
  phase: Phase,
  organizationName?: string
) => {
  // phase.name should override anything specified here
  if (phase.name) {
    return phase.name;
  }

  switch (phase.type) {
    case PHASE_TYPE_SELF:
      if (isWizardType(campaign, WIZARD_TYPE_ONA_ONLY)) {
        return (
          <FormattedMessage
            id="campaign.wizard_type.ona_only.phase.self.name"
            defaultMessage="Your network"
          />
        );
      }
      return (
        <FormattedMessage
          id="app.utils.models.performance.phase_type_self.title.you_and_your_network"
          defaultMessage="You + your network"
        />
      );
    case PHASE_TYPE_OTHERS:
      if (
        isWizardType(campaign, WIZARD_TYPE_ENGAGEMENT_ONLY) ||
        isWizardType(campaign, WIZARD_TYPE_ENPS_ONLY)
      ) {
        return (
          <FormattedMessage
            id="campaign.wizard_type.engagement_enps_only.phase.others.name"
            defaultMessage="Feedback for {organizationName}"
            values={{ organizationName }}
          />
        );
      }
      // There are three features that can be enabled / not enabled, handle output for each combo
      else if (
        phaseWithFeatureEnabled(phase, PERFORMANCE_FEATURE_ASSESS_MANAGER)
      ) {
        if (
          phaseWithFeatureEnabled(phase, PERFORMANCE_FEATURE_PEER_FEEDBACK) &&
          phaseWithFeatureEnabled(
            phase,
            PERFORMANCE_FEATURE_ASSESS_ORGANIZATION
          )
        ) {
          return (
            <FormattedMessage
              id="app.utils.models.performance.phase_type_others.title.peer_and_upward_and_feedback_for_organization"
              defaultMessage="Peer, upward + feedback for {organizationName}"
              values={{ organizationName }}
            />
          );
        } else if (
          phaseWithFeatureEnabled(phase, PERFORMANCE_FEATURE_PEER_FEEDBACK)
        ) {
          return (
            <FormattedMessage
              id="app.utils.models.performance.phase_type_others.title.peer_and_upward_feedback"
              defaultMessage="Peer + upward feedback"
            />
          );
        } else if (
          phaseWithFeatureEnabled(
            phase,
            PERFORMANCE_FEATURE_ASSESS_ORGANIZATION
          )
        ) {
          return (
            <FormattedMessage
              id="app.utils.models.performance.phase_type_others.title.upward_and_feedback_for_organization"
              defaultMessage="Upward + feedback for {organizationName}"
              values={{ organizationName }}
            />
          );
        } else {
          return (
            <FormattedMessage
              id="app.utils.models.performance.phase_type_others.title.upward_feedback"
              defaultMessage="Feedback for manager"
            />
          );
        }
      } else if (
        phaseWithFeatureEnabled(phase, PERFORMANCE_FEATURE_PEER_FEEDBACK)
      ) {
        if (
          phaseWithFeatureEnabled(
            phase,
            PERFORMANCE_FEATURE_ASSESS_ORGANIZATION
          )
        ) {
          return (
            <FormattedMessage
              id="app.utils.models.performance.phase_type_others.title.peer_and_feedback_for_organization"
              defaultMessage="Peer reviews + feedback for {organizationName}"
              values={{ organizationName }}
            />
          );
        } else {
          return (
            <FormattedMessage
              id="app.utils.models.performance.phase_type_others.title.peer_feedback"
              defaultMessage="Peer feedback"
            />
          );
        }
      } else if (
        phaseWithFeatureEnabled(
          phase,
          PERFORMANCE_FEATURE_ASSESS_ORGANIZATION
        ) ||
        phaseWithFeatureEnabled(
          phase,
          PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED
        )
      ) {
        return (
          <FormattedMessage
            id="app.utils.models.performance.phase_type_others.title.feedback_for_organization"
            defaultMessage="Feedback for {organizationName}"
            values={{ organizationName }}
          />
        );
      } else {
        // Just to have a fallback...
        return (
          <FormattedMessage
            id="app.utils.models.performance.phase_type_others.title.feedback"
            defaultMessage="Feedback"
          />
        );
      }
    case PHASE_TYPE_EVALUATION:
      return (
        <FormattedMessage
          id="app.utils.models.performance.phase_type_evaluation.title.manager_reviews"
          defaultMessage="Manager reviews"
        />
      );
    case PHASE_TYPE_CALIBRATION:
    case PHASE_TYPE_REPORTING:
    default:
      console.error('getPhaseDisplayName: Unexpected phase');
  }
};

export const getPhaseDisplayDescription = (
  campaign: Campaign,
  phase: Phase,
  organizationName?: string
) => {
  // phase.description should override anything specified here
  if (phase.description) {
    return phase.description;
  }

  switch (phase.type) {
    case PHASE_TYPE_SELF:
      if (isWizardType(campaign, WIZARD_TYPE_ONA_ONLY)) {
        return (
          <FormattedMessage
            id="campaign.wizard_type.ona_only.phase.self.description"
            defaultMessage="You give feedback about your network."
          />
        );
      } else if (
        phaseWithFeatureEnabled(
          phase,
          PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS
        )
      ) {
        return (
          <FormattedMessage
            id="app.utils.models.performance.phase_type_self.description.basic_and_self"
            defaultMessage="You provide some basic information and a self-reflection."
          />
        );
      } else {
        return (
          <FormattedMessage
            id="app.utils.models.performance.phase_type_self.description.basic_and_network"
            defaultMessage="You provide some basic information about yourself and your network."
          />
        );
      }
    case PHASE_TYPE_OTHERS:
      if (
        isWizardType(campaign, WIZARD_TYPE_ENGAGEMENT_ONLY) ||
        isWizardType(campaign, WIZARD_TYPE_ENPS_ONLY)
      ) {
        return (
          <FormattedMessage
            id="campaign.wizard_type.engagement_enps_only.phase.others.description"
            defaultMessage="You give feedback about {organizationName}."
            values={{ organizationName }}
          />
        );
      } else if (
        phaseWithFeatureEnabled(phase, PERFORMANCE_FEATURE_ASSESS_MANAGER)
      ) {
        if (
          phaseWithFeatureEnabled(phase, PERFORMANCE_FEATURE_PEER_FEEDBACK) &&
          phaseWithFeatureEnabled(
            phase,
            PERFORMANCE_FEATURE_ASSESS_ORGANIZATION
          )
        ) {
          return (
            <FormattedMessage
              id="app.utils.models.performance.phase_type_others.description.assess_manager_and_peers_and_organization"
              defaultMessage="You give feedback about your peers, manager, and {organizationName}."
              values={{ organizationName: organizationName }}
            />
          );
        } else if (
          phaseWithFeatureEnabled(phase, PERFORMANCE_FEATURE_PEER_FEEDBACK)
        ) {
          return (
            <FormattedMessage
              id="app.utils.models.performance.phase_type_others.description.assess_manager_and_peers"
              defaultMessage="You give feedback about your peers and manager."
            />
          );
        } else if (
          phaseWithFeatureEnabled(
            phase,
            PERFORMANCE_FEATURE_ASSESS_ORGANIZATION
          )
        ) {
          return (
            <FormattedMessage
              id="app.utils.models.performance.phase_type_others.description.assess_manager_and_organization"
              defaultMessage="You give feedback about your manager and {organizationName}."
              values={{ organizationName: organizationName }}
            />
          );
        } else {
          return (
            <FormattedMessage
              id="app.utils.models.performance.phase_type_others.description.assess_manager"
              defaultMessage="You give feedback about your manager."
            />
          );
        }
      } else if (
        phaseWithFeatureEnabled(phase, PERFORMANCE_FEATURE_PEER_FEEDBACK)
      ) {
        if (
          phaseWithFeatureEnabled(
            phase,
            PERFORMANCE_FEATURE_ASSESS_ORGANIZATION
          )
        ) {
          return (
            <FormattedMessage
              id="app.utils.models.performance.phase_type_others.description.peer_and_organization_feedback"
              defaultMessage="You give feedback about your peers and {organizationName}."
              values={{ organizationName: organizationName }}
            />
          );
        } else {
          return (
            <FormattedMessage
              id="app.utils.models.performance.phase_type_others.description.peer_feedback"
              defaultMessage="You give feedback about your peers."
            />
          );
        }
      } else if (
        phaseWithFeatureEnabled(phase, PERFORMANCE_FEATURE_ASSESS_ORGANIZATION)
      ) {
        return (
          <FormattedMessage
            id="app.utils.models.performance.phase_type_others.description.organization_feedback"
            defaultMessage="You give feedback about {organizationName}."
            values={{ organizationName: organizationName }}
          />
        );
      } else {
        // Just to have a fallback...
        return (
          <FormattedMessage
            id="app.utils.models.performance.phase_type_others.description.fallback_feedback"
            defaultMessage="You give feedback."
          />
        );
      }
    case PHASE_TYPE_EVALUATION:
      return (
        <FormattedMessage
          id="app.utils.models.performance.phase_type_evaluation.description.manager_reviews"
          defaultMessage="Managers give feedback about each of their direct reports."
        />
      );
    case PHASE_TYPE_CALIBRATION:
    case PHASE_TYPE_REPORTING:
    default:
      console.error('getPhaseDisplayName: Unexpected phase');
  }
};

export const getPhaseCustomWelcomeMessageHeader = (phase) => {
  return phase && phase[PERFORMANCE_FEATURE_WELCOME_HEADER];
};

export const getPhaseCustomWelcomeMessageHtml = (phase) => {
  return phase && phase[PERFORMANCE_FEATURE_WELCOME_MESSAGE_HTML];
};

export const getEvaluationPhaseOpenResponsesHelperText = (phase) => {
  return (
    phase &&
    phase[PERFORMANCE_FEATURE_EVALUATION_PHASE_OPEN_RESPONSES_HELPER_TEXT]
  );
};

// returns the phase objects that are to be used in the frontend
export const getPerformancePhasesDisplayObjects = (campaignPhases) => {
  return campaignPhases?.filter(getPhaseIsVisibleInReviewFlow);
};

export const useCampaignAsDemoOrActiveBasedOnUrl = (campaignId, isPreview) => {
  // if current pathname starts with consts.PREVIEW_CAMPAIGN then this
  // is a demo campaign, so fetch the campaign from the server and set
  // status to demo then return it, otherwise return currentPerfCampaign
  // @ts-expect-error FIXME
  const currentPerfCampaign = useSelector((state) => state.currentPerfCampaign);
  // @ts-expect-error FIXME
  const currentOrganization = useSelector((state) => state.currentOrganization);
  // @ts-expect-error FIXME
  const demoPeople = useSelector((state) => state.demoPeople);
  // @ts-expect-error FIXME
  const me = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const setCurrentPerfCampaignInDatastore = (changes) =>
    dispatch(setCurrentPerfCampaign(changes));

  // for persisting preview or demo campaign just for current session
  const currentDemoOrPreviewPerfCampaigns = useSelector(
    // @ts-expect-error FIXME
    (state) => state.currentDemoOrPreviewPerfCampaigns
  );
  const setCurrentDemoOrPreviewPerfCampaignInDatastore = (changes) =>
    dispatch(setCurrentDemoOrPreviewPerfCampaign(changes));

  const [campaign, setCampaign] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const { user } = useAuth0();
  const userSub = user?.sub;
  // @ts-expect-error FIXME
  const currentProxyPerson = useSelector((state) => state.currentPerfCampaign);

  useEffect(() => {
    // if campaignId provided, use it explicitly, else use current perf campaign
    if (!campaignId) {
      setCampaign(currentPerfCampaign);
    }

    // if this is a demo or preview and we have this campaign already stored, fetch
    // from datastore instead of server so we persist data between screens
    const foundDemoOrPreviewCampaignFromCurrentSession =
      currentDemoOrPreviewPerfCampaigns?.[campaignId];
    if (foundDemoOrPreviewCampaignFromCurrentSession) {
      setCampaign(foundDemoOrPreviewCampaignFromCurrentSession);
      return;
    }

    // fetch campaign from the server (note: this could be a PREVIEW of a campaign)
    if (userSub && typeof campaign === 'undefined' && campaignId) {
      ConfirmAPI.getObject(
        userSub,
        currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.CAMPAIGNS,
        campaignId,
        (data) => {
          // if this is a preview campaign, set the status to demo
          // before returning so it is honored when walking through the flow
          setCampaign(
            isPreview
              ? {
                  ...data,
                  status: CAMPAIGN_STATUSES.DEMO,
                  // inject fake peers into demo that persist within the current session
                  // only via redux (not saved to the server); for quick previewing,
                  // only add 3 peers and 3 direct reports
                  relationships: [
                    ...(data?.relationships || []),
                    // add 3 non-direct reports as peers
                    ...demoPeople.slice(3, 6).map((p) => ({
                      from_person: me,
                      to_person: p,
                      type: RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR,
                      dataset: 'demo',
                    })),
                    // add fake ONA relationships so viewing direct reports in the
                    // preview looks a bit more real
                    ...demoPeople.slice(0, 3).map((p, index) => ({
                      from_person: demoPeople[index + 1],
                      to_person: p,
                      type: RELATIONSHIP_TYPES.ENERGIZED_BY,
                      positive_skills: [{ id: 1, name: 'leadership' }],
                    })),
                    ...demoPeople.slice(0, 3).map((p, index) => ({
                      from_person: demoPeople[index + 2],
                      to_person: p,
                      type: RELATIONSHIP_TYPES.ENERGIZED_BY,
                      positive_skills: [
                        { id: 1, name: 'leadership' },
                        { id: 2, name: 'optimism' },
                      ],
                    })),
                    ...demoPeople.slice(0, 3).map((p, index) => ({
                      from_person: demoPeople[index + 3],
                      to_person: p,
                      type: RELATIONSHIP_TYPES.ENERGIZED_BY,
                      positive_skills: [
                        { id: 1, name: 'leadership' },
                        { id: 3, name: 'positive attitude' },
                        { id: 4, name: 'product design' },
                      ],
                    })),
                    ...demoPeople.slice(0, 3).map((p, index) => ({
                      from_person: demoPeople[index + 4],
                      to_person: p,
                      type: RELATIONSHIP_TYPES.ADVISED_BY,
                      positive_skills: [
                        { id: 2, name: 'optimism' },
                        { id: 5, name: 'communication' },
                        { id: 6, name: 'grit' },
                      ],
                    })),
                    ...demoPeople.slice(0, 3).map((p, index) => ({
                      from_person: demoPeople[index + 5],
                      to_person: p,
                      type: RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO,
                      positive_comments:
                        'Did an awesome job in the project we worked on together.',
                      positive_skills: [
                        { id: 7, name: 'writing' },
                        { id: 8, name: 'collaboration' },
                      ],
                    })),
                    ...demoPeople.slice(0, 3).map((p, index) => ({
                      from_person: demoPeople[index + 6],
                      to_person: p,
                      type: RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO,
                      positive_comments:
                        'Produced 2 times the amount of work that others in the same role did - great team player as well.',
                      positive_skills: [
                        { id: 7, name: 'execution' },
                        { id: 7, name: 'commitment' },
                        { id: 8, name: 'collaboration' },
                      ],
                    })),
                    ...demoPeople.slice(0, 3).map((p, index) => ({
                      from_person: demoPeople[index + 7],
                      to_person: p,
                      type: RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT,
                      negative_comments:
                        'May need to take more time outside of work to recharge.',
                      negative_skills: [{ id: 10, name: 'work-life balance' }],
                    })),
                  ],
                }
              : data
          );
        },
        (message) => {
          setErrorMessage(message);
        },
        {
          organization: currentOrganization?.id,
          ...(currentProxyPerson ? { proxy: currentProxyPerson.email } : {}),
        }
      );
    }
  }, [
    campaign,
    campaignId,
    currentDemoOrPreviewPerfCampaigns,
    currentOrganization?.id,
    currentPerfCampaign,
    currentProxyPerson,
    demoPeople,
    isPreview,
    me,
    userSub,
  ]);

  return {
    campaign,
    setCampaign: campaignId
      ? setCurrentDemoOrPreviewPerfCampaignInDatastore
      : setCurrentPerfCampaignInDatastore,
    errorMessage,
  };
};

// React hook to use for any pages that are part of the
// performance cycle process (this will fetch the latest
// data and return the latest objects which may or may not
// come from the local storage cache).

// Used as a singleton to allow the ability to abort requests
// if multiple accumulate due to user navigation
export const useCampaignsAndSurveyResponses = (function () {
  let controller; // Used for aborting requests (in case user leaves page)

  return (campaignId, isPreview, needsDirectReportReceivedRelationships) => {
    const { user } = useAuth0();
    const userSub = user?.sub;
    // @ts-expect-error FIXME
    const currentProxyPerson = useSelector((state) => state.currentProxyPerson);

    const dispatch = useDispatch();
    // (re)fetch campaigns and survey responses when the proxy user changes
    useEffect(() => {
      // we need this parameter before we fetch to know whether
      // or not to fetch these additional relationships (as we don't
      // want to for performance unless we have to)
      if (
        !campaignId &&
        !isPreview &&
        typeof needsDirectReportReceivedRelationships !== 'undefined'
      ) {
        log('Fetching campaigns and survey responses.');

        // Abort if request already exists (no action if request already finished)
        if (controller) {
          controller.cancel();
        }

        // NOTE: Deprecated if we update Axios to 0.22+
        // Instead should switch to AbortController
        // (https://axios-http.com/docs/cancellation)
        controller = axios.CancelToken.source();

        dispatch(
          loadCampaignsAndSurveyResponses(
            userSub,
            currentProxyPerson?.email,
            needsDirectReportReceivedRelationships,
            controller.token
          )
        );
      }
    }, [
      currentProxyPerson,
      userSub,
      dispatch,
      needsDirectReportReceivedRelationships,
      isPreview,
      campaignId,
    ]);

    // fetch relevant objects from the Redux store
    // @ts-expect-error FIXME
    const campaigns = useSelector((state) => state.campaigns);
    // @ts-expect-error FIXME
    const surveyResponses = useSelector((state) => state.surveyResponses);
    const { campaign, setCampaign, errorMessage } =
      useCampaignAsDemoOrActiveBasedOnUrl(campaignId, isPreview);
    const currentPerfSurveyResponse = useSelector(
      // @ts-expect-error FIXME
      (state) => state.currentPerfSurveyResponse
    );

    return {
      campaign,
      setCampaign,
      campaigns,
      surveyResponses,
      currentPerfSurveyResponse,
      errorMessage,
    };
  };
})();

export const getCurrentPerformancePreviewPathPrefix = () => {
  // if path starts with /preview/cycles/[id]/, capture that part
  // of the url in a variable so we can use it to build the next step
  // path
  const previewPathMatch = location.pathname.match(/\/preview\/cycles\/\d+/);
  const previewPathPrefix = previewPathMatch?.length ? previewPathMatch[0] : '';

  return previewPathPrefix;
};

export const getPhaseDateHasStarted = (startDateString) => {
  // consider the current date matching as started
  return new Date(startDateString) <= new Date();
};

export const getPhaseDateHasPassed = (endDateString) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  // date is midnight on left, but the date end is meant to be after 11:59:59pm,
  // so we need to subtract a date from the current day to account for that
  return new Date(endDateString) < yesterday;
};

export const updateLatestPerfStep = (
  currentPerfSurveyResponse,
  currentStepNumber
) => {
  return {
    ...currentPerfSurveyResponse,
    configs: {
      ...currentPerfSurveyResponse?.configs,
      current_step:
        currentStepNumber > currentPerfSurveyResponse?.configs?.current_step
          ? currentStepNumber
          : currentPerfSurveyResponse?.configs?.current_step,
    },
  };
};

export const getSelectedTimeFrameForObjectives = (campaign) => {
  const timeFrameFromCampaign = campaign?.phases?.find(
    (it) => it.type === 'S'
  )?.[PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP]?.quarter;

  if (!timeFrameFromCampaign) {
    return null;
  }
  return {
    start: parseDateFromUnicode(timeFrameFromCampaign.start, 'yyyy-MM-dd'),
    end: parseDateFromUnicode(timeFrameFromCampaign.end, 'yyyy-MM-dd'),
    type: timeFrameFromCampaign.type ?? 'QUARTER', // default to quarter if not specified, for backward compatibility
  };
};

export const getPerformanceFeatureEnabledWithFallback = (
  campaign,
  featureList
) => {
  return (
    featureList.reduce((acc, feature) => {
      if (typeof acc != 'undefined') {
        return acc;
      }
      const phase = campaign.phases.find((p) => feature in p);
      if (phase) {
        const value = phase[feature];
        if (typeof value === 'boolean') {
          return value;
        }
        if (typeof value === 'object') {
          return value.enabled;
        }
        throw new Error(`Unexpected value for feature ${feature}: ${value}`);
      }
      return acc;
    }, undefined) ?? false
  );
};

export const hasPerformanceFeature = (campaign, feature) => {
  return campaign.phases?.findIndex((p) => feature in p) !== -1;
};

export const getPerformanceFeatureEnabled = (campaign, feature) => {
  return (
    campaign.phases?.findIndex(
      (p) => p[feature] === true || p[feature]?.enabled === true
    ) !== -1
  );
};

export const getPerformanceFeatureEnabledAndNonEmtpy = (campaign, feature) => {
  const value = getPerformanceFeatureValue(campaign, feature);
  return value && value.length > 0;
};

export const getPerformanceFeatureValue = (campaign, feature) => {
  // go through phases and return value of the feature in the first
  // phase it is found
  const phase = campaign.phases?.find((p) => p[feature]);
  return phase?.[feature];
};

export const isDirectReportEvaluatedByManager = (config): boolean => {
  return config?.is_evaluated_by_manager ?? true;
};

export const isRatedByManager = (config): boolean => {
  return isDirectReportEvaluatedByManager(config) && (config?.is_rated ?? true);
};

const isEligibleForFeedback = (
  personIdToSurveyResponses,
  person,
  ignorePersonStatus = false
) => {
  // if status if person isn't active or on leave, return
  if (
    person.status &&
    !ignorePersonStatus &&
    !isCurrentEmployee(person.status)
  ) {
    return false;
  }

  const response = personIdToSurveyResponses[person?.id];

  if (!response) {
    // if no survey response, be permissive (in cases of someone)
    // who has not yet been added to dataset or companies without
    // a dataset
    return true;
  }

  // Typically reserved for "On Leave" folks who still need to receive
  // a manager review, but is otherwise not particpating in any other phase
  if (response?.configs?.is_only_receiving_review === true) {
    return true;
  }

  // if not eligible for phase 1, you're not eligible for manager review,
  // unless the "is_only_receiving_review" flag is declared (above).
  // NOTE: we check for false explicitly because undefined should be true
  // as any participants can participate in phase 1 by default
  if (response?.configs?.is_participating_in_self_phase === false) {
    // if not defined, we assume by default that they are participating
    // to be permissive for companies that haven't deeply defined their
    // datasets
    return false;
  }

  if (response?.configs?.is_participating === false) {
    // if not defined, we assume by default that they are participating
    // to be permissive for companies that haven't deeply defined their
    // datasets
    return false;
  }

  if (!isDirectReportEvaluatedByManager(response?.configs)) {
    // if not defined, we assume by default that they are being evaluated
    // to be permissive for companies that haven't deeply defined their
    // datasets
    return false;
  }

  // passed all other checks
  return true;
};

export const getDirectReportsEligibleForFeedback = (
  managerPerson,
  campaign,
  relationships,
  personIdToSurveyResponses,
  demoPeople,
  ignorePersonStatus = false
) => {
  // get actual direct reports which are indicated by any relationships
  // for which you are the to_person, type is REPORTS_TO, and it was
  // generated by a dataset, and sort by full name by default
  const realDirectReports = relationships
    ?.filter(
      (r) =>
        r.dataset &&
        (r.type === RELATIONSHIP_TYPES.REPORTS_TO ||
          r.type == RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER) &&
        peopleObjectsAreEqual(r.to_person, managerPerson) &&
        isEligibleForFeedback(
          personIdToSurveyResponses,
          r.from_person,
          ignorePersonStatus
        )
    )
    .map((r) => ({
      ...r.from_person,
      isAdditionalDirectReport:
        r.type === RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER,
    }))
    .sort((a, b) => a.full_name.localeCompare(b.full_name));

  if (
    campaign.status === CAMPAIGN_STATUSES.DEMO &&
    !(realDirectReports?.length > 0)
  ) {
    // only show 3 fake direct reports for simple progressing through preview
    return demoPeople?.slice(0, 3);
  } else {
    return realDirectReports;
  }
};

export const isAdditionalManagerEligibleForUpwardFeedback = () => {
  // for now, all additional managers are eligible for upward feedback
  return true;
};

export const getManagersEligibleForUpwardFeedback = (
  directReportPerson,
  campaign,
  relationships,
  demoPeople
) => {
  // get actual direct reports which are indicated by any relationships
  // for which you are the to_person, type is REPORTS_TO, and it was
  // generated by a dataset, and sort by full name by default

  const principalManager = getManagerPerson(
    directReportPerson,
    campaign,
    demoPeople,
    false
  );

  const additionalManagers = relationships
    ?.filter(
      (r) =>
        r.dataset &&
        r.type === RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER &&
        peopleObjectsAreEqual(r.from_person, directReportPerson) &&
        isAdditionalManagerEligibleForUpwardFeedback()
    )
    .map((r) => ({
      ...r,
      to_person: {
        ...r.to_person,
        isAdditionalManager:
          r.type === RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER,
      },
    }))
    .map(transformToSuveyTarget)
    .sort((a, b) => a.full_name.localeCompare(b.full_name));

  const allManagers = [principalManager, ...additionalManagers].filter(
    (p) => !!p
  );

  if (
    campaign.status === CAMPAIGN_STATUSES.DEMO &&
    !(allManagers?.length > 0)
  ) {
    // only show 2 fake managers for simple progressing through preview
    return demoPeople?.slice(3, 5);
  } else {
    return allManagers;
  }
};

export const isPersonInPeopleList = (person, peopleList) => {
  return person && peopleList?.find((p) => peopleObjectsAreEqual(person, p));
};

export const sortRelationshipsByPriority = (a, b) => {
  if (!a.priority || !b.priority) {
    return 0;
  }
  if (a.priority < b.priority) {
    return -1;
  }
  if (a.priority > b.priority) {
    return 1;
  }
  return 0;
};

export const getAssignedPeers = (
  meId,
  campaign,
  areDeclined = false,
  maxPeers = 5
) => {
  // If campaign has maximum_peers_to_show, use that instead of parameter value
  const campaignMaxPeers = getCampaignFeature(
    campaign,
    PERFORMANCE_FEATURE_MAXIMUM_PEERS_TO_SHOW
  );
  if (campaignMaxPeers && campaignMaxPeers > maxPeers) {
    maxPeers = campaignMaxPeers;
  }

  // get actual peers which are indicated by any relationships
  // for which you are the to_person, type is IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR, and it was
  // generated by a dataset
  const realPeers = campaign.relationships
    ?.filter(
      (r) =>
        r.dataset &&
        r.type === RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR &&
        ((areDeclined && r.decline_reason) ||
          (!areDeclined && !r.decline_reason)) &&
        peopleIdsAreEqual(r.from_person?.id, meId)
    )
    .sort((a, b) => {
      const p = sortRelationshipsByPriority(a, b);
      if (p !== 0) return p;
      return a.to_person.full_name.localeCompare(b.to_person.full_name);
    })
    .map((r) => transformToSuveyTarget(r));

  if (!(realPeers?.length > 0)) {
    return [];
  }

  // if more than maxPeers, filter out to the ones earlier in the priority
  // (where lower number = higher priority, e.g. 1 = highest priority)
  return realPeers.slice(0, maxPeers);
};

export const getOptionalPeers = (meId, campaign, maxPeers = 2) => {
  const undeclinedPeers = getAssignedPeers(meId, campaign, false);

  // get outgoing peers you have written about which are not in your
  // undeclined peers list
  const optionalPeers = campaign.relationships
    ?.filter(
      (r) =>
        !r.dataset &&
        r.type === RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR &&
        !r.decline_reason &&
        peopleIdsAreEqual(r.from_person?.id, meId) &&
        undeclinedPeers.findIndex((p) =>
          peopleObjectsAreEqual(p, r.to_person)
        ) === -1
    )
    .sort((a, b) => a.id - b.id) // ensure adding order on screen is consistent
    .map((r) => transformToSuveyTarget(r));

  if (
    campaign.status === CAMPAIGN_STATUSES.DEMO &&
    !(optionalPeers?.length > 0)
  ) {
    // default to no optional peers for demo
    return [];
  } else {
    // if more than maxPeers, filter out to the ones earlier in the priority
    // (where lower number = higher priority, e.g. 1 = highest priority)
    return optionalPeers.slice(0, maxPeers);
  }
};

export const getPerformancePhases = (
  me,
  currentOrganization,
  campaign,
  demoPeople,
  formatMessage
) => {
  const wizardType = getWizardType(campaign);

  const directReports = getDirectReportsEligibleForFeedback(
    me,
    campaign,
    campaign.relationships,
    toPersonIdToSurveyResponseLookup(campaign.survey_responses),
    demoPeople
  );

  const managers = getManagersEligibleForUpwardFeedback(
    me,
    campaign,
    campaign.relationships,
    demoPeople
  );

  const undeclinedPeers = getAssignedPeers(me?.id, campaign, false);
  const optionalPeers = getOptionalPeers(me?.id, campaign);

  const completeResumeStepIsEnabled =
    getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_COMPLETE_RESUME_STEP
    ) && wizardType !== WIZARD_TYPE_ONA_ONLY;

  const contributionSelfReflectionsEnabled =
    getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS
    ) && wizardType !== WIZARD_TYPE_ONA_ONLY;

  const completeObjectivesStepIsEnabled =
    getPerformanceFeatureEnabledWithFallback(campaign, [
      PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP,
      PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_STEP,
    ]) && wizardType !== WIZARD_TYPE_ONA_ONLY;

  const assessManagerIsEnabled =
    getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_ASSESS_MANAGER
    ) &&
    wizardType !== WIZARD_TYPE_ENPS_ONLY &&
    wizardType !== WIZARD_TYPE_ENGAGEMENT_ONLY;

  const peersAreEnabled =
    getPerformanceFeatureEnabled(campaign, PERFORMANCE_FEATURE_PEER_FEEDBACK) &&
    wizardType !== WIZARD_TYPE_ENPS_ONLY &&
    wizardType !== WIZARD_TYPE_ENGAGEMENT_ONLY;

  const optionalPeersAreEnabled =
    getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_ADD_OPTIONAL_PEER_FEEDBACK
    ) &&
    wizardType !== WIZARD_TYPE_ENPS_ONLY &&
    wizardType !== WIZARD_TYPE_ENGAGEMENT_ONLY;

  const assessOrganizationisEnabled = getPerformanceFeatureEnabled(
    campaign,
    PERFORMANCE_FEATURE_ASSESS_ORGANIZATION
  );

  const employeeNPSEnabled =
    getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED
    ) && wizardType !== WIZARD_TYPE_ENGAGEMENT_ONLY;

  const openResponseQuestionsEnabled =
    getPerformanceFeatureEnabledAndNonEmtpy(
      campaign,
      PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS
    ) && wizardType !== WIZARD_TYPE_ONA_ONLY;

  const hasDirectReports = directReports?.length > 0;
  const hasSelfPhase = getPhaseByType(campaign, PHASE_TYPE_SELF);
  const hasOthersPhase = getPhaseByType(campaign, PHASE_TYPE_OTHERS);
  const hasEvaluationPhase = getPhaseByType(campaign, PHASE_TYPE_EVALUATION);
  const disableSelfReflection = wizardType === WIZARD_TYPE_ONA_ONLY;

  // NOTE: when updating this, ensure that the correspond step calculation in
  // models.py in the backend is updated as well to match
  const getPhaseSteps = (phase) => {
    if (phase.type === PHASE_TYPE_SELF) {
      return [
        consts.PERFORMANCE_STEP_WELCOME(formatMessage),
        consts.PERFORMANCE_STEP_BASICS(formatMessage),
        ...(getPerformanceFeatureEnabled(
          campaign,
          PERFORMANCE_FEATURE_DISABLE_NETWORK
        )
          ? []
          : [consts.PERFORMANCE_STEP_NETWORK(formatMessage)]),
        ...(getPerformanceFeatureEnabled(
          campaign,
          PERFORMANCE_FEATURE_DISABLE_CALLOUTS
        )
          ? []
          : [consts.PERFORMANCE_STEP_CALLOUTS(formatMessage)]),
        ...(getPerformanceFeatureEnabled(
          campaign,
          PERFORMANCE_FEATURE_HIGH_PRIORITY_PEERS
        ) &&
        getPerformanceFeatureEnabled(
          campaign,
          PERFORMANCE_FEATURE_PEER_FEEDBACK
        ) &&
        campaign?.phases?.length > 1 &&
        directReports?.length > 0
          ? [consts.PERFORMANCE_STEP_CHOOSE_MUST_HAVE_PEERS(formatMessage)]
          : []),
        ...(!disableSelfReflection &&
        (completeResumeStepIsEnabled ||
          contributionSelfReflectionsEnabled ||
          completeObjectivesStepIsEnabled ||
          openResponseQuestionsEnabled)
          ? [
              campaign?.phases?.length > 1 || !completeResumeStepIsEnabled
                ? consts.PERFORMANCE_STEP_ASSESS_SELF(formatMessage)
                : {
                    ...consts.PERFORMANCE_STEP_ASSESS_SELF(formatMessage),
                    name: 'Resume',
                  },
            ]
          : []),
        {
          ...((hasEvaluationPhase && directReports?.length > 0) ||
          hasOthersPhase
            ? consts.PERFORMANCE_STEP_SELF_PHASE_COMPLETE(formatMessage)
            : consts.PERFORMANCE_STEP_EVERYTHING_COMPLETE(formatMessage)),
          ignoreInNav: true,
        },
      ];
    }

    if (phase.type === PHASE_TYPE_OTHERS) {
      return [
        hasSelfPhase
          ? consts.PERFORMANCE_STEP_OTHERS_PHASE_WELCOME(formatMessage)
          : consts.PERFORMANCE_STEP_WELCOME(formatMessage),
        ...(assessManagerIsEnabled
          ? [consts.PERFORMANCE_STEP_ASSESS_MANAGER(formatMessage, managers)]
          : []),
        ...(peersAreEnabled
          ? [
              {
                ...consts.PERFORMANCE_STEP_ASSESS_PEERS(formatMessage),
                children: undeclinedPeers,
              },
            ]
          : []),
        ...(optionalPeersAreEnabled
          ? [
              {
                ...consts.PERFORMANCE_STEP_ASSESS_OPTIONAL_PEERS(formatMessage),
                children: optionalPeers,
              },
            ]
          : []),
        ...(assessOrganizationisEnabled || employeeNPSEnabled
          ? [
              {
                ...consts.PERFORMANCE_STEP_ASSESS_ORGANIZATION(formatMessage),
                name: currentOrganization?.name + ' experience',
              },
            ]
          : []),
        {
          ...(hasEvaluationPhase && directReports?.length > 0
            ? consts.PERFORMANCE_STEP_OTHERS_PHASE_COMPLETE(formatMessage)
            : consts.PERFORMANCE_STEP_EVERYTHING_COMPLETE(formatMessage)),
          ignoreInNav: true,
        },
      ];
    }

    if (phase.type === PHASE_TYPE_EVALUATION) {
      return [
        consts.PERFORMANCE_STEP_EVALUATION_PHASE_WELCOME(formatMessage),
        {
          ...consts.PERFORMANCE_STEP_ASSESS_DIRECT_REPORTS(formatMessage),
          children: directReports,
        },
        {
          ...consts.PERFORMANCE_STEP_EVERYTHING_COMPLETE(formatMessage),
          ignoreInNav: true,
        },
      ];
    }

    if (
      phase.type === PHASE_TYPE_CALIBRATION ||
      phase.type === PHASE_TYPE_REPORTING
    ) {
      // no user-facing steps to walk through in the actual performance cycle,
      // so just show final phase complete screen
      return [
        {
          ...consts.PERFORMANCE_STEP_EVERYTHING_COMPLETE(formatMessage),
          ignoreInNav: true,
        },
      ];
    }

    console.error('Unrecognized phase type: ' + JSON.stringify(phase));
  };

  return campaign?.phases
    ?.map((p) => ({
      ...p,
      steps: getPhaseSteps(p),
    }))
    .filter((p) => hasDirectReports || !getPhaseIsManagersOnly(p));
};

export const getStepNumber = (
  me,
  currentOrganization,
  campaign,
  people,
  path,
  formatMessage
) => {
  const performancePhases = getPerformancePhases(
    me,
    currentOrganization,
    campaign,
    people,
    formatMessage
  );

  let counter = 0;

  for (let i = 0; i < performancePhases.length; i++) {
    for (let j = 0; j < performancePhases[i].steps?.length; j++) {
      counter++;
      if (path === performancePhases[i].steps[j]?.path) {
        return counter;
      }
    }
  }

  // not found
  return 0;
};

export const useCurrentStepNumber = (campaign) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const currentOrganization = useSelector<ReduxState, Organization>(
    (state) => state.currentOrganization
  );
  const me = useSelector<ReduxState, Me>((state) => state.me);
  const demoPeople = useSelector<ReduxState, any[]>(
    (state) => state.demoPeople
  );

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        me,
        currentOrganization,
        campaign,
        demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      currentOrganization,
      campaign,
      demoPeople,
      me,
      formatMessage,
    ]
  );

  return currentStepNumber;
};

export const getPerformanceStepIsCompleted = (
  path,
  me,
  currentOrganization,
  campaign,
  currentPerfSurveyResponse,
  people,
  formatMessage
) => {
  if (!currentPerfSurveyResponse?.configs?.current_step) {
    return false;
  }

  const performancePhases = getPerformancePhases(
    me,
    currentOrganization,
    campaign,
    people,
    formatMessage
  );

  let counter = 0;

  for (let i = 0; i < performancePhases.length; i++) {
    for (let j = 0; j < performancePhases[i].steps?.length; j++) {
      counter++;
      if (path === performancePhases[i].steps[j]?.path) {
        return counter <= currentPerfSurveyResponse?.configs?.current_step;
      }
    }
  }

  // not found
  return false;
};

export const getPerformanceNextStep = (
  me,
  currentOrganization,
  campaign,
  currentPathPossibleWithPreviewPrefix,
  currentPathSearch,
  people,
  formatMessage
) => {
  const previewPathPrefix = getCurrentPerformancePreviewPathPrefix();

  // strip match from currentPathPossibleWithPreviewPrefix for matching below
  const currentPath = currentPathPossibleWithPreviewPrefix.replace(
    previewPathPrefix,
    ''
  );

  // if a search is in the path, we're evaluating a specific person.
  // After we are done, we want to go back to the high level step
  const multiplePeoplePaths = [
    consts.PERFORMANCE_STEP_ASSESS_DIRECT_REPORTS().path,
    consts.PERFORMANCE_STEP_ASSESS_PEERS().path,
    consts.PERFORMANCE_STEP_ASSESS_MANAGER().path,
    consts.PERFORMANCE_STEP_ASSESS_OPTIONAL_PEERS().path,
  ];

  if (
    multiplePeoplePaths.includes(currentPath) &&
    currentPathSearch?.length > 0
  ) {
    return previewPathPrefix + currentPath;
  }

  const performancePhases = getPerformancePhases(
    me,
    currentOrganization,
    campaign,
    people,
    formatMessage
  );

  for (let i = 0; i < performancePhases.length; i++) {
    for (let j = 0; j < performancePhases[i].steps?.length; j++) {
      if (currentPath === performancePhases[i].steps[j]?.path) {
        if (j === performancePhases[i].steps?.length - 1) {
          return previewPathPrefix + performancePhases[i + 1].steps[0]?.path;
        } else {
          return previewPathPrefix + performancePhases[i].steps[j + 1]?.path;
        }
      }
    }
  }

  console.error(
    'getPerformanceNextStep found no next step for path: ' + currentPath
  );

  return null;
};

export const getCurrentPerfStep = (
  me,
  organization,
  campaign,
  surveyResponse,
  people,
  formatMessage
) => {
  if (!campaign || !surveyResponse) {
    // no campaign or no survey info received yet, so go to welcome page
    return consts.PERFORMANCE_STEP_WELCOME(formatMessage);
  }

  const performancePhases = getPerformancePhases(
    me,
    organization,
    campaign,
    people,
    formatMessage
  );

  let noUpcomingPhases = false;

  for (let i = 0; i < performancePhases.length; i++) {
    const phase = performancePhases[i];
    const nextPhase =
      i === performancePhases.length - 1 ? null : performancePhases[i + 1];

    const phaseClosedInThePast =
      campaign.status !== CAMPAIGN_STATUSES.DEMO &&
      getPhaseDateHasPassed(phase?.end_date);

    const phaseIsOpen =
      campaign.status === CAMPAIGN_STATUSES.DEMO ||
      (new Date(phase?.start_date) <= new Date() && !phaseClosedInThePast);

    const nextPhaseClosedInThePast = nextPhase
      ? campaign.status !== CAMPAIGN_STATUSES.DEMO &&
        getPhaseDateHasPassed(nextPhase?.end_date)
      : false;

    const nextPhaseIsOpen = nextPhase
      ? campaign.status === CAMPAIGN_STATUSES.DEMO ||
        (new Date(nextPhase?.start_date) <= new Date() &&
          !nextPhaseClosedInThePast)
      : false;

    const phaseIsCalibrationOrReporting =
      phase?.type === PHASE_TYPE_CALIBRATION ||
      phase?.type === PHASE_TYPE_REPORTING;

    // We don't want to count Calibration or Reporting
    // phase as a blocker for completion
    noUpcomingPhases =
      phaseClosedInThePast ||
      nextPhaseClosedInThePast ||
      phaseIsCalibrationOrReporting;

    if (phaseIsOpen) {
      let performancePhaseSteps = performancePhases[i].steps;
      if (
        surveyResponse?.configs?.is_only_selecting_high_priority_peers &&
        phase?.type === PHASE_TYPE_SELF
      ) {
        // This is a non-participating manager who only selects high
        // priority peers for their direct reports
        performancePhaseSteps = filterSelectHighPriorityOnlySteps(
          performancePhases[i].steps
        );
      }
      for (let j = 0; j < performancePhaseSteps?.length; j++) {
        if (
          getPerformanceStepIsCompleted(
            performancePhaseSteps[j].path,
            me,
            organization,
            campaign,
            surveyResponse,
            people,
            formatMessage
          )
        ) {
          // if this is the last step in the phase and the next phase either
          // does not exist or isn't open, return this phase
          if (!nextPhaseIsOpen && j === performancePhaseSteps.length - 1) {
            return performancePhaseSteps[j];
          }
        } else {
          // we skip the ignoreInNav ones (e.g. end of phases) if
          // the next phase is open as we want to advance to that next
          // phase
          if (performancePhaseSteps[j].ignoreInNav && nextPhaseIsOpen) {
            return performancePhases[i + 1].steps[0];
          } else {
            return performancePhaseSteps[j];
          }
        }
      }
    }
  }

  // if there are no valid upcoming phases, go to all done page
  if (noUpcomingPhases) {
    return consts.PERFORMANCE_STEP_EVERYTHING_COMPLETE(formatMessage);
  }

  // by default, go to welcome page
  return consts.PERFORMANCE_STEP_WELCOME(formatMessage);
};

export const canViewStepInCampaign = (
  isDemoOrPreviewOfCampaign: boolean,
  hasPhaseSpecialAccessToken: boolean,
  pathForStep: string | null | undefined,
  me: any,
  organization: any,
  campaign: any,
  surveyResponse: any,
  people: any[] | undefined | null,
  formatMessage: IntlShape['formatMessage']
) => {
  if (
    !campaign ||
    !pathForStep ||
    isDemoOrPreviewOfCampaign ||
    // if the user has a special access token, they can view any step
    // see discussion here https://github.com/Confirm-HR/core/pull/4701#issuecomment-2329622493
    hasPhaseSpecialAccessToken
  ) {
    return true;
  }
  const currentStep = getCurrentPerfStep(
    me,
    organization,
    campaign,
    surveyResponse,
    people,
    formatMessage
  );
  const performancePhases = getPerformancePhases(
    me,
    organization,
    campaign,
    people,
    formatMessage
  );

  const currentPathScore = getCycleProgressionScore(
    performancePhases,
    currentStep.path
  );

  const reuquestedPathScore = getCycleProgressionScore(
    performancePhases,
    pathForStep
  );

  return (
    currentPathScore > -1 &&
    reuquestedPathScore > -1 &&
    reuquestedPathScore <= currentPathScore
  );
};

export const getCycleProgressionScore = (
  performancePhases: any[] | null | undefined,
  pathToMatch: string | undefined | null
) => {
  return (
    (performancePhases ?? [])
      .flatMap((phase, phaseIdx) =>
        (phase?.steps ?? []).map((step, stepIdx) => [
          phaseIdx * 1000 + stepIdx,
          step,
        ])
      )
      .find(([, step]) => step.path === pathToMatch)?.[0] ?? -1
  );
};

export const perfCampaignCallback = (
  me,
  organization,
  campaign,
  history,
  people,
  data,
  formatMessage
) => {
  const nextStep = getPerformanceNextStep(
    me,
    organization,
    campaign,
    history.location.pathname,
    history.location.search,
    people,
    formatMessage
  );

  if (nextStep === null) {
    const pathPrefix =
      campaign.status === CAMPAIGN_STATUSES.DEMO
        ? getCurrentPerformancePreviewPathPrefix()
        : '';
    // When no next step is found, redirect to performance homepage
    history.push(`${pathPrefix}${consts.PERFORMANCE().path}`);
  } else if (campaign.status === CAMPAIGN_STATUSES.DEMO) {
    history.push(nextStep);
  } else {
    if (data) {
      history.push(nextStep);
    }
  }
};

export const getRatingName = (formatMessage, campaign, ratingValue) => {
  const ratings = getCampaignRatings(campaign);

  const ratingNumber =
    typeof ratingValue === 'string' ? parseFloat(ratingValue) : ratingValue;

  const ratingObject = ratings.find((r) => r.value === ratingNumber);

  if (ratingObject) {
    return ratingObject.name;
  } else {
    // default (e.g. for ? / TBD)
    return formatMessage({
      id: 'app.utils.models.performance.rating_name.default',
      defaultMessage: 'pending',
    });
  }
};

export const getRatingDescription = (campaign, ratingValue) => {
  const ratings = getCampaignRatings(campaign);

  const ratingNumber =
    typeof ratingValue === 'string' ? parseFloat(ratingValue) : ratingValue;

  const ratingObject = ratings.find((r) => r.value === ratingNumber);

  if (ratingObject?.description) {
    return <span>{ratingObject.description}</span>;
  } else {
    // default (e.g. for ?)
    return null;
  }
};

export const getCampaignHasCalibrationPhase = (campaign) =>
  campaign?.phases?.findIndex((p) => p.type === PHASE_TYPE_CALIBRATION) !== -1;

export const getCampaignHasEvaluationPhase = (campaign) =>
  campaign?.phases?.findIndex((p) => p.type === PHASE_TYPE_EVALUATION) !== -1;

export const getCampaignHasONA = (campaign: Campaign): boolean => {
  const self = getPhaseByType(campaign, PHASE_TYPE_SELF);
  if (!self) {
    return false;
  }
  return (
    !getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_DISABLE_NETWORK
    ) ||
    !getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_DISABLE_CALLOUTS
    )
  );
};

export const replaceCampaignQuestionText = (
  string,
  name,
  campaign,
  organization,
  formatMessage
) => {
  if (!string || typeof string !== 'string') {
    return string;
  }

  const replaceDict = {
    '{{name}}': name,
    '{{duration}}': getCampaignCoverageDurationMonthString(
      campaign,
      formatMessage
    ),
    '{{organization}}': organization?.name,
  };

  for (const key in replaceDict) {
    string = string.replaceAll(key, replaceDict[key]);
  }

  return string;
};

export const DEFAULT_OPEN_RESPONSE_QUESTIONS = [
  {
    type: INPUT_TYPES.SKILLS,
    name: 'positive_skills',
    label:
      'In the past {{duration}}, what are the strongest skills and behaviors {{name}} demonstrated?',
  },
  {
    type: INPUT_TYPES.TEXTAREA,
    name: 'positive_comments',
    required: true,
    maxLength: 1000,
    minRows: 3,
    maxRows: 15,
    label: 'Give an example or two of how you saw these demonstrated.',
  },
  {
    type: INPUT_TYPES.SKILLS,
    name: 'negative_skills',
    required: true,
    label:
      'What skills or behaviors does {{name}} need guidance or support with moving forward?',
  },
  {
    type: INPUT_TYPES.TEXTAREA,
    name: 'negative_comments',
    required: true,
    maxLength: 1000,
    minRows: 3,
    maxRows: 15,
    label:
      'Share an idea or two of how {{name}} could further develop in these areas.',
  },
];

// default questions if none provided
// NOTE: anonymous_*-prefixed names like the ones below
// explicitly are anonymized in the backend serializers, and they
// are meant for questions which companies want to have anonymous
// responses for

// If editing, please edit `get_required_engagement_survey_questions`
// in campaigns.py accordingly
export const DEFAULT_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS = [
  {
    type: INPUT_TYPES.LIKERT,
    name: 'anonymous_likert',
    required: true,
    objects: [
      {
        name: 'anonymous_pulse_overall',
        value: 'I would recommend {{organization}} as a great place to work.',
      },
      {
        name: 'anonymous_pulse_direction',
        value: 'I have what I need to be successful in my role.',
      },
    ],
  },
  {
    type: INPUT_TYPES.TEXTAREA,
    minRows: 2,
    name: 'anonymous_pulse_company_support',
    required: true,
    label:
      "What's one thing {{organization}} can do to support you in the next 6 months?",
    maxLength: consts.PULSE_COMPANY_SUPPORT_MAX_LENGTH,
  },
];

export const ASSESS_ORGANIZATION_EMPLOYEE_NPS_QUESTION = {
  type: INPUT_TYPES.EMPLOYEE_NPS,
  name: 'custom_question_employee_nps_rating',
};

export const ASSESS_ORGANIZATION_EMPLOYEE_NPS_COMMENT = (formatMessage) => ({
  type: INPUT_TYPES.TEXTAREA,
  maxLength: 1000,
  minRows: 3,
  maxRows: 15,
  name: 'custom_question_employee_nps_comment',
  placeholder: formatMessage({
    id: 'app.utils.models.performance.employee_nps_comment.placeholder',
    defaultMessage: 'Add a confidential comment here (optional)',
  }),
});

export const getSelectHighPriorityOnlyPhases = (
  me,
  organization,
  campaign,
  demoPeople,
  formatMessage
) => {
  const phases = getPerformancePhases(
    me,
    organization,
    campaign,
    demoPeople,
    formatMessage
  );
  return phases.map((p) => {
    if (p.type === PHASE_TYPE_SELF) {
      p.steps = filterSelectHighPriorityOnlySteps(p.steps);
    }
    return p;
  });
};

export const filterSelectHighPriorityOnlySteps = (steps) =>
  steps.filter(
    (s) => s.path === PERFORMANCE_STEP_CHOOSE_MUST_HAVE_PEERS().path
  );

export interface SurveyResponseCompletion {
  survey_response_id: number;
  required_answers: number;
  optional_answers: number;
}

export type SurveyResponseCompletionLookup = {
  [key: number]: SurveyResponseCompletion;
};

export type RatingScale = number[];

export interface EngagementCompletion {
  required_questions: number;
  optional_questions: number;
  answers: SurveyResponseCompletion[];
}

export interface CompletionStats {
  enps: EngagementCompletion;
  engagement_survey: EngagementCompletion;
}

export interface OthersPhaseProgress {
  requested: number[];
  declined: number[];
  completed: number[];
  pending: number[];
}

export interface QuestionsBasedStepProgress {
  required_questions: number;
  required_answers: number;
  optional_questions: number;
  optional_answers: number;
}

export interface RetVal {
  others: {
    upward: OthersPhaseProgress;
    peer: OthersPhaseProgress;
  };
  others_alt: {
    upward: OthersPhaseProgress;
    peer: OthersPhaseProgress;
  };
  enps: QuestionsBasedStepProgress;
  engagement_survey: QuestionsBasedStepProgress;
}
/**
 * getOthersPhaseProgress
 *
 * Get status / progress of the given person ("me") in the "others" phase, both
 * "outgoing" (i.e., person was requested to write for others) and "incoming"
 * (i.e., person is receiving feedback from others).
 *
 * Logic includes both "peer" feedback and "upward" feedback (i.e. from direct to
 * their manager))
 *
 * We return an object like:
 *
 * {
 *   "others": { // "from" is me, "to" is the person I need to write feedback for
 *     "upward": {
 *       "requested": <list of person ids>,
 *       "declined":  <list of person ids>,
 *       "completed": <list of person ids>,
 *       "pending":   <list of person ids>,
 *     },
 *     "peer": {
 *       "requested": <list of person ids>,
 *       "declined":  <list of person ids>,
 *       "completed": <list of person ids>,
 *       "pending":   <list of person ids>,
 *     },
 *   },
 *   "others_alt": { // "from" is the person writing feedback for me, "to" is me
 *     "upward": {
 *       "requested": <list of person ids>,
 *       "declined":  <list of person ids>,
 *       "completed": <list of person ids>,
 *       "pending":   <list of person ids>,
 *     },
 *     "peer": {
 *       "requested": <list of person ids>,
 *       "declined":  <list of person ids>,
 *       "completed": <list of person ids>,
 *       "pending":   <list of person ids>,
 *     },
 *   },
 * }
 *
 * if the value is null, then the person is not required to provide it. if the value
 * is an empty list, then the person is required to provide it but hasn't done it.
 * otherwise, the value is a list of person ids
 *
 * N.B. in general, we don't double-check that the current person is participating in the
 * campaign or in the "others" phase. instead, we assume that the caller has done all
 * necessary checking for campaign participation in all data passed to this function,
 * and we just focus here on evaluating logic, assuming that every person is a valid
 * participant in this campaign and phase.
 *
 * @param {*} meAsPerson -- Person info for current user
 * @param {*} relationships -- all relationships from this campaign where "me" is either the from or to person
 * @param {*} surveyResponseLookup -- SurveyResponse map (person.id -> SurveyResponse)
 */
export const getOthersPhaseProgress = (
  meAsPerson,
  relationships,
  completion_stats: CompletionStats,
  surveyResponseLookup,
  enpsResponsesLookup: SurveyResponseCompletionLookup,
  engagementSurveyResponsesLookup: SurveyResponseCompletionLookup,
  campaign
) => {
  const me = surveyResponseLookup[meAsPerson.id];

  const retval: RetVal = {
    others: {
      upward: {
        requested: [],
        declined: [],
        completed: [],
        pending: [],
      },
      peer: {
        requested: [],
        declined: [],
        completed: [],
        pending: [],
      },
    },
    others_alt: {
      upward: {
        requested: [],
        declined: [],
        completed: [],
        pending: [],
      },
      peer: {
        requested: [],
        declined: [],
        completed: [],
        pending: [],
      },
    },
    enps: {
      required_questions: 0,
      required_answers: 0,
      optional_questions: 0,
      optional_answers: 0,
    },
    engagement_survey: {
      required_questions: 0,
      required_answers: 0,
      optional_questions: 0,
      optional_answers: 0,
    },
  };

  if (
    getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED
    )
  ) {
    const enps_stats = completion_stats.enps;
    retval.enps.required_questions = enps_stats.required_questions;
    retval.enps.optional_questions = enps_stats.optional_questions;

    const sr = enpsResponsesLookup[me.id];
    retval.enps.required_answers = sr?.required_answers || 0;
    retval.enps.optional_answers = sr?.optional_answers || 0;
  }

  if (getCampaignFeature(campaign, PERFORMANCE_FEATURE_ASSESS_ORGANIZATION)) {
    const engagement_survey_stats = completion_stats.engagement_survey;
    retval.engagement_survey.required_questions =
      engagement_survey_stats.required_questions;
    retval.engagement_survey.optional_questions =
      engagement_survey_stats.optional_questions;

    const sr = engagementSurveyResponsesLookup[me.id];
    retval.engagement_survey.required_answers = sr?.required_answers || 0;
    retval.engagement_survey.optional_answers = sr?.optional_answers || 0;
  }

  relationships.forEach((r) => {
    if (!r.from_person || !r.to_person) {
      return;
    }

    // upward feedback relationship:
    //    dataset: null
    //    type: REPORTS_TO
    //    from: the direct report who is providing the feedback
    //    to:   the manager
    if (
      r.type === RELATIONSHIP_TYPES.REPORTS_TO ||
      r.type === RELATIONSHIP_TYPES.REPORTS_TO_ADDITIONAL_MANAGER
    ) {
      // outgoing upward feedback
      if (r.from_person.id === me.person.id) {
        if (r.dataset) {
          if (me.configs.is_writing_upward_manager_review !== false) {
            retval.others.upward.requested.push(r.to_person.id);
          }
        } else if (relationshipIsCompleted(r, campaign)) {
          retval.others.upward.completed.push(r.to_person.id);
        }
      }

      // incoming upward feedback
      else if (r.to_person.id === me.person.id) {
        if (r.dataset) {
          const sr = surveyResponseLookup[r.from_person.id];
          if (sr && sr.configs.is_writing_upward_manager_review !== false) {
            retval.others_alt.upward.requested.push(r.from_person.id);
          }
        } else if (relationshipIsCompleted(r, campaign)) {
          retval.others_alt.upward.completed.push(r.from_person.id);
        }
      }
    }

    // peer feedback relationship:
    //    type: IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR
    //    from: person writing the feedback (i.e. "me")
    //    to:   person to receive the feedback
    //    dataset: if truthy, represents the request; if falsy, the feedback
    //    decline_reason: if truthy, request was declined. should only appear on
    //      relationship where dataset is not null (i.e. original request)
    else if (
      r.type === RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR
    ) {
      // outgoing peer feedback
      if (r.from_person.id === me.person.id) {
        if (r.dataset) {
          retval.others.peer.requested.push(r.to_person.id);
          if (r.decline_reason) {
            retval.others.peer.declined.push(r.to_person.id);
          }
        } else if (relationshipIsCompleted(r, campaign)) {
          retval.others.peer.completed.push(r.to_person.id);
        }
      }

      // incoming peer feedback
      else if (r.to_person.id === me.person.id) {
        if (r.dataset) {
          retval.others_alt.peer.requested.push(r.from_person.id);
          if (r.decline_reason) {
            retval.others_alt.peer.declined.push(r.from_person_id);
          }
        } else if (relationshipIsCompleted(r, campaign)) {
          retval.others_alt.peer.completed.push(r.from_person.id);
        }
      }
    }
  });

  const cleanup = (progress): OthersPhaseProgress => {
    const requested: number[] = uniq(progress.requested);
    const declined: number[] = intersection(requested, progress.declined);
    const completed: number[] = intersection(requested, progress.completed);
    const pending: number[] = difference(requested, completed, declined);
    return {
      requested,
      declined,
      completed,
      pending,
    };
  };

  // for the purpose of progress calculation, ignore feedback that was
  // not explicitly required
  Object.keys(retval)
    .filter((k) => k !== 'enps' && k !== 'engagement_survey')
    .forEach((subphase) => {
      Object.keys(retval[subphase]).forEach(
        (key) => (retval[subphase][key] = cleanup(retval[subphase][key]))
      );
    });

  return retval;
};

export const buildCompletionLookup = (
  engagementCompletion: EngagementCompletion
): SurveyResponseCompletionLookup => {
  return engagementCompletion
    ? engagementCompletion.answers.reduce((acc, sr) => {
        acc[sr.survey_response_id] = sr;
        return acc;
      }, {})
    : {};
};

export const getStepCompleted = (
  stepEnabled: boolean,
  q: QuestionsBasedStepProgress,
  phaseCompleted?: boolean
): 0 | 1 => {
  if (!stepEnabled) {
    return 0;
  }

  if (q.required_questions > 0) {
    return q.required_questions === q.required_answers ? 1 : 0;
  }

  return phaseCompleted ? 1 : 0;
};

export const getPerfLearnMorePopover = (
  initialText,
  visibilityText,
  footer
) => {
  return (
    <div>
      {initialText}
      <div className="mt-3">
        <i className="fe fe-eye" />{' '}
        <span className="fw-bold">
          <FormattedMessage
            id="app.utils.models.performance.who_can_see_my_responses.text"
            defaultMessage="Who can see my responses?"
          />
        </span>{' '}
        <br />
        {visibilityText}
        {footer}
      </div>
    </div>
  );
};

const LearnMoreAboutOnaInternal: FC = () => (
  <div className="mt-3 text-muted">
    <span>
      <span>
        <FormattedMessage
          id="app.utils.models.performance.popover.learn_more"
          defaultMessage="<link>Learn more</link> about how your responses are used to reduce bias and identify quiet contributors."
          values={{
            link: (chunks) => (
              <ArticleLink id={ARTICLE_IDS.HOW_ONA_REDUCE_BIAS}>
                {chunks}
              </ArticleLink>
            ),
          }}
        />
      </span>
    </span>
  </div>
);
export const LearnMoreAboutOna: FC = React.memo(LearnMoreAboutOnaInternal);

// list of "special" questions that we default to in the question asking UIs which can
// be overridden by clients
// These all have their own field on a django model.
// KEEP IN SYNC WITH core/admin/api/logic/campaigns.py
export const HARDCODED_QUESTION_NAMES_LIST = [
  'positive_skills',
  'positive_comments',
  'negative_skills',
  'negative_comments',
  'rating',
  'rating_comments',
  'recommend_for_promotion',
  'promotion_comments',
  'recommend_for_salary_increase',
  'salary_increase_comments',
];

export const QUESTION_NAMES_THAT_CANNOT_BE_COPIED_SET = new Set([
  'positive_skills',
  'positive_comments',
  'negative_skills',
  'negative_comments',
  'rating',
  'rating_comments',
]);

export const CUSTOM_QUESTION_NONANONYMOUS_PREFIX = 'custom_';
export const CUSTOM_QUESTION_ANONYMOUS_PREFIX = 'anonymous_';

const addCustomResponseValuesFromPrefix = (responses, formObject, prefix) => {
  for (const field in responses) {
    if (field.startsWith(prefix)) {
      if (typeof formObject[field] !== 'undefined') {
        throw new Error(`Duplicate custom response id: ${field}`);
      }

      formObject[field] = responses[field];
    }
  }
  return formObject;
};

export const addCustomResponseValues = (responses, formObject) => {
  return addCustomResponseValuesFromPrefix(
    responses,
    formObject,
    CUSTOM_QUESTION_NONANONYMOUS_PREFIX
  );
};

export const addAnonymousResponseValues = (responses, formObject) => {
  return addCustomResponseValuesFromPrefix(
    responses,
    formObject,
    CUSTOM_QUESTION_ANONYMOUS_PREFIX
  );
};

const extractCustomResponsesFromPrefix = (formObject, keyPrefix) => {
  return Object.entries(formObject)
    .filter(([key]) => key.startsWith(keyPrefix))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        // if the value is a complex object with an id, extract just the id
        // @ts-expect-error FIXME
        [key]: value?.id ? value.id : value,
      }),
      {}
    );
};

export const extractCustomResponses = (formObject) => {
  return extractCustomResponsesFromPrefix(
    formObject,
    CUSTOM_QUESTION_NONANONYMOUS_PREFIX
  );
};

export const extractAnonymousResponses = (formObject) => {
  return extractCustomResponsesFromPrefix(
    formObject,
    CUSTOM_QUESTION_ANONYMOUS_PREFIX
  );
};

// NOTE: we define LIKERT_SCALE_AS_ARRAY in
// LikertScaleInput.js to avoid circular dependency
// issues
export const LIKERT_SCALE = {
  1: {
    text: 'Strongly disagree',
    color: 'bg-danger-soft text-danger',
  },
  2: {
    text: 'Disagree',
    color: 'bg-warning-soft text-warning',
  },
  3: {
    text: 'Neither agree nor disagree',
    color: 'bg-light-soft text-muted',
  },
  4: {
    text: 'Agree',
    color: 'bg-success-soft text-success',
  },
  5: {
    text: 'Strongly agree',
    color: 'bg-success-soft text-success',
  },
};

/**
 * Returns if a phase is complete according to settings in the survey response configs.
 */
export const getPhaseIsComplete = (phaseType, surveyResponse) => {
  const name = PHASE_NAMES[phaseType];
  if (
    surveyResponse?.configs &&
    `phase_${name}_complete` in (surveyResponse?.configs ?? {})
  ) {
    return !!surveyResponse?.configs[`phase_${name}_complete`];
  }
  return false;
};

export const isParticipatingInPhase = (phaseType, surveyResponse) => {
  const name = PHASE_NAMES[phaseType];
  const key = `is_participating_in_${name}_phase`;
  if (surveyResponse?.configs && key in (surveyResponse?.configs ?? {})) {
    return !!surveyResponse?.configs[key];
  }
  return true;
};

const noResponseElement = (
  <span className="fst-italic">
    <FormattedMessage
      id="app.utils.models.performance.no_response.text"
      defaultMessage="No response"
    />
  </span>
);

// Determine correct response to show (ie. Show text + value for
// multiple choice questions)
export const parseAndFormatQuestionResponse = ({
  q,
  noneElement = noResponseElement,
  formatForCsv = false,
  locale = 'en-US',
  formatMessage,
}) => {
  const question = q?.question;
  const questionType = q.question?.type?.toUpperCase();
  const response = q?.response;

  if (
    questionType === INPUT_TYPES.RICH_TEXT_EDITOR ||
    questionType === INPUT_TYPES.MENTION_EDITOR
  ) {
    return response ? (
      <RichTextViewer model={response} expanded={true} />
    ) : (
      noneElement
    );
  }

  if (
    questionType === INPUT_TYPES.SWITCH ||
    questionType === INPUT_TYPES.TOGGLE
  ) {
    return response
      ? formatMessage({
          id: 'app.utils.models.response.yes',
          defaultMessage: 'Yes',
        })
      : formatMessage({
          id: 'app.utils.models.response.no',
          defaultMessage: 'No',
        });
  }

  if (questionType === INPUT_TYPES.DROPDOWN) {
    if (question?.objects) {
      const selectedChoice = question?.objects.find(
        (choice) => String(choice?.id) === response
      );
      if (selectedChoice?.name) {
        return selectedChoice?.name ?? noneElement;
      }
    }
  }

  if (questionType === INPUT_TYPES.SELECT) {
    if (question?.options) {
      const selectedChoice = question?.options.find(
        (choice) => choice?.name === response || choice?.id === response
      );
      if (selectedChoice?.name) {
        return selectedChoice?.name ?? noneElement;
      }
    }
  }

  if (questionType === INPUT_TYPES.MULTIPLE_CHOICE) {
    if (question?.options) {
      const selectedChoice = question?.options.find(
        (choice) => String(choice?.id) === response
      );
      if (selectedChoice?.name) {
        return selectedChoice?.name ?? noneElement;
      }
    }
  }

  if (questionType === INPUT_TYPES.DATE_PICKER) {
    return response
      ? getPrettyDate({ dateString: response, locale })
      : noneElement;
  }

  if (questionType === INPUT_TYPES.PEOPLE_EDITOR) {
    return response ? (
      Array.isArray(response) ? (
        formatForCsv ? (
          response?.map((p) => p.full_name).join(', ')
        ) : (
          <PersonCardList people={response} />
        )
      ) : formatForCsv ? (
        response?.full_name
      ) : (
        <Avatar size="xs" person={response} />
      )
    ) : (
      noneElement
    );
  }

  if (questionType === INPUT_TYPES.LIKERT) {
    let hasAnyOutput = false;

    // NOTE: we have different behavior for single-question likerts vs
    // multi because if we want to, for example, ask the rating question
    // as likert, we want to save just the value instead of an object.
    // Specifically, single-question, we store just the value, whereas
    // multi, we store the objects.
    const likertQuestionHasLabel = question?.label?.length > 0;
    const hasMultipleValues = question?.objects?.length > 1;

    const output = question?.objects?.map((likertQuestion, index) => {
      // if hasMultipleValues, then response is a dict, else it is the value
      // of the one question
      const responseValue =
        hasMultipleValues &&
        typeof response?.[likertQuestion.name] !== 'undefined'
          ? response?.[likertQuestion.name]
          : response;

      if (typeof responseValue === 'undefined') {
        return noneElement;
      }

      // use scale provided in question, else default to default scale
      const outputString =
        question.scale?.find((s) => s.value === responseValue)?.name ??
        LIKERT_SCALE[responseValue]?.text;

      if (outputString) {
        hasAnyOutput = true;
      }

      // output question then response each on new line
      return (
        <div key={index} className={index === 0 ? '' : 'mt-3'}>
          {(hasMultipleValues || likertQuestionHasLabel) && (
            <div className="fw-bold">{likertQuestion.value}</div>
          )}
          <div>{outputString ?? noneElement}</div>
        </div>
      );
    });

    if (!hasAnyOutput) {
      return noneElement;
    }

    return <div>{output}</div>;
  }

  if (
    questionType === INPUT_TYPES.SKILLS ||
    question?.name === 'positive_skills' ||
    question?.name === 'negative_skills'
  ) {
    // format skills as comma-separated list taken from
    // top level surveyResponse object (for positive_skills and negative_skills)
    return response?.length > 0 ? (
      <TagsList skills={response} isExternalUrl={true} />
    ) : (
      noneElement
    );
  }

  // TODO: add support for autosuggest, tags input, people editor, places input

  // default to just showing the response
  return response ?? noneElement;
};

export const DEFAULT_AUTO_CALIBRATION_RULES = [
  {
    template:
      '{name} has gone {direction} {delta} or more rating levels since last cycle.',
    condition: {
      type: 'delta',
      delta: 2,
      direction: 'down',
    },
    period: {
      type: 'cycles',
      duration: 2,
    },
  },
  {
    template:
      '{name} has gone {direction} {delta} or more rating levels since last cycle.',
    condition: {
      type: 'delta',
      delta: 2,
      direction: 'up',
    },
    period: {
      type: 'cycles',
      duration: 2,
    },
  },
  {
    template:
      '{name} has less than 1 year of tenure but received a {target} rating.',
    condition: {
      type: 'event',
      event: 'hired',
      target: 'positive',
    },
    period: {
      type: 'years',
      duration: 1,
    },
  },
  {
    template:
      '{name} had a role transfer last cycle but is already receiving a {target} rating.',
    condition: {
      type: 'event',
      event: 'role_change',
      target: 'positive',
    },
    period: {
      type: 'cycles',
      duration: 1,
    },
  },
  {
    template:
      '{name} was promoted last cycle but is already receiving a {target} rating.',
    condition: {
      type: 'event',
      event: 'promotion',
      target: 'positive',
    },
    period: {
      type: 'cycles',
      duration: 1,
    },
  },
  {
    template:
      '{name} got a {target} rating but also received a heads up from the network.',
    condition: {
      type: 'ona',
      ona: ['heads_ups'],
      threshold: 1,
      target: 'positive',
    },
    period: {
      type: 'cycles',
      duration: 1,
    },
  },
  {
    template:
      '{name} got a {target} rating but also received two or more gold stars from the network.',
    condition: {
      type: 'ona',
      ona: ['gold_stars'],
      threshold: 2,
      target: 'negative',
    },
    period: {
      type: 'cycles',
      duration: 1,
    },
  },
  {
    template: '{name} received two consecutive {target} ratings.',
    condition: {
      type: 'consecutive',
      target: 'negative',
    },
    period: {
      type: 'cycles',
      duration: 2,
    },
  },
];

export const getAccomplishmentQuestionText = ({
  formatMessage,
  minimumAccomplishments,
  maximumAccomplishments,
  campaignDuration,
}) => {
  if (minimumAccomplishments === maximumAccomplishments) {
    return minimumAccomplishments == 1
      ? formatMessage(
          {
            id: 'app.utils.models.performance.accomplishment_question_text_single',
            defaultMessage: `List your top accomplishment that impacted your team's goals or key initiatives from the past {campaignDuration}.`,
          },
          { campaignDuration }
        )
      : formatMessage(
          {
            id: 'app.utils.models.performance.accomplishment_question_text_plural',
            defaultMessage: `List your top {minimumAccomplishments} accomplishments that impacted your team's goals or key initiatives from the past {campaignDuration}.`,
          },
          { campaignDuration, minimumAccomplishments }
        );
  }

  if (maximumAccomplishments) {
    return formatMessage(
      {
        id: 'app.utils.models.performance.accomplishment_question_text_range',
        defaultMessage: `List your top {minimumAccomplishments}-{maximumAccomplishments} accomplishments that impacted your team's goals or key initiatives from the past {campaignDuration}.`,
      },
      { minimumAccomplishments, maximumAccomplishments, campaignDuration }
    );
  }

  return formatMessage(
    {
      id: 'app.utils.models.performance.accomplishment_question_text_nomax',
      defaultMessage: `List at least {minimumAccomplishments} accomplishments that impacted your team's goals or key initiatives from the past {campaignDuration}.`,
    },
    { minimumAccomplishments, campaignDuration }
  );
};

export const getCampaignAutoCalibrationRules = (campaign) => {
  for (const phase of campaign.phases) {
    if (phase.auto_calibration_rules) {
      return phase.auto_calibration_rules;
    }
  }
  return DEFAULT_AUTO_CALIBRATION_RULES;
};

export const isONAEnabled = (campaign) =>
  getPhase(campaign, PHASE_TYPE_SELF) &&
  (!getCampaignHasFeatureEnabled(
    campaign,
    PERFORMANCE_FEATURE_DISABLE_NETWORK
  ) ||
    !getCampaignHasFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_DISABLE_CALLOUTS
    ));

export const isEngagementSurveyOnlyCampaign = (campaign) => {
  const hasOnlyOthersPhase =
    campaign.phases.length === 1 &&
    campaign.phases[0].type == PHASE_TYPE_OTHERS;

  if (!hasOnlyOthersPhase) return false;

  const shouldAssessManager = getCampaignFeature(
    campaign,
    PERFORMANCE_FEATURE_ASSESS_MANAGER
  );

  const shouldAssessPeers =
    getCampaignFeature(campaign, PERFORMANCE_FEATURE_PEER_FEEDBACK) ||
    getCampaignFeature(
      campaign,
      PERFORMANCE_FEATURE_ADD_OPTIONAL_PEER_FEEDBACK
    );

  const shouldAssessOrg = getCampaignFeature(
    campaign,
    PERFORMANCE_FEATURE_ASSESS_ORGANIZATION
  );

  const shouldCompleteEnps = getCampaignFeature(
    campaign,
    PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED
  );

  return (
    !(shouldAssessManager || shouldAssessPeers) &&
    (shouldAssessOrg || shouldCompleteEnps)
  );
};

const onaCalloutsEnabled = (campaign: Campaign): boolean =>
  !(
    getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_DISABLE_CALLOUTS
    ) ?? false
  );

const onaNetworkEnabled = (campaign: Campaign): boolean =>
  !(
    getPerformanceFeatureEnabled(
      campaign,
      PERFORMANCE_FEATURE_DISABLE_NETWORK
    ) ?? false
  );

export const onaIsEnabled = (campaign: Campaign): boolean =>
  onaCalloutsEnabled(campaign) || onaNetworkEnabled(campaign);

export const peer360Enabled = (campaign: Campaign): boolean =>
  getCampaignHasFeatureEnabled(campaign, PERFORMANCE_FEATURE_PEER_FEEDBACK) ??
  false;

export const upwardFeedbackEnabled = (campaign: Campaign): boolean =>
  getCampaignHasFeatureEnabled(campaign, PERFORMANCE_FEATURE_ASSESS_MANAGER) ??
  false;
export const WIZARD_TYPE_KEY = 'wizard_type';

export const isWizardType = (campaign: Campaign, wizardType: string): boolean =>
  getWizardType(campaign) === wizardType;
