import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useRef } from 'react';

import { Button } from 'reactstrap';
import { Objective } from '../../utils/models/Objective';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';

interface Props {
  isEditable?: boolean;
  setShowChildren?: (boolean) => void;
  setShowDeleteModal: (boolean) => void;
  addChild: (Objective) => void;
  value: Objective;
  setValue: (any) => void;
}

const ObjectiveItemAddDeleteControls: FC<Props> = ({
  isEditable,
  setShowChildren,
  setShowDeleteModal,
  addChild,
  value,
  setValue,
}) => {
  const { formatMessage } = useIntl();
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const deleteButtonRef = useRef<HTMLButtonElement>(null);

  const output = (
    <>
      {isEditable && (
        <>
          <Button
            size="sm"
            color="secondary"
            id={`add-child-button-${value.key}`}
            aria-label={formatMessage({
              id: 'app.views.person.personal_objective_item.add_child.button.aria_label',
              defaultMessage: 'Add subobjective button',
            })}
            outline
            onClick={() => addChild({ value, setValue, setShowChildren })}
            innerRef={addButtonRef}
          >
            <i className="fe fe-plus" />
          </Button>
          <UncontrolledPopover
            placement="top"
            trigger="hover"
            target={addButtonRef}
          >
            <FormattedMessage
              id="app.views.person.personal_objective_item.add_child"
              defaultMessage="Add a subobjective"
            />
          </UncontrolledPopover>
          <Button
            size="sm"
            color="secondary"
            id={`delete-button-${value.key}`}
            aria-label={formatMessage({
              id: 'app.views.person.personal_objective_item.delete.button.aria_label',
              defaultMessage: 'Delete button',
            })}
            outline
            onClick={() => {
              setShowDeleteModal(true);
            }}
            innerRef={deleteButtonRef}
          >
            <i className="fe fe-trash" />
          </Button>
          <UncontrolledPopover
            placement="top"
            trigger="hover"
            target={deleteButtonRef}
          >
            <FormattedMessage
              id="app.views.person.personal_objective_item.delete"
              defaultMessage="Delete"
            />
          </UncontrolledPopover>
        </>
      )}
    </>
  );
  return output;
};

export default ObjectiveItemAddDeleteControls;
