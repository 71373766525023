// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/file.min.css';
import 'froala-editor/css/plugins/image.min.css';
import 'froala-editor/css/plugins/table.min.css';
import 'froala-editor/css/plugins/video.min.css';
// Import Froala plugins
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';

import * as consts from '../../../consts/consts';

import { Button, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AUTH0_PARAMS } from '../../../utils/api/AuthProvider';
import CardHeaderTitle from '../Cards/CardHeaderTitle';
import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Modals/ConfirmationDialogModal';
import ElasticsearchAPI from '../../../utils/api/ElasticsearchAPI';
import EmptyState from '../EmptyState';
// eslint-disable-next-line import/default
import FroalaEditor from 'react-froala-wysiwyg';
import Loading from '../Loading';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import RichTextViewer from './RichTextViewer';
import TestRichTextEditor from '../TestRichTextEditor';
// note: Tribute implemented with Froala via tips from:
// https://github.com/froala/react-froala-wysiwyg/issues/189
import Tribute from 'tributejs';
import { configForLocale } from '../../../locale/messages';
import { connect } from 'react-redux';
import { redirectLoginOptionsGenerator } from '../../../utils/util/utiltsx';
import { renderSuggestion } from './ValidatedAutosuggest';
import { renderToStaticMarkup } from 'react-dom/server';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { withSelfRecoveryErrorBoundary } from 'views/Error/ErrorBoundary';

const TIME_TO_WAIT_TO_AUTOSAVE_IN_MS = 2000;

const fileUploadUrl =
  process.env.REACT_APP_CONFIRM_API_URL + '/files/activity-file';
const imageAndVideoUploadUrl =
  process.env.REACT_APP_CONFIRM_API_URL + '/files/activity-image';

const getTributeOptions = (
  intl,
  currentProxyPerson,
  userSub,
  currentOrgId,
  onMention,
  mentionSearchUrl
) => {
  const { formatMessage } = intl;
  const mentionString = formatMessage({
    id: 'app.widgets.richtexteditor.mention.placeholder',
    defaultMessage: 'Mention a person, activity, skill, etc.',
  });

  return {
    // template for when no match is found (optional),
    // If no template is provided, menu is hidden.
    noMatchTemplate: function () {
      return `<div class="text-muted px-3 py-2">${mentionString}</div>`;
    },

    // When your values function is async, an optional loading template to show
    loadingItemTemplate:
      '<div class="text-muted px-3 py-2">${mentionString}</div>',

    // config options found here: https://github.com/zurb/tribute
    collection: [
      {
        // symbol or string that starts the lookup
        trigger: '@',

        // element to target for @mentions
        iframe: null,

        // class added in the flyout menu for active item
        selectClass: 'is-active',

        // class added to the menu container
        containerClass: 'tribute-container',

        // class added to each list item
        itemClass: '',

        // function called on select that returns the content to insert
        selectTemplate: function (item) {
          const hit = item.original;

          // callback to indicate something was selected
          if (onMention) {
            onMention(hit);
          }

          return (
            '<a href="' +
            hit.url +
            '">' +
            hit.name.replace(/<[^>]*>?/gm, '') +
            '</a>'
          );
        },

        // template for displaying item in menu
        menuItemTemplate: function (item) {
          const element = renderSuggestion(intl, item.original);
          return renderToStaticMarkup(element);
        },

        // specify an alternative parent container for the menu
        // container must be a positioned element for the menu to appear correctly ie. `position: relative;`
        // default container is the body
        menuContainer: document.body,

        // column to search against in the object (accepts function or string)
        lookup: (item) => {
          return item.name;
        },

        // column that contains the content to insert by default
        fillAttr: 'value',

        // REQUIRED: array of objects to match or a function that returns data (see 'Loading remote data' for an example)
        values: function (text, cb) {
          if (!text) {
            return cb([]);
          }

          ElasticsearchAPI.search(
            // only cache if no query provided (so we don't take up too much
            // memory story search results as searches are done)
            text ? undefined : userSub,
            currentProxyPerson,
            currentOrgId,
            mentionSearchUrl,
            text ? { name: text } : {},
            (hits) => {
              const objectList = hits.map(
                ElasticsearchAPI.defaultSelectorMapFunction
              );
              return cb(objectList);
            },
            (error) => {
              console.error('RichTextEditor error: ' + JSON.stringify(error));
              return cb([]);
            }
          );
        },

        // specify whether a space is required before the trigger string
        requireLeadingSpace: true,

        // specify whether a space is allowed in the middle of mentions
        allowSpaces: false,

        // optionally specify a custom suffix for the replace text
        // (defaults to empty space if undefined)
        replaceTextSuffix: '\n',

        // specify whether the menu should be positioned.  Set to false and use in conjuction with menuContainer to create an inline menu
        // (defaults to true)
        positionMenu: true,

        // when the spacebar is hit, select the current match
        spaceSelectsMatch: false,

        // turn tribute into an autocomplete
        autocompleteMode: false,

        // Customize the elements used to wrap matched strings within the results list
        // defaults to <span></span> if undefined
        searchOpts: {
          pre: '<span>',
          post: '</span>',
          skip: false, // true will skip local search, useful if doing server-side search
        },

        // Limits the number of items in the menu
        menuItemLimit: 5,

        // specify the minimum number of characters that must be typed before menu appears
        menuShowMinLength: 0,
      },
    ],
  };
};

const getFileErrorMessage = (error, response) => {
  console.error(
    'File error: ' +
      JSON.stringify(error) +
      ' - response: ' +
      JSON.stringify(response)
  );

  if (error.code === 1) {
    return 'Bad link';
  } else if (error.code === 2) {
    return 'No link in upload response';
  } else if (error.code === 3) {
    try {
      const parsedResponse = JSON.parse(response);
      if (parsedResponse?.type === 'invalid_file_type') {
        return parsedResponse.message;
      }
    } catch (e) {
      // do nothing
    }
    return 'Error during file upload';
  } else if (error.code === 4) {
    return 'Parsing response failed';
  } else if (error.code === 5) {
    return 'File too large';
  } else if (error.code === 6) {
    return 'Invalid file type';
  } else if (error.code === 7) {
    return 'File can be uploaded only to same domain in Internet Explorer 8 and 9';
  }
};

const getImageErrorMessage = (error, response) => {
  console.error(
    'Image error: ' +
      JSON.stringify(error) +
      ' - response: ' +
      JSON.stringify(response)
  );

  if (error.code === 1) {
    return 'Bad link';
  } else if (error.code === 2) {
    return 'No link in upload response';
  } else if (error.code === 3) {
    return 'Error during image upload';
  } else if (error.code === 4) {
    return 'Parsing response failed';
  } else if (error.code === 5) {
    return 'Image too large';
  } else if (error.code === 6) {
    return 'Invalid image type';
  } else if (error.code === 7) {
    return 'Image can be uploaded only to same domain in Internet Explorer 8 and 9';
  }
};

const getVideoErrorMessage = (error, response) => {
  console.error(
    'Video error: ' +
      JSON.stringify(error) +
      ' - response: ' +
      JSON.stringify(response)
  );

  if (error.code === 1) {
    return 'Bad link';
  } else if (error.code === 2) {
    return 'No link in upload response';
  } else if (error.code === 3) {
    return 'Error during video upload';
  } else if (error.code === 4) {
    return 'Parsing response failed';
  } else if (error.code === 5) {
    return 'Video too large';
  } else if (error.code === 6) {
    return 'Invalid video type';
  } else if (error.code === 7) {
    return 'Video can be uploaded only to same domain in Internet Explorer 8 and 9';
  }
};

const DEFAULT_EVENTS = {};
const DEFAULT_CONFIG = {};

const FroalaEditorWithErrorBoundary =
  withSelfRecoveryErrorBoundary(FroalaEditor);

const RichTextEditor: FC<Props> = ({
  autoSave: propsAutosave = false,
  inForm = false,
  value: propsValue = '',
  defaultValue: propsDefaultValue = '',
  showToolbar = true,
  events: propsEvents = DEFAULT_EVENTS,
  config: propsConfig = DEFAULT_CONFIG,
  autoFocus = false,
  mentionSearchUrl = 'get-all-by-name',
  emptyStateTitle = 'No description',
  emptyStateSubtitle = 'Please provide a description.',
  ...props
}) => {
  const intl = useIntl();
  const { formatMessage, locale } = intl;
  const SAVING_STATUS_INDICATOR = useMemo(
    () => (
      <span>
        <i
          className="spinner-border"
          style={{
            width: '0.8rem',
            height: '0.8rem',
            position: 'relative',
            top: '-3px',
          }}
        />
        <span className="ms-2">
          <FormattedMessage
            id="app.views.widgets.inputs.rich_text_editor.saving"
            defaultMessage="Saving..."
          />
        </span>
      </span>
    ),
    []
  );

  const SAVED_STATUS_INDICATOR = useMemo(
    () => (
      <span>
        <i className="fe fe-check pe-2" />
        <FormattedMessage
          id="app.views.widgets.inputs.rich_text_editor.saved"
          defaultMessage="Saved"
        />
      </span>
    ),
    []
  );

  const ref = useRef({ editor: null });
  const [isFroalaInitialized, setIsFroalaInitialized] = useState(false);
  const [tributeIsSet, setTributeIsSet] = useState(false);
  const [model, setModel] = useState(propsValue);
  const [modelHasChanged, setModelHasChanged] = useState(false);
  const [autoSaveStatus, setAutoSaveStatus] = useState(SAVED_STATUS_INDICATOR);
  const [userClickedEdit, setUserClickedEdit] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(propsAutosave);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmDiscardModal, setConfirmDiscardModal] = useState(false);
  const [confirmResetModal, setConfirmResetModal] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);
  const toggleConfirmDiscardModal = () =>
    setConfirmDiscardModal(!confirmDiscardModal);
  const toggleConfirmResetModal = () =>
    setConfirmResetModal(!confirmResetModal);
  const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const location = useLocation();

  const objectId = props.saveId;
  const propsMethod = props.method;
  const propsName = props.name;
  const propsUrl = props.url;
  const propsCallback = props.callback;
  const propsOnChange = props.onChange;
  const propsPreSubmit = props.preSubmit;
  const onMention = props.onMention;
  const userSub = user?.sub;
  const currentOrgId = props.currentOrganizationId;
  const propsOnValidChange = props.onValidChange;
  const propsRequired = props.required;

  const leavePromptMessage = consts.UNSAVED_CHANGES_PROMPT;

  const onUnload = useCallback(
    (e) => {
      if (!props.disableUnsavedChangesPrompt && modelHasChanged) {
        e.preventDefault();
        e.returnValue = leavePromptMessage;
        return leavePromptMessage;
      }
    },
    [props.disableUnsavedChangesPrompt, modelHasChanged, leavePromptMessage]
  );

  const isSignup = location.pathname === '/signup';

  // update access token for file/image uploading as close to actually sending as possible
  // to ensure that if someone leaves and comes back after the token expires,
  // the save will work (NOTE: we update this whenever the model changes to ensure
  // this is ALWAYS up to date)
  useEffect(() => {
    (async () => {
      let token = null;
      try {
        // @ts-expect-error
        token = await getAccessTokenSilently(AUTH0_PARAMS);
      } catch (e) {
        console.debug(e);
        // @ts-expect-error
        if (e.error === 'login_required' || e.error === 'consent_required') {
          // @ts-expect-error
          loginWithRedirect(redirectLoginOptionsGenerator(location, isSignup));
        }
        throw e;
      }

      setAccessToken(token);
    })();
  }, [model, getAccessTokenSilently, loginWithRedirect, location, isSignup]);

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, [modelHasChanged, onUnload]);

  // update screen when object changes (e.g. on save call, ensure the saved version shows immediately)
  useEffect(() => {
    setModel(propsValue);
  }, [propsValue]);

  const autosaveTimeoutId = useRef(null);

  const resetTextEditor = useCallback(() => {
    setModel(propsValue);
    setIsInEditMode(propsAutosave);
    setModelHasChanged(false);
  }, [propsAutosave, propsValue]);

  const onSubmitCallback = useCallback(
    (data, error, hardErrorMessage = null) => {
      setIsSubmitting(false);

      if (error) {
        // failure; keep modal open
        if (hardErrorMessage) {
          // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
          // leave this message blank as those errors will get surfaced below
          toast.error(hardErrorMessage);
        }

        if (typeof propsCallback !== 'undefined') {
          // @ts-expect-error
          propsCallback(null, error, hardErrorMessage);
        }
      } else {
        if (propsAutosave) {
          setAutoSaveStatus(SAVED_STATUS_INDICATOR);
          setModelHasChanged(false);
        } else {
          toast.success(
            formatMessage({
              id: 'app.views.widgets.inputs.rich_text_editor.toast.saved',
              defaultMessage: 'Saved!',
            })
          );
          resetTextEditor();
        }

        if (typeof propsCallback !== 'undefined') {
          // @ts-expect-error
          propsCallback(data);
        }
      }
    },
    [
      SAVED_STATUS_INDICATOR,
      propsAutosave,
      propsCallback,
      resetTextEditor,
      formatMessage,
    ]
  );

  const preSubmit = useCallback(() => {
    if (typeof propsPreSubmit !== 'undefined') {
      // @ts-expect-error
      propsPreSubmit();
    }
  }, [propsPreSubmit]);

  const onSave = useCallback(
    (overrideModel) => {
      const objectToSend = {
        id: objectId,
      };

      // save model if passed in manually (for autosaving)
      objectToSend[propsName] =
        propsAutosave && typeof overrideModel === 'string'
          ? overrideModel
          : model;

      if (!isSubmitting) {
        setIsSubmitting(true);
        // if no url provided, this is a frontend-only person widget
        if (propsUrl) {
          const method = propsMethod ? propsMethod : 'POST';
          const url =
            method === 'PATCH' ? propsUrl + '/' + objectToSend.id : propsUrl;
          const object =
            method === 'PATCH'
              ? { ...objectToSend, id: undefined }
              : objectToSend;
          ConfirmAPI.sendRequestToConfirm(
            method,
            url,
            object,
            onSubmitCallback,
            preSubmit
          );
        } else {
          // frontend-only, so just send the object
          if (typeof propsCallback !== 'undefined') {
            // @ts-expect-error
            propsCallback(objectToSend);
          }
        }
      }
    },
    [
      objectId,
      propsName,
      propsAutosave,
      model,
      isSubmitting,
      propsUrl,
      propsMethod,
      onSubmitCallback,
      preSubmit,
      propsCallback,
    ]
  );

  useEffect(() => {
    if (propsRequired) {
      const isValid =
        typeof model !== 'undefined' && model !== null && model !== '';
      // @ts-expect-error
      setValidationMessage(isValid ? null : 'Please enter some text.');
    } else {
      setValidationMessage(null);
    }
  }, [model, propsRequired]);

  useEffect(() => {
    if (propsOnValidChange) {
      propsOnValidChange(validationMessage);
    }
  }, [validationMessage, propsOnValidChange]);

  const onModelChange = useCallback(
    (updatedModel) => {
      if (!isFroalaInitialized) {
        // if string is passed in, this will get called before the editor is initialized, so skip for now
        return;
      }

      // only changed model object if it actually changes
      // (so we avoid calling onChange if no change is made; this ensures that setHasUnsavedChanges
      // doesn't get set to true erroneously)
      if (model !== updatedModel) {
        // NOTE: this is called in a delay manner for performance,
        // so any immediate detection should happen in the keydown event handler in the Froala config
        setModel(updatedModel);
        if (propsOnChange) {
          propsOnChange(updatedModel);
        }

        if (propsAutosave) {
          // set short timer (so we don't save EVERY keystroke)
          // and when reached, try auto-saving
          if (autosaveTimeoutId?.current) {
            clearTimeout(autosaveTimeoutId.current);
          }

          // save objective to backend
          setAutoSaveStatus(SAVING_STATUS_INDICATOR);

          // @ts-expect-error
          autosaveTimeoutId.current = setTimeout(() => {
            onSave(updatedModel);
          }, TIME_TO_WAIT_TO_AUTOSAVE_IN_MS);
        }
      }
    },
    [
      isFroalaInitialized,
      model,
      propsOnChange,
      propsAutosave,
      SAVING_STATUS_INDICATOR,
      onSave,
    ]
  );

  // Do after initialization
  const propsOnKeyDown = props.onKeyDown;
  const propsOnInput = props.onInput;

  useEffect(() => {
    // NOTE: onMention is NOT required (e.g. in ActivityPage, it's not needed/used)
    if (
      userSub &&
      isFroalaInitialized &&
      // @ts-expect-error
      ref?.current?.editor?.data &&
      mentionSearchUrl &&
      currentOrgId &&
      !tributeIsSet
    ) {
      const editor = ref.current.editor;

      //ref.current.editor.data._init = null;
      const tribute = new Tribute(
        getTributeOptions(
          intl,
          props.currentProxyPerson,
          userSub,
          currentOrgId,
          onMention,
          mentionSearchUrl
        )
      );
      // @ts-expect-error
      tribute.attach(editor.el);
      setTributeIsSet(true);

      // @ts-expect-error
      editor.events.on(
        'keydown',
        function (e) {
          // if keydown callback provided, call that
          if (propsOnKeyDown) {
            propsOnKeyDown(e);
          }

          // ENTER
          if (e.which === 13 && tribute.isActive) {
            return false;
          }
        },
        true
      );

      // this is immediate (unlike onModelChange) so is useful when you need
      // to detect/display changes immediately
      // @ts-expect-error
      editor.events.on('input', function () {
        if (propsOnInput) {
          // @ts-expect-error
          propsOnInput(ref.current.editor.html.get());
        }
      });
    }
  }, [
    userSub,
    isFroalaInitialized,
    ref,
    mentionSearchUrl,
    onMention,
    currentOrgId,
    tributeIsSet,
    propsOnKeyDown,
    props.currentProxyPerson,
    propsOnInput,
    intl,
  ]);

  useEffect(() => {
    if (isFroalaInitialized) {
      const editor = ref?.current?.editor;
      if (editor) {
        if (props.disabled) {
          // @ts-expect-error
          editor.edit.off();
        } else {
          // @ts-expect-error
          editor.edit.on();
        }
      }
    }
  }, [ref, props.disabled, isFroalaInitialized]);

  const enableEditMode = useCallback(() => {
    setUserClickedEdit(true);

    // if no model, set to default value if one was provided
    // @ts-expect-error
    if (!(propsValue?.length > 0)) {
      setModel(propsDefaultValue);
    }

    setIsInEditMode(true);
  }, [propsValue, propsDefaultValue]);

  useEffect(() => {
    if (propsAutosave) {
      // configure anything (e.g. default values)
      enableEditMode();
    }
  }, [enableEditMode, propsAutosave]);

  const config = useMemo(() => {
    // this is a boolean option to the Froala editor that enables the character counter if set.
    // logic: if charCounterCount is explicitly set, use it. otherwise show the counter if
    // charCounterMax (max size of edit buffer) is explicitly set
    const charCounterCount =
      // @ts-expect-error
      propsConfig?.charCounterCount !== undefined
        ? // @ts-expect-error
          propsConfig.charCounterCount
        : // @ts-expect-error
          propsConfig?.charCounterMax !== undefined;
    return {
      language: configForLocale(locale).richTextEditor,
      editorClass: inForm ? props.className : undefined,
      // TODO: instead of 32 use calculated value
      heightMin: props.minRows ? props.minRows * 32 : undefined,
      charCounterCount: charCounterCount,
      attribution: false,
      autofocus: autoFocus,
      // default to adding a new line to not putting in paragraphs but instead
      // adding line breaks (so it looks single-spaced)
      enter: 2, // HACK: this equals ENTER_BR (using FroalaEditor.ENTER_BR makes content have <undefined> tags))
      pastePlain: true,
      fileUpload: true,
      fileUploadParam: 'file',
      fileUploadParams: {
        organization: currentOrgId,
      },
      fileUploadURL: fileUploadUrl,
      fileUploadMethod: 'PUT',
      fileUploadRemoteUrls: true,
      fileMaxSize: 20 * 1024 * 1024, // Set max file size to 20MB.
      fileAllowedTypes: ['*'],
      // TODO: FILENAME needs to change based on file submitted
      // TODO: integrate IMAGE MANAGER TOO FOR DELETE =>
      // see https://froala.com/wysiwyg-editor/docs/concepts/image/manager/
      // NOTE: response.document.file contains file with success
      // TODO: host custom proxy server for reading images inserted by URL
      // and add imageCORSProxy below - see https://github.com/Rob--W/cors-anywhere
      imageEditButtons: [
        'imageDisplay',
        'imageAlign',
        'imageCaption',
        'imageAlt',
        'imageRemove',
      ],
      imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'webp'],
      imageManagerDeleteMethod: 'DELETE',
      imageManagerDeleteURL: imageAndVideoUploadUrl,
      imageMaxSize: 5 * 1024 * 1024, // Set max image size to 5MB.
      imageUpload: true,
      imageUploadMethod: 'PUT',
      imageUploadParam: 'file',
      imageUploadParams: {
        organization: currentOrgId,
      },
      imageUploadRemoteUrls: true,
      imageUploadURL: imageAndVideoUploadUrl,
      videoAllowedTypes: ['mp4', 'webm', 'ogg', 'quicktime'],
      videoManagerDeleteMethod: 'DELETE',
      videoManagerDeleteURL: imageAndVideoUploadUrl,
      videoMaxSize: 20 * 1024 * 1024, // Set video max size to 20MB.
      videoUpload: true,
      videoUploadMethod: 'PUT',
      videoUploadParam: 'file',
      videoUploadParams: {
        organization: currentOrgId,
      },
      videoUploadRemoteUrls: true,
      videoUploadURL: imageAndVideoUploadUrl,
      listAdvancedTypes: false,
      // ensure links always open new window
      linkAlwaysBlank: true,
      // this will prevent issues where content overflows and style is otherwise
      // messed up when someone pastes content from a word processor
      pasteDeniedAttrs: ['class', 'id', 'style', 'aria-level', 'dir'],
      // NOTE: per https://github.com/froala/react-froala-wysiwyg/issues/65 updating placeholder
      // text will not update an already instantiated Froala instance; need to use "key" to reinstantiate per
      // https://stackoverflow.com/questions/52260258/reactjs-destroy-old-component-instance-and-create-new
      placeholderText: props.placeholder,
      requestHeaders: {
        Authorization: 'Bearer ' + accessToken,
      },
      requestWithCORS: true,
      events: {
        initialized: () => {
          setIsFroalaInitialized(true);
        },
        'file.error': (error, response) => {
          toast.error(getFileErrorMessage(error, response));
        },
        'image.error': (error) => {
          // @ts-expect-error
          toast.error(getImageErrorMessage(error));
        },
        'video.error': (error) => {
          // @ts-expect-error
          toast.error(getVideoErrorMessage(error));
        },
        keydown: function (keydownEvent) {
          setModelHasChanged(true);

          // @ts-expect-error
          if (propsEvents?.keydown) {
            // @ts-expect-error
            propsEvents.keydown(keydownEvent);
          }
        },
      },
      toolbarButtons: showToolbar
        ? {
            moreText: {
              buttons: [
                'bold',
                'italic',
                'underline',
                'formatOLSimple',
                'formatUL',
              ],
              buttonsVisible: 5,
            },
            moreParagraph: {
              buttons: [],
            },
            moreRich: {
              buttons: [
                'insertLink',
                'insertImage',
                'insertVideo',
                'insertFile',
                'insertTable',
              ],
              align: 'center',
              buttonsVisible: 5,
            },
          }
        : [],
      toolbarBottom: props.toolbarBottom,
      toolbarInline: props.toolbarInline,
      toolbarVisibleWithoutSelection: props.toolbarVisibleWithoutSelection,
      ...propsConfig,
      key: process.env.REACT_APP_FROALA_ACTIVATION_KEY,
    };
  }, [
    propsConfig,
    inForm,
    props.className,
    props.minRows,
    autoFocus,
    props.placeholder,
    showToolbar,
    props.toolbarBottom,
    props.toolbarInline,
    props.toolbarVisibleWithoutSelection,
    propsEvents,
    currentOrgId,
    accessToken,
    locale,
  ]);

  const onDiscardClicked = useCallback(() => {
    if (modelHasChanged) {
      // if changes made, display "Are you sure?"
      setConfirmDiscardModal(true);
    } else {
      resetTextEditor();
    }
  }, [modelHasChanged, resetTextEditor]);

  const confirmDiscard = useCallback(() => {
    setModel(propsValue);
    setConfirmDiscardModal(false);
    resetTextEditor();
  }, [propsValue, resetTextEditor]);

  const onResetClicked = useCallback(() => {
    setConfirmResetModal(true);
  }, []);

  const confirmReset = useCallback(() => {
    setModel(propsDefaultValue);
    setIsInEditMode(propsAutosave);
    setConfirmResetModal(false);
    setModelHasChanged(false);
  }, [propsAutosave, propsDefaultValue]);

  // This is for adapting Froala to run on react testing library.
  // https://github.com/froala/react-froala-wysiwyg/issues/341
  // Substitutes the Froala editor with a simple textarea.
  if (props.isTesting) {
    if (!isFroalaInitialized) {
      setIsFroalaInitialized(true);
      return null;
    }
    return (
      <TestRichTextEditor
        {...props}
        autoSave={propsAutosave}
        inForm={inForm}
        value={propsValue}
        defaultValue={propsDefaultValue}
        showToolbar={showToolbar}
        events={propsEvents}
        config={propsConfig}
        autoFocus={autoFocus}
        mentionSearchUrl={mentionSearchUrl}
        emptyStateTitle={emptyStateTitle}
        emptyStateSubtitle={emptyStateSubtitle}
        onModelChange={onModelChange}
        // @ts-expect-error
        onKeyDown={props.onKeyDown}
        // @ts-expect-error
        model={model}
      />
    );
  }

  // NOTE: we want to ensure the org id and access token are set before instantiating
  // TODO: need to explicitly reinitialized editor if config changes
  const editor =
    currentOrgId && accessToken ? (
      <FroalaEditorWithErrorBoundary
        ref={ref}
        // @ts-expect-error
        model={model}
        onModelChange={onModelChange}
        tag="textarea"
        config={{
          ...consts.FROALA_CONFIG_DEFAULTS,
          ...config,
        }}
      />
    ) : null;

  if (inForm) {
    return editor;
  }

  return (
    <>
      {/* @ts-expect-error */}
      <CardHeader className={inForm ? undefined : props.className}>
        <Row className="align-items-center">
          <Col>
            {props.title && <CardHeaderTitle>{props.title}</CardHeaderTitle>}
          </Col>
          {!propsAutosave && !isInEditMode && (
            <Col className="col-auto">
              <Button color="link" className="p-0" onClick={enableEditMode}>
                <span className={'me-2 fe fe-edit'}></span>
                <small>
                  <FormattedMessage
                    id="app.wigets.inputs.rich_text_editor.edit.button.text"
                    defaultMessage="Edit"
                  />
                </small>
              </Button>
            </Col>
          )}
          {!propsAutosave && isInEditMode && (
            <>
              <Prompt
                when={modelHasChanged}
                message={leavePromptMessage}
                // @ts-expect-error
                beforeUnload={true}
              />
              <Col className="col-auto px-0">
                <Button
                  color="link"
                  className="p-0 me-5"
                  onClick={onDiscardClicked}
                >
                  <small>
                    {modelHasChanged ? 'Discard changes' : 'Cancel'}
                  </small>
                </Button>
              </Col>
              <ConfirmationDialogModal
                isOpen={confirmDiscardModal}
                toggle={toggleConfirmDiscardModal}
                confirmCallback={confirmDiscard}
                title={formatMessage({
                  id: 'app.views.widgets.inputs.rich_text_editor.title.discard_changes',
                  defaultMessage: 'Discard changes?',
                })}
                description="Are you sure that you want to discard your changes?"
                confirmText={formatMessage({
                  id: 'app.views.widgets.inputs.rich_text_editor.confirm_text.discard_changes',
                  defaultMessage: 'Discard Changes',
                })}
              />
              {isSubmitting && <Loading />}
              {!isSubmitting && (
                <Col className="col-auto">
                  <Button color="primary" onClick={onSave}>
                    <FormattedMessage
                      id="app.wigets.inputs.rich_text_editor.save.button.text"
                      defaultMessage="Save"
                    />
                  </Button>
                </Col>
              )}
            </>
          )}
          {propsAutosave && (
            <Col className="col-auto">
              <span className="text-muted">{autoSaveStatus}</span>
            </Col>
          )}
          {propsDefaultValue && props.showResetToDefaultButton && (
            <Col className="col-auto">
              <ConfirmationDialogModal
                isOpen={confirmResetModal}
                toggle={toggleConfirmResetModal}
                confirmCallback={confirmReset}
                title={formatMessage({
                  id: 'app.views.widgets.inputs.rich_text_editor.title.reset_content_to_default',
                  defaultMessage: 'Reset content to default?',
                })}
                description={formatMessage({
                  id: 'app.views.widgets.inputs.rich_text_editor.description.reset_content_to_default',
                  defaultMessage:
                    'Are you sure that you want to reset this content to default?',
                })}
                confirmText={formatMessage({
                  id: 'app.views.widgets.inputs.rich_text_editor.confirm_text.reset_to_default',
                  defaultMessage: 'Reset to default',
                })}
              />
              <Button color="light" className="btn-sm" onClick={onResetClicked}>
                <FormattedMessage
                  id="app.wigets.inputs.rich_text_editor.reset_to_default.button.text"
                  defaultMessage="Reset to default"
                />
              </Button>
            </Col>
          )}
        </Row>
      </CardHeader>
      <CardBody>
        {/* @ts-expect-error */}
        {!isInEditMode && !(model?.length > 0) && (
          // @ts-expect-error
          <EmptyState title={emptyStateTitle} subtitle={emptyStateSubtitle}>
            <Button className="mb-4" color="primary" onClick={enableEditMode}>
              <span className={'me-3 fe fe-edit'}></span>
              <FormattedMessage
                id="app.wigets.inputs.rich_text_editor.add_description.button.text"
                defaultMessage="Add description"
              />
            </Button>
          </EmptyState>
        )}
        <Row>
          <Col>
            {isInEditMode && editor}
            {!isInEditMode && (
              // @ts-expect-error
              <RichTextViewer model={model} expanded={userClickedEdit} />
            )}
          </Col>
        </Row>
      </CardBody>
    </>
  );
};

const RichTextEditor_propTypes = {
  autoSave: PropTypes.bool,
  charCounterCount: PropTypes.bool,
  className: PropTypes.string,
  inForm: PropTypes.bool,
  saveId: PropTypes.string,
  value: PropTypes.string,
  // when value is blank, this is the default template to show
  defaultValue: PropTypes.string,
  // use object and name when inForm is false (saving/object handling will be done here in that case)
  name: PropTypes.string.isRequired,
  method: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  preSubmit: PropTypes.func,
  callback: PropTypes.func,
  onChange: PropTypes.func,
  onMention: PropTypes.func,
  onKeyDown: PropTypes.func,
  minRows: PropTypes.number,
  showToolbar: PropTypes.bool,
  toolbarBottom: PropTypes.bool,
  toolbarInline: PropTypes.bool,
  toolbarVisibleWithoutSelection: PropTypes.bool,
  config: PropTypes.object,
  events: PropTypes.object,
  autoFocus: PropTypes.bool,
  mentionSearchUrl: PropTypes.string,
  emptyStateTitle: PropTypes.string,
  emptyStateSubtitle: PropTypes.string,
  showResetToDefaultButton: PropTypes.bool,
  disableUnsavedChangesPrompt: PropTypes.bool,
  required: PropTypes.bool,
  onValidChange: PropTypes.func,
  disabled: PropTypes.bool,
  isTesting: PropTypes.bool,
  onInput: PropTypes.func,
};

type Props = PropTypes.InferProps<typeof RichTextEditor_propTypes> &
  Pick<ReduxState, 'currentProxyPerson' | 'isTesting'> & {
    currentOrganizationId?: number;
  };

// These props come from the application's
// state when it is started
const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson, isTesting } = state;

  return {
    currentOrganizationId: currentOrganization?.id,
    currentProxyPerson,
    isTesting,
  };
};

export default connect(mapStateToProps)(React.memo(RichTextEditor));
