import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Button } from 'reactstrap';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import { ICONS } from 'consts/consts';
import { createCSVFileName } from 'utils/util/util';

const HEADER_KEY_BOX_NAME = 'box_name';
const HEADER_KEY_FULL_NAME = 'full_name';
const HEADER_KEY_EMAIL = 'email';

const HEADERS = [
  { label: 'Full name', key: HEADER_KEY_FULL_NAME },
  { label: 'Email', key: HEADER_KEY_EMAIL },
  { label: 'Box name', key: HEADER_KEY_BOX_NAME },
];

const CSVDownload = ({ config, idToPerson }) => {
  const ref = useRef();
  const [requested, setRequested] = useState(false);
  const handleClick = useCallback(() => {
    setRequested(true);
  }, []);

  useEffect(() => {
    if (requested) {
      if (ref.current) {
        // @ts-expect-error
        ref.current.link.click();
      }
      setRequested(false);
    }
  }, [requested]);

  const data = useMemo(() => {
    if (!requested || !config || !idToPerson) {
      return [];
    }
    return config.quadrants.flatMap((cell) =>
      cell.people_ids
        .map((it) => idToPerson?.[it] ?? null)
        .filter((it) => !!it)
        .map((it) => ({
          [HEADER_KEY_EMAIL]: it.email,
          [HEADER_KEY_FULL_NAME]: it.full_name,
          [HEADER_KEY_BOX_NAME]: cell.title,
        }))
    );
  }, [config, idToPerson, requested]);

  return (
    <div
      className="text-end"
      style={{
        marginBottom: '-1.6rem',
        position: 'relative',
        zIndex: 1,
      }}
    >
      <Button
        color="light"
        className="btn btn-light btn-sm"
        onClick={handleClick}
      >
        <i className={ICONS.EXPORT + ' me-2'} />
        <FormattedMessage
          id="app.views.talent_matrix.button.export_to_csv"
          defaultMessage="Export"
        />
        {requested && (
          <CSVLink
            data={data}
            headers={HEADERS}
            filename={createCSVFileName('talent_matrix')}
            target="_blank"
            ref={ref}
          />
        )}
      </Button>
    </div>
  );
};

export default React.memo(CSVDownload);
