import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes, { InferProps } from 'prop-types';
import React, { FC, useMemo, useRef } from 'react';

import Loading from '../Widgets/Loading';
import PerformancePeersSelectorPage from './Peers/PerformancePeersSelectorPage';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { connect } from 'react-redux';
import { getAssignedPeers } from '../../utils/models/Performance';
import { setCurrentPerfSurveyResponse } from '../../actions';
import { withRouter } from 'react-router-dom';

const PerformanceStepAssessPeers: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const campaign = props.campaign;
  const setCampaign = props.setCampaign;
  const assessPeersLearnMoreRef = useRef();

  const undeclinedPeers = useMemo(
    // @ts-expect-error
    () => getAssignedPeers(props.me?.id, campaign, false),
    // @ts-expect-error
    [campaign, props.me?.id]
  );

  const declinedPeers = useMemo(
    // @ts-expect-error
    () => getAssignedPeers(props.me?.id, campaign, true),
    // @ts-expect-error
    [campaign, props.me?.id]
  );

  if (!undeclinedPeers) {
    return <Loading />;
  }

  return (
    <PerformancePeersSelectorPage
      campaign={campaign}
      setCampaign={setCampaign}
      title={formatMessage({
        id: 'app.views.performance.performance_step_assess_peers.title.provide_feedback_to_your_peers',
        defaultMessage: 'Provide feedback to your peers.',
      })}
      peers={undeclinedPeers}
      declinedPeers={declinedPeers}
      isOptional={false}
    >
      <></>
      <div className="mb-4">
        <FormattedMessage
          id="app.views.performance.performance_step_assess_peers.this_list_includes"
          defaultMessage="
        This peer list includes some people you work closely with and others you
        may not."
        />{' '}
        {/* @ts-expect-error */}
        <span className="text-primary" ref={assessPeersLearnMoreRef}>
          <FormattedMessage
            id="app.views.performance.performance_step_assess_peers.learn_more"
            defaultMessage="
          Learn more
        "
          />
        </span>
        <br />
        <UncontrolledPopover
          delay={50}
          trigger="hover"
          placement="top"
          // @ts-expect-error
          target={assessPeersLearnMoreRef}
        >
          <h4>
            <FormattedMessage
              id="app.views.performance.performance_step_assess_peers.how_are_selected"
              defaultMessage="How were these peers selected?"
            />
          </h4>
          <div>
            <FormattedMessage
              id="app.views.performance.performance_step_assess_peers.our_algorithm"
              defaultMessage="
            Our algorithm prioritizes manager peer requests, key stakeholders
            and activities in common (if applicable). Five is the maximum number
            of peer reviews you may be asked to write.
          "
            />
          </div>
          <h4 className="mt-4">
            <FormattedMessage
              id="app.views.performance.performance_step_assess_peers.some_do_not_match"
              defaultMessage="
            Some of my peers don't feel like great matches, so I'm
            concerned about the people who will write about me.
          "
            />
          </h4>
          <div>
            <FormattedMessage
              id="app.views.performance.performance_step_assess_peers.most_of_peer"
              defaultMessage="
            Most of your peer reviewers should generally be people who you have
            worked with over the past performance period. There may be peers who
            are not day-to-day colleagues, but receiving feedback from a diverse
            peer set can be valuable for your growth and development.
          "
            />
          </div>
          <h4 className="mt-4">
            <FormattedMessage
              id="app.views.performance.performance_step_assess_peers.should_decline"
              defaultMessage="Should I decline a peer?"
            />
          </h4>
          <div>
            <FormattedMessage
              id="app.views.performance.performance_step_assess_peers.not_need_to_know"
              defaultMessage="
            You don't need to know someone's work closely to give them
            feedback. Even a small amount of feedback is better than declining
            to give any.
          "
            />
          </div>
        </UncontrolledPopover>
        <span className="text-muted small">
          <FormattedMessage
            id="app.views.performance.performance_step_assess_peers.if_must_decline"
            defaultMessage="
          If you must decline a peer, we'll let their manager know. Your
          peer will not be notified.
        "
          />
        </span>
      </div>
    </PerformancePeersSelectorPage>
  );
};

const PerformanceStepAssessPeers_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = InferProps<typeof PerformanceStepAssessPeers_propTypes>;

const mapStateToProps = (state) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error
)(withRouter(React.memo(PerformanceStepAssessPeers)));
