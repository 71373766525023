import * as consts from '../../../../consts/consts';

import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import {
  ONA_BREAKDOWN,
  TeamDashboardPerson,
  getONACellListHeader,
  getONACellListHelperText,
} from './common';
import React, { Fragment, ReactNode, useRef } from 'react';

import { ICONS } from '../../../../consts/consts';
import { Link } from 'react-router-dom';
import { Relationship } from '../../../../types';
import ScrollableUncontrolledPopover from '../../ScrollableUncontrolledPopover';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import WordCloud from '../../WordCloud';
import { groupBy } from 'lodash';

type InfluenceCellProps = {
  person: TeamDashboardPerson;
};

type InfluenceListProps = {
  header: ReactNode;
  advised: Relationship[];
  energized: Relationship[];
  helperText: ReactNode;
};

const InfluenceList: React.FC<InfluenceListProps> = ({
  advised = [],
  energized = [],
  header,
  helperText = null,
}) => {
  const targetRef = useRef<HTMLSpanElement>(null);
  if (advised.length + energized.length < 1) {
    return <></>;
  }

  return (
    <div className="mb-0">
      <div className="mb-3 fw-bold">
        <i className={ICONS.INFLUENCE} />
        &nbsp;
        {advised.length + energized.length}
        &nbsp;
        {header}
        &nbsp;
        {helperText && (
          <span ref={targetRef}>
            <i className={ICONS.HELP} />
          </span>
        )}
      </div>
      {helperText && (
        <UncontrolledPopover placement="top" trigger="hover" target={targetRef}>
          {helperText}
        </UncontrolledPopover>
      )}
      <div className="fw-bold comment-body px-3 py-3 mb-3 w-100">
        {advised.length > 0 && (
          <div className={energized?.length > 0 ? 'mb-3' : ''}>
            <span>
              <FormattedMessage
                id="app.views.widgets.dashboards.team_dashboard.advises"
                defaultMessage="Advises"
                description="Precedes a list of names of who the person advises"
              />{' '}
            </span>
            <FormattedList
              value={advised.map((influence, index) => {
                const fromPerson = influence.from_person;
                return (
                  <Fragment key={`advised-from-${fromPerson.id}-${index}`}>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={fromPerson.url ?? ''}
                    >
                      {fromPerson.full_name}
                    </Link>
                  </Fragment>
                );
              })}
            />
          </div>
        )}
        {energized.length > 0 && (
          <div className="w-100">
            <span>
              <FormattedMessage
                id="app.views.widgets.dashboards.team_dashboard.energizes"
                description="Precedes a list of names of who the person energizes"
                defaultMessage="Energizes"
              />{' '}
            </span>
            <FormattedList
              value={energized.map((influence, index) => {
                const fromPerson = influence.from_person;
                return (
                  <Fragment key={`energized-from-${fromPerson.id}-${index}`}>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={fromPerson.url ?? ''}
                    >
                      {fromPerson.full_name}
                    </Link>
                  </Fragment>
                );
              })}
            />
          </div>
        )}
      </div>
      <hr></hr>
    </div>
  );
};

const InfluenceCell: React.FC<InfluenceCellProps> = ({ person }) => {
  const targetRef = useRef<HTMLSpanElement>(null);
  const intl = useIntl();

  const advised_by_type = groupBy(
    person.influence_advised ?? [],
    'ona_source_type'
  );

  const energized_by_type = groupBy(
    person.influence_energized ?? [],
    'ona_source_type'
  );

  const skills = person.influence_wordcloud_skills ?? [];

  return (
    <>
      <span ref={targetRef} className="influence-cell">
        <i className={ICONS.INFLUENCE} />
        &nbsp;
        {person.influence_count}
      </span>
      {person.influence_count > 0 && (
        <ScrollableUncontrolledPopover
          placement="left"
          trigger="hover"
          target={targetRef}
        >
          {ONA_BREAKDOWN.map((ona_source_type, index) => (
            <Fragment key={`influence-list-item-${index}`}>
              <InfluenceList
                advised={advised_by_type[ona_source_type]}
                energized={energized_by_type[ona_source_type]}
                header={getONACellListHeader(ona_source_type, intl)}
                helperText={getONACellListHelperText(ona_source_type)}
              />
            </Fragment>
          ))}
          {skills.length > 0 && (
            <>
              <h6 className="text-uppercase text-muted text-center mb-3">
                <FormattedMessage
                  id="app.views.widgets.dashboards.team_dashboard.mentioned_skills_and_behaviors"
                  defaultMessage="Mentioned skills and behaviors"
                />
              </h6>
              <WordCloud
                isExternalUrl={true}
                className="text-center mt-1"
                emptyText="No skills"
                objects={skills}
                pathPrefix={consts.SKILLS().path}
              />
            </>
          )}
        </ScrollableUncontrolledPopover>
      )}
    </>
  );
};

export default InfluenceCell;
