import React from 'react';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';

export const createName = (
  formatMessage,
  name,
  count,
  id,
  size = 'fs-5',
  limit = 50
) => {
  const showPopover = limit && name?.length > limit;
  const visibleName = truncateString(name, limit);

  return (
    <div>
      <div id={id} className={'fw-bold text-dark ' + size}>
        <RichTextViewer model={visibleName} expanded={false} />
      </div>
      {showPopover && (
        <UncontrolledPopover placement="top" trigger="hover click" target={id}>
          <RichTextViewer model={name} expanded={false} />
        </UncontrolledPopover>
      )}
      <div className="text-muted">
        {formatMessage(
          {
            id: 'app.views.engagement_survey.engagement_survey.responses_count',
            defaultMessage:
              '{count} {count, plural, one {response} other {responses}}',
          },
          { count }
        )}
      </div>
    </div>
  );
};

export const truncateString = (string, limit) => {
  // Note that this function may return a string that is 3 characters
  // longer than limit (due to '...')

  if (limit && string?.length > limit) {
    // To ensure we don't truncate the middle of a word
    const latestSpace = string.lastIndexOf(' ', limit);

    return string.slice(0, latestSpace) + '...';
  }
  return string;
};
