import { Button, Col, Container, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes, { InferProps } from 'prop-types';

import Avatar from '../Widgets/People/Avatar';
// @ts-expect-error
import { IntercomAPI } from '../../vendor/react-intercom';
import React from 'react';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { connect } from 'react-redux';

const WelcomePage: React.FC<Props> = (props: Props) => {
  const { formatMessage } = useIntl();
  const person = props.me;
  const toggleSupport = () => IntercomAPI('show');

  return (
    <Container className="container-lg container-fluid">
      <Row className="justify-content-center">
        <Col className="col-12 col-lg-10 col-xl-8">
          <div className="header mt-5 text-center">
            <div className="header-body">
              <Row className="align-items-center">
                <Col>
                  <div className="d-inline-block" id="welcome-avatar">
                    <Avatar
                      hidePopover={true}
                      // @ts-expect-error
                      person={person}
                      size="xl"
                      className="mb-4"
                    />
                  </div>
                  <UncontrolledPopover
                    placement="right"
                    trigger="hover"
                    target="welcome-avatar"
                  >
                    <FormattedMessage
                      id="app.views.welcome.welcome_page.popover.content"
                      defaultMessage="
                    This image of you was provided by <a>Gravatar</a>. If you would like to change it, <span>let us know</span>."
                      values={{
                        a: (chunks) => (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.gravatar.com/"
                          >
                            {chunks}
                          </a>
                        ),
                        span: (chunks) => (
                          <span
                            className="text-primary"
                            role="button"
                            onClick={toggleSupport}
                          >
                            {chunks}
                          </span>
                        ),
                      }}
                    />
                  </UncontrolledPopover>
                  <h6 className="header-pretitle"></h6>
                  <h1 className="header-title d-inline-block">{props.title}</h1>
                </Col>
              </Row>
            </div>
          </div>
          {props.children}
          {props.onClick && (
            <>
              <hr className="my-5" />
              <Row className="align-items-center justify-content-center">
                <Col className="text-center align-self-center col-12 col-md-3">
                  <Button
                    color="primary"
                    className="btn-lg w-100"
                    block={false}
                    onClick={props.onClick}
                  >
                    {props.nextButtonText ??
                      formatMessage({
                        id: 'app.views.welcome.welcome_page.button.continue',
                        defaultMessage: 'Continue',
                      })}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

type Props = InferProps<typeof WelcomePage_propTypes>;

const WelcomePage_propTypes = {
  me: PropTypes.object.isRequired,
  step: PropTypes.string,
  title: PropTypes.string,
  nextButtonText: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const mapStateToProps = (state) => {
  const { authUser, me } = state;

  return {
    authUser,
    me,
  };
};

export default connect(mapStateToProps)(React.memo(WelcomePage));
