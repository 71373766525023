import { Button, Col, ModalBody, ModalHeader, Row } from 'reactstrap';
import {
  ENGAGEMENT_SURVEY_OTHERS_EXPLANATION,
  ICONS,
} from '../../../../consts/consts';
import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import AccordionTable from '../../AccordionTable';
import { CATCHALL_GROUP_NAME } from './util';
import { CSVLink } from 'react-csv';
import Modal from '../../../../components/SafeModal';
import RichTextViewer from '../../Inputs/RichTextViewer';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { capitalize } from '../../../../utils/util/formatter';
import { createCSVFileName } from '../../../../utils/util/util';
import { createName } from '../../../EngagementSurvey/utils';

interface EnpsCommentsCardProps {
  currentSegment: string;
  filterDisplayName: string;
  rows: Array<{
    id: string;
    displayName: string;
    count: number;
    data: FormattedComments;
  }>;
}

export interface FormattedComments {
  [fieldId: string]: {
    [fieldValue: string]: {
      eligible: number;
      responses: string[];
    };
  };
}

const EnpsCommentsCard: FC<EnpsCommentsCardProps> = ({
  currentSegment = 'overall',
  rows,
  filterDisplayName,
}) => {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<{
    name: string;
    data: Record<string, object>;
  } | null>(null);

  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const setModal = useCallback(
    (data) => {
      setModalData(data);
      toggleModal();
    },
    [toggleModal]
  );

  const COLUMNS = [
    {
      name: formatMessage({
        id: 'app.views.widgets.cards.text_question_table_card.text_question',
        defaultMessage: 'Text Question',
      }),
      field: 'name',
      className: 'col-9',
    },
    {
      name: '',
      field: 'action',
      className: 'col-3 text-end',
    },
  ];

  const tableRows = useMemo(
    () =>
      rows?.map((q, i) => ({
        name: createName(
          formatMessage,
          q.displayName,
          q.count,
          'text-' + i,
          'fs-4',
          100
        ),
        action: (
          <>
            {q.count > 0 && (
              <Button
                title={formatMessage({
                  id: 'app.views.widgets.cards.text_question_table_card.title.view_responses',
                  defaultMessage: 'View responses',
                })}
                onClick={() => {
                  setModal({
                    name: q.displayName,
                    data: q.data,
                  });
                }}
                color="primary"
                className="btn fs-4"
              >
                <FormattedMessage
                  id="app.views.widgets.cards.text_question_table_card.view_responses"
                  defaultMessage="View responses"
                />
              </Button>
            )}
          </>
        ),
      })),
    [rows, setModal, formatMessage]
  );

  return (
    <>
      {showModal && modalData && (
        <EnpsCommentsModal
          filterDisplayName={filterDisplayName}
          currentSegment={currentSegment}
          name={modalData.name}
          data={modalData.data}
          isOpen={showModal}
          toggle={toggleModal}
        />
      )}
      <AccordionTable columns={COLUMNS} rows={tableRows} />
    </>
  );
};

export default React.memo(EnpsCommentsCard);

interface EnpsCommentsModalProps {
  filterDisplayName: string;
  currentSegment: string;
  name: string;
  data: Record<string, object>;
  isOpen: boolean;
  toggle: () => void;
}

const EnpsCommentsModal: FC<EnpsCommentsModalProps> = ({
  filterDisplayName,
  currentSegment,
  name,
  data,
  isOpen,
  toggle,
}) => {
  const { formatMessage } = useIntl();
  const [segment, setSegment] = useState<{ id: string; name: string } | null>(
    null
  );

  const segments = useMemo(
    () => Object.keys(data)?.map((s) => ({ id: s, name: s })) ?? [],
    [data]
  );

  const updateSegment = useCallback(
    (segmentName) => {
      const match = segments.find((s) => s.id === segmentName);
      setSegment(match || null);
    },
    [segments]
  );

  useEffect(() => {
    if (segment === null && segments.length > 0) {
      updateSegment(currentSegment);
    }
  }, [currentSegment, segment, segments, updateSegment]);

  const shouldShowField = currentSegment !== 'overall';

  const columns = useMemo(
    () => [
      ...(shouldShowField
        ? [
            {
              name: filterDisplayName || '',
              field: 'name',
              className: 'col-3',
            },
          ]
        : []),
      {
        name: formatMessage({
          id: 'app.views.widgets.cards.text_question_table_card.response',
          defaultMessage: 'Response',
        }),
        field: 'value',
        className: 'col-9',
      },
    ],
    [filterDisplayName, shouldShowField, formatMessage]
  );

  const rows = useMemo(() => {
    const selectedData = data[segment?.name || ''];
    const dataRows: Array<{
      name: ReactElement | string;
      rawName: string;
      value: string;
    }> = [];

    for (const field in selectedData) {
      const responses = selectedData[field]?.responses || [];

      responses.forEach((r: string, i: number) => {
        const id = 'text-modal-others-tooltip-' + i;
        const fieldName =
          field === CATCHALL_GROUP_NAME ? (
            <>
              <span id={id}>
                <FormattedMessage
                  id="app.views.widgets.cards.text_question_table_card.rows.others"
                  defaultMessage="Others"
                />
                <i
                  className={
                    ICONS.HELP + ' small ms-1 text-primary position-relative'
                  }
                />
              </span>
              <UncontrolledPopover
                placement="top"
                trigger="hover click"
                target={id}
              >
                {ENGAGEMENT_SURVEY_OTHERS_EXPLANATION(formatMessage)}
              </UncontrolledPopover>
            </>
          ) : (
            capitalize(field)
          );

        dataRows.push({
          name: fieldName,
          rawName: field,
          value: r,
        });
      });
    }

    dataRows.sort((a, b) => {
      if (a.rawName.localeCompare(b.rawName) === 0) {
        return b.value.length - a.value.length;
      } else {
        return a.rawName.localeCompare(b.rawName);
      }
    });

    return dataRows;
  }, [data, segment, formatMessage]);

  const createCsvData = () => {
    const headers = [
      ...(currentSegment !== 'overall' ? [filterDisplayName || ''] : []),
      formatMessage({
        id: 'app.views.widgets.dashboards.enps_comments_card.columns.comment.title',
        defaultMessage: 'Comment',
      }),
    ];

    const getGroupName = (name: string | object) =>
      typeof name === 'string' ? name.replace(/"/g, '""') : 'Other';

    const bodyRows = rows.map((r) => [
      ...(currentSegment !== 'overall' ? [getGroupName(r.name)] : []),
      r.value.replace(/"/g, '""'),
    ]);

    return [headers, ...bodyRows];
  };

  const exportButton = (
    <CSVLink
      className="btn btn-light btn-sm"
      filename={createCSVFileName(`enps_comments_by_${currentSegment}`)}
      data={createCsvData()}
    >
      <i className={ICONS.EXPORT + ' me-2'} />
      <FormattedMessage
        id="app.views.widgets.cards.enps_comments_card.csv_link.export_comments"
        defaultMessage="Export comments"
      />
    </CSVLink>
  );

  return (
    <Modal autoFocus={false} isOpen={isOpen} toggle={toggle} size={'xl'}>
      <ModalHeader toggle={toggle}>
        <Row>
          <Col className="col-9 align-self-center">
            <RichTextViewer model={name} expanded={false} />
          </Col>
          <Col className="col-3">
            <Row className="justify-content-end">
              <Col className="col-auto me-2">{exportButton}</Col>
            </Row>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <AccordionTable columns={columns} rows={rows} />
      </ModalBody>
    </Modal>
  );
};
