import * as consts from '../../consts/consts';

import {
  Button,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  ModalBody,
  Row,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Me, Person, Task } from '../../types';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  TASK_TYPE_CONTRIBUTION,
  TASK_TYPE_CUSTOM_PRIORITY,
  TASK_TYPE_FEEDBACK_REQUEST,
} from '../../utils/models/Task';
import {
  acceptTask,
  declineTask,
  ignoreContribution,
  loadTasks,
  setTasks,
} from '../../actions';

import Avatar from '../Widgets/People/Avatar';
import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import Modal from '../../components/SafeModal';
import ModalFeedbackDeclineEditor from '../Feedback/ModalFeedbackDeclineEditor';
import ModalFeedbackEditorButton from '../Feedback/ModalFeedbackEditorButton';
import TaskDescription from '../Widgets/TaskDescription';
import UncontrolledHoverDropdown from '../Widgets/Dropdowns/UncontrolledHoverDropdown';
import UncontrolledPopover from 'components/SafeUncontrolledPopover';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';

const MAX_TASKS = 3;

interface Props {
  acceptedTask: Task;
  acceptTask: (task: Task | null) => void;
  currentOrganization: object;
  currentProxyPerson?: Person;
  declinedTask: Task;
  declineTask: (task: Task | null) => void;
  ignoreContribution: (
    contribution: object,
    callback: (
      response: object,
      error: string | null,
      hardErrorMessage: string | null
    ) => void
  ) => void;
  loadTasks: (userSub?: string, email?: string) => Array<Task>;
  me: Me;
  meId: number;
  setTasks: (input?: Array<Task>) => void;
  tasks: Array<Task>;
}

const TasksNotificationsIndicator: FC<Props> = ({
  acceptedTask,
  acceptTask,
  currentOrganization,
  currentProxyPerson,
  declinedTask,
  declineTask,
  ignoreContribution,
  loadTasks,
  me,
  meId,
  setTasks,
  tasks,
}: Props) => {
  const { formatMessage } = useIntl();
  const { user } = useAuth0();
  const userSub = user?.sub;
  const modalHasBeenShown = useRef(false);
  const history = useHistory();
  const location = useLocation();

  const [acceptedFeedbackRequestTask, setAcceptedFeedbackRequestTask] =
    useState<Task | null>(null);
  const [declinedFeedbackRequestTask, setDeclinedFeedbackRequestTask] =
    useState<Task | null>(null);
  const [completedTask, setCompletedTask] = useState<Task | null>(null);
  const [activeTask, setActiveTask] = useState<Task | null>(null);
  const propsTasks = useMemo(() => tasks ?? [], [tasks]);
  const [tasksLength, setTasksLength] = useState<number>(propsTasks.length);
  const [showTasksListModal, setShowTasksListModal] = useState<boolean>(false);
  const toggleTasksListModal = () => setShowTasksListModal(!showTasksListModal);

  // when task is completed, remove it from the list
  const propsSetTasks = setTasks;
  useEffect(() => {
    if (completedTask) {
      propsSetTasks(propsTasks?.filter((t) => t !== completedTask));
      setCompletedTask(null);
    }
  }, [completedTask, propsTasks, propsSetTasks]);

  const acceptTaskObjectByType = useCallback(
    (task) => {
      const type = task.type;
      const object = task.object;

      if (type === TASK_TYPE_CUSTOM_PRIORITY.id) {
        history.push(object.url);
      } else if (type === TASK_TYPE_CONTRIBUTION.id) {
        // go to activity page with claim dialog open
        // NOTE: we don't call success callback as the claiming must be fully completed first
        history.push(
          consts.ACTIVITIES().path + '/' + object.activity.id + '#claim'
        );
      } else if (type === TASK_TYPE_FEEDBACK_REQUEST.id) {
        setAcceptedFeedbackRequestTask(task);
      } else {
        console.error('Unexpected accept type: ' + type);
      }
    },
    [history]
  );

  const propsIgnoreContribution = ignoreContribution;
  const declineTaskObjectByType = useCallback(
    (task) => {
      const type = task.type;
      const object = task.object;
      const successCallback = (data) => {
        if (task.callback) {
          task.callback(data);
        }
        setCompletedTask(task);
      };

      if (type === TASK_TYPE_CONTRIBUTION.id) {
        propsIgnoreContribution(
          object,
          (response, error, hardErrorMessage = null) => {
            if (error) {
              // failure; keep modal open
              if (hardErrorMessage) {
                // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
                // leave this message blank as those errors will get surfaced below
                toast.error(hardErrorMessage);
              } else {
                toast.error(error);
              }
              // don't trigger callback in error case
              return;
            } else if (response) {
              if (successCallback) {
                successCallback(response);
              }
            }
          }
        );
      } else if (type === TASK_TYPE_FEEDBACK_REQUEST.id) {
        setDeclinedFeedbackRequestTask(task);
      } else {
        console.error('Unexpected decline type: ' + type);
      }
    },
    [propsIgnoreContribution]
  );

  useEffect(() => {
    if (meId) {
      loadTasks(userSub, currentProxyPerson?.email);
    }
  }, [loadTasks, meId, userSub, currentProxyPerson?.email]);

  useEffect(() => {
    setTasksLength(propsTasks?.length);

    if (!modalHasBeenShown.current && propsTasks?.length > 0) {
      modalHasBeenShown.current = true;
      setShowTasksListModal(true);

      // check URL to see if specific task should pop up
      if (propsTasks?.length) {
        if (location.search) {
          const queryParams = new URLSearchParams(location.search);
          let taskId: string | null = null;
          let taskType: string | null = null;

          // NOTE: TASK_TYPE_CUSTOM_PRIORITY tasks should never show in a popup
          if (queryParams.has(TASK_TYPE_FEEDBACK_REQUEST.queryParam)) {
            taskId = queryParams.get(TASK_TYPE_FEEDBACK_REQUEST.queryParam);
            taskType = TASK_TYPE_FEEDBACK_REQUEST.id;
          } else if (queryParams.has(TASK_TYPE_CONTRIBUTION.queryParam)) {
            taskId = queryParams.get(TASK_TYPE_CONTRIBUTION.queryParam);
            taskType = TASK_TYPE_CONTRIBUTION.id;
          }

          if (taskId && taskType) {
            const matchedTasks = propsTasks?.filter(
              (t) =>
                t?.type === taskType && t?.object?.id?.toString() === taskId
            );
            if (matchedTasks?.length === 1) {
              setActiveTask(matchedTasks[0]);
              return;
            } else {
              // NOTE: We will (validly) get 0 matches if the request
              // has already been completed (ie. if someone reuses a URL)
              // in which case, we fall back to default behavior

              // If multiple matches are found, we alert the error and fall
              // back to default behavior of showing first task in queue
              if (matchedTasks?.length > 1) {
                console.error(
                  `Multiple matched tasks for ${taskType} ID ${taskId}: ${JSON.stringify(
                    matchedTasks
                  )}`
                );
              }
            }
          }
        }
      }
    }
  }, [propsTasks, location]);

  const propsAcceptTask = acceptTask;
  const propsDeclineTask = declineTask;
  useEffect(() => {
    // if an accepted task is passed in via Redux,
    // complete the accept action and then clear out
    // the accepted task
    if (acceptedTask) {
      setShowTasksListModal(false);
      acceptTaskObjectByType(acceptedTask);
      propsAcceptTask(null);
    }
  }, [propsAcceptTask, acceptedTask, acceptTaskObjectByType]);

  useEffect(() => {
    // do the same as accepted tasks above but for declines
    // NOTE: not all tasks are necessarily declinable
    if (declinedTask) {
      setShowTasksListModal(false);
      declineTaskObjectByType(declinedTask);
      propsDeclineTask(null);
    }
  }, [propsDeclineTask, declinedTask, declineTaskObjectByType, acceptedTask]);

  // when closing feedback modal, clear out accepted / declined task, respectively
  // that could have been passed in from outside this component
  const onCloseFeedbackModal = useCallback(() => {
    setAcceptedFeedbackRequestTask(null);
  }, []);

  const onCloseFeedbackDeclineModal = useCallback(() => {
    setDeclinedFeedbackRequestTask(null);
  }, []);

  const acceptedFeedbackOnSubmitCallback = useCallback(() => {
    setCompletedTask(acceptedFeedbackRequestTask);
  }, [acceptedFeedbackRequestTask]);

  // we should only show the modal if a specific task is in the url
  // (i.e. someone clicked from an email or notification to complete
  // a specific task, e.g. a feedback request)
  return (
    <>
      {currentOrganization && (
        <>
          <ModalFeedbackEditorButton
            toggle={() => setAcceptedFeedbackRequestTask(null)}
            isOpen={acceptedFeedbackRequestTask ? true : false}
            onClosed={onCloseFeedbackModal}
            hideButton={true}
            feedbackRequest={acceptedFeedbackRequestTask?.object}
            callback={acceptedFeedbackOnSubmitCallback}
          />
          <ModalFeedbackDeclineEditor
            toggle={() => setDeclinedFeedbackRequestTask(null)}
            isOpen={declinedFeedbackRequestTask ? true : false}
            onClosed={onCloseFeedbackDeclineModal}
            hideButton={true}
            feedbackRequest={declinedFeedbackRequestTask?.object}
            callback={() => setCompletedTask(declinedFeedbackRequestTask)}
          />
        </>
      )}
      {activeTask && !window.location.hash && (
        <Modal
          // prevent Esc from closing editor (to avoid issues e.g.
          // when someone escapes file dialog and presses twice)
          keyboard={false}
          // prevent hiding when clicking outside
          backdrop="static"
          isOpen={showTasksListModal}
          toggle={toggleTasksListModal}
        >
          <ModalBody className="text-center">
            <button
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label={formatMessage({
                id: 'app.views.layout.tasks_notifications_indicator.aria_label.close',
                defaultMessage: 'Close',
              })}
              onClick={() => setShowTasksListModal(false)}
            />
            <div className="mt-4 mb-3 text-muted">
              <FormattedMessage
                id="app.views.layout.tasks_notifications_indicator.active_task.before_you_continue"
                defaultMessage="Before you continue..."
                description=""
              />
            </div>
            <Avatar
              size="xl"
              className="mb-3"
              person={activeTask.sender ? activeTask.sender : me}
            />
            {activeTask.popupDescription}
            <Row className="mt-5 justify-content-center">
              {activeTask.acceptText && (
                <Col className="col-6">
                  <Button
                    id="task-modal-accept-button"
                    block
                    color="primary"
                    onClick={() => acceptTask(activeTask)}
                  >
                    {activeTask.acceptText}
                  </Button>
                  {activeTask.acceptPopoverContent && (
                    <UncontrolledPopover
                      placement="bottom"
                      trigger="hover"
                      target={'task-modal-accept-button'}
                    >
                      {activeTask.acceptPopoverContent}
                    </UncontrolledPopover>
                  )}
                </Col>
              )}
              {activeTask.declineText && (
                <Col className="col-6">
                  <Button
                    id="task-modal-decline-button"
                    block
                    color="light"
                    onClick={() => declineTask(activeTask)}
                  >
                    {activeTask.declineText}
                  </Button>
                  {activeTask.declinePopoverContent && (
                    <UncontrolledPopover
                      placement="bottom"
                      trigger="hover"
                      target={'task-modal-decline-button'}
                    >
                      {activeTask.declinePopoverContent}
                    </UncontrolledPopover>
                  )}
                </Col>
              )}
            </Row>
          </ModalBody>
        </Modal>
      )}
      {/* @ts-expect-error */}
      <UncontrolledHoverDropdown className="me-3 d-flex">
        <DropdownToggle tag="span" className="navbar-user-link">
          <div className="avatar avatar-sm">
            <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
              <i className="fe fe-bell"></i>
            </div>
          </div>
          {tasksLength > 0 && (
            <span
              className="badge bg-primary rounded-pill"
              style={{
                margin: '-8px 4px 0 0',
                position: 'absolute',
                bottom: 0,
                right: '-13px',
              }}
            >
              <strong>{tasksLength}</strong>
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu
          end
          style={{ minWidth: '300px', right: 0 }}
          className="dropdown-menu-card"
        >
          <CardHeader>
            <CardHeaderTitle>
              <FormattedMessage
                id="app.views.layout.tasks_notifications_indicator.tasks_dropdown.title"
                defaultMessage="Tasks"
                description="Title for the tasks dropdown"
              />
            </CardHeaderTitle>
            {tasksLength > MAX_TASKS ? (
              <Link
                className="text-primary small"
                to={consts.TASKS(formatMessage).path}
              >
                {tasksLength - MAX_TASKS > 1 ? (
                  <FormattedMessage
                    id="app.views.layout.tasks_notifications_indicator.view_more_tasks.one"
                    defaultMessage="View one more task"
                    description="Expand the viewable list to see the last task"
                  />
                ) : (
                  <FormattedMessage
                    id="app.views.layout.tasks_notifications_indicator.view_more_tasks.plural"
                    defaultMessage="View {count} more tasks"
                    values={{ count: tasksLength - MAX_TASKS }}
                    description="Expand the viewable list to see the last task"
                  />
                )}
              </Link>
            ) : (
              <Link
                className="text-primary small"
                to={consts.TASKS(formatMessage).path}
              >
                <FormattedMessage
                  id="app.views.layout.tasks_notifications_indicator.view_all_tasks"
                  defaultMessage="View all tasks"
                  description="Expand the viewable list to see all tasks"
                />
              </Link>
            )}
          </CardHeader>
          <CardBody>
            {tasksLength === 0 && (
              <div className="text-muted mb-4">
                <FormattedMessage
                  id="app.views.layout.tasks_notifications_indicator.no_tasks"
                  defaultMessage="You have no other pending tasks. Hooray!"
                  description="Happy message that there are no tasks left!"
                />
              </div>
            )}
            {tasksLength > 0 && (
              <ListGroup className="list-group-flush my-n3">
                {propsTasks?.slice(0, MAX_TASKS).map((task, taskIndex) => (
                  <ListGroupItem key={taskIndex} className="text-reset">
                    <Row className="pb-0">
                      <Col className="ps-2 col-auto">
                        <Avatar person={task.sender ? task.sender : me} />
                      </Col>
                      <Col className="ms-n3 me-n3">
                        <div className="mb-3 task-description">
                          <TaskDescription
                            className="small"
                            description={task.listDescription}
                          />
                        </div>
                        {task.acceptText && (
                          <Button
                            color="primary"
                            className="btn-sm"
                            onClick={() => acceptTask(task)}
                          >
                            {task.acceptText}
                          </Button>
                        )}
                      </Col>
                      {task.declineText && (
                        <Col className="col-auto">
                          {/* @ts-expect-error */}
                          <UncontrolledHoverDropdown>
                            <DropdownToggle
                              tag="div"
                              role="button"
                              className="dropdown-ellipses text-muted text-decoration-none"
                            >
                              <i className="fe fe-more-vertical"></i>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <DropdownItem onClick={() => declineTask(task)}>
                                {task.declineText}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledHoverDropdown>
                        </Col>
                      )}
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
            )}
          </CardBody>
        </DropdownMenu>
      </UncontrolledHoverDropdown>
    </>
  );
};

// These props come from the application's
// state when it is started
const mapStateToProps = (state) => {
  const {
    me,
    currentOrganization,
    currentProxyPerson,
    tasks,
    acceptedTask,
    declinedTask,
  } = state;

  return {
    me,
    meId: me?.id,
    currentOrganization,
    currentProxyPerson,
    tasks,
    acceptedTask,
    declinedTask,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTasks: (userSub, proxy) => dispatch(loadTasks(userSub, proxy)),
    acceptTask: (task) => dispatch(acceptTask(task)),
    declineTask: (task) => dispatch(declineTask(task)),
    setTasks: (tasks) => dispatch(setTasks(tasks)),
    ignoreContribution: (contribution, callback) =>
      dispatch(ignoreContribution(contribution, callback)),
  };
};

// all tracking in app will be passed through here
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(TasksNotificationsIndicator));
