import * as consts from '../../../consts/consts';

import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  GOAL_VISIBILITIES,
  MAX_SUGGESTED_SUPPORTERS,
  aspirationsAreEqual,
  getRecommendationSearchFilters,
} from '../../../utils/models/Goal';
import { INPUT_ATTRIBUTES, INPUT_TYPES } from '../Inputs/ValidatedInputTypes';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import ElasticsearchAPI from '../../../utils/api/ElasticsearchAPI';
import ModalEditor from './ModalEditor';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { getInvalidPeopleError } from '../../../utils/models/Person';
import { isEqual } from 'lodash';
import { prepTagsForSubmit } from '../../../utils/util/util';

const PROPS_ASPIRATION_DEFAUILT = {};

const ModalAspirationEditor: FC<Props> = ({
  aspiration: propsAspiration = PROPS_ASPIRATION_DEFAUILT,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [suggestedSupporters, setSuggestedSupporters] = useState(null);
  const [showDescriptionEditor, setShowDescriptionEditor] = useState(
    // @ts-expect-error
    propsAspiration?.description?.length > 0
  );
  const [recommendationSearchFilters, setRecommendationSearchFilters] =
    useState(getRecommendationSearchFilters(propsAspiration));
  const [aspiration, setAspiration] = useState(propsAspiration);

  const propsCallback = props.callback;
  const propsOnClosed = props.onClosed;

  // update aspiration when new one passed in
  useEffect(() => {
    setAspiration(propsAspiration);
  }, [propsAspiration]);

  // @ts-expect-error
  const currentOrgId = props.currentOrganization?.id;

  const onSuccess = useCallback(
    (aspiration) => {
      if (aspiration) {
        if (propsCallback) {
          propsCallback(aspiration);
        }
        if (propsOnClosed) {
          propsOnClosed();
        }
      }
    },
    [propsCallback, propsOnClosed]
  );

  // render inputs into three separate steps
  const renderInputs = useCallback(
    (inputs) => {
      return (
        <>
          <Row className="align-items-center">
            <Col>
              {inputs[0]}
              {inputs[1]}
              {inputs[2]}
              <Row className="justify-content-between">
                <Col className="col-auto">
                  {!showDescriptionEditor && (
                    <Button
                      color="link"
                      className="px-0 ps-md-2"
                      onClick={() => setShowDescriptionEditor(true)}
                    >
                      <i className="fe fe-edit" />
                      <FormattedMessage
                        id="app.views.widgets.modals.modal_aspiration_editor.add_description"
                        defaultMessage="Add a detailed description"
                      />
                    </Button>
                  )}
                </Col>
                <Col className="col-auto">{inputs[3]}</Col>
              </Row>
              {showDescriptionEditor && inputs[4]}
            </Col>
          </Row>
        </>
      );
    },
    [showDescriptionEditor]
  );

  // when tags are updated, update supporter recommendations
  useEffect(() => {
    const newRecommendationSearchFilters =
      getRecommendationSearchFilters(aspiration);

    if (!isEqual(newRecommendationSearchFilters, recommendationSearchFilters)) {
      setRecommendationSearchFilters(newRecommendationSearchFilters);
    }
  }, [aspiration, recommendationSearchFilters]);

  useEffect(() => {
    if (recommendationSearchFilters && currentOrgId) {
      const queryParams = {
        unwrap_source: 'true',
        filters: recommendationSearchFilters,
        size: MAX_SUGGESTED_SUPPORTERS,
        sort: [
          {
            status: {
              unmapped_type: 'keyword',
              order: 'asc',
            },
            'full_name.raw': {
              unmapped_type: 'keyword',
              order: 'asc',
            },
          },
        ],
      };

      ElasticsearchAPI.search(
        // only cache if no query provided (so we don't take up too much
        // memory story search results as searches are done)
        undefined,
        // @ts-expect-error
        props.currentProxyPerson,
        currentOrgId,
        'get-people-by-name',
        queryParams,
        (hitSources) => {
          setSuggestedSupporters(hitSources);
        },
        (message) => {
          console.error('Aspiration editor error: ' + JSON.stringify(message));
        }
      );
    } else {
      // @ts-expect-error
      setSuggestedSupporters([]);
    }
    // @ts-expect-error
  }, [currentOrgId, props.currentProxyPerson, recommendationSearchFilters]);

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      return {
        ...object,
        skills: prepTagsForSubmit(
          object.tags?.filter((o) => o._index === 'skills'),
          currentOrgId
        ),
        credentials: prepTagsForSubmit(
          object.tags?.filter((o) => o._index === 'credentials'),
          currentOrgId
        ),
        organization: currentOrgId,
        // don't pass to backend the placeholders used to generate the above
        tags: undefined,
        supporter_people: object.supporter_people?.map((p) => p.id),
      };
    },
    [currentOrgId]
  );

  const onChange = useCallback(
    (obj) => {
      const newAspiration = {
        ...obj,
        skills: obj.tags.filter((o) => o._index === 'skills'),
        credentials: obj.tags.filter((o) => o._index === 'credentials'),
        // strip intermediary fields
        tags: undefined,
      };

      // only change if an update occurred
      if (!aspirationsAreEqual(newAspiration, aspiration)) {
        setAspiration(newAspiration);
      }
    },
    [aspiration]
  );

  const object = useMemo(
    () => ({
      ...aspiration,
      tags: [
        // @ts-expect-error
        ...(aspiration?.skills
          ? // @ts-expect-error
            aspiration.skills.map((o) => ({ ...o, _index: 'skills' }))
          : []),
        // @ts-expect-error
        ...(aspiration?.credentials
          ? // @ts-expect-error
            aspiration.credentials.map((o) => ({
              ...o,
              _index: 'credentials',
            }))
          : []),
      ],
      goals: undefined,
    }),
    [aspiration]
  );

  // @ts-expect-error
  const validDomains = props.currentOrganization?.email_domains;

  const onValidate = useCallback(
    (obj) => {
      const errors = {};

      // allow no invalid supporters to be passed in
      errors['supporter_people'] =
        obj.supporter_people?.length > 0
          ? getInvalidPeopleError(
              formatMessage,
              obj.supporter_people,
              validDomains
            )
          : null;
      return errors;
    },
    [validDomains, formatMessage]
  );

  return (
    <ModalEditor
      url="aspirations"
      title={props.title}
      submitText={formatMessage({
        id: 'app.views.widgets.modals.modal_aspiration_editor.submit_text.save',
        defaultMessage: 'Save',
      })}
      object={object}
      onChange={onChange}
      onValidate={onValidate}
      transformObjectBeforeSubmit={transformObjectBeforeSubmit}
      renderInputs={renderInputs}
      inputs={[
        {
          required: true,
          name: 'name',
          label: formatMessage({
            id: 'app.views.widgets.modals.modal_aspiration_editor.name.label',
            defaultMessage: 'Aspiration name',
          }),
          helperText: formatMessage({
            id: 'app.views.widgets.modals.modal_aspiration_editor.name.helper_text',
            defaultMessage: "In a few words, what's your aspiration?",
          }),
          autoFocus: true,
          maxLength: consts.ASPIRATION_NAME_MAX_LENGTH,
        },
        {
          ...INPUT_ATTRIBUTES(formatMessage).SKILLS_AND_CREDENTIALS,
          name: 'tags',
          label: formatMessage({
            id: 'app.views.widgets.modals.modal_aspiration_editor.tags.label',
            defaultMessage:
              'What skills, behaviors, and credentials will help you achieve this?',
          }),
          helperText: formatMessage({
            id: 'app.views.widgets.modals.modal_aspiration_editor.tags.helper_text',
            defaultMessage: 'Providing this will enable resources to help you.',
          }),
        },
        {
          type: INPUT_TYPES.PEOPLE_EDITOR,
          name: 'supporter_people',
          label: formatMessage({
            id: 'app.views.widgets.modals.modal_aspiration_editor.supporter_people.label',
            defaultMessage: 'Who can support you?',
          }),
          helperText: formatMessage({
            id: 'app.views.widgets.modals.modal_aspiration_editor.supporter_people.helper_text',
            defaultMessage: 'We will not contact anyone you mention.',
          }),
          suggestions: suggestedSupporters,
          maxSuggestions: MAX_SUGGESTED_SUPPORTERS,
        },
        {
          name: 'visibility',
          type: INPUT_TYPES.TOGGLE,
          objects: GOAL_VISIBILITIES(formatMessage),
          defaultValue: GOAL_VISIBILITIES(formatMessage)[0].id,
          showName: true,
        },
        {
          type: INPUT_TYPES.RICH_TEXT_EDITOR,
          name: 'description',
          label: formatMessage({
            id: 'app.views.widgets.modals.modal_aspiration_editor.description.label',
            defaultMessage: 'Description',
          }),
          helperText: formatMessage({
            id: 'app.views.widgets.modals.modal_aspiration_editor.description.helper_text',
            defaultMessage: 'Long term action plan, vision, or other details',
          }),
          autoFocus: true,
          minRows: 4,
          config: {
            charCounterMax: consts.ASPIRATION_DESCRIPTION_MAX_LENGTH,
          },
        },
      ]}
      callback={onSuccess}
      anchorTrigger="#add-aspiration"
      isOpen={props.isOpen}
      toggle={props.toggle}
      onClosed={props.onClosed}
    />
  );
};

const ModalAspirationEditor_propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onClosed: PropTypes.func,
  callback: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  aspiration: PropTypes.object,
};

type Props = PropTypes.InferProps<typeof ModalAspirationEditor_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(ModalAspirationEditor));
